// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IPlaybackEngineCreator } from '@wbd/player-sdk-core';
import type { IEngineCreatorConfig } from './IEngineCreatorConfig';
import { IScrubberOptions } from './IScrubberOptions';
import {
  defaultMaxResolution,
  defaultNetworkRequestRetry,
  defaultScrubberOptions,
  defaultSupportedAudioCodecs,
  defaultSupportedVideoCodecs,
  defaultVideoGCInterval
} from './utils';

/**
 * video implementation interface
 * @public
 */
export class AbstractVideoCapabilities {
  // prohibit class initialization as we only want to use te static functions
  protected constructor() {}

  /**
   * Returns the appropriate video engine for the targeted platform
   * @param config - video engine initialization configuration
   * @returns GluonPlaybackEngineCreator
   */
  public static async getPlayerEngineCreatorAsync(
    config?: IEngineCreatorConfig
  ): Promise<IPlaybackEngineCreator> {
    const { GluonPlaybackEngineCreator } = await import(
      /*webpackChunkName:"player-sdk-engine-gluon"*/ '@wbd/player-sdk-gluon-engine'
    );
    return new GluonPlaybackEngineCreator(config);
  }

  /**
   * Returns the engine creator config for the target platform
   * @returns IEngineCreatorConfig
   */
  public static async getPlayerEngineConfigAsync(): Promise<IEngineCreatorConfig> {
    return Promise.resolve({
      resolutionOverrides: {
        maxResolution: defaultMaxResolution
      },
      supportedVideoCodecs: defaultSupportedVideoCodecs,
      supportedAudioCodecs: defaultSupportedAudioCodecs,
      networkRequestRetry: defaultNetworkRequestRetry
    });
  }

  /**
   * returns override default engine config with inline playback specific options
   * @returns Promise<IEngineCreatorConfig>
   */
  public static async getInlinePlayerEngineConfigAsync(): Promise<IEngineCreatorConfig> {
    const defaultEngineConfig = await this.getPlayerEngineConfigAsync();
    const bufferOptions = defaultEngineConfig.bufferOptions ?? {};
    return {
      ...defaultEngineConfig,
      bufferOptions: {
        ...bufferOptions,
        noAudio: true
      }
    };
  }

  /**
   * Determine if device supports DOM rendering for subtitles
   * @returns boolean
   */
  public static supportsDomCueRendering(): boolean {
    return false;
  }

  /**
   * Determine if device supports HTMLVideo transformations
   * e.g. as used by the end card component in video player page
   * @returns boolean
   */
  public static async supportsHTMLVideoTransformations(): Promise<boolean> {
    return true;
  }

  /**
   * Returns default Player Scrubber options.
   * Each of the device platforms then can override those options
   * and pass specific tweaks so that we have unique scrubber experience
   * @returns
   */
  public static async getScrubberOptions(): Promise<IScrubberOptions> {
    return Promise.resolve(defaultScrubberOptions);
  }

  /**
   * Determine if device supports trick play scrubbing
   * e.g when user is scrubbing the video player there is
   * thumbnail preview of the video frame
   * @returns boolean
   */
  public static async supportsTrickPlayScrubbing(): Promise<boolean> {
    return Promise.resolve(true);
  }

  /**
   * Returns the default video GC interval
   * that is used on the Video Player page
   * to manually trigger GC for memory cleanup
   * @returns number
   */
  public static getVideoGCInterval(): number {
    return defaultVideoGCInterval;
  }
}

/**
 * video implementation interface
 * @public
 */
export type IVideoCapabilities = Omit<typeof AbstractVideoCapabilities, 'prototype'>;
