// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { Configuration } from '../../core';
import { IRequestMeasureV1Payload } from '../../generated';
import { NetworkMonitor } from './NetworkMonitor';

/**
 * The Request monitoring namespace that allows access to Measure apis
 * @public
 */
/* eslint-disable @typescript-eslint/no-namespace */
export namespace RequestMonitor {
  /** instance of the request measure monitor that is defined on initialization */
  export let measureInstance: NetworkMonitor;

  /** initializes the request measure monitor and connects the public apis to the private apis */
  export function initialize(config: Configuration): void {
    measureInstance = new NetworkMonitor(config.telegraphUri, config.httpClient);
  }

  export function update(config: Configuration): void {
    measureInstance.update(config.telegraphUri, config.httpClient);
  }

  export namespace Measure {
    /** creates measures from two marks */
    export function createMeasure(measure: IRequestMeasureV1Payload): void {
      return measureInstance.createMeasure(measure);
    }
  }
}
