// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { Consent, IConsentOption } from '../data-models';
import { BoltDataModelCollection, Transformer } from '../data-transformer';
import { HttpClient, RequestConfigOverride } from '../http-client';
import { DataTypes, ITypedJsonApiDocumentFromModel } from '../json-api';

/**
 * Both the initial consent to a term and any updates to the same consent is created using a POST request.
 * By creating a new consent any previous consent to the same term will be revoked.
 *
 * @param termId - the term identifier to give a consent for
 * @param consentOptions - the consent specific options
 * @public
 *
 * @see https://docs.disco-api.com/apidocs/#update-user-consents
 */
export const updateConsentRequest = async (
  termId: string,
  consentOptions: IConsentOption[],
  config?: RequestConfigOverride
): Promise<Consent> => {
  const payload = {
    data: {
      type: DataTypes.Consent,
      attributes: {
        consentOptions: consentOptions
      },
      relationships: {
        term: {
          data: {
            id: termId,
            type: DataTypes.Term
          }
        }
      }
    }
  } as ITypedJsonApiDocumentFromModel<DataTypes.Consent, Consent>;
  const http = HttpClient.instance;
  const response = await http.post<ITypedJsonApiDocumentFromModel<DataTypes.Consent, Consent>>(
    '/legal/consents',
    payload,
    config
  );

  const store = new Transformer(BoltDataModelCollection, response.data);
  const [consent] = store.findAll(DataTypes.Consent);
  return consent;
};
