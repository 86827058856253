// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * The season attributes.
 * @see https://docs.disco-api.com/apidocs/#seasons
 * @public
 */
export interface ISeasonAttributes {
  /** Available audiotracks for this season. An array of language codes (two letter ISO 639) in lowercase. */
  audioTracks?: string[];
  /** Season description. */
  description?: string;
  /** The string to use to represent this season in the UI. */
  displayName?: string;
  /** The end date of the season. Format: YYYY-MM-DD Example: 2016-06-30 */
  endDate?: string;
  /** The number of videos of type Episode that have this season as a parent. */
  episodeCount?: number;
  /** Identifier of the season */
  id: string;
  /** Season display name. */
  name?: string;
  /** The number of planned episodes for this season. May be larger than the episodeCount. */
  plannedEpisodeCount?: number;
  /** Season number. */
  seasonNumber?: number;
  /** The start date of the season. Format: YYYY-MM-DD Example: 2016-01-01 */
  startDate?: string;
  /** Available subtitles for this season. An array of language codes (two letter ISO 639) in lowercase. */
  subtitles?: string[];
  /** The number of videos that have this season as a parent. */
  videoCount?: number;
}

/**
 * @public
 * The show BoltModel combining the attributes and relationships
 */
export class Season extends TypedModel.generate<DataTypes.Season, ISeasonAttributes>() {}
