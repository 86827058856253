// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * A holder for static methods for working with generic objects.
 *
 * @public
 */
export class ObjectUtils {
  /**
   * Given an array of items and a partitioning function, return an array of the partition
   * hits and misses.
   * @param items - An array to be partitioned by the result of the given filter
   * @param filter - A function by which we partition items
   */
  public static partition<T>(items: T[], filter: (element: T) => boolean): [T[], T[]] {
    const hits: T[] = [];
    const misses: T[] = [];

    items.forEach((item) => (filter(item) ? hits : misses).push(item));

    return [hits, misses];
  }

  /**
   * Partitions items from a list into those which satisfy a type guard, and those which do
   * not satisfy that type guard. The returned lists are strongly typed according to the
   * type guard's return type.
   *
   * @param items - the list of items to be partitioned by type
   * @param isGuarded - a type guard function which all elements in the first returned list will
   * satisfy, and which none of the items in the second list will satisfy
   */
  public static partitionByType<TParent, TGuarded extends TParent>(
    items: TParent[],
    isGuarded: (item: TParent) => item is TGuarded
  ): [TGuarded[], Exclude<TParent, TGuarded>[]] {
    const guarded: TGuarded[] = [];
    const other: Exclude<TParent, TGuarded>[] = [];
    items.forEach((item) =>
      isGuarded(item) ? guarded.push(item) : other.push(item as Exclude<TParent, TGuarded>)
    );
    return [guarded, other];
  }

  /**
   * A type guard function that narrows the type of the given object based on whether the given `propertyName`
   * is the name of one of its own properties.
   *
   * This function just allows TypeScript to use the result of `hasOwnProperty()` for type narrowing.
   *
   * @param obj - some object
   * @param propertyName - the name of the property whose existence is being verified
   */
  public static hasOwnProperty<TPropertyKey extends PropertyKey>(
    obj: object,
    propertyName: TPropertyKey
  ): obj is Record<TPropertyKey, unknown> {
    return obj.hasOwnProperty(propertyName);
  }

  /**
   * Allows type-safe filtering of `(TElement | undefined)[]` into `TElement[]`.
   *
   * @param element - an object which may be of type `TElement` or `undefined`
   */
  public static isDefined<TElement>(element: TElement | undefined): element is TElement {
    return element !== undefined;
  }

  /**
   * Type guard that verifies that a given `PropertyKey` is a valid key for the given object.
   *
   * Following this type guard, `obj[propertyKey]` will be accepted by the compiler.
   *
   * @param obj - the object that we are checking against
   * @param propertyKey - the property name that we are checking against the object
   */
  public static isKeyOf<T extends {}>(obj: T, propertyKey: PropertyKey): propertyKey is keyof T {
    return Object.prototype.hasOwnProperty.call(obj, propertyKey);
  }
}
