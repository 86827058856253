// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @public
 */
export interface ITermOptionAttributes {
  /** Term option alias. */
  alias: string;
  /** Term option text description. */
  text?: string;
}

/**
 * @public
 */
export class TermOption extends TypedModel.generate<DataTypes.TermOption, ITermOptionAttributes>() {}
