// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#config
 * @public
 */
export interface IConfigAttributes {
  /** config name */
  name: string;
  /** config alias */
  alias: string;
  /** config data */
  config: Record<string, unknown>;
}

/**
 * @public
 */
export class Config extends TypedModel.generate<DataTypes.Config, IConfigAttributes>() {}
