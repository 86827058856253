// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { loadAllFonts } from '@wbd/beam-ctv-shared-ui/lib/assets'; // only fonts
import type { IUiLaunchV1Err } from '@wbd/instrumentation-sdk';
import { UiLaunchV1Classification, UiLaunchV1Scope } from '@wbd/instrumentation-sdk';
import type { IInitialContext } from '../application';
interface ILoadUIResult {
  start: (context: IInitialContext) => Promise<void>;
  startWithError: (context: Partial<IInitialContext>) => Promise<void>;
}

/**
 * Load the Lightning UI layer of the application
 */
export async function loadUI(): Promise<ILoadUIResult> {
  const [loadFontsResult, startResult] = await Promise.allSettled([
    loadAllFonts(),
    import(/* webpackChunkName: "start" */ '../application')
  ]);

  if (loadFontsResult.status === 'rejected') {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    await import('@wbd/instrumentation-sdk').then(({ InstrumentationSDK, generateV1Err }) => {
      InstrumentationSDK.Errors.Ui.Launch.v1.capture({
        err: generateV1Err<IUiLaunchV1Err>(loadFontsResult.reason, {
          classification: UiLaunchV1Classification.DEPENDENCY_FAILURE,
          scope: UiLaunchV1Scope.RECOVERABLE
        })
      });
    });
  }

  if (startResult.status === 'rejected') {
    throw startResult.reason;
  }

  return startResult.value;
}
