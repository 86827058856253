// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#user-entitlements-summary
 * @public
 */
export interface IUserEntitlementsSummaryAttributes {
  /** Array of all ad-strategies associated with the current user.
   *
   * Intended for information purposes only. Should not be used by clients for building
   * logic around displaying ads to the user. */
  adStrategies?: string[];
  /** Capabilities acquired by the user which are currently active on their account. */
  hasActiveAcquiredCapabilities: boolean;
  /** Capabilities previously acquired by the which have now expired. */
  hasExpiredAcquiredCapabilities: boolean;
  /** Whether the user is allowed to view content offline. */
  hasOfflineViewing: boolean;
}

/**
 * @public
 */
export class UserEntitlementsSummary extends TypedModel.generate<
  DataTypes.UserEntitlementsSummary,
  IUserEntitlementsSummaryAttributes
>() {}
