// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { RelativeTimeFormatterOptions } from 'globalize';

/**
 * Defines the options that Globalize accepts for formatting relative times.
 *
 * @public
 */
export interface IGlobalizeRelativeTimeFormatInfo {
  /**
   * The unit to be formatted (e.g. "day").
   *
   * According to http://www.unicode.org/reports/tr35/tr35-dates.html#Calendar_Fields, the CLDR data for English
   * supports "year", "quarter", "month", "week", "day", specific days of the week ("mon", "tue", "wed", "thu",
   * "fri", "sat", and "sun"), and "hour", "minute", and "second". Not all regions will define all of these, so
   * it's probably best to stick with "year", "month", "day", "hour", and "minute", which will probably suffice
   * for our purposes.
   *
   * Note that different languages may provide special translations for small numbers of, e.g., relative days.
   * For example, in English, -1 ("yesterday"), 0 ("today"), and 1 ("tomorrow") get special translations.
   * In German, -2 ("Vorgestern"), -1 ("Gestern"), 0 ("Heute"), 1 ("Morgen"), and 2 ("Übermorgen") get special
   * translations.
   */
  unit: string;

  /** The Globalize relative time formatter options. */
  options?: RelativeTimeFormatterOptions;
}

/**
 * The standard relative time formats supported by this library. This is a union across the formats required by all projects
 * that use this library. If your project requires a format that is not yet defined, feel free to add it.
 *
 * Some useful resources:
 *
 * @public
 */
export enum RelativeTimeFormat {
  /**
   * A relative time expressed as a number of days, in long form.
   */
  DAY = 'DAY',

  /**
   * A relative time expressed as a number of days, in short form.
   */
  DAY_SHORT = 'DAY_SHORT',

  /**
   * A relative time expressed as a number of hours, in short form.
   */
  HOUR_SHORT = 'HOUR_SHORT',

  /**
   * A relative time expressed as a number of minutes, in short form.
   */
  MINUTE_SHORT = 'MINUTE_SHORT'
}

/**
 * A mapping from `RelativeTimeFormats` enum values to the corresponding Globalize relative time format options objects.
 *
 * @public
 */
export const RELATIVE_TIME_FORMATS_MAP: Map<RelativeTimeFormat, IGlobalizeRelativeTimeFormatInfo> = new Map<
  RelativeTimeFormat,
  IGlobalizeRelativeTimeFormatInfo
>([
  [RelativeTimeFormat.DAY, { unit: 'day' }],
  [RelativeTimeFormat.DAY_SHORT, { unit: 'day', options: { form: 'short' } }],
  [RelativeTimeFormat.HOUR_SHORT, { unit: 'hour', options: { form: 'short' } }],
  [RelativeTimeFormat.MINUTE_SHORT, { unit: 'minute', options: { form: 'short' } }]
]);
