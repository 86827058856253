// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { ILegalRegion } from '../data-models';
import { HttpClient, RequestConfigOverride } from '../http-client';

/**
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getRegionRequest = async (config?: RequestConfigOverride): Promise<ILegalRegion> => {
  const http = HttpClient.instance;
  const response = await http.get<ILegalRegion>('/legal/region', config);
  return response.data;
};
