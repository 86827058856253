// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { CanonicalizedCldrLocale } from '../generated';
import { getCanonicalizedCldrLocales } from '../getters';

/**
 * Type guard function for CanonicalizedCldrLocale values.
 *
 * @param tag - a possible CanonicalizedCldrLocale
 *
 * @public
 */
export function isCanonicalizedCldrLocale(tag: string): tag is CanonicalizedCldrLocale {
  return getCanonicalizedCldrLocales().has(tag as CanonicalizedCldrLocale);
}
