// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';
import type { ArticleBodyRichText } from './ArticleBodyRichText';
import { Consent } from './Consent';
import type { TermKind } from './TermKind';
import type { TermOption } from './TermOption';

/**
 * @see https://docs.disco-api.com/apidocs/#user-terms
 * @public
 */
export interface ITermAttributes {
  /** ISO language code */
  lang?: string;
  /** Version of the term as specified by editors */
  version?: number;
  /** Date this version was published */
  publishDate?: string;
  /** Date from which this term is active */
  activeFrom?: string;
  /** Channel description. */
  mandatory?: boolean;
  /** Whether the user consented using continue button */
  autoConsent: boolean;
  /** Indicates the user have to approve to move forward */
  reqConsent?: boolean;
}

/**
 * @see https://docs.disco-api.com/apidocs/#user-terms
 * @public
 */
export interface ITermRelationships {
  body?: ArticleBodyRichText;
  kind?: TermKind;
  consent?: Consent;
  options: TermOption[];
}

/**
 * @see https://docs.disco-api.com/apidocs/#user-terms
 * @public
 */
export class Term extends TypedModel.generate<DataTypes.Term, ITermAttributes, ITermRelationships>() {}
