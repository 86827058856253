// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  CURRENCY_FORMATS_MAP,
  CurrencyFormat,
  DATE_FORMATS_MAP,
  DateFormat,
  DurationFormat,
  IGlobalizeCurrencyFormatInfo,
  IGlobalizeDateFormatInfo,
  IGlobalizeNumberFormatInfo,
  IGlobalizeRelativeTimeFormatInfo,
  IGlobalizeUnitFormatInfo,
  NUMBER_FORMATS_MAP,
  NumberFormat,
  PhoneNumberFormat,
  RELATIVE_TIME_FORMATS_MAP,
  RelativeTimeFormat,
  UNIT_FORMATS_MAP,
  UnitFormat
} from '../formats';
import {
  CldrLocale,
  FormatCode,
  getCldrLocaleForCanonicalizedCldrLocale,
  isCanonicalizedCldrLocale
} from '@wbd/localization-core';
import { GlobalizeInstance, GlobalizeStatic } from '../globalize';

import { ICurrencyValue } from '../i18n-types';
import { IFormatter } from './IFormatter';
import { RelativeTimeFormatterOptions } from 'globalize';

/**
 * Provides runtime formatting of data to a target format code.
 *
 * Does not provide access to translated strings, formatting of string templates, or interpolation of variables
 * into strings. For that, please see `Translator.ts`.
 * @public
 */
export class Formatter implements IFormatter {
  private readonly _formatCode: FormatCode;
  private readonly _globalizeInstance: GlobalizeInstance;

  /**
   * Create a `Formatter` instance which formats data according to the formatting rules associated with
   * `formatCode`.
   * @param formatCode - the format code according to whose rules this `Formatter` will format data. The
   * `formatCode` can be either a region `FormatCode` or a `LanguageBasedFormatCode`, which means that the resulting
   * `Formatter` is exposing an interface that may fail at runtime if a `LanguageBasedFormatCode` is used to format
   * something unsupported by language formats, like currency.
   * @param globalize - a compiled Globalize object with formatting functions applicable to the requested
   * format code
   */
  public constructor(formatCode: FormatCode, globalizeStatic: GlobalizeStatic) {
    if (!isCanonicalizedCldrLocale(formatCode)) {
      throw new Error(`Expected format code "${formatCode}" to be a valid 'CanonicalizedCldrLocale'`);
    }

    this._formatCode = formatCode;

    const cldrLocale: CldrLocale = getCldrLocaleForCanonicalizedCldrLocale(formatCode);
    this._globalizeInstance = globalizeStatic(cldrLocale);
  }

  /**
   * Returns the format code for this `Formatter`.
   */
  public get formatCode(): FormatCode {
    return this._formatCode;
  }

  /**
   * Format a currency value in the general pattern described by `currencyFormat`, according to the rules specified
   * by the format code for that general pattern.
   *
   * @param currencyValue - the currency value to be formatted
   * @param currencyFormat - the general way in which the currency value should be formatted; Globalizer will provide
   * the specifics corresponding to the format code
   */
  public formatAsCurrency(currencyValue: ICurrencyValue, currencyFormat: CurrencyFormat): string {
    const currencyFormatInfo: IGlobalizeCurrencyFormatInfo = CURRENCY_FORMATS_MAP.get(currencyFormat)!;
    try {
      return this._globalizeInstance.formatCurrency(
        currencyValue.currencyAmount,
        currencyValue.currencyCode,
        currencyFormatInfo.options
      );
    } catch (error) {
      if (error.message === 'this.currencyFormatter(...) is not a function') {
        throw new Error(`Currency ${currencyValue.currencyCode} is not supported in ${this._formatCode}`);
      } else {
        throw error;
      }
    }
  }

  /**
   * Format a date in the general pattern described by `dateFormat`, according to the rules specified
   * by the format code for that general pattern.
   *
   * @param date - the date value to be formatted
   * @param dateFormat - the general way in which the date should be formatted; Globalizer will provide
   * the specifics corresponding to the format code
   */
  public formatAsDate(date: Date, dateFormat: DateFormat): string {
    try {
      const dateFormatInfo: IGlobalizeDateFormatInfo = DATE_FORMATS_MAP.get(dateFormat)!;
      return this._globalizeInstance.formatDate(date, dateFormatInfo.options);
    } catch (e) {
      throw new Error(
        `Error formatting "${date.toUTCString()}" as "${dateFormat}" for "${this._formatCode}": ${
          e.message
        } ${e.stack}`
      );
    }
  }

  /**
   * Format a duration in the general pattern described by `durationFormat`, according to the rules specified
   * by the format code for that general pattern.
   *
   * @param duration - the duration value in seconds to be formatted
   * @param durationFormat - the general way in which the duration should be formatted; Globalizer will provide
   * the specifics corresponding to the format code
   * @param unitFormat - the general way in which the hours and minutes should be formatted; Globalizer will provide
   * the specifics corresponding to the format code
   */
  public formatAsDuration(
    duration: number,
    durationFormat: DurationFormat,
    unitFormat: {
      hourFormat: UnitFormat.HOUR | UnitFormat.HOUR_SHORT | UnitFormat.HOUR_NARROW;
      minuteFormat: UnitFormat.MINUTE | UnitFormat.MINUTE_SHORT | UnitFormat.MINUTE_NARROW;
    } = { hourFormat: UnitFormat.HOUR_SHORT, minuteFormat: UnitFormat.MINUTE_SHORT }
  ): string {
    try {
      const hourFormatInfo: IGlobalizeUnitFormatInfo = UNIT_FORMATS_MAP.get(unitFormat.hourFormat)!;
      const minuteFormatInfo: IGlobalizeUnitFormatInfo = UNIT_FORMATS_MAP.get(unitFormat.minuteFormat)!;
      switch (durationFormat) {
        case DurationFormat.DURATION:
          const totalMins = Math.ceil(duration / 60);
          const displayHours = Math.floor(totalMins / 60);
          const displayMins = totalMins % 60;
          if (displayMins > 0 && displayHours > 0) {
            // NOTE: normally string concatenation should be avoided at all costs.  This instance is acceptable because it is
            // concatenating two unit strings together rather than any sort of phrase.
            return `${this._globalizeInstance.formatUnit(
              displayHours,
              hourFormatInfo.unit,
              hourFormatInfo.options
            )} ${this._globalizeInstance.formatUnit(
              displayMins,
              minuteFormatInfo.unit,
              minuteFormatInfo.options
            )}`;
          } else if (displayHours > 0) {
            return this._globalizeInstance.formatUnit(
              displayHours,
              hourFormatInfo.unit,
              hourFormatInfo.options
            );
          } else {
            return this._globalizeInstance.formatUnit(
              displayMins,
              minuteFormatInfo.unit,
              minuteFormatInfo.options
            );
          }

        case DurationFormat.RECENCY:
          const targetDate = new Date(duration);
          const currentDate = new Date();
          const recencyInMins = Math.floor((currentDate.getTime() - targetDate.getTime()) / 60000); // 60000 milliseconds in a minute
          const recencyInHours = Math.floor(recencyInMins / 60);
          const recencyInDays = Math.floor(recencyInHours / 24);
          if (recencyInMins < 2) {
            // we return 1 min ago even if dateAdded is in the future
            return this._globalizeInstance.formatRelativeTime(
              -1,
              minuteFormatInfo.unit,
              minuteFormatInfo.options as RelativeTimeFormatterOptions
            );
          } else if (recencyInHours < 1) {
            return this._globalizeInstance.formatRelativeTime(
              -recencyInMins,
              minuteFormatInfo.unit,
              minuteFormatInfo.options as RelativeTimeFormatterOptions
            );
          } else if (recencyInHours < 2) {
            return this._globalizeInstance.formatRelativeTime(
              -1,
              hourFormatInfo.unit,
              hourFormatInfo.options as RelativeTimeFormatterOptions
            );
          } else if (recencyInDays < 1) {
            return this._globalizeInstance.formatRelativeTime(
              -recencyInHours,
              hourFormatInfo.unit,
              hourFormatInfo.options as RelativeTimeFormatterOptions
            );
          } else if (recencyInDays < 2) {
            return this._globalizeInstance.formatRelativeTime(-1, UnitFormat.DAY);
          } else if (recencyInDays <= 3) {
            // up to and including 3 days
            return this._globalizeInstance.formatRelativeTime(-recencyInDays, UnitFormat.DAY);
          } else if (targetDate.getUTCFullYear() === currentDate.getUTCFullYear()) {
            return this.formatAsDate(targetDate, DateFormat.DAY_MONTH_MEDIUM);
          } else {
            // not current year
            return this.formatAsDate(targetDate, DateFormat.DAY_MONTH_YEAR_MEDIUM);
          }
      }
    } catch (e) {
      throw new Error(
        `Error formatting "${duration}" as "${durationFormat}" with "${unitFormat}" for "${this._formatCode}": ${e.message} ${e.stack}`
      );
    }
  }

  /**
   * Format a number in the general pattern described by `numberFormat`, according to the rules specified
   * by the format code for that general pattern.
   *
   * @param number - the number value to be formatted
   * @param numberFormat - the general way in which the number should be formatted; Globalizer will provide
   * the specifics corresponding to the format code
   */
  public formatAsNumber(value: number, numberFormat: NumberFormat): string {
    try {
      const numberFormatInfo: IGlobalizeNumberFormatInfo = NUMBER_FORMATS_MAP.get(numberFormat)!;
      return this._globalizeInstance.formatNumber(value, numberFormatInfo?.options);
    } catch (e) {
      throw new Error(
        `Error formatting "${value}" as "${numberFormat}" for "${this._formatCode}": ${e.message} ${e.stack}`
      );
    }
  }

  /**
   * Format a phone number in the general pattern described by `phoneNumberFormat`, according to the rules specified
   * by the format code for that general pattern.
   *
   * @param phoneNumber - the phone number value to be formatted
   * @param phoneNumberFormat - the general way in which the phone number should be formatted; Globalizer will provide
   * the specifics corresponding to the format code
   */
  public formatAsPhoneNumber(phoneNumber: string, phoneNumberFormat: PhoneNumberFormat): string {
    // TODO: figure out how to format phone numbers
    return `${phoneNumber} (${phoneNumberFormat})`;
  }

  /**
   * Format a relative time in the general pattern described by `relativeTimeFormat`, according to the rules specified
   * by the format code for that general pattern.
   *
   * @param relativeTime - the relativeTime value to be formatted
   * @param relativeTimeFormat - the general way in which the relative time should be formatted; Globalizer will provide
   * the specifics corresponding to the format code
   */
  public formatAsRelativeTime(valueInUnits: number, relativeTimeFormat: RelativeTimeFormat): string {
    try {
      const relativeTimeFormatInfo: IGlobalizeRelativeTimeFormatInfo =
        RELATIVE_TIME_FORMATS_MAP.get(relativeTimeFormat)!;
      return this._globalizeInstance.formatRelativeTime(
        valueInUnits,
        relativeTimeFormatInfo.unit,
        relativeTimeFormatInfo.options
      );
    } catch (e) {
      throw new Error(
        `Error formatting "${valueInUnits}" as "${relativeTimeFormat}" for "${this._formatCode}": ${e.message} ${e.stack}`
      );
    }
  }

  /**
   * Format a unit in the general pattern described by `unitFormat`, according to the rules specified
   * by the format code for that general pattern.
   *
   * @param unit - the unit value to be formatted
   * @param unitFormat - the general way in which the unit should be formatted; Globalizer will provide
   * the specifics corresponding to the format code
   */
  public formatAsUnit(value: number, unitFormat: UnitFormat): string {
    try {
      const unitFormatInfo: IGlobalizeUnitFormatInfo = UNIT_FORMATS_MAP.get(unitFormat)!;
      const formattedUnits: string = this._globalizeInstance.formatUnit(
        value,
        unitFormatInfo.unit,
        unitFormatInfo?.options
      );

      return formattedUnits;
    } catch (e) {
      throw new Error(
        `Error formatting "${value}" as "${unitFormat}" for "${this._formatCode}": ${e.message} ${e.stack}`
      );
    }
  }
}
