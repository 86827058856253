// Copyright text placeholder, Warner Bros. Discovery, Inc.

let orderNumber: number = 0;

/**
 * Returns the next session order number
 * @returns order number
 */
export function nextSessionOrder(): number {
  return ++orderNumber;
}

/**
 * Resets the session order number to 0
 */
export function reset(): void {
  orderNumber = 0;
}
