// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#available-language-tags
 * @public
 */
export interface ILanguageTagAttributes {
  /** Detailed display name for the language, informational only. */
  displayName: string;
  /** Delineates whether the LanguageTag is the user's default language. */
  isDefault?: boolean;
  /** Non-localized name for the language, informational only */
  name: string;
}

/**
 * @public
 */
export class LanguageTag extends TypedModel.generate<DataTypes.LanguageTag, ILanguageTagAttributes>() {}
