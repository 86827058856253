// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#payment-methods
 * @public
 */
export interface IPaymentMethodAttributes {
  /** Card expiry year and month in `yyyy-mm` format (e.g. `2030-03`). */
  cardExpire?: string;
  /** Card provider name (e.g. `Visa`). */
  cardProvider?: string;
  /** Last four digits of the card. */
  maskedCardNumber?: string;
  /** Payment method type. */
  paymentType?: string;
  /** Payment provider (e.g. `PayPal`, `AdyenCheckout`). */
  provider?: string;
  /** Payment method status.
   * @see https://docs.disco-api.com/apidocs/#payment-methods for available status types. */
  status?: string;
}

/**
 * @public
 */
export class PaymentMethod extends TypedModel.generate<DataTypes.PaymentMethod, IPaymentMethodAttributes>() {}
