// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { ILocalizationService } from '@wbd/beam-ctv-localization';
import type { IControllerButtons, IInputKeyMap } from './types';

/* eslint-disable @typescript-eslint/naming-convention */

/**
 * Abstract input implementation
 * @public
 */
export class AbstractInput {
  /**
   * Whether mouse/remote pointer is supported
   */
  protected static _pointerEnabled: boolean = false;

  // prohibit class initialization as we only want to use te static functions
  protected constructor() {}

  /**
   * Support optional transformation of the input 'type' DOM attribute by platform
   * for example we use 'password' type on Tizen devices to show a 'number' keyboard
   */
  public static transformInputFieldType(type: string): string {
    return type;
  }

  /**
   * Checks if mouse/remote pointer support is enabled in the device
   * Note: do not override, use `setIsPointerEnabled` during bootstrap
   */
  public static isPointerEnabled(): boolean {
    return AbstractInput._pointerEnabled;
  }

  /**
   * Defines if mouse/remote pointer support is enabled in the device
   */
  public static setIsPointerEnabled(enabled: boolean): void {
    AbstractInput._pointerEnabled = enabled;
  }

  /**
   * Returns the Key mapping for the remote input async
   * The values should be uppercased strings to be aligned with the Lightning framework default key mapping
   */
  public static getKeyMapAsync(): Promise<IInputKeyMap> {
    return new Promise((resolve, reject) => {
      resolve({
        8: 'Back', // key backspace
        13: 'Enter',
        27: 'Exit', // key escape
        37: 'Left',
        38: 'Up',
        39: 'Right',
        40: 'Down',
        48: '0',
        49: '1',
        50: '2',
        51: '3',
        52: '4',
        53: '5',
        54: '6',
        55: '7',
        56: '8',
        57: '9',
        67: 'CC', // key C
        70: 'Forward', // key F
        76: 'Pause', // key L
        79: 'Play', // key O
        80: 'PlayPause', // key P
        82: 'Rewind', // key R
        83: 'Stop', // key S
        88: 'SkipForward', // key X
        90: 'SkipBackward' // key Z
      });
    });
  }

  /**
   * returns an interface containing buttons images and labels for top/left/right controller buttons in consoles,
   * or undefined in other platforms
   * @returns IControllerButtons | undefined
   */
  public static getControllerButtons(translation: ILocalizationService): IControllerButtons | undefined {
    return undefined;
  }
}

/**
 * input (RCU) implementation interface
 * @public
 */
export type IInput = Omit<typeof AbstractInput, 'prototype'>;
