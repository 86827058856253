// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IStorage } from '@wbd/beam-dom-extensions';
import type { BoltHttpClient, IRequestRetryConfig } from '@wbd/bolt-http';

/**
 *
 * Configuration defined by the client to be used by the Instrumentation SDK to wire up data transmission and heartbeat events
 * @public
 */
export interface IClientConfiguration {
  /** persistant storage for events */
  eventStorage?: IStorage;

  /** bolt http client responsible for making requests */
  httpClient?: BoltHttpClient;

  /** bolt http client owned retry policy. Recommended against setting this, as Transmission library has bespoke retry logic */
  httpClientRetryPolicy?: IRequestRetryConfig;

  /** The URI to be used when posting event data to telegraph */
  telegraphUri?: string;
}

/**
 * Internal Configuration used by the Instrumentation SDK to wire up data transmission and heartbeat events
 *
 * Defined in https://wbdstreaming.atlassian.net/wiki/spaces/GCX/pages/107382232/LADR+010+Telemetry+Config+Schema
 * @public
 */
export class Configuration implements IClientConfiguration {
  /** default configuration values */
  public static readonly defaults: Required<Omit<Configuration, 'httpClient' | 'eventStorage'>> = {
    flushInterval: 2000,
    flushThrottleFactor: 2,
    heartbeatInterval: 30000,
    minDisableInterval: 30,
    maxEventBatchSize: 10,
    maxRetries: 5,
    telegraphUri: '/events',
    httpClientRetryPolicy: {
      retries: 0
    }
  };

  /** persistant storage for events */
  public eventStorage: IStorage | undefined;

  /** The number of milliseconds to wait until we send all telegraph logs. */
  public flushInterval: number = 2000;

  /** The factor that will be multiplied to the flushInterval to throttle flushing in the event of a transmission error */
  public flushThrottleFactor: number = 2;

  /** The number of milliseconds between each heartbeat event. */
  public heartbeatInterval: number = 30000;

  /** The minimum number of minutes to disable the transmission library after failures exceed maxRetries. */
  public minDisableInterval: number = 30;

  /** The URI to be used when posting event data to telegraph */
  public httpClient?: BoltHttpClient;

  /** The URI to be used when posting event data to telegraph */
  public telegraphUri: string = '/events';

  /** bolt http client owned retry policy. Recommended against setting this, as Transmission library has bespoke retry logic */
  public httpClientRetryPolicy: IRequestRetryConfig = {
    retries: 0
  };

  /** The maximum number of events to be sent in each batch request. */
  public maxEventBatchSize: number = 10;

  /** The maximum number of retries to attempt after a transmission failure. */
  public maxRetries: number = 5;

  public constructor(props: Partial<Configuration> = {}) {
    Object.assign(this, Configuration.defaults, props);
  }
}
