// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { UnRegionCode, UnRegionCodeSet } from '../generated';

/**
 * Returns a ReadonlySet containing all of the UnRegionCode values.
 *
 * @public
 */
export function getUnRegionCodes(): ReadonlySet<UnRegionCode> {
  return UnRegionCodeSet;
}
