// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { CldrLocale, LanguageCode } from '@wbd/localization-core';
import { GlobalizeInstance, GlobalizeStatic } from '../globalize';
import {
  IFormattedMessageParameters,
  IRuntimeMessageSpecification,
  ITranslationSourceData,
  ITranslations,
  JsonCompatibleString,
  MessageKey,
  MessageKeyWithParameters,
  MessageKeyWithoutParameters,
  isIRuntimeMessageSpecification
} from '../i18n-types';
import { getCldrLocaleForCanonicalizedCldrLocale, isCanonicalizedCldrLocale } from '@wbd/localization-core';

/**
 * Provides runtime translation of strings to a target language (defined by a language code).
 *
 * Relies on a `Formatter` object to format any parameters to those strings.
 *
 * @public
 */
export class Translator<TSourceData extends ITranslationSourceData> {
  /**
   * The suffix to append onto a message key to generate the key for its accessible version.
   * Message keys ending in this suffix should never be included in a translation specification.
   */
  public static readonly _ACCESSIBLE_SUFFIX: string = '___ACCESSIBLE';

  private readonly _languageCode: LanguageCode;
  private readonly _translationSourceData: TSourceData;
  private readonly _translatedMessages: ITranslations;
  private readonly _compiledMessages: GlobalizeInstance;

  /**
   * Create a `Translator` instance which translates messages from `TSourceData` into the target language
   * (defined by `languageCode`).
   *
   * Note that the provided `Formatter` will format data correctly according to a format code, which will
   * generally not be the same as the language code.
   *
   * @param languageCode - the language code for the language into which this `Translator` will translate its messages
   * @param translationSourceData - the ITranslationSourceData which defines the messages and their parameters
   * @param translatedMessages - the translated ICU templates for the requested language code
   * @param globalize - a compiled Globalize object with messageFormatter() functions for the requested language code
   */
  public constructor(
    languageCode: LanguageCode,
    translationSourceData: TSourceData,
    translatedMessages: ITranslations,
    globalizeStatic: GlobalizeStatic
  ) {
    if (!isCanonicalizedCldrLocale(languageCode)) {
      throw new Error(`Expected language code "${languageCode}" to be a valid 'CanonicalizedCldrLocale'`);
    }

    this._languageCode = languageCode;
    this._translationSourceData = translationSourceData;
    this._translatedMessages = translatedMessages;

    const cldrLocale: CldrLocale = getCldrLocaleForCanonicalizedCldrLocale(languageCode);
    this._compiledMessages = globalizeStatic(cldrLocale);
  }

  /**
   * Returns the language code for this `Translator`.
   */
  public get languageCode(): LanguageCode {
    return this._languageCode;
  }

  /**
   * Returns the translation source specifications for this `Translator`. Used by the containing `Globalizer`
   * instance to apply the appropriate formatting at runtime to the message parameters.
   */
  public get translationSourceData(): TSourceData {
    return this._translationSourceData;
  }

  /**
   * Looks up the requested message key in the runtime language pack for the language code, and returns the
   * corresponding translated string.
   *
   * The message template must not expect parameters.
   *
   * Although the message key is constrained by the translation source file, we know that it will be defined
   * in the language pack, because our build tools guarantee to generate language packs with translations for
   * all of the translation source file's message keys (falling back to the messages in the translation source
   * file itself if no more specific fallback translation is available).
   *
   * @param messageKey - the requested message key
   */
  public translate<TMessageKey extends MessageKeyWithoutParameters<TSourceData> & string>(
    messageKey: TMessageKey
  ): string {
    return this._translateMessageKey(messageKey);
  }

  /**
   * Looks up the requested message key in the runtime language pack for the language code, and returns the
   * corresponding translated accessible string. If the message key does not have an accessible version,
   * the translated standard version is returned.
   *
   * The message template must not expect parameters.
   *
   * Although the message key is constrained by the translation source file, we know that it will be defined
   * in the language pack, because our build tools guarantee to generate language packs with translations for
   * all of the translation source file's message keys (falling back to the messages in the translation source
   * file itself if no more specific fallback translation is available).
   *
   * @param messageKey - the requested message key
   */
  public translateAccessible<TMessageKey extends MessageKeyWithoutParameters<TSourceData> & string>(
    messageKey: TMessageKey
  ): string {
    if (this._hasAccessibleVersion(messageKey)) {
      return this._translateMessageKey(messageKey + Translator._ACCESSIBLE_SUFFIX);
    } else {
      return this._translateMessageKey(messageKey);
    }
  }

  private _hasAccessibleVersion<
    TMessageKey extends
      | (MessageKeyWithoutParameters<TSourceData> & string)
      | (MessageKeyWithParameters<TSourceData> & string)
  >(messageKey: TMessageKey): boolean {
    return this._translationSourceData[messageKey].accessibilityTemplate !== undefined;
  }

  private _translateMessageKey(messageKey: string): string {
    try {
      return this._compiledMessages.formatMessage(messageKey);
    } catch (e) {
      const error: Error = new Error(
        `Error "${e.message}" translating message key "${messageKey}" into "${this._languageCode}"`
      );
      error.name = e.name;
      error.stack = e.stack;
      throw error;
    }
  }

  /**
   * Returns a localized string for the given message key, with properly formatted and interpolated variables.
   *
   * The template corresponding to the message key must expect parameters.
   *
   * The localization process:
   *  1) Look up the message specification for the message key in `_translationSourceData`.
   *  2) For each parameter format in the message specification, invoke the appropriate formatting method
   *    on the `Formatter` with that parameter's value and the requested format.
   *  3) Invoke `_globalizeModule.messageFormatter()` with the message key and the formatted parameters.
   *
   * Although the message key and its corresponding parameters are constrained by the translation source file,
   * we know that it will be defined in the language pack with the same parameters, because our build tools
   * guarantee to generate language packs with translations for all of the translation source file's message keys
   * (falling back to the messages in the translation source file itself if no more specific fallback translation
   * is available), with the same parameters as in the translation source file.
   *
   * @param messageKey - the requested message key
   * @param formattedParameters - an object containing the parameters required by the message specification for the given
   * message key (as defined in the translation source file), formatted appropriately according to that specification
   */
  public translateWithParameters<TMessageKey extends MessageKeyWithParameters<TSourceData> & string>(
    messageKey: TMessageKey,
    formattedParameters: IFormattedMessageParameters<TSourceData, TMessageKey>
  ): string {
    try {
      return this._compiledMessages.formatMessage(messageKey, formattedParameters);
    } catch (e) {
      const error: Error = new Error(
        `Error "${e.message}" translating accessible message key "${messageKey}" into "${
          this._languageCode
        }" with parameters "${JSON.stringify(formattedParameters)}"`
      );
      error.name = e.name;
      error.stack = e.stack;
      throw error;
    }
  }

  /**
   * Returns a localized accessible string for the given message key, with properly formatted and interpolated variables.
   * If the message key does not have an accessible version, the translated standard version is returned.
   *
   * The template corresponding to the message key must expect parameters.
   *
   * The localization process:
   *  1) Look up the message specification for the message key in `_translationSourceData`.
   *  2) For each parameter format in the message specification, invoke the appropriate formatting method
   *    on the `Formatter` with that parameter's value and the requested format.
   *  3) Invoke `_globalizeModule.messageFormatter()` with the message key and the formatted parameters.
   *
   * Although the message key and its corresponding parameters are constrained by the translation source file,
   * we know that it will be defined in the language pack with the same parameters, because our build tools
   * guarantee to generate language packs with translations for all of the translation source file's message keys
   * (falling back to the messages in the translation source file itself if no more specific fallback translation
   * is available), with the same parameters as in the translation source file.
   *
   * @param messageKey - the requested message key
   * @param formattedParameters - an object containing the parameters required by the message specification for the given
   * message key (as defined in the translation source file), formatted appropriately according to that specification
   */
  public translateAccessibleWithParameters<
    TMessageKey extends MessageKeyWithParameters<TSourceData> & string
  >(
    messageKey: TMessageKey,
    formattedParameters: IFormattedMessageParameters<TSourceData, TMessageKey>
  ): string {
    const accessibleMessageKey: string = this._hasAccessibleVersion(messageKey)
      ? messageKey + Translator._ACCESSIBLE_SUFFIX
      : messageKey;

    try {
      return this._compiledMessages.formatMessage(accessibleMessageKey, formattedParameters);
    } catch (e) {
      const error: Error = new Error(
        `Error "${e.message}" translating accessible message key "${accessibleMessageKey}" into "${
          this._languageCode
        }" with parameters "${JSON.stringify(formattedParameters)}"`
      );
      error.name = e.name;
      error.stack = e.stack;
      throw error;
    }
  }

  /**
   * Returns the raw ICU `template` string for the given message key in the language code for which the messages were
   * compiled. Does not accept parameters, and does not perform any kind of formatting or interpolation.
   *
   * @deprecated this exists to support legacy use cases in Hadron. New code should call the other methods on this
   * object, which will delegate template instantiation, variable interpolation, and data formatting to this library.
   */
  public getTemplate<TMessageKey extends MessageKey<TSourceData> & string>(messageKey: TMessageKey): string {
    const template: undefined | string | readonly string[] | IRuntimeMessageSpecification =
      this._translatedMessages[messageKey];

    if (template === undefined) {
      throw new Error(`Undefined message key [${messageKey}]`);
    }

    return isIRuntimeMessageSpecification(template)
      ? Translator._jsonCompatibleStringToString(template.template)
      : Translator._jsonCompatibleStringToString(template);
  }

  /**
   * Returns the raw ICU `accessibilityTemplate` string for the given message key in the language code for which the
   * messages were compiled. Does not accept parameters, and does not perform any kind of formatting or interpolation.
   *
   * @deprecated this exists to support legacy use cases in Hadron. New code should call the other methods on this
   * object, which will delegate template instantiation, variable interpolation, and data formatting to this library.
   */
  public getAccessibilityTemplate<TMessageKey extends MessageKey<TSourceData> & string>(
    messageKey: TMessageKey
  ): string {
    const template: string | readonly string[] | IRuntimeMessageSpecification =
      this._translatedMessages[messageKey];

    if (template === undefined) {
      throw new Error(`Undefined message key [${messageKey}]`);
    }

    return isIRuntimeMessageSpecification(template)
      ? Translator._jsonCompatibleStringToString(template.accessibilityTemplate)
      : Translator._jsonCompatibleStringToString(template);
  }

  private static _jsonCompatibleStringToString(template: JsonCompatibleString): string {
    return typeof template === 'string' ? template : template.join('');
  }
}
