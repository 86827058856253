// Copyright text placeholder, Warner Bros. Discovery, Inc.

import FontFaceObserver from 'fontfaceobserver';

/**
 *
 * Create font loaders from a configuration list
 *
 * @param fonts - the list of fonts to load
 * @returns A promise that resolves when all fonts started (or finished) loaded
 *
 * @public
 */
function preloadWebFonts(fonts: { fontFamily: string; await?: boolean; text?: string }[]): Promise<void> {
  const fontsLoaders = fonts.map((font) => {
    const fontObserver = new FontFaceObserver(font.fontFamily);
    const awaiter = fontObserver.load(font.text).catch(() => {
      throw new Error(`Failed to load font: ${font.fontFamily}`);
    });

    return awaiter;
  });

  return Promise.all(fontsLoaders).then(() => {});
}

/**
 *
 * @returns A promise that resolves when the css file has been loaded
 *
 * @public
 */
export function loadFontsCss(): Promise<unknown> {
  return import('./fonts.css');
}

/**
 * Preloads a list of fonts
 *
 * @returns - A promise that resolves when the list of fonts is processed
 */
export function loadFonts(): Promise<void> {
  const fonts: { fontFamily: string; text?: string }[] = [
    // main application fonts
    { fontFamily: 'thin' },
    { fontFamily: 'book' },
    { fontFamily: 'medium' },
    { fontFamily: 'bold' }
  ];

  return preloadWebFonts(fonts);
}

/**
 * Preload less essential fonts at a later time in the application.
 *
 * @param onError - Function to run when an error occurs loading extra fonts.
 * @returns - void
 * @public
 */
export function loadExtraFonts(onError: (payload: unknown) => void): void {
  const fonts: { fontFamily: string; await?: boolean; text?: string }[] = [
    { fontFamily: 'numbers', text: '12345' },
    // captions fonts
    { fontFamily: 'type' },
    { fontFamily: 'serif_cinecav' },
    { fontFamily: 'monospace_cinecav' },
    { fontFamily: 'sansserif_cinecav' },
    { fontFamily: 'casual' },
    { fontFamily: 'script' },
    { fontFamily: 'small-caps' }
  ];

  preloadWebFonts(fonts).catch(onError);
}
