// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  AbstractVideoCapabilities,
  ResolutionConstraint,
  defaultNetworkRequestRetry,
  defaultSupportedAudioCodecs,
  type IAudioCodecsOverride,
  type IEngineCreatorConfig,
  type IScrubberOptions,
  type IVideoCodecsOverride
} from '@wbd/beam-ctv-platform-adapters';
import {
  InstrumentationSDK,
  PlatformVendorV1Scope,
  generateV1Err,
  type IPlatformVendorV1Err
} from '@wbd/instrumentation-sdk';
import { PlayStationDevice } from './device';

/**
 * Covers for both PS4, PS5
 */
export class PlayStationVideoCapabilities extends AbstractVideoCapabilities {
  public static _getSupportedAudioCodecs(): IAudioCodecsOverride {
    const supportedAudioCodecs = { ...defaultSupportedAudioCodecs };

    /**
     * Returns true if PS4/PS5 supports dolby atmos
     */
    const isDolbyAtmosSupported = MediaSource.isTypeSupported(
      'audio/mp4; codecs="ec-3", channels=7.1, features="JOC"'
    );
    const isDolbyDigitalPlusSupported = MediaSource.isTypeSupported('audio/mp4; codecs="ec-3"');

    supportedAudioCodecs.ac3 = isDolbyDigitalPlusSupported;
    supportedAudioCodecs.atmos = isDolbyAtmosSupported;

    return supportedAudioCodecs;
  }

  public static async getPlayerEngineConfigAsync(): Promise<IEngineCreatorConfig> {
    const supportedVideoCodecs: IVideoCodecsOverride = {
      avc: true,
      hevcSdr: false,
      hevcHdr: false,
      dvh: false
    };

    let isHdrSuppported = false;
    let is4KSupported = false;

    try {
      const { dynamicRange, resolution } = await msdk.device.getDisplayInfoImmediate();
      if (dynamicRange === 'HDR') {
        // expected to be true for PS4 Pro and PS5
        isHdrSuppported = true;
      }
      if (resolution === '4K') {
        is4KSupported = true;
      }
    } catch (error) {
      InstrumentationSDK.Errors.Platform.Vendor.v1.capture({
        err: generateV1Err<IPlatformVendorV1Err>(error, {
          classification: 'platform-adapter-video',
          scope: PlatformVendorV1Scope.RECOVERABLE
        })
      });
    }

    const isHEVCSupported = MediaSource.isTypeSupported('video/mp4; codecs="hvc1.1.6.L60.90"');
    if (isHEVCSupported) {
      supportedVideoCodecs.hevcSdr = true;
      if (isHdrSuppported) {
        supportedVideoCodecs.hevcHdr = true;
      }
    }

    const supportedAudioCodecs = this._getSupportedAudioCodecs();

    return {
      resolutionOverrides: {
        maxResolution: is4KSupported ? ResolutionConstraint.UHD_4K : ResolutionConstraint.HD_1080P
      },
      supportedVideoCodecs,
      supportedAudioCodecs,
      networkRequestRetry: defaultNetworkRequestRetry,
      bufferOptions: {
        bufferingGoal: 8
      }
    };
  }

  public static async getScrubberOptions(): Promise<IScrubberOptions> {
    const defaultScrubbingOptions = await super.getScrubberOptions();
    // TODO this is actully calling the PS4 Device Adapter we need to find a better way to do this
    const model: string = await PlayStationDevice.getDeviceModelNameAsync();
    const isPS4 = model?.length && model.includes('PS4');

    return Promise.resolve({
      ...defaultScrubbingOptions,
      ...(isPS4 && {
        timeToKeepNotifyingMS: 200,
        timeRepeatKeyMS: 600,
        timeRepeatKeyStartLongPressMS: 700
      })
    });
  }

  public static async supportsTrickPlayScrubbing(): Promise<boolean> {
    return Promise.resolve(true);
  }

  public static getVideoGCInterval(): number {
    /**
     * PS4 has a memory issue if we don't override
     * the default 30 seconds interval to 1 second
     * so we garbage collect each 1 second, especially
     * when scrubbing.
     */
    const PS4_GC_INTERVAL = 1_000;
    return PS4_GC_INTERVAL;
  }
}
