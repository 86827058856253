// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @public
 */
export interface IContentDescriptorAttributes {
  /** Code assigned to the content by the regulator. */
  code: string;
  /** Regulator who rated the content. */
  system: string;
}

/**
 * @public
 */
export class ContentDescriptor extends TypedModel.generate<
  DataTypes.ContentDescriptor,
  IContentDescriptorAttributes
>() {}
