// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { CldrLocale, CldrLocaleSet } from '../generated';

/**
 * Returns a ReadonlySet containing all of the CldrLocale values.
 *
 * @public
 */
export function getCldrLocales(): ReadonlySet<CldrLocale> {
  return CldrLocaleSet;
}
