// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import type { Image } from './Image';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#avatars-api
 * @public
 */
export interface IAvatarAttributes {
  /** Avatar accessibility title. */
  accessibilityTitle?: string;
  /** Avatar alias. */
  alias?: string;
  /** Whether avatar is selected by default. */
  isDefault?: boolean;
  /** "Internal" name. */
  name?: string;
  /** A localizable, human-readable title of the avatar */
  title?: string;
  /** A localizable, human-readable description of the avatar */
  description?: string;
  /** Color hex code (e.g. #AABBCC) */
  color?: string;

  /** @deprecated Avatar category. */
  category?: string;
  /** @deprecated Avatar display name. */
  displayName?: string;
  /** @deprecated Avatar image URL. */
  imageUrl?: string;
}

/**
 * @public
 */
export interface IAvatarRelationships {
  /** @deprecated Collection of images. Contains exactly one entry. */
  images?: Image[];

  /** Image connected to the Avatar */
  avatarImage?: Image;
}

/**
 * @public
 */
export class Avatar extends TypedModel.generate<
  DataTypes.Avatar,
  IAvatarAttributes,
  IAvatarRelationships
>() {}
