// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IsoRegionCode, IsoRegionCodeSet } from '../generated';

/**
 * Returns a ReadonlySet containing all of the IsoRegionCode values.
 *
 * @public
 */
export function getIsoRegionCodes(): ReadonlySet<IsoRegionCode> {
  return IsoRegionCodeSet;
}
