// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { FormatCode, LanguageCode } from '@wbd/localization-core';
import { Formatter, Translator } from '@wbd/localization';
import { SUPPORTED_FORMATS } from '@wbd/localization-formats';
import TRANSLATION_SOURCE_SPEC from './resources.json';

/**
 * Localized resources specification
 * @public
 */
export type IBeamCTVResourceSpec = typeof TRANSLATION_SOURCE_SPEC;

/**
 * Constructs a `Translator` based on the files in the resource bundle folder where this file appears:
 *    1) The message templates in `resources.ts` provide the static typing for the `Translator` instance,
 *      and the template strings which are compiled into the `language-packs` build artifacts.
 *    2) The build artifacts for each language code are `translated-messages.json` and `compiled-messages.js`.
 *      `translated-messages.json` contains the template strings, translated into the target language.
 *      `compiled-messages.json` contains the compiled Globalize message-formatting functions for the target language.
 *    2) In the sandbox, the `language-packs` build artifacts are generated into the directories in `lib` and
 *      `lib-commonjs` corresponding to the resource bundle. Within those directories, the `language-packs` sub-folders
 *      provides compiled Globalize message formatting methods for all supported language codes. For each language code:
 *        a) `language-packs/<formatCode>/translated-messages.js` provides the raw template strings translated into that language, and
 *        b) `language-packs/<languageCode>/compiled-messages.js` provides the compiled Globalize message formatting methods.
 *
 *  * Note: `await import`, unlike `import`, does not directly return the value defined in the Javascript module.
 * Instead, it returns an object with a `default` property whose value is the defined value.
 * @public
 */
export async function loadTranslatorAsync(
  languageCode: LanguageCode
): Promise<Translator<typeof TRANSLATION_SOURCE_SPEC>> {
  const compiledMessagesContainer = await import(
    /* webpackChunkName: "i18n/language-packs/[request]" */
    `./language-packs/${languageCode}/compiled-messages.js`
  );
  return new Translator<typeof TRANSLATION_SOURCE_SPEC>(
    languageCode,
    TRANSLATION_SOURCE_SPEC,
    {}, // deprecated
    compiledMessagesContainer.default
  );
}

/**
 * Constructs a `Formatter` based on one of the format-packs in the `@wbd/localization-formats` library.
 *
 * @public
 */
export async function loadFormatterAsync(formatCode: FormatCode): Promise<Formatter> {
  if (!SUPPORTED_FORMATS.includes(formatCode)) {
    throw new Error(`Unsupported FormatCode "${formatCode}"`);
  }
  const compiledFormatterContainer = await import(
    /* webpackChunkName: "i18n/formats/[request]" */
    `@wbd/localization-formats/lib/generated/format-packs/${formatCode}/index`
  );
  return new Formatter(formatCode, compiledFormatterContainer.default);
}
