// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ContentRestrictionLevel } from './ContentRestrictionLevel';
import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * Relationship allowing users to set content restrictions on Kids profiles.
 *
 * @see https://docs.disco-api.com/apidocs/#content-decorators
 * @public
 */
export interface IContentRestrictionLevelsRelationships {
  /** Ordered array of ContentRestrictionLevels */
  contentRestrictionLevels: ContentRestrictionLevel[];
  /** Default ContentRestrictionLevel. */
  defaultContentRestrictionLevel: ContentRestrictionLevel;
}

/**
 * @public
 */
export class ContentRestrictionLevels extends TypedModel.generate<
  DataTypes.ContentRestrictionLevels,
  {},
  IContentRestrictionLevelsRelationships
>() {}
