// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ArticleBodyRichText } from './ArticleBodyRichText';
import type { DataTypes } from '../json-api';
import type { MarketingCallToAction } from './MarketingCallToAction';
import type { MarketingCollectionGroup } from './MarketingCollectionGroup';
import { TypedModel } from '../json-api';

/**
 * A Marketing Page Item will have exactly one of the relationship
 * to `text`, `marketingCollectionGroup`, or `cta`.
 *
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export interface IMarketingPageItemRelationships {
  //relationships
  /** CTA to be shown in the page item. */
  cta?: MarketingCallToAction;
  /** Collection group of collections containing plan cards. */
  marketingCollectionGroup?: MarketingCollectionGroup;
  /** Rich tet to be displayed in the page item. */
  text?: ArticleBodyRichText;
}

/**
 * @public
 */
export class MarketingPageItem extends TypedModel.generate<
  DataTypes.MarketingPageItem,
  {},
  IMarketingPageItemRelationships
>() {}
