// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { Avatar } from './Avatar';
import type { ContentRestrictionLevel } from './ContentRestrictionLevel';
import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.discomax.com/#profiles
 * @public
 */
export interface IProfileAttributes {
  /** User age. */
  age?: number;
  /** Whether age restrictions have been enabled on this profile. */
  ageRestricted?: boolean;
  /** Avatar id */
  avatarId?: string;
  /** @deprecated replaced by {@link IProfileAttributes.avatarId} */
  avatarName: string;
  /** In bits per second. (e.g. 350000 = 350kbps) */
  bandwidthPreference?: number;
  /** Integer from 1-31 inclusive. */
  birthDay?: number;
  /** Integer from 1-12 inclusive. */
  birthMonth?: number;
  /** Profile birth year. */
  birthYear?: number;
  /** @deprecated in favour of ProfileRelationships. */
  contentRestrictionLevelId?: string;
  /** Profile gender. */
  gender?: 'M' | 'F' | 'O' | 'P';
  /** Whether this is the default profile on the account. */
  isDefault: boolean;
  /** Whether this is the default kids profile on the account. */
  isDefaultKids?: boolean;
  /** Whether this profile can be deleted. */
  isDeletable: boolean;
  /** `true` if preview all is set, `false` if preview specific date/time is set. */
  isPreview?: boolean;
  /** Returns `true` if a pin has been set, otherwise not returned. */
  pinRestricted?: boolean;
  /** Array of RFC 4647-based language tags. */
  preferredLanguageTags?: string[];
  /** Date/time in epoch milliseconds. */
  previewInstant?: number;
  /** Profile name. */
  profileName?: string;
  /** Requires Kid Proof Exit Pin */
  requiresExitPin?: boolean;
}

/**
 * @public
 */
export type ProfileDetails = Omit<
  IProfileAttributes,
  'isDefault' | 'isDefaultKids' | 'isDeletable' | 'isPreview' | 'pinRestricted' | 'avatarName'
> &
  Required<Pick<IProfileAttributes, 'profileName'>>;

/**
 * @public
 */
export interface IProfileRelationships {
  contentRestrictionLevel?: ContentRestrictionLevel;
  /** Profile avatar */
  avatar?: Avatar;
}

/**
 * @public
 */
export class Profile extends TypedModel.generate<
  DataTypes.Profile,
  IProfileAttributes,
  IProfileRelationships
>() {}
