// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export interface IMarketingCallToActionAttributes {
  /** One of predefined list of codes which tell clients what to do next if the user
   * interacts with this call to action */
  code: string;
  /** Text to be displayed in the CTA. */
  text: string;
  /** CTA variant. */
  variant: 'primary' | 'secondary' | 'tertiary';
  /** The href for CTA links. */
  href?: string;
}

/**
 * @public
 */
export class MarketingCallToAction extends TypedModel.generate<
  DataTypes.MarketingCallToAction,
  IMarketingCallToActionAttributes
>() {}
