// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { BoltEnvironment, Platform } from '@wbd/bolt-http';
import {
  SubscriptionFlow,
  IPurchasePlan,
  PaymentProvider,
  ISubscriptionPayloadMVPD,
  IStoreReceipt,
  IUnlinkedReceipt
} from './types';
import type { IStorage } from '@wbd/beam-dom-extensions';

/**
 * Abstract commerce implementation
 * @public
 */
export class AbstractCommerce {
  /**
   * Let you know which kind of subscription flow to deal depending on the platform
   * @returns SubscriptionFlow
   */
  public static getSubscriptionFlow(): SubscriptionFlow {
    return SubscriptionFlow.DTC;
  }

  /**
   * This method to be used to know which payment provider has to be use for the subscription-journey/start service
   * @public
   */
  public static getPaymentProvider(): PaymentProvider {
    throw new Error('getPaymentProvider not implemented');
  }

  /**
   * This method will return the partner ID for given environment to use with the subscription-journey/start service. This is only needed for MVPD devices like Comcast/Cox.
   * @param environment - BoltEnvironment
   * @returns string
   */
  public static getPartnerId(environment: BoltEnvironment, platform: Platform): string {
    throw new Error('getPartnerId not implemented');
  }

  /**
   * This method will retrieve the subscriptions plans from the store, this method should be implemented for IAP flows
   * @returns IPurchasePlan[]
   */
  public static getStoreSubscriptionPlansIAP(): Promise<IPurchasePlan[]> {
    return Promise.resolve([]);
  }

  /**
   * This method will start the payment flow in the IAP store and will return a success once the payment is done
   * @param purchasePlan - IPurchasePlan
   * @returns Promise<IStoreReceipt>
   */
  public static purchasePlanInStoreIAP(purchasePlan: IPurchasePlan): Promise<IStoreReceipt> {
    throw new Error('purchasePlanInStoreIAP not implemented');
  }

  /**
   * MVPD method to redirect the user to their store, some use cases will allow a redirect url(deep link)
   * @param subscriptionPayload - ISubscriptionPayloadMVPD
   */
  public static subscribePlanWithMVPD(subscriptionPayload: ISubscriptionPayloadMVPD): void {
    throw new Error('subscribePlanWithMVPD not implemented');
  }

  /**
   * Returns an unlinked receipt
   * @param storage - IStorage
   * @returns IUnlinkedReceipt | undefined
   */
  public static getUnlinkedReceipt(storage: IStorage): IUnlinkedReceipt | undefined {
    return undefined;
  }

  /**
   * Removes any unlinked receipt
   * @param storage - IStorage
   */
  public static cleanUnlinkedReceipt(storage: IStorage): void {}

  /**
   * Stores an unlinked receipt
   * @param payload - IUnlinkedReceipt
   * @param storage - IStorage
   */
  public static storeUnlinkedReceipt(payload: IUnlinkedReceipt, storage: IStorage): void {}
}

/**
 * device implementation interface
 * @public
 */
export type ICommerce = Omit<typeof AbstractCommerce, 'prototype'>;
