// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import type { PricePlan } from './PricePlan';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#products
 * @public
 */
export interface IProductAttributes {
  /** Key for the product. This value should never change. */
  alias: string;
  /** Indicates if any active campaigns exist for the product. */
  campaignsExist: boolean;
  /** Product description. */
  description?: string;
  /** Product name. */
  name: string;
}

/**
 * @public
 */
export interface IProductRelationships {
  /** Array of price plans associated with this product. */
  pricePlan?: PricePlan[];
}

/**
 * @public
 */
export class Product extends TypedModel.generate<
  DataTypes.Product,
  IProductAttributes,
  IProductRelationships
>() {}
