// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { createV4Uuid } from '@wbd/beam-js-extensions';
import { Emitter } from './Emitter';
import { IBaseErrorCapture } from './IBaseErrorCapture';
import { IVersionedEvent } from './IVersionedEvent';
import { nextSessionOrder } from './SessionOrder';

/**
 * The base errors object that all errors events are modeled after
 * @public
 */
export class BaseError<TErrorProps = {}, TErrorPayload = {}> {
  public payload: TErrorPayload | undefined;

  /** the event type for the error */
  protected eventType: string = 'beam.errors.base';

  /**
   * method to listen to an event
   * @param callback - callback function to be called when the event is emitted
   */
  public addEventListener(callback: (event: IVersionedEvent<TErrorPayload>) => void): void {
    Emitter.addEventTypeListener<TErrorPayload>(this.eventType, callback);
  }

  /**
   * builds a versioned event with a payload including base error properties
   * @param payload - the payload to be sent to the error event
   * @returns a versioned event with a payload
   */
  protected _build(payload: TErrorProps | TErrorPayload): IVersionedEvent<TErrorPayload> {
    return {
      eventType: this.eventType,
      sessionOrder: nextSessionOrder(),
      deviceOccurredAt: Date.now(),
      payload: payload as TErrorPayload
    };
  }

  /**
   * captures an error event and returns an error object
   * @param payload - the payload to be sent to the error event
   * @returns error object
   */
  public capture(payload: TErrorProps): IBaseErrorCapture<TErrorPayload> {
    const event = this._build(payload);
    this.payload = event.payload;
    this._emit(event);
    return {
      eventType: this.eventType,
      payload: this.payload
    };
  }

  /**
   * emit out the error to any bound listeners or the emitter
   * @param event - the event to be emitted
   */
  private _emit(event: IVersionedEvent<TErrorPayload>): void {
    Emitter.emitEventAsync(event).catch(() => {});
  }

  /**
   * gets an unique error id
   * @returns error id
   */
  protected _getErrorId(): string {
    return createV4Uuid();
  }
}
