// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { ConsentManagement } from '@wbd/beam-ctv-consent';
import { createGlobalizer } from '@wbd/beam-ctv-localization/lib/packlets/globalizer';
import type { IPlatformAdapter } from '@wbd/beam-ctv-platform-adapters';
import type { SequenceError } from '@wbd/beam-js-extensions';
import { createSequence, type ISequence } from '@wbd/beam-js-extensions';
import {
  getBootstrap,
  getProfiles,
  getToken,
  getUser,
  getUserEntitlements,
  type AuthTokenProvider
} from '@wbd/bolt-dataservice';
import { BoltEnvironment } from '@wbd/bolt-http';
import {
  generateV1Err,
  GlobalContextV1ConnectionType,
  InstrumentationSDK,
  PlatformConfigurationV1Classification,
  PlatformConfigurationV1Scope,
  PlatformRuntimeV1Scope,
  type IPlatformConfigurationV1Err,
  type IPlatformRuntimeV1Err
} from '@wbd/instrumentation-sdk';
import type { FormatCode, LanguageCode } from '@wbd/localization-core';

import type { IGlobalErrorContext, IInitialContext } from '../application';
import { bootstrapBolt } from './bootstrapBolt';
import { getBootstrapDeviceInformation } from './bootstrapDeviceInfo';
import { bootstrapLabs } from './bootstrapLabs';
import { bootstrapSplash } from './bootstrapSplash';
import { getEnvironmentFromUrl } from './EnvironmentFromUrl';

type BootSequenceSteps =
  | 'Initial context'
  | 'Load device info'
  | 'Prepare Splash'
  | 'Save deeplink'
  | 'Bolt Environment'
  | 'Initialize local feature flags'
  | 'Bootstrap Bolt'
  | 'Initialize instrumentation'
  | 'Get preferred languages'
  | 'Load locales'
  | 'Get client config'
  | 'Initialize ConsentManager'
  | 'Load user and profiles'
  | 'Load Labs feature flags'
  | 'Return context';

function createBootSequence(): ISequence<Partial<IInitialContext>, IInitialContext, BootSequenceSteps> {
  let context!: IInitialContext;

  return createSequence('Boot sequence')
    .pipe('Initial context', async (initialContext: Partial<IInitialContext>) => {
      context = initialContext as IInitialContext;
      return context.platformAdapter;
    })
    .pipe('Load device info', async (adapter: IPlatformAdapter) => {
      context.deviceBootInfo = await getBootstrapDeviceInformation(
        adapter.device,
        adapter.video,
        adapter.storage
      );
      return adapter;
    })
    .pipe('Prepare Splash', async (adapter: IPlatformAdapter) => {
      bootstrapSplash(context);
      return adapter;
    })
    .pipe('Save deeplink', async (adapter: IPlatformAdapter) => {
      // this avoids accessing the mutable reference `context.platformAdapter` which
      // would cause a potential race condition according to eslint
      context.deeplinkParams = await adapter.device.getDeeplinkParamsAsync();
    })
    .pipe('Initialize local feature flags', async () => {
      const { FeatureFlagService } = await import('../service/feature-flags');

      const featureFlagsEnabled: boolean =
        DEBUG || Boolean(context.platformAdapter.device.getURLParams().get('debug'));
      const featureFlags = FeatureFlagService.create({
        isEnabled: featureFlagsEnabled,
        platformAdapter: context.platformAdapter
      });

      context.featureFlags = featureFlags;

      if (featureFlags.isEnabled('ClearStorage')) {
        await context.platformAdapter.storage.clearAsync();
      }
    })
    .pipe('Bolt Environment', async () => {
      const defaultBoltEnvironment = BoltEnvironment.PRD;
      const overrideBoltEnvironment = getEnvironmentFromUrl(context.platformAdapter);
      context.boltEnvironment = overrideBoltEnvironment ?? defaultBoltEnvironment;
    })
    .pipe('Bootstrap Bolt', async () => {
      const initialContext = context;
      const httpClient = await bootstrapBolt(context);

      initialContext.httpClient = httpClient;

      return httpClient;
    })
    .pipe('Initialize instrumentation', async (httpClient) => {
      const versionParts = httpClient.sessionConfig.applicationVersion.split('.');
      const deployedVersion = versionParts.slice(0, 3).join('.');
      const buildNumber = Number(versionParts[3]);

      InstrumentationSDK.updateGlobalContext({
        application: {
          deployedVersion,
          buildNumber,
          omd: {
            /**
             * This value comes from the omd.json file in each project
             * and is replaced using webpack's DefinePlugin
             */
            component: OMD_COMPONENT_NAME
          }
        },
        // device: {
        // browser: {
        // name: BROWSER_NAME_HERE,
        // version: BROWSER_VERSION_HERE
        // }
        // },
        applicationState: {
          network: {
            connectionType: GlobalContextV1ConnectionType.UNSPECIFIED
          }
        }
      });
    })
    .pipe('Get preferred languages', async () => {
      const { device } = context.platformAdapter;
      const deviceLocale = await device.getDeviceLocaleAsync();
      return [deviceLocale];
    })
    .pipe('Get client config', async (devicePreferredLanguages) => {
      // call headwaiter to get fresh bootstrap config payload
      // this should be the first call to services on boot (after acquiring a valid token)
      try {
        await getBootstrap(devicePreferredLanguages);
      } catch (error) {
        // bootstrap failed to load but we can continue with defaults.
        // if we happen to have a stale payload services will tell us to reload it
        InstrumentationSDK.Errors.Platform.Configuration.v1.capture({
          err: generateV1Err<IPlatformConfigurationV1Err>(error, {
            classification: PlatformConfigurationV1Classification.HEADWAITER,
            scope: PlatformConfigurationV1Scope.RECOVERABLE
          })
        });
      }
      return { devicePreferredLanguages, httpClient: context.httpClient };
    })
    .pipe('Load locales', async ({ devicePreferredLanguages, httpClient }) => {
      // get language information from headwaiter payload
      const localizationPayload = httpClient.sessionState.getLocalizationPayload();
      // define wantLanguagesCodes and formatCode
      const wantLanguageCodes = (localizationPayload?.selectedLanguages ??
        devicePreferredLanguages) as LanguageCode[];
      const formatCode = localizationPayload?.locale as FormatCode | undefined;
      // create globalizer localization instance
      context.globalizer = await createGlobalizer(wantLanguageCodes, formatCode);
    })
    .pipe('Initialize ConsentManager', async () => {
      const consentManagement = new ConsentManagement(
        context.platformAdapter.storage,
        context.httpClient.sessionConfig.authTokenProvider as AuthTokenProvider
      );
      // capture any consent errors in reporting
      consentManagement.onError((error) => {
        InstrumentationSDK.Errors.Platform.Runtime.v1.capture({
          err: generateV1Err<IPlatformRuntimeV1Err>(error, {
            scope: PlatformRuntimeV1Scope.RECOVERABLE
          })
        });
      });
      context.consentManagement = consentManagement;
      return consentManagement;
    })
    .pipe('Load user and profiles', async (consentManagement: ConsentManagement) => {
      async function loadUserData(): Promise<void> {
        const [user, profiles, entitlements] = await Promise.all([
          getUser(),
          getProfiles(),
          getUserEntitlements(),
          consentManagement.syncWithLegalService()
        ]);
        context.user = user;
        context.profiles = profiles;
        context.entitlements = entitlements;
      }

      try {
        await loadUserData();
      } catch (error) {
        const errors = error.response?.data?.errors;
        const isInvalidToken =
          Array.isArray(errors) && errors.some((error) => error.code === 'invalid.token');

        if (!isInvalidToken) {
          throw error;
        }

        await getToken(true);
        await loadUserData();
      }
    })
    .pipe('Load Labs feature flags', async () => {
      const initialContext = context;
      initialContext.labsFeatureFlags = await bootstrapLabs(context);
    })
    .pipe('Return context', async () => {
      return context;
    });
}

export async function setup(
  platformAdapter: IPlatformAdapter,
  globalError: IGlobalErrorContext
): Promise<Partial<IInitialContext>> {
  const sequence = createBootSequence();
  const context: Partial<IInitialContext> = {
    platformAdapter,
    globalError
  };
  return sequence.start(context).catch((startError: SequenceError) => {
    context.startupError = startError;
    return context;
  });
}
