// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { Term } from '../data-models';
import { BoltDataModelCollection, Transformer } from '../data-transformer';
import { HttpClient, RequestConfigOverride } from '../http-client';
import { DataTypes, ITypedJsonApiDocumentFromModel } from '../json-api';

/**
 * This endpoint is used to get a list of the latest version of all terms that apply to the service
 * and whether or not the user has consented to that version. Each term returned from this end point
 * that the user has consented to will have a consent relationship.
 *
 * Clients should use this endpoint to render a list of terms that apply to the service so the user
 * can edit their consent or approve a new version of a term.
 *
 * @param params - The request parameters
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 *
 * @see https://docs.disco-api.com/apidocs/#user-terms
 */
export const termsRequest = async (
  params?: { include?: 'kind' | 'consent' | 'kind,consent' },
  config?: RequestConfigOverride
): Promise<Term[]> => {
  const http = HttpClient.instance;
  const response = await http.get<ITypedJsonApiDocumentFromModel<DataTypes.Term, Term>>(`/legal/terms`, {
    params: params,
    signal: config?.signal
  });

  const store = new Transformer(BoltDataModelCollection, response.data);
  return store.findAll(DataTypes.Term);
};

/**
 * This endpoint is used to get a the latest version of a specific term
 *
 * @param id - a term id
 * @param params - The request parameters
 * @param config - The request config used to pass in properties like an abort signal.
 *
 * @public
 *
 * @see https://docs.disco-api.com/apidocs/#user-terms
 */
export const termRequest = async (
  id: string,
  params?: { include?: 'kind' | 'consent' | 'kind,consent' },
  config?: RequestConfigOverride
): Promise<Term> => {
  const http = HttpClient.instance;
  const response = await http.get<ITypedJsonApiDocumentFromModel<DataTypes.Term, Term>>(
    `/legal/terms/${id}`,
    {
      params: params,
      signal: config?.signal
    }
  );

  const termId = response.data.data.id;

  const store = new Transformer(BoltDataModelCollection, response.data);
  return store.findNoNull(DataTypes.Term, termId);
};
