// Copyright text placeholder, Warner Bros. Discovery, Inc.

/* eslint-disable @rushstack/packlets/mechanics */
import { SessionManager } from '@wbd/instrumentation-session-manager';
import { Configuration } from '../core';
import { ApplicationHeartbeatV1 } from '../generated/events';

/**
 * The Heartbeat monitor class used to enable, disable and emit heartbeat events
 * @public
 */
export class _HeartbeatMonitor {
  private static readonly _defaultHeartbeatInterval: number = 30000;
  private _heartbeatInterval: number | null = null;
  private _delayedTimer: ReturnType<typeof setTimeout> | undefined;
  private _intervalTimer: ReturnType<typeof setInterval> | undefined;
  private _v1: ApplicationHeartbeatV1 = new ApplicationHeartbeatV1();

  public constructor(heartbeatInterval?: number) {
    this.heartbeatInterval = heartbeatInterval;
    this._emit();
  }

  /**
   * delays the scheduling based on a delay amount
   * @param delay - the delay to schedule the heartbeat events
   */
  private _delaySchedule(delay: number): void {
    clearTimeout(this._delayedTimer);
    this._delayedTimer = setTimeout(this._schedule.bind(this), delay);
  }

  /**
   * disables the heartbeat events by clearing the interval timer
   */
  public disable(): void {
    clearInterval(this._intervalTimer);
    this._intervalTimer = undefined;
  }

  /**
   * calls the emit function on the heartbeat event
   */
  private _emit(): void {
    if (!SessionManager.isSessionExpired()) {
      this._v1.emit({});
    }
  }

  /**
   * enables the heartbeat events by scheduling heartbeats
   */
  public enable(): void {
    if (!this._intervalTimer) {
      this._emit();
      this._schedule();
    }
  }

  /**
   * gets the heartbeat interval
   */
  public get heartbeatInterval(): number {
    return this._heartbeatInterval ?? _HeartbeatMonitor._defaultHeartbeatInterval;
  }

  public set heartbeatInterval(heartbeatInterval: number | undefined) {
    const originalInterval = this._heartbeatInterval;
    const newInterval = Math.max(heartbeatInterval ?? 0, _HeartbeatMonitor._defaultHeartbeatInterval);

    // no change in interval so we can return
    if (originalInterval === newInterval) {
      return;
    }

    this._heartbeatInterval = newInterval;

    // original interval was null so we need to schedule immediately
    if (originalInterval === null) {
      this._schedule();
      return;
    }

    // there is a difference between the original and new interval and we must delay scheduling
    const delay = Math.max(newInterval - originalInterval, 0);
    this._delaySchedule(delay);
  }

  /**
   * schedules the heartbeat emitting
   */
  private _schedule(): void {
    this._intervalTimer = setInterval(this._emit.bind(this), this.heartbeatInterval);
  }
}

/**
 * The Heartbeat namespace that allows enable and disable heartbeat events
 * @public
 */
/* eslint-disable @typescript-eslint/no-namespace */
export namespace HeartbeatMonitor {
  /** instance of the heartbeat monitor that is defined on initialization */
  export let heartbeatInstance: _HeartbeatMonitor;

  /** initializes the heartbeat monitor and connects the public apis to the private apis */
  export function initialize(config: Configuration): void {
    heartbeatInstance = new _HeartbeatMonitor(config.heartbeatInterval);
  }

  /** updates the heartbeat monitor with new configuration */
  export function update(config: Configuration): void {
    heartbeatInstance.heartbeatInterval = config.heartbeatInterval;
  }

  /**
   *  The Heartbeat namespace that allows for enabling and disabling heartbeat events
   */
  export namespace Heartbeat {
    /** enables the heartbeat events */
    export function enable(): void {
      return heartbeatInstance.enable();
    }

    /** disables the heartbeat events */
    export function disable(): void {
      return heartbeatInstance.disable();
    }
  }
}
