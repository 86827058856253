// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { Listener, Unsubscribe } from '@wbd/light-events';
import { EventWithParams } from '@wbd/light-events';

import type { IInterceptor, IInterceptors, IRequestConfig, IResponse } from '../http-internal';
import { AuthToken } from '../token';
import type { ILocalizationPayload } from './ILocalizationPayload';
import type { ISessionState } from './ISessionState';
import type { IStorage } from './IStorage';
import { isRefreshSignal, RefreshSignal } from './RefreshSignal';

const SESSION_STATE_KEY: string = 'session-state';

/**
 * This class manages retrieval, caching and manipulation of Session-state headers for Headwaiter responses in the client
 * @public
 */
export class SessionState implements ISessionState {
  private readonly _onRefreshEvent: EventWithParams<[RefreshSignal, ILocalizationPayload, AuthToken?]> =
    new EventWithParams<[RefreshSignal, ILocalizationPayload, AuthToken?]>();

  /**
   * Persistent storage
   */
  private _persistentStorage?: IStorage;
  /**
   * A string that holds the latest value of the session state header
   */
  private _sessionStateHeader?: string;

  /**
   * An object that holds decoded session state payload values
   */
  private _payloadValues: Record<string, string | undefined> = {};

  /**
   * Set to persistent storage
   * @public
   */
  public constructor(sessionStorage?: IStorage) {
    this._persistentStorage = sessionStorage;
  }

  /**
   * Response interceptor for x-wbd-session-state
   * @internal
   */
  private _responseInterceptor: IInterceptor<IResponse> = async (response) => {
    const sessionStateValue = response.headers['x-wbd-session-state'];

    if (sessionStateValue) {
      // Writes `x-wbd-session-state` from response headers to storage
      this.setHeader(sessionStateValue);
    }

    const refreshType = response.headers['x-wbd-refresh'];
    if (isRefreshSignal(refreshType)) {
      // @ts-ignore
      const resource = response.data?.data;
      const token = resource?.attributes?.token;
      // Trigger `x-wbd-refresh` update to clients
      const localizationPayload = this.getLocalizationPayload() as ILocalizationPayload;
      await this._onRefreshEvent.fire(refreshType, localizationPayload, token);
    }
    return response;
  };

  /**
   * Request interceptor for x-wbd-session-state
   * @internal
   */
  private _requestInterceptor: IInterceptor<IRequestConfig> = (config) => {
    const sessionStateHeader = this.getHeader();
    // Adds `x-wbd-session-state` inside request headers by reading from session storage
    if (sessionStateHeader && config.headers) {
      config.headers['x-wbd-session-state'] = sessionStateHeader;
    }

    return config;
  };

  public configureInterceptors(interceptors: IInterceptors): void {
    interceptors.request.use(this._requestInterceptor);
    interceptors.response.use(this._responseInterceptor);
  }
  /**
   * Adds a listener to be called when onRefreshEvent is fired
   * @param listener - The listener to be added to the onRefreshEvent
   * @public
   */
  public onRefresh(
    listener: Listener<[RefreshSignal, ILocalizationPayload, AuthToken | undefined]>
  ): Unsubscribe {
    return this._onRefreshEvent.addListener(listener);
  }

  /**
   * Gets the current payload headers if sessionState private variable is undefined
   * @returns
   */
  public getHeader(): string | undefined {
    if (!this._sessionStateHeader && this._persistentStorage) {
      this._sessionStateHeader = this._persistentStorage.readSync(SESSION_STATE_KEY);
    }
    return this._sessionStateHeader;
  }

  /**
   * Takes a key/value pair and saves it to storage synchronously
   * @param value - The value to be stored under `key`
   * @param append - Default to false
   * @throws An Exception when the key/value pair cannot be added
   */
  public setHeader(value: string): void {
    // break the header down in its payload values
    if (value) {
      this._updatePayloadValues(value);
      const sessionStateHeader = this._payloadValuesToString();
      this._persistentStorage?.writeSync(SESSION_STATE_KEY, sessionStateHeader);
      this._sessionStateHeader = sessionStateHeader;
    }
  }

  /**
   * get the localization object from the headwaiter session state header.
   * This objects contains the  locale and selectedLanguages
   * information needed by the client in order to properly enable
   * a language
   * @returns (ILocalizationPayload) localization object
   */
  public getLocalizationPayload(): ILocalizationPayload | undefined {
    const clonedPayloadValues = Object.assign({}, this._payloadValues);
    const payloadValue = clonedPayloadValues.localization;
    if (!payloadValue) return;
    return this._decodePayload<ILocalizationPayload>(payloadValue);
  }

  /**
   * Converts a string of key:value pairs, separated by semicolons, into an object.
   *
   * The string is expected to be in the following form:
   *   key1:val1;key2:val2;...keyN:valN;
   *
   * This function is unsafe in the sense that no validation is done on the input string's format, for performance.
   * If the input does not follow the expected format, the output will be malformed.
   *
   * @param payload - String of key-value pairs, separated by semicolon (see function documentation for expected format)
   * @returns An object composed of the key-value pairs parsed from the input.
   */
  private _updatePayloadValues(payload: string): void {
    const keyValuePairs = payload.split(';');
    keyValuePairs.forEach((keyValuePair) => {
      if (keyValuePair && keyValuePair.length) {
        const keyValueArray = keyValuePair.split(':');
        if (keyValueArray.length === 2 && keyValueArray[1].length) {
          this._payloadValues[keyValueArray[0]] = keyValueArray[1];
        } else if (Object.prototype.hasOwnProperty.call(this._payloadValues, keyValueArray[0])) {
          delete this._payloadValues[keyValueArray[0]];
        }
      }
    });
  }

  /**
   * Converts key:value pairs into a string.
   *
   * The string is expected to be in the following form:
   *   key1:val1;key2:val2;...keyN:valN;
   * @returns header key:value pairs concatenated as string
   */
  private _payloadValuesToString(): string {
    let header = '';
    if (this._payloadValues) {
      for (const key in this._payloadValues) {
        if (Object.prototype.hasOwnProperty.call(this._payloadValues, key)) {
          header += `${key}:${this._payloadValues[key]};`;
        }
      }
    }
    return header;
  }

  /**
   * decodes a base64 encoded payload value into a json object
   * @param payloadValue - the payload value from the headwaiter response header
   * @returns Decoded payload object
   */
  private _decodePayload<DecodedPayloadFormat>(payloadValue: string): DecodedPayloadFormat {
    try {
      const decodedValue = atob(payloadValue.split('.')[1]);
      return JSON.parse(decodedValue);
    } catch (e) {
      /**
       * if we can not parse the localization payload throw an error
       */
      throw new Error(`Invalid encoding for session-state header with payload ${payloadValue}`);
    }
  }
}
