// Copyright text placeholder, Warner Bros. Discovery, Inc.

export const parseBrowserUserAgent = (
  userAgent = window.navigator.userAgent
): {
  deviceName: string | undefined;
  deviceVersion: string | undefined;
} => {
  const browserRegex = /(firefox|msie|chrome|safari)[/\s]([\d.]+)/i;
  const parsedResult = userAgent.match(browserRegex);
  let deviceName,
    deviceVersion = undefined;
  if (parsedResult) {
    [, deviceName, deviceVersion] = parsedResult;
  }
  return {
    deviceName,
    deviceVersion
  };
};
