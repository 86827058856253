// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { LanguageCode } from '../../nominals';
import { isLanguageCode } from '../type-checkers';

/**
 * Adds "LanguageCode" to the type of the given code, without reducing the specificity of its type.
 *
 * @public
 */
export function asLanguageCode<TCode extends string>(code: TCode): TCode & LanguageCode {
  if (isLanguageCode(code)) {
    return code;
  } else {
    throw new Error(`Code "${code}" is not a LanguageCode`);
  }
}
