// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  CurrencyFormat,
  DateFormat,
  DurationFormat,
  NumberFormat,
  PhoneNumberFormat,
  RelativeTimeFormat,
  UnitFormat
} from '../formats';
import {
  DataType,
  IFormattedMessageParameters,
  ITranslationSourceData,
  MessageKeyWithParameters,
  MessageKeyWithoutParameters,
  MessageParameters,
  ParameterSpecifications,
  isICurrencyValue
} from '../i18n-types';
import { FormatCode, ObjectUtils } from '@wbd/localization-core';

import { FormatterLoader } from './FormatterLoader';
import { GlobalizationContext } from './GlobalizationContext';
import { HybridLocaleFormatter } from './HybridLocaleFormatter';
import { IFormatter } from './IFormatter';
import { Translator } from './Translator';
import { TranslatorLoader } from './TranslatorLoader';

// Internally, we use this type to pass around the various formats where we are not correctly
// determining the specific type with type algebra. I'll get it working with the strong types
// later. But, for now, it's just a union of all of the format types, and then we re-verify
// them with runtime type checks later.
type AnyFormat =
  | CurrencyFormat
  | DateFormat
  | DurationFormat
  | NumberFormat
  | PhoneNumberFormat
  | RelativeTimeFormat
  | UnitFormat;

/**
 * Holds the resources necessary to localize strings and data into a particular language and format.
 *
 * Language/translation resources are defined by a `resources.ts` file and the corresponding `translations.json`
 * file for the specified language code.
 *
 * Formatting resources are defined by the CLDR dataset (via Globalize) for the specified format code.
 *
 * @public
 */
export class Globalizer<TSourceData extends ITranslationSourceData> {
  private readonly _globalizationContext: GlobalizationContext;
  private readonly _translator: Translator<TSourceData>;
  private readonly _formatter: IFormatter;

  /**
   * Constructs a `Globalizer` for the given `GlobalizationContext`, using the given `Translator` and `Formatter`.
   *
   * The `languageCode` of `globalizationContext` must match the `languageCode` of `translator`.
   *
   * The `regionBasedFormatCode` and `languageBasedFormatCode` of `globalizationContext` must match the `FormatCode`(s) of `formatter`:
   *    - If `globalizationContext` has different `regionBasedFormatCode` and `languageBasedFormatCode` values, `formatter`
   *      must be a `HybridLocaleFormatter` whose `regionBasedFormatter`'s `FormatCode` must match the `HybridLocaleFormatter`'s
   *      `regionBasedFormatCode`, and whose `languageBasedFormatter`'s `FormatCode` must match the `HybridLocaleFormatter`'s `languageBasedFormatCode`.
   *    - If `globalizationContext` has the same `FormatCode` for `regionBasedFormatCode` and `languageBasedFormatCode`, `formatter`
   *      must be a `Formatter` whose `formatCode` matches that `FormatCode`.
   *
   * @param globalizationContext - the `GlobalizationContext` for the `Globalizer`
   * @param translator - the `Translator` for the `Globalizer`
   * @param formatter - the `Formatter` for the `Globalizer`, whose format(s) must match `globalizationContext`
   */
  public constructor(
    globalizationContext: GlobalizationContext,
    translator: Translator<TSourceData>,
    formatter: IFormatter
  ) {
    this._globalizationContext = globalizationContext;
    this._translator = translator;
    this._formatter = formatter;
  }

  /**
   * A factory method that asynchronously loads the translation and formatting resources needed to create a
   * `Globalizer` for a particular resource bundle's resources for a given `GlobalizationContext`. The type information
   * of the created `Globalizer` is inferred from the type information of the provided `TranslatorLoader` function.
   *
   * This means that the provided `TranslatorLoader` must retain the full type information of its corresponding
   * resource bundle. It must be typed as `TranslatorLoader<TSourceData>`, where `TSourceData` is the type of the
   * translation specification from the particular resource bundle. It cannot be cast to `TranslatorLoader<unknown>`.
   *
   * For pure-Javascript and hybrid Javascript/TypeScript projects, where accurate typing information is not maintained
   * throughout the project, the `TranslatorLoader` can be cast to `TranslatorLoader<any>` to disable the strong type
   * checking of the runtime API in the returned `Globalizer`.
   *
   * @param globalizationContext - the `GlobalizationContext` for which to load the resources for the `Globalizer`
   * @param translatorLoader - the `TranslatorLoader` function which will asynchronously load the `Translator` for the
   *    target language code
   * @param formatterLoader - the `FormatterLoader` function which will asynchronously load the `Formatter` for the
   *    target format code
   */
  public static async loadGlobalizerAsync<TSourceData extends ITranslationSourceData>(
    globalizationContext: GlobalizationContext,
    translatorLoader: TranslatorLoader<TSourceData>,
    formatterLoader: FormatterLoader
  ): Promise<Globalizer<TSourceData>> {
    const [translator, formatter]: [Translator<TSourceData>, IFormatter] = await Promise.all([
      translatorLoader(globalizationContext.languageCode),
      this._createFormatterForContextAsync(globalizationContext, formatterLoader)
    ]);
    return new Globalizer(globalizationContext, translator, formatter);
  }

  private static async _createFormatterForContextAsync(
    globalizationContext: GlobalizationContext,
    formatterLoader: FormatterLoader
  ): Promise<IFormatter> {
    if (globalizationContext.languageBasedFormatCode === globalizationContext.regionBasedFormatCode) {
      return await formatterLoader(globalizationContext.regionBasedFormatCode);
    } else {
      const [regionBasedFormatter, languageBasedFormatter]: [IFormatter, IFormatter] = await Promise.all([
        formatterLoader(globalizationContext.regionBasedFormatCode),
        formatterLoader(globalizationContext.languageBasedFormatCode as FormatCode)
      ]);
      return new HybridLocaleFormatter(regionBasedFormatter, languageBasedFormatter);
    }
  }

  /**
   * Returns the `GlobalizationContext` for this `Globalizer`.
   */
  public get globalizationContext(): GlobalizationContext {
    return this._globalizationContext;
  }

  /**
   * Returns the `Translator` for this `Globalizer`.
   */
  public get translator(): Translator<TSourceData> {
    return this._translator;
  }

  /**
   * Returns the `Formatter` for this `Globalizer`.
   */
  public get formatter(): IFormatter {
    return this._formatter;
  }

  /**
   * Looks up the requested message key in the runtime language pack for the language code, and returns the
   * corresponding translated string.
   *
   * The message template must not expect parameters.
   *
   * Although the message key is constrained by the translation source file, we know that it will be defined
   * in the language pack, because our build tools guarantee to generate language packs with translations for
   * all of the translation source file's message keys (falling back to the messages in the translation source
   * file itself if no more specific fallback translation is available).
   *
   * @param messageKey - the requested message key
   */
  public globalize<TMessageKey extends MessageKeyWithoutParameters<TSourceData> & string>(
    messageKey: TMessageKey
  ): string {
    return this.translator.translate(messageKey);
  }

  /**
   * Looks up the requested message key in the runtime language pack for the language code, and returns the
   * corresponding translated accessible string. If the message key does not have an accessible version,
   * the translated standard message is returned.
   *
   * The message template must not expect parameters.
   *
   * Although the message key is constrained by the translation source file, we know that it will be defined
   * in the language pack, because our build tools guarantee to generate language packs with translations for
   * all of the translation source file's message keys (falling back to the messages in the translation source
   * file itself if no more specific fallback translation is available).
   *
   * @param messageKey - the requested message key
   */
  public globalizeAccessible<TMessageKey extends MessageKeyWithoutParameters<TSourceData> & string>(
    messageKey: TMessageKey
  ): string {
    return this.translator.translateAccessible(messageKey);
  }

  /**
   * Returns a localized string for the given message key, with properly formatted and interpolated variables.
   * The template corresponding to the message key must expect parameters.
   *
   * The localization process:
   *  1) Look up the message specification for the message key in `_translationSourceData`.
   *  2) For each parameter format in the message specification, invoke the appropriate formatting method
   *    on the `Formatter` with that parameter's value and the requested format.
   *  3) Invoke `_globalizeModule.messageFormatter()` with the message key and the formatted parameters.
   *
   * Although the message key and its corresponding parameters are constrained by the translation source file,
   * we know that it will be defined in the language pack with the same parameters, because our build tools
   * guarantee to generate language packs with translations for all of the translation source file's message keys
   * (falling back to the messages in the translation source file itself if no more specific fallback translation
   * is available), with the same parameters as in the translation source file.
   *
   * @param messageKey - the requested message key
   * @param parameters - an object containing the parameters required by the message specification for the given
   * message key (as defined in the translation source file)
   */
  public globalizeWithParameters<TMessageKey extends MessageKeyWithParameters<TSourceData> & string>(
    messageKey: TMessageKey,
    parameters: MessageParameters<TSourceData, TMessageKey>
  ): string {
    return this._globalizeWithParametersInternal(messageKey, parameters, false);
  }

  /**
   * Returns the localized accessible string for the given message key, with properly formatted and interpolated variables.
   * If the given message key does not have an accessible version, the standard version is returned.
   *
   * See `globalizeWithParameters()` for details.
   *
   * @param messageKey - the requested message key
   * @param parameters - an object containing the parameters required by the message specification for the given
   * message key (as defined in the translation source file)
   */
  public globalizeAccessibleWithParameters<
    TMessageKey extends MessageKeyWithParameters<TSourceData> & string
  >(messageKey: TMessageKey, parameters: MessageParameters<TSourceData, TMessageKey>): string {
    return this._globalizeWithParametersInternal(messageKey, parameters, true);
  }

  private _globalizeWithParametersInternal<
    TMessageKey extends MessageKeyWithParameters<TSourceData> & string
  >(
    messageKey: TMessageKey,
    parameters: MessageParameters<TSourceData, TMessageKey>,
    isAccessible: boolean
  ): string {
    const formalParameters: ParameterSpecifications<TSourceData, TMessageKey> =
      this._translator.translationSourceData[messageKey].parameters;

    if (formalParameters === undefined) {
      throw new Error(`Message key "${messageKey}" does not take parameters`);
    }

    if (parameters === undefined) {
      throw new Error(`No parameters provided for message key "${messageKey}"`);
    }

    const allParameters: IFormattedMessageParameters<TSourceData, TMessageKey> = {
      ...parameters,
      ...this._formatParameters(parameters, formalParameters)
    };

    return isAccessible
      ? this.translator.translateAccessibleWithParameters(messageKey, allParameters)
      : this.translator.translateWithParameters(messageKey, allParameters);
  }

  private _formatParameters<TMessageKey extends MessageKeyWithParameters<TSourceData>>(
    parameters: MessageParameters<TSourceData, TMessageKey>,
    formalParameters: ParameterSpecifications<TSourceData, TMessageKey>
  ): IFormattedMessageParameters<TSourceData, TMessageKey> {
    const formattedParameters: IFormattedMessageParameters<TSourceData, TMessageKey> = {};

    if (formalParameters) {
      for (const [parameterName, parameterSpecification] of Object.entries(formalParameters)) {
        // The compiler loses the type information about `parameterName` when passing through
        // `Object.entries()`, so we re-validate it here to reassure the compiler.
        if (!ObjectUtils.isKeyOf(parameters, parameterName)) {
          throw new Error(`No value provided for parameter "${parameterName}"`);
        }

        if (parameterSpecification.format !== undefined) {
          formattedParameters[parameterName] = this._formatParameter(
            parameterName,
            parameters[parameterName],
            parameterSpecification.type,
            parameterSpecification.format
          );
        }

        if (parameterSpecification.formatted !== undefined) {
          for (const [formattedName, formattedFormat] of Object.entries(parameterSpecification.formatted)) {
            formattedParameters[formattedName] = this._formatParameter(
              parameterName,
              parameters[parameterName],
              parameterSpecification.type,
              formattedFormat
            );
          }
        }
      }
    }
    return formattedParameters;
  }

  /**
   * All of the dynamic type checks here are formally unnecessary -- I just didn't have the . We'll come back and fix it when we have time, or (more likely)
   * Pete and Mickey will replace the whole mess with a dynamically generated .d.ts file.
   */
  private _formatParameter(
    parameterName: string,
    value: unknown,
    dataType: DataType,
    format: AnyFormat
  ): string {
    switch (dataType) {
      case DataType.CURRENCY: {
        if (!isICurrencyValue(value)) {
          throw new Error(
            `Value given ("${value}") for currency parameter "${parameterName}" is not an ICurrencyValue object`
          );
        }
        if (!this._isEnumValue<CurrencyFormat>(format, Object.values(CurrencyFormat))) {
          throw new Error(
            `Format given ("${format}") for currency parameter "${parameterName}" is not a CurrencyFormat`
          );
        }
        return this._formatter.formatAsCurrency(value, format);
      }

      case DataType.DATE: {
        if (!(value instanceof Date)) {
          throw new Error(
            `Value given ("${value}") for date parameter "${parameterName}" is not a Date object`
          );
        }
        if (!this._isEnumValue<DateFormat>(format, Object.values(DateFormat))) {
          throw new Error(
            `Format given ("${format}") for date parameter "${parameterName}" is not a DateFormat`
          );
        }
        return this._formatter.formatAsDate(value, format);
      }

      case DataType.DURATION: {
        if (typeof value !== 'number') {
          throw new Error(
            `Value given ("${value}") for duration parameter "${parameterName}" is not a number`
          );
        }
        if (!this._isEnumValue<DurationFormat>(format, Object.values(DurationFormat))) {
          throw new Error(
            `Format given ("${format}") for duration parameter "${parameterName}" is not a DurationFormat`
          );
        }
        return this._formatter.formatAsDuration(value, format);
      }

      case DataType.NUMBER: {
        if (typeof value !== 'number') {
          throw new Error(`Value given ("${value}") for number parameter "${parameterName}" is not a number`);
        }
        if (!this._isEnumValue<NumberFormat>(format, Object.values(NumberFormat))) {
          throw new Error(
            `Format given ("${format}") for number parameter "${parameterName}" is not a NumberFormat`
          );
        }
        return this._formatter.formatAsNumber(value, format);
      }

      case DataType.PHONE_NUMBER: {
        if (typeof value !== 'string') {
          throw new Error(
            `Value given ("${value}") for PhoneNumber parameter "${parameterName}" is not a string`
          );
        }
        if (!this._isEnumValue<PhoneNumberFormat>(format, Object.values(PhoneNumberFormat))) {
          throw new Error(
            `Format given ("${format}") for PhoneNumber parameter "${parameterName}" is not aPhoneNumberFormat`
          );
        }
        return this._formatter.formatAsPhoneNumber(value, format);
      }

      case DataType.RELATIVE_TIME: {
        if (typeof value !== 'number') {
          throw new Error(
            `Value given ("${value}") for RelativeTime parameter "${parameterName}" is not a number`
          );
        }
        if (!this._isEnumValue<RelativeTimeFormat>(format, Object.values(RelativeTimeFormat))) {
          throw new Error(
            `Format given ("${format}") for RelativeTime parameter "${parameterName}" is not a RelativeTimeFormat`
          );
        }
        return this._formatter.formatAsRelativeTime(value, format);
      }

      case DataType.UNIT: {
        if (typeof value !== 'number') {
          throw new Error(`Value given ("${value}") for Unit parameter "${parameterName}" is not a number`);
        }
        if (!this._isEnumValue<UnitFormat>(format, Object.values(UnitFormat))) {
          throw new Error(
            `Format given ("${format}") for Unit parameter ${parameterName} is not a UnitFormat`
          );
        }
        return this._formatter.formatAsUnit(value, format);
      }

      default:
        return '' + value;
    }
  }

  private _isEnumValue<TEnum extends AnyFormat>(
    format: AnyFormat,
    values: (string | AnyFormat)[]
  ): format is TEnum {
    return values.includes(format as string);
  }
}
