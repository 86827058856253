// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IUiLaunchV1Err } from '@wbd/instrumentation-sdk';
import {
  generateV1Err,
  InstrumentationSDK,
  UiLaunchV1Classification,
  UiLaunchV1LaunchState,
  UiLaunchV1Scope
} from '@wbd/instrumentation-sdk';
import { bootstrapAppAsync, type IBootstrapAppOptions } from './bootstrapAppAsync';
import { loadCanvasErrorFallback } from './loadUIFallback';

/**
 * Sync wrapper for bootstrap to capture uncaught promise exceptions
 * @param config - bootstrap configuration
 */
export function bootstrapApp(config: IBootstrapAppOptions): void {
  bootstrapAppAsync(config).catch((error) => {
    InstrumentationSDK.Errors.Ui.Launch.v1.capture({
      err: generateV1Err<IUiLaunchV1Err>(error, {
        scope: UiLaunchV1Scope.USER_FACING,
        classification: UiLaunchV1Classification.UNKNOWN
      }),
      launchState: UiLaunchV1LaunchState.INITIALIZING
    });

    // present user fallback message
    loadCanvasErrorFallback({ ...config, startupError: error });
  });
}
