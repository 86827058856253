// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ArticleBodyRichText } from './ArticleBodyRichText';
import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @public
 */
export interface IMarketingBadgeAttributes {
  /** Background badge color (e.g. 0xFF00FFFF) */
  backgroundColor?: string;
}
/**
 * @public
 */
export interface IMarketingBadgeRelationships {
  text: ArticleBodyRichText;
}

/**
 * @public
 */
export class MarketingBadge extends TypedModel.generate<
  DataTypes.MarketingBadge,
  IMarketingBadgeAttributes,
  IMarketingBadgeRelationships
>() {}
