// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { CompositeError } from './CompositeError';
import { Listener } from './Listener';
import { Unsubscribe } from './Unsubscribe';

/**
 * An event that can register event listeners (callback functions), and invoke them when the event is fired.
 *
 * The listeners will be invoked with the event parameters defined by `TParams`. If the listeners do not take
 * event parameters, use `NotificationEvent` instead.
 *
 * The listeners will be invoked asynchronously and in indeterminate order, using `Promise.allSettled()`.
 *
 * @example - Parameterized event (listeners will be called with the event parameters).
 * ```
 * export type OnNameChangedParams = [oldName: string, newName: string] => Promise<void>;
 *
 * export public MyClass {
 *   private readonly _onNameChanged: EventWithParams<OnNameChangedParams> = new EventWithParams();
 *   public readonly onNameChanged: ListenerAdder<Listener<OnNameChangedParams> = this._onNameChanged.addListener;
 *
 *   public async myMethod(oldName: string, newName: string): void {
 *     // This will invoke each registered listener with the parameters (oldName, newName).
 *     await this._onNameChanged.fire(oldName, newName);
 *   }
 * }
 * ```
 *
 * The types
 * `EventWithParams<[foo: string, bar: number]>`
 * and
 * `NotificationEvent<(foo: string, bar: number) => void | Promise<void>>`
 * are identical.
 *
 * @param TParams -  The type(s) of the parameters(s) for the event listeners of this event
 *
 * @public
 */
export class EventWithParams<TParams extends unknown[] = []> {
  private readonly _listeners: Listener<TParams>[] = [];

  /**
   * Registers a listener (callback function) to be notified when the event is fired. To remove the listener,
   * call the function that is returned by this method.
   *
   * The listener will be invoked with the event parameters specified by the `TListener` call signature.
   *
   * The listener will be invoked asynchronously. If the listener is an `async` function, it will complete
   * before the `fire()` method resolves.
   *
   * @param listener - a listener (callback function) to be registered with this event
   *
   * @returns an "unregister" function that will remove the listener when it is called
   */
  public addListener(listener: Listener<TParams>): Unsubscribe {
    this._listeners.push(listener);
    return () => {
      const indexOfListener: number = this._listeners.indexOf(listener);
      if (indexOfListener >= 0) {
        this._listeners.splice(indexOfListener, 1);
      }
    };
  }

  /**
   * Fires the event, invoking each registered listener (callback function) with the given event event parameters.
   *
   * The listeners will be invoked asynchronously, and this method will not resolve until all of the listeners have
   * resolved.
   *
   * @param eventParams - the event parameters that will be passed to each listener when it is invoked
   */
  public async fire(...eventParams: TParams): Promise<void> {
    const listenerPromises: Promise<void>[] = this._listeners.map(
      async (listener) => await listener(...eventParams)
    );
    const results: PromiseSettledResult<void>[] = await Promise.allSettled(listenerPromises);
    this._handleErrors(results);
  }

  private _handleErrors(results: PromiseSettledResult<void>[]): void {
    const errors: Error[] = [];
    for (const result of results) {
      if (result.status === 'rejected') {
        const reason = result.reason;
        errors.push(reason instanceof Error ? reason : new Error(String(reason)));
      }
    }
    if (errors.length > 0) {
      if (errors.length === 1) {
        throw errors[0];
      } else {
        throw new CompositeError(`${errors.length} event listeners threw errors.`, errors);
      }
    }
  }
}
