// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @public
 */
export enum ImageKind {
  Alternate = 'alternate',
  BackgroundDesktop = 'background_desktop',
  BackgroundCTV = 'background_ctv',
  CenteredBackgroundSmall = 'centered-background-small',
  CoverArtwork = 'cover-artwork',
  CoverArtworkHorizontal = 'cover-artwork-horizontal',
  CoverArtworkHovered = 'cover-artwork-hover',
  CoverArtworkSquare = 'cover-artwork-square',
  CoverHover = 'cover-hover',
  Default = 'default',
  DefaultWide = 'default-wide',
  Desktop = 'desktop',
  GrayScale = 'logo_grayscale',
  Logo = 'logo',
  LogoAlternate = 'logo_alternate',
  LogoAttr = 'logo_attribution',
  LogoCentered = 'logo-centered',
  LogoLeft = 'logo-left',
  Poster = 'poster',
  PosterWithLogo = 'poster-with-logo',
  Square = 'square',
  Thumbnail = 'thumbnail'
}

/**
 * @see https://docs.disco-api.com/apidocs/#image-api
 * @public
 */
export interface IImageAttributes {
  /** Image alias. */
  alias?: string;
  /** Coordinates of the centre-point when cropping an image. */
  cropCenter?: {
    x: number;
    y: number;
  };
  default?: boolean;
  /** Image description. */
  description?: string;
  /** Image height. */
  height: number;
  /** Image kind (e.g. `default`, `thumbnail`). */
  kind: ImageKind;
  /** Image name. */
  name?: string;
  /** Image path for requesting. */
  src: string;
  /** Image title. */
  title?: string;
  /** Image width. */
  width: number;
}

/**
 * @public
 */
export class Image extends TypedModel.generate<DataTypes.Image, IImageAttributes>() {}
