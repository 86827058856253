// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IFeatureFlagOptions, IFeatureFlagResponse } from '@wbd/bolt-dataservice';
import { featureFlagsRequest } from '@wbd/bolt-dataservice';
import { BoltEnvironment } from '@wbd/bolt-http';
import {
  InstrumentationSDK,
  PlatformConfigurationV1Classification,
  PlatformConfigurationV1Scope,
  generateV1Err
} from '@wbd/instrumentation-sdk';
import type { IInitialContext } from '../application';

const CDEV_LABS_PROJECT_IDS: Record<BoltEnvironment, string> = {
  [BoltEnvironment.DEV]: 'b0533db5-c169-472c-8fd0-bd9885049a8f',
  [BoltEnvironment.INT]: '9238f465-6cfc-44e9-bd87-ace0b24737d2',
  [BoltEnvironment.STG]: 'dc1343cd-4b7d-4c21-889c-5eacf5b73c3d',
  [BoltEnvironment.PRD]: 'af9e9ccd-689d-4b72-b03c-e54455369b2c'
};

/**
 * Available Segment ids that we can send to labs
 * https://wbdstreaming.atlassian.net/wiki/spaces/GCX/pages/279085057/CDEV-LADR+004+Labs+Flags
 */
export enum LabsSegmentIds {
  /**
   * An optional unique string for segmenting a specific device model and/or chipset
   */
  DEVICE_CHIPSET = 'deviceChipset',
  /**
   * DEPRECATED: Instead use osModelYear
   */
  DEVICE_GROUP = 'deviceGroup',
  /**
   * Expects the performance string ('high', 'average', 'low') that comes from DevicePerformance.ts
   */
  DEVICE_PERFORMANCE = 'devicePerformance',
  /**
   * This segment expects platform-year. E.g. 'samsungtv-2016'
   */
  OS_MODEL_YEAR = 'osModelYear'
}

export async function bootstrapLabs(
  context: IInitialContext
): Promise<Partial<IFeatureFlagResponse> | undefined> {
  const requestConfig: IFeatureFlagOptions = {
    projectId: CDEV_LABS_PROJECT_IDS[context.boltEnvironment]
  };

  const { deviceGroup, performance, modelYear, chipset, boltPlatformName } = context.deviceBootInfo;

  const LabsSegments: Record<LabsSegmentIds, string> = {
    [LabsSegmentIds.DEVICE_GROUP]: deviceGroup || '',
    [LabsSegmentIds.DEVICE_PERFORMANCE]: performance || '',
    [LabsSegmentIds.OS_MODEL_YEAR]: `${boltPlatformName}-${modelYear}`,
    [LabsSegmentIds.DEVICE_CHIPSET]: chipset
  };
  requestConfig.context = LabsSegments;

  try {
    const featureFlagsResponse = await featureFlagsRequest(requestConfig);
    return featureFlagsResponse;
  } catch (error) {
    InstrumentationSDK.Errors.Platform.Configuration.v1.capture({
      err: generateV1Err(error, {
        classification: PlatformConfigurationV1Classification.FEATURE_FLAGS,
        scope: PlatformConfigurationV1Scope.RECOVERABLE
      })
    });
  }
}
