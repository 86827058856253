// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * The script subtags from the IETF BCP 47 standard (see https://wiki.hbo.com/pages/viewpage.action?pageId=160140159#LanguageCodesandFormatCodes:Locale\>GloablizationContext-ISO639-1Alpha2LanguageCodes).
 *
 * Official values were sourced from the Library of Congress:
 *    Website: https://id.loc.gov/vocabulary/iso639-1.html
 *    Data file URL: https://id.loc.gov/vocabulary/iso639-1.json
 *    Sourced on: 2021-03-23
 *
 * This file was generated by `localization-enum-generator`. Please edit the template there to make any desired
 * changes. Changes made directly to this file will be overwritten without warning if this file is re-generated.
 *
 * @public
 */
export const enum IetfScriptCode {
  /** Adlam */
  ADLM = 'Adlm',

  /** Afaka */
  AFAK = 'Afak',

  /** Caucasian Albanian */
  AGHB = 'Aghb',

  /** Ahom | Tai Ahom */
  AHOM = 'Ahom',

  /** Arabic */
  ARAB = 'Arab',

  /** Arabic (Nastaliq variant) */
  ARAN = 'Aran',

  /** Imperial Aramaic */
  ARMI = 'Armi',

  /** Armenian */
  ARMN = 'Armn',

  /** Avestan */
  AVST = 'Avst',

  /** Balinese */
  BALI = 'Bali',

  /** Bamum */
  BAMU = 'Bamu',

  /** Bassa Vah */
  BASS = 'Bass',

  /** Batak */
  BATK = 'Batk',

  /** Bengali | Bangla */
  BENG = 'Beng',

  /** Bhaiksuki */
  BHKS = 'Bhks',

  /** Blissymbols */
  BLIS = 'Blis',

  /** Bopomofo */
  BOPO = 'Bopo',

  /** Brahmi */
  BRAH = 'Brah',

  /** Braille */
  BRAI = 'Brai',

  /** Buginese */
  BUGI = 'Bugi',

  /** Buhid */
  BUHD = 'Buhd',

  /** Chakma */
  CAKM = 'Cakm',

  /** Unified Canadian Aboriginal Syllabics */
  CANS = 'Cans',

  /** Carian */
  CARI = 'Cari',

  /** Cham */
  CHAM = 'Cham',

  /** Cherokee */
  CHER = 'Cher',

  /** Chorasmian */
  CHRS = 'Chrs',

  /** Cirth */
  CIRT = 'Cirt',

  /** Coptic */
  COPT = 'Copt',

  /** Cypro-Minoan */
  CPMN = 'Cpmn',

  /** Cypriot syllabary */
  CPRT = 'Cprt',

  /** Cyrillic */
  CYRL = 'Cyrl',

  /** Cyrillic (Old Church Slavonic variant) */
  CYRS = 'Cyrs',

  /** Devanagari | Nagari */
  DEVA = 'Deva',

  /** Dives Akuru */
  DIAK = 'Diak',

  /** Dogra */
  DOGR = 'Dogr',

  /** Deseret | Mormon */
  DSRT = 'Dsrt',

  /** Duployan shorthand | Duployan stenography */
  DUPL = 'Dupl',

  /** Egyptian demotic */
  EGYD = 'Egyd',

  /** Egyptian hieratic */
  EGYH = 'Egyh',

  /** Egyptian hieroglyphs */
  EGYP = 'Egyp',

  /** Elbasan */
  ELBA = 'Elba',

  /** Elymaic */
  ELYM = 'Elym',

  /** Ethiopic | Geʻez | Ge'ez */
  ETHI = 'Ethi',

  /** Khutsuri (Asomtavruli and Nuskhuri) */
  GEOK = 'Geok',

  /** Georgian (Mkhedruli and Mtavruli) */
  GEOR = 'Geor',

  /** Glagolitic */
  GLAG = 'Glag',

  /** Gunjala Gondi */
  GONG = 'Gong',

  /** Masaram Gondi */
  GONM = 'Gonm',

  /** Gothic */
  GOTH = 'Goth',

  /** Grantha */
  GRAN = 'Gran',

  /** Greek */
  GREK = 'Grek',

  /** Gujarati */
  GUJR = 'Gujr',

  /** Gurmukhi */
  GURU = 'Guru',

  /** Han with Bopomofo (alias for Han + Bopomofo) */
  HANB = 'Hanb',

  /** Hangul | Hangŭl | Hangeul */
  HANG = 'Hang',

  /** Han | Hanzi | Kanji | Hanja */
  HANI = 'Hani',

  /** Hanunoo | Hanunóo */
  HANO = 'Hano',

  /** Han (Simplified variant) */
  HANS = 'Hans',

  /** Han (Traditional variant) */
  HANT = 'Hant',

  /** Hatran */
  HATR = 'Hatr',

  /** Hebrew */
  HEBR = 'Hebr',

  /** Hiragana */
  HIRA = 'Hira',

  /** Anatolian Hieroglyphs | Luwian Hieroglyphs | Hittite Hieroglyphs */
  HLUW = 'Hluw',

  /** Pahawh Hmong */
  HMNG = 'Hmng',

  /** Nyiakeng Puachue Hmong */
  HMNP = 'Hmnp',

  /** Japanese syllabaries (alias for Hiragana + Katakana) */
  HRKT = 'Hrkt',

  /** Old Hungarian | Hungarian Runic */
  HUNG = 'Hung',

  /** Indus | Harappan */
  INDS = 'Inds',

  /** Old Italic (Etruscan, Oscan, etc.) */
  ITAL = 'Ital',

  /** Jamo (alias for Jamo subset of Hangul) */
  JAMO = 'Jamo',

  /** Javanese */
  JAVA = 'Java',

  /** Japanese (alias for Han + Hiragana + Katakana) */
  JPAN = 'Jpan',

  /** Jurchen */
  JURC = 'Jurc',

  /** Kayah Li */
  KALI = 'Kali',

  /** Katakana */
  KANA = 'Kana',

  /** Kharoshthi */
  KHAR = 'Khar',

  /** Khmer */
  KHMR = 'Khmr',

  /** Khojki */
  KHOJ = 'Khoj',

  /** Khitan large script */
  KITL = 'Kitl',

  /** Khitan small script */
  KITS = 'Kits',

  /** Kannada */
  KNDA = 'Knda',

  /** Korean (alias for Hangul + Han) */
  KORE = 'Kore',

  /** Kpelle */
  KPEL = 'Kpel',

  /** Kaithi */
  KTHI = 'Kthi',

  /** Tai Tham | Lanna */
  LANA = 'Lana',

  /** Lao */
  LAOO = 'Laoo',

  /** Latin (Fraktur variant) */
  LATF = 'Latf',

  /** Latin (Gaelic variant) */
  LATG = 'Latg',

  /** Latin */
  LATN = 'Latn',

  /** Leke */
  LEKE = 'Leke',

  /** Lepcha | Róng */
  LEPC = 'Lepc',

  /** Limbu */
  LIMB = 'Limb',

  /** Linear A */
  LINA = 'Lina',

  /** Linear B */
  LINB = 'Linb',

  /** Lisu | Fraser */
  LISU = 'Lisu',

  /** Loma */
  LOMA = 'Loma',

  /** Lycian */
  LYCI = 'Lyci',

  /** Lydian */
  LYDI = 'Lydi',

  /** Mahajani */
  MAHJ = 'Mahj',

  /** Makasar */
  MAKA = 'Maka',

  /** Mandaic | Mandaean */
  MAND = 'Mand',

  /** Manichaean */
  MANI = 'Mani',

  /** Marchen */
  MARC = 'Marc',

  /** Mayan hieroglyphs */
  MAYA = 'Maya',

  /** Medefaidrin | Oberi Okaime | Oberi Ɔkaimɛ */
  MEDF = 'Medf',

  /** Mende Kikakui */
  MEND = 'Mend',

  /** Meroitic Cursive */
  MERC = 'Merc',

  /** Meroitic Hieroglyphs */
  MERO = 'Mero',

  /** Malayalam */
  MLYM = 'Mlym',

  /** Modi | Moḍī */
  MODI = 'Modi',

  /** Mongolian */
  MONG = 'Mong',

  /** Moon | Moon code | Moon script | Moon type */
  MOON = 'Moon',

  /** Mro | Mru */
  MROO = 'Mroo',

  /** Meitei Mayek | Meithei | Meetei */
  MTEI = 'Mtei',

  /** Multani */
  MULT = 'Mult',

  /** Myanmar | Burmese */
  MYMR = 'Mymr',

  /** Nandinagari */
  NAND = 'Nand',

  /** Old North Arabian | Ancient North Arabian */
  NARB = 'Narb',

  /** Nabataean */
  NBAT = 'Nbat',

  /** Newa | Newar | Newari | Nepāla lipi */
  NEWA = 'Newa',

  /** Naxi Dongba | na²¹ɕi³³ to³³ba²¹ | Nakhi Tomba */
  NKDB = 'Nkdb',

  /** Naxi Geba | na²¹ɕi³³ gʌ²¹ba²¹ | 'Na-'Khi ²Ggŏ-¹baw | Nakhi Geba */
  NKGB = 'Nkgb',

  /** N’Ko | N'Ko */
  NKOO = 'Nkoo',

  /** Nüshu */
  NSHU = 'Nshu',

  /** Ogham */
  OGAM = 'Ogam',

  /** Ol Chiki | Ol Cemet' | Ol | Santali */
  OLCK = 'Olck',

  /** Old Turkic | Orkhon Runic */
  ORKH = 'Orkh',

  /** Oriya | Odia */
  ORYA = 'Orya',

  /** Osage */
  OSGE = 'Osge',

  /** Osmanya */
  OSMA = 'Osma',

  /** Old Uyghur */
  OUGR = 'Ougr',

  /** Palmyrene */
  PALM = 'Palm',

  /** Pau Cin Hau */
  PAUC = 'Pauc',

  /** Proto-Cuneiform */
  PCUN = 'Pcun',

  /** Proto-Elamite */
  PELM = 'Pelm',

  /** Old Permic */
  PERM = 'Perm',

  /** Phags-pa */
  PHAG = 'Phag',

  /** Inscriptional Pahlavi */
  PHLI = 'Phli',

  /** Psalter Pahlavi */
  PHLP = 'Phlp',

  /** Book Pahlavi */
  PHLV = 'Phlv',

  /** Phoenician */
  PHNX = 'Phnx',

  /** Klingon (KLI pIqaD) */
  PIQD = 'Piqd',

  /** Miao | Pollard */
  PLRD = 'Plrd',

  /** Inscriptional Parthian */
  PRTI = 'Prti',

  /** Proto-Sinaitic */
  PSIN = 'Psin',

  /** Ranjana */
  RANJ = 'Ranj',

  /** Rejang | Redjang | Kaganga */
  RJNG = 'Rjng',

  /** Hanifi Rohingya */
  ROHG = 'Rohg',

  /** Rongorongo */
  RORO = 'Roro',

  /** Runic */
  RUNR = 'Runr',

  /** Samaritan */
  SAMR = 'Samr',

  /** Sarati */
  SARA = 'Sara',

  /** Old South Arabian */
  SARB = 'Sarb',

  /** Saurashtra */
  SAUR = 'Saur',

  /** SignWriting */
  SGNW = 'Sgnw',

  /** Shavian | Shaw */
  SHAW = 'Shaw',

  /** Sharada | Śāradā */
  SHRD = 'Shrd',

  /** Shuishu */
  SHUI = 'Shui',

  /** Siddham | Siddhaṃ | Siddhamātṛkā */
  SIDD = 'Sidd',

  /** Khudawadi | Sindhi */
  SIND = 'Sind',

  /** Sinhala */
  SINH = 'Sinh',

  /** Sogdian */
  SOGD = 'Sogd',

  /** Old Sogdian */
  SOGO = 'Sogo',

  /** Sora Sompeng */
  SORA = 'Sora',

  /** Soyombo */
  SOYO = 'Soyo',

  /** Sundanese */
  SUND = 'Sund',

  /** Syloti Nagri */
  SYLO = 'Sylo',

  /** Syriac */
  SYRC = 'Syrc',

  /** Syriac (Estrangelo variant) */
  SYRE = 'Syre',

  /** Syriac (Western variant) */
  SYRJ = 'Syrj',

  /** Syriac (Eastern variant) */
  SYRN = 'Syrn',

  /** Tagbanwa */
  TAGB = 'Tagb',

  /** Takri | Ṭākrī | Ṭāṅkrī */
  TAKR = 'Takr',

  /** Tai Le */
  TALE = 'Tale',

  /** New Tai Lue */
  TALU = 'Talu',

  /** Tamil */
  TAML = 'Taml',

  /** Tangut */
  TANG = 'Tang',

  /** Tai Viet */
  TAVT = 'Tavt',

  /** Telugu */
  TELU = 'Telu',

  /** Tengwar */
  TENG = 'Teng',

  /** Tifinagh | Berber */
  TFNG = 'Tfng',

  /** Tagalog | Baybayin | Alibata */
  TGLG = 'Tglg',

  /** Thaana */
  THAA = 'Thaa',

  /** Thai */
  THAI = 'Thai',

  /** Tibetan */
  TIBT = 'Tibt',

  /** Tirhuta */
  TIRH = 'Tirh',

  /** Tangsa */
  TNSA = 'Tnsa',

  /** Toto */
  TOTO = 'Toto',

  /** Ugaritic */
  UGAR = 'Ugar',

  /** Vai */
  VAII = 'Vaii',

  /** Visible Speech */
  VISP = 'Visp',

  /** Vithkuqi */
  VITH = 'Vith',

  /** Warang Citi | Varang Kshiti */
  WARA = 'Wara',

  /** Wancho */
  WCHO = 'Wcho',

  /** Woleai */
  WOLE = 'Wole',

  /** Old Persian */
  XPEO = 'Xpeo',

  /** Sumero-Akkadian cuneiform */
  XSUX = 'Xsux',

  /** Yezidi */
  YEZI = 'Yezi',

  /** Yi */
  YIII = 'Yiii',

  /** Zanabazar Square | Zanabazarin Dörböljin Useg | Xewtee Dörböljin Bicig | Horizontal Square Script */
  ZANB = 'Zanb',

  /** Code for inherited script */
  ZINH = 'Zinh',

  /** Mathematical notation */
  ZMTH = 'Zmth',

  /** Symbols (Emoji variant) */
  ZSYE = 'Zsye',

  /** Symbols */
  ZSYM = 'Zsym',

  /** Code for unwritten documents */
  ZXXX = 'Zxxx',

  /** Code for undetermined script */
  ZYYY = 'Zyyy',

  /** Code for uncoded script */
  ZZZZ = 'Zzzz'
}

// prettier-ignore
/**
 * set of values from the IetfScriptCode const enums
 * @public
 */
export const IetfScriptCodeSet: ReadonlySet<IetfScriptCode> = new Set([
'Adlm', 'Afak', 'Aghb', 'Ahom', 'Arab', 'Aran', 'Armi', 'Armn', 'Avst', 'Bali', 'Bamu', 'Bass', 'Batk', 'Beng', 'Bhks', 'Blis', 'Bopo', 'Brah', 'Brai', 'Bugi', 'Buhd', 'Cakm', 'Cans', 'Cari', 'Cham', 'Cher', 'Chrs', 'Cirt', 'Copt', 'Cpmn', 'Cprt', 'Cyrl', 'Cyrs', 'Deva', 'Diak', 'Dogr', 'Dsrt', 'Dupl', 'Egyd', 'Egyh', 'Egyp', 'Elba', 'Elym', 'Ethi', 'Geok', 'Geor', 'Glag', 'Gong', 'Gonm', 'Goth', 'Gran', 'Grek', 'Gujr', 'Guru', 'Hanb', 'Hang', 'Hani', 'Hano', 'Hans', 'Hant', 'Hatr', 'Hebr', 'Hira', 'Hluw', 'Hmng', 'Hmnp', 'Hrkt', 'Hung', 'Inds', 'Ital', 'Jamo', 'Java', 'Jpan', 'Jurc', 'Kali', 'Kana', 'Khar', 'Khmr', 'Khoj', 'Kitl', 'Kits', 'Knda', 'Kore', 'Kpel', 'Kthi', 'Lana', 'Laoo', 'Latf', 'Latg', 'Latn', 'Leke', 'Lepc', 'Limb', 'Lina', 'Linb', 'Lisu', 'Loma', 'Lyci', 'Lydi', 'Mahj', 'Maka', 'Mand', 'Mani', 'Marc', 'Maya', 'Medf', 'Mend', 'Merc', 'Mero', 'Mlym', 'Modi', 'Mong', 'Moon', 'Mroo', 'Mtei', 'Mult', 'Mymr', 'Nand', 'Narb', 'Nbat', 'Newa', 'Nkdb', 'Nkgb', 'Nkoo', 'Nshu', 'Ogam', 'Olck', 'Orkh', 'Orya', 'Osge', 'Osma', 'Ougr', 'Palm', 'Pauc', 'Pcun', 'Pelm', 'Perm', 'Phag', 'Phli', 'Phlp', 'Phlv', 'Phnx', 'Piqd', 'Plrd', 'Prti', 'Psin', 'Ranj', 'Rjng', 'Rohg', 'Roro', 'Runr', 'Samr', 'Sara', 'Sarb', 'Saur', 'Sgnw', 'Shaw', 'Shrd', 'Shui', 'Sidd', 'Sind', 'Sinh', 'Sogd', 'Sogo', 'Sora', 'Soyo', 'Sund', 'Sylo', 'Syrc', 'Syre', 'Syrj', 'Syrn', 'Tagb', 'Takr', 'Tale', 'Talu', 'Taml', 'Tang', 'Tavt', 'Telu', 'Teng', 'Tfng', 'Tglg', 'Thaa', 'Thai', 'Tibt', 'Tirh', 'Tnsa', 'Toto', 'Ugar', 'Vaii', 'Visp', 'Vith', 'Wara', 'Wcho', 'Wole', 'Xpeo', 'Xsux', 'Yezi', 'Yiii', 'Zanb', 'Zinh', 'Zmth', 'Zsye', 'Zsym', 'Zxxx', 'Zyyy', 'Zzzz'
]) as Set<IetfScriptCode>;
