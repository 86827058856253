// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IPlatformAdapter } from '@wbd/beam-ctv-platform-adapters';
import { PlayStationAccount } from './account';
import { PlayStationCommerce } from './commerce';
import { PlayStationDevice } from './device';
import { PlayStationInput } from './input';
import { PlayStationStage } from './stage';
import { PlayStationStorage } from './storage';
import { PlayStationVideoCapabilities } from './video';

export const playStationAdapter: IPlatformAdapter = {
  input: PlayStationInput,
  storage: PlayStationStorage,
  device: PlayStationDevice,
  account: PlayStationAccount,
  stage: PlayStationStage,
  commerce: PlayStationCommerce,
  video: PlayStationVideoCapabilities
};
