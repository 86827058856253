// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { INetworkInformation, NetworkType } from './NetworkInformation';

import type { ILocalizationService } from '@wbd/beam-ctv-localization';
import type { IStorage } from '@wbd/beam-dom-extensions';
import { createV4Uuid } from '@wbd/beam-js-extensions';
import type { Profile } from '@wbd/bolt-dataservice';
import { ClientId, Platform } from '@wbd/bolt-http';
import { StorageDeviceConstants } from '../storage';
import { DevicePerformance } from './DevicePerformance';
import { parseBrowserUserAgent } from './parseUserAgent';
import { DeeplinkContentType, DeviceType, IPaywallData, type IDeeplinkParams } from './types';

/**
 * Abstract device implementation
 * @public
 */
export class AbstractDevice {
  // prohibit class initialization as we only want to use te static functions
  protected constructor() {}

  /**
   * Returns the unique client identifier
   */
  public static getBoltClientId(): ClientId {
    return ClientId.WEBOS;
  }

  /**
   * Returns the platform name
   */
  public static getBoltPlatformNameAsync(): Promise<Platform> {
    return Promise.resolve(Platform.LGTV);
  }

  /**
   * Returns the platform Chipset
   *  @returns string
   */
  public static getDeviceChipsetAsync(): Promise<string> {
    return Promise.resolve('');
  }

  /**
   * Get the current model name (ex. UN65JS9500)
   * @returns string
   */
  public static getDeviceModelNameAsync(): Promise<string> {
    return Promise.resolve(parseBrowserUserAgent().deviceName || '');
  }

  /**
   * Get the current model group (ex. 17_KANTM_UHD_BASIC)
   */
  public static getDeviceModelGroupAsync(): Promise<string> {
    return Promise.resolve('');
  }

  /**
   * Get the current performance (HIGH, AVERAGE, LOW)
   */
  public static getDevicePerformanceIndicatorAsync(): Promise<DevicePerformance> {
    return Promise.resolve(DevicePerformance.HIGH);
  }

  /**
   * Get the current device type classification (TV, STB, CONSOLE)
   */
  public static getDeviceTypeAsync(): Promise<DeviceType> {
    return Promise.resolve(DeviceType.TV);
  }

  /**
   * Device groups categorize a set of devices upon which feature flags can be targeted to
   * e.g on LG a group of all 2017 devices can return `lg2017` as the device group name
   */
  public static async getDeviceGroupAsync(): Promise<string | undefined> {
    return undefined;
  }

  /**
   * Get device OS name as string (ex. Tizen / webOS)
   */
  public static getDeviceOsAsync(): Promise<string> {
    return Promise.resolve('');
  }
  /**
   * Get device OS model year string
   * @returns string
   */
  public static getDeviceModelYearAsync(): Promise<string> {
    return Promise.resolve('');
  }

  /**
   * Get the current device OS version (ex. 4.5)
   */
  public static getDeviceOSVersionAsync(): Promise<string> {
    return Promise.resolve(parseBrowserUserAgent().deviceVersion || '');
  }

  /**
   * Get device brand name like the TV brand or MVPD name (ex. Samsung, Comcast)
   */
  public static getDeviceBrandAsync(): Promise<string> {
    return Promise.resolve('');
  }

  /**
   * Get device manufacturer (ex. Samsung, Huawei)
   */
  public static getDeviceManufacturerAsync(): Promise<string> {
    return Promise.resolve('');
  }

  /**
   * Get the device network information
   */
  public static getDeviceNetworkInformationAsync(): Promise<INetworkInformation> {
    return Promise.resolve({
      type: NetworkType.UNKNOWN
    });
  }

  /**
   * Check if the current device is connected to the internet
   */
  public static isDeviceConnectedAsync(): Promise<boolean> {
    if (typeof window.navigator?.onLine === 'boolean') {
      return Promise.resolve(navigator.onLine);
    } else {
      return Promise.resolve(true);
    }
  }

  /**
   * Get current device locale following ISO-639 and ISO-3166 (example en-US)
   */
  public static getDeviceLocaleAsync(): Promise<string> {
    let country = 'US';
    let language = 'en';

    if (!navigator || !navigator.language) {
      return Promise.resolve([language, country].join('-'));
    }

    const browserLocale = navigator.language.toLocaleLowerCase();

    if (browserLocale && browserLocale.split('-').length > 1) {
      const localeArray = browserLocale.split('-');
      language = localeArray[0];
      country = localeArray[1].toUpperCase();
    } else {
      language = browserLocale;
    }

    return Promise.resolve([language, country].join('-'));
  }

  /**
   * Returns the Store country code for In-App-Purchase as ISO-3166 (example US)
   */
  public static getStoreCountryCodeAsync(): Promise<string> {
    return Promise.resolve('');
  }

  /**
   * Get unique device identifier
   */
  public static getDeviceIdentifierAsync(storage: IStorage): Promise<string> {
    let uuid = storage.readSync(StorageDeviceConstants.UUID);
    if (!uuid) {
      uuid = createV4Uuid();
      storage.writeSync(StorageDeviceConstants.UUID, uuid);
    }
    return Promise.resolve(uuid);
  }

  /**
   * Get platform ad identifier
   */
  public static getDeviceAdIdentifierAsync(storage: IStorage): Promise<string> {
    let uuid = storage.readSync(StorageDeviceConstants.ADVERTISEMENT_UUID);
    if (!uuid) {
      uuid = createV4Uuid();
      storage.writeSync(StorageDeviceConstants.ADVERTISEMENT_UUID, uuid);
    }
    return Promise.resolve(uuid);
  }

  /**
   * Returns if the device / OS signals that limited ad tracking should be used - defaults to false
   */
  public static getLimitAdTrackingAsync(): Promise<boolean> {
    return Promise.resolve(false);
  }

  /**
   * Returns if speech synthesis is enabled.
   */
  public static useSpeechSynthesis(): (() => boolean) | undefined {
    return undefined;
  }

  /**
   * Determine if platform guidelines recommend to show a confirmation dialog before closing the app
   */
  public static requiresCloseDialog(): boolean {
    return true;
  }

  /**
   * Exit the application
   */
  public static exit(): void {
    window.close();
  }

  /**
   * Can the app be closed from javascirpt or a platform level api
   * @returns boolean
   */
  public static canAppBeClosed(): boolean {
    return true;
  }

  /**
   * Returns URLSearchParams data structure containing
   * all params from window.location
   */
  public static getURLParams(): URLSearchParams {
    const urlParams = new URLSearchParams(sanitizeQueryString(window.location.search));
    const urlHash = window.location.hash;

    /**
     * Because of the way our router works (using hash #), in a hashy url
     * like "/#catalog/page/some-page-urn?language=es&locale=us"
     * when we get location.search, we'll get an empty string.
     *
     * So to be able to pick ALL search params, from hash or not, we have to
     * do a workaround.
     */
    const hashQSIndex = urlHash.indexOf('?');

    if (hashQSIndex !== -1) {
      const queryString = urlHash.substr(hashQSIndex);

      new URLSearchParams(sanitizeQueryString(queryString)).forEach((value, key) => {
        urlParams.set(key, value);
      });
    }

    return urlParams;

    function sanitizeQueryString(qs: string = ''): string {
      const endStringBackslashRegex = /\/$/;
      return qs.replace(endStringBackslashRegex, '');
    }
  }

  /**
   * Parse and returns the deeplink parameters sent by the platform
   */

  public static async getDeeplinkParamsAsync(): Promise<IDeeplinkParams | undefined> {
    const urlParams = this.getURLParams();

    const makePayload = (
      type: DeeplinkContentType,
      id: string | undefined | null
    ): IDeeplinkParams | undefined => {
      return id ? { id, type } : undefined;
    };

    if (urlParams.has('launchpoint')) {
      const videoDeeplink = makePayload(DeeplinkContentType.VIDEO, urlParams.get('assetId'));
      const showDeeplink = makePayload(DeeplinkContentType.SHOW, urlParams.get('entityId'));
      const searchDeeplink = makePayload(DeeplinkContentType.SEARCH, urlParams.get('query'));

      switch (urlParams.get('launchpoint')) {
        case 'playback':
          return videoDeeplink;
        case 'detail':
          return showDeeplink;
        case 'search':
          return searchDeeplink;
      }
    }

    return undefined;
  }

  /**
   *
   * Registers a callback to be executed when the application is resumed with a deeplink attached
   *
   * @param onResume - callback to execute on deeplink resume
   */
  public static onDeeplinkResume(onResume: (params?: IDeeplinkParams) => void): void {}

  /**
   * Returns whether the device / OS has a native keyboard available
   */
  public static isNativeKeyboardAvailable(): boolean {
    return false;
  }

  /**
   * Adds a callback to be executed after the keyboard is shown
   * @param callback - the callback function when keyboard is shown
   */
  public static onAfterKeyboardShown(callback: () => void): void {}

  /**
   * Adds a callback to be executed after the keyboard is hidden
   * @param callback - the callback function when keyboard is hidden
   */
  public static onAfterKeyboardHidden(callback: () => void): void {}

  /**
   * Hides the static start splash screen
   */
  public static hideStaticSplash(onComplete?: VoidFunction): void {
    AbstractDevice.dismissSplash(() => {
      document.getElementById('splash')?.classList.add('hide');
      document.getElementById('spinner')?.classList.add('hide');
    }, onComplete);
  }

  /**
   * Removes splash screen and video when completed
   * @param onReady - VoidFunction
   * @param onComplete - VoidFunction shows the app
   */
  public static dismissSplash(onReady: VoidFunction, onComplete?: VoidFunction): void {
    const video = document.getElementById('splash-video') as HTMLVideoElement;
    if (video) {
      let retries = 0;
      // eslint-disable-next-line prefer-const
      let videoComplete!: () => void;
      try {
        const timeout = window.setTimeout(() => videoComplete(), 6000);
        const splashVideoPlayback = (): void => {
          const cleanupSplashVideo = (): void => {
            if (video) {
              video.removeEventListener('ended', videoComplete!);
              video.onerror = null;
              video.parentElement?.removeChild(video);
            }
          };

          videoComplete = () => {
            window.clearTimeout(timeout);
            cleanupSplashVideo();
            onComplete?.(); //show app
          };

          if (video.getAttribute('data-loaded')) {
            onReady();

            const playSplashVideo = (): void => {
              video.addEventListener('ended', videoComplete);
              video.play().catch((e) => {
                videoComplete();
              });
            };

            playSplashVideo();
          } else {
            if (retries++ > 9) {
              onReady();
              cleanupSplashVideo();
              onComplete?.(); //show app
              return;
            }
            setTimeout(splashVideoPlayback, 300);
          }
        };
        splashVideoPlayback();
      } catch (e) {
        if (video) {
          video.removeEventListener('ended', videoComplete!);
          video.parentElement?.removeChild(video);
        }
        onReady();
        onComplete?.(); //show app
      }
    } else {
      onReady();
      onComplete?.(); //show app
    }
  }

  /**
   * Hook for updating preview rails, universal guide etc
   */
  public static onUserActivity(translation: ILocalizationService, profile: Profile | undefined): void {}

  /**
   * Hook for sending certain playback events to the platform
   */
  public static onPlayerEvent(type: string): void {}

  /**
   * Returns the CC state of the platform
   * @returns boolean
   */
  public static async getDeviceClosedCaptionsState(): Promise<boolean | undefined> {
    return Promise.resolve(undefined);
  }

  /**
   * Get paywall data(title, description, ctas, etc.)
   * @returns Promise<IPaywallData>
   */
  public static async getPaywallDataAsync(): Promise<IPaywallData> {
    return Promise.resolve({
      title: 'Paywall.Title',
      titleA11y: 'Paywall.Title.A11y',
      description: 'Paywall.Description',
      descriptionA11y: 'Paywall.Description.A11y',
      ctas: [
        {
          title: 'PaywallCard.SubscribeNow.Title',
          titleAnalytics: 'Subscribe Now',
          description: 'PaywallCard.SubscribeNow.Description',
          accessibleLabel: [
            'PaywallCard.SubscribeNow.Title.A11y',
            'PaywallCard.SubscribeNow.Description.A11y'
          ],
          id: 'SubscribeButton'
        },
        {
          title: 'PaywallCard.ConnectProvider.Title',
          titleAnalytics: 'Connect Provider',
          description: 'PaywallCard.ConnectProvider.Description',
          accessibleLabel: [
            'PaywallCard.ConnectProvider.Title.A11y',
            'PaywallCard.ConnectProvider.Description.A11y'
          ],
          id: 'ConnectButton'
        },
        {
          title: 'Generic.SignIn',
          titleAnalytics: 'Sign In',
          description: 'PaywallCard.SignIn.Description',
          accessibleLabel: ['PaywallCard.SignIn.Title.A11y', 'PaywallCard.SignIn.Description.A11y'],
          id: 'SignInButton'
        }
      ]
    });
  }

  /**
   * Informs whether device's ARIA support needs extra compatibility effort
   */
  public static useSafeAriaAnnounce(): boolean {
    return false;
  }
}

/**
 * device implementation interface
 * @public
 */
export type IDevice = Omit<typeof AbstractDevice, 'prototype'>;
