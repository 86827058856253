// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ArticleBodyRichText } from './ArticleBodyRichText';
import type { DataTypes } from '../json-api';
import { JsonApiIdentifier } from 'json-api-models';
import type { MarketingBadge } from './MarketingBadge';
import { PlanCardProductAttribute } from './PlanCardProductAttribute';
import type { Price } from './Price';
import { TypedModel } from '../json-api';
import type { MarketingCallToAction } from './MarketingCallToAction';

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export interface IPlanCardAttributes {
  /** ID of the price plan in the provider's app store. */
  inAppStoreId?: string;
  /** Provider of the price plans. */
  provider: string;
  /**Analytics data used for the purchase schema */
  analyticsData: {
    productId: string;
    provider: string;
    plan: string;
    period: string;
    price: string;
    currency: string;
    pricePlanId: string;
  };
}

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export interface IPlanCardRelationships {
  /** Marketing badge that should be shown with the plan card. */
  badge: MarketingBadge;
  /** Badge denoting that this is the user's current plan. */
  currentPlanBadge: MarketingBadge;
  /** Rich text for free trial content. */
  freeTrialText: ArticleBodyRichText;
  /** Rich text for the legal content. */
  legalText: ArticleBodyRichText;
  /** Original price information (e.g. customer paying full-price before a promotion). */
  originalPrice: Price;
  /** Price information for the plan card. */
  price: Price;
  /** Details about the price offer rules */
  priceDetails?: ArticleBodyRichText;
  /** ID of the price plan.
   *
   * Used in monetization API when creating/changing subscriptions.
   * Only ever included as a relationship. */
  pricePlan: JsonApiIdentifier;
  /** Ordered list of included/extended features of the product. */
  productAttributes: PlanCardProductAttribute[];
  /** Rich text for the product name. */
  productName: ArticleBodyRichText;
  /** Rich text for promotional content. */
  promotionText: ArticleBodyRichText;
  /** ID of the user's subscription.
   *
   * Used in monetization API when creating/changing subscriptions.
   * Only ever included as a relationship. */
  subscription?: JsonApiIdentifier;
  /** An array of `call-to-action`s connected to a plan card. */
  ctas?: MarketingCallToAction[];
}

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export class PlanCard extends TypedModel.generate<
  DataTypes.PlanCard,
  IPlanCardAttributes,
  IPlanCardRelationships
>() {}
