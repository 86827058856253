// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#articles
 * @public
 */
export interface IArticleAttributes {
  /** Article alias. */
  alias: string;
  /** Article description. */
  description?: string;
  /** Internal name used in admin UI. */
  name?: string;
  /** Used for search engine optimization and web crawlers. */
  pageMetadataDescription?: string;
  /** Article published date. */
  published: string;
  /** Article state.
   * @defaultValue `'published'`
   * */
  state: 'published';
  /** Article title. */
  title?: string;
  /** Article accessibility title. */
  accessibilityTitle?: string;
}

/**
 * @public
 */
export class Article extends TypedModel.generate<DataTypes.Article, IArticleAttributes>() {}
