// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { LanguageCode } from '../nominals';
import { LanguageCodes } from '../localization';

/**
 * Determines if an exact match for a desired language is present
 * on a list of available languages.
 * @param wantLanguage - wantLanguage Desired language
 * @param haveLanguages - haveLanguages Available languages
 */
function languageIsPresent(
  wantLanguage: string,
  haveLanguages: LanguageCode[]
): wantLanguage is LanguageCode {
  return haveLanguages.includes(wantLanguage as LanguageCode);
}

/**
 * Maps a desired language to one of a list of supported languages,
 * applying language distance logic to find near matches.
 *
 * Any language with an exact match in the list of available languages
 * is returned directly. Otherwise, any variant of English is promoted
 * to en-US and any variant of Spanish is promoted to es-419.
 *
 * Returns undefined if the desired language cannot be mapped onto a
 * supported language.
 *
 * @param wantLanguage - Desired language
 * @param haveLanguages - Available languages
 *
 * @public
 */
export function mapLanguageToSupported(
  wantLanguage: string,
  haveLanguages: LanguageCode[]
): LanguageCode | undefined {
  if (languageIsPresent(wantLanguage, haveLanguages)) {
    return wantLanguage;
  }

  let fallbackLanguage = wantLanguage;
  if (wantLanguage.startsWith('en-')) {
    fallbackLanguage = LanguageCodes.US_ENGLISH;
  } else if (wantLanguage.startsWith('es-')) {
    // Use es-ES if wanted and present. Otherwise map
    // any spanish to es-419
    fallbackLanguage = LanguageCodes.LATAM_SPANISH;
  }

  if (fallbackLanguage !== wantLanguage && languageIsPresent(fallbackLanguage, haveLanguages)) {
    return fallbackLanguage;
  }

  return undefined;
}

/**
 * Maps a list of desired languages onto one of a list of supported languages,
 * applying language distance logic and an optional default language.
 *
 * Searches the list of desired languages in order and returns the first
 * matching mapped language it finds.
 *
 * If no desired languages can be mapped attempts to map the default
 * language.
 *
 * If no desired language matches and default language is missing or cannot be
 * mapped, returns en-US.
 *
 * @param wantLanguages - Desired languages
 * @param haveLanguages - Available languages
 * @param defaultLanguage - Optional default language
 *
 * @public
 */
export function selectLanguage(
  wantLanguages: string[],
  haveLanguages: LanguageCode[],
  defaultLanguage?: LanguageCode
): LanguageCode {
  const tryLanguages = [...wantLanguages];

  if (defaultLanguage) {
    tryLanguages.push(defaultLanguage);
  }

  for (const language of tryLanguages) {
    const gotLanguage = mapLanguageToSupported(language, haveLanguages);
    if (gotLanguage !== undefined) {
      return gotLanguage;
    }
  }

  return LanguageCodes.US_ENGLISH;
}
