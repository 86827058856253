// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import type { PlanCard } from './PlanCard';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export interface IMarketingCollectionItemRelationships {
  /** A plan card to be shown to the user.
   * Optional to allow additional extensibility in the future when collection items
   * can contain other content. */
  planCard?: PlanCard;
}

/**
 * @public
 */
export class MarketingCollectionItem extends TypedModel.generate<
  DataTypes.MarketingCollectionItem,
  {},
  IMarketingCollectionItemRelationships
>() {}
