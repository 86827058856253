// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { CanonicalizedCldrLocale, LanguageCode, LanguageCodes } from '@wbd/localization-core';

/**
 * List of enabled client supported language codes
 *
 * this is a manual curated list of the on language pack export from localization-tools / GSM
 * to prevent we expose language packs that are not fully translated / ready for production usage
 * @public
 */
export const CLIENT_ENABLED_LANGUAGE_CODES: LanguageCode[] = [
  LanguageCodes.US_ENGLISH,
  CanonicalizedCldrLocale.PSEUDO_LOC as LanguageCode,
  CanonicalizedCldrLocale.PSEUDO_LONG as LanguageCode
];
