// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { BoltHttpClient } from '@wbd/bolt-http';

/**
 * Internal singleton reference of the HttpClient
 * @internal
 */
export class HttpClient {
  private static _http: BoltHttpClient | undefined;

  public static get instance(): BoltHttpClient {
    if (!this._http)
      throw new Error("ensure to call 'registerHttpClient' before making bolt-dataservice requests");
    return this._http;
  }

  public static initialize(httpClient: BoltHttpClient): void {
    this._http = httpClient;
  }
}

/**
 * Allow clients to register the http client
 * @param httpClient - http instance
 *
 * @public
 */
export function registerHttpClient(httpClient: BoltHttpClient): void {
  HttpClient.initialize(httpClient);
}
