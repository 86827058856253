// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { getRegionCodes } from '../getters';
import { RegionCode } from '../RegionCode';

/**
 * Type guard function for IsoRegionCode values.
 *
 * @param tag - a possible IsoRegionCode
 *
 * @public
 */
export function isRegionCode(tag: string): tag is RegionCode {
  return getRegionCodes().has(tag as RegionCode);
}
