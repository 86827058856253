// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';
import { ContentRatingSystem } from './ContentRatingSystem';
import type { Image } from './Image';

/**
 * @public
 */
export interface IContentRatingAttributes {
  /** Code assigned to the content by the regulator. */
  code: string;
  /**
   * Regulator who rated the content.
   * @deprecated please use 'contentRatingSystem' instead
   */
  system: string;
}

/**
 * @public
 */
export interface IContentRatingRelationships {
  /** Code assigned to the content by the regulator. */
  contentRatingSystem: ContentRatingSystem;
  /** Content rating images. */
  images?: Image[];
}

/**
 * @public
 */
export class ContentRating extends TypedModel.generate<
  DataTypes.ContentRating,
  IContentRatingAttributes,
  IContentRatingRelationships
>() {}
