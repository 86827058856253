// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { BoltHttpClient, IRequestMethodConfig, IRequestRetryConfig, IResponse } from '@wbd/bolt-http';
import { Configuration } from '../core';

/**
 * Event Transmission Class
 * @public
 */
export class EventTransmitter {
  private static _httpClient?: BoltHttpClient;
  private static _telegraphUri: string;

  // TODO: GCX-4733 - Implement transmission retry flow
  private static _retryConfig: IRequestRetryConfig;

  /**
   * initializes EventTransmitter with the data it needs to be able to place requests.
   * @param configuration - IEventTransmitterConfiguration object containing the required data.
   * @public
   */
  public static initialize(configuration: Configuration): void {
    this._httpClient = configuration.httpClient;
    this._telegraphUri = configuration.telegraphUri;
    this._retryConfig = configuration.httpClientRetryPolicy;
  }

  /**
   * transmits a given serialized body string via the initialized BoltHttpClient.
   * inserts a sentAt timestamp before transmission.
   * @param body - serialized event batch data.
   * @returns a promise that resolved to the response data
   * @public
   */
  public static async transmitBatch(body: string): Promise<IResponse<unknown, unknown>> {
    if (!this._httpClient || !this._telegraphUri || !this._retryConfig) {
      throw new Error('you must initialize EventTransmitter before placing requests');
    }
    const sentAt = Date.now();
    const requestConfig: IRequestMethodConfig = {
      headers: {
        'x-sent-at': sentAt
      }
    };
    return await this._httpClient.post(this._telegraphUri, body, requestConfig, this._retryConfig);
  }
}
