// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type lng from '@3rdparty/lightningjs-core';
import { DevicePerformance } from '../device';

/**
 * stage implementation interface
 * @public
 */
export class AbstractStage {
  // prohibit class initialization as we only want to use te static functions
  protected constructor() {}

  /**
   * Provides lightning stage configuration
   * @param stageOverrides - custom stage overrides
   * @returns
   */
  public static getStageOptionsAsync(stageOverrides?: lng.IStageOptions): Promise<lng.IStageOptions> {
    const precision: number =
      Math.round(window.innerWidth || document.documentElement.clientWidth) > 1280 &&
      Math.round(window.innerHeight || document.documentElement.clientHeight) > 720
        ? 1
        : 2 / 3;

    const stage = {
      w: 1920 * precision,
      h: 1080 * precision,
      precision,
      defaultFontFace: 'book',
      fontSharp: false,
      memoryPressure: 20e6,
      ...stageOverrides
    };

    return Promise.resolve(stage);
  }

  /**
   * This defines the total number of pixels that may be allocated in GPU memory,
   * which allows you to tweak the amount of GPU memory that the Lightning app can use for texture caching
   *
   * If this amount is reached, Lightning performs an 'unused texture' cleanup (normal GC)
   * A single pixel uses between 4 and 6 bytes of GPU memory.
   *
   * @example
   * 12e6 = ~45-66Mb VRAM Max
   * 16e6 = ~61-91Mb VRAM Max
   * 20e6 = ~76-114Mb VRAM Max
   * 24e6 = ~91-137Mb VRAM Max
   */
  public static getMemoryPressure(devicePerformance: DevicePerformance = DevicePerformance.AVERAGE): number {
    switch (devicePerformance) {
      case DevicePerformance.LOW:
        return 16e6;
      case DevicePerformance.HIGH:
        return 24e6;
      case DevicePerformance.AVERAGE:
      default:
        return 20e6;
    }
  }
}

/**
 * device implementation interface
 * @public
 */
export type IStage = Omit<typeof AbstractStage, 'prototype'>;
