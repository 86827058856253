// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  AuthMigrationTokenProvider,
  ImageKind,
  registerHttpClient,
  registerJsonApiFilter
} from '@wbd/bolt-dataservice';
import { BoltEnvironment, BoltHttpClient } from '@wbd/bolt-http';
import { getBoltSessionConfig } from './constants';

import type { IDeviceInfo } from '@wbd/bolt-http';
import {
  InstrumentationSDK,
  PlatformRuntimeV1Scope,
  generateV1Err,
  type IPlatformRuntimeV1Err
} from '@wbd/instrumentation-sdk';
import type { IInitialContext } from '../application';

/**
 * storage key for the auth token
 */
export const BEAM_AUTH_TOKEN_KEY: string = 'beamAuthToken';

// JSON API used images
const INCLUDE_IMAGE_KINDS: Set<string> = new Set([
  ImageKind.Alternate,
  ImageKind.BackgroundCTV,
  ImageKind.CoverArtworkHorizontal,
  ImageKind.CoverArtworkHovered,
  ImageKind.CoverArtworkSquare,
  ImageKind.Default,
  ImageKind.DefaultWide,
  ImageKind.LogoLeft,
  ImageKind.PosterWithLogo
]);

/**
 * function that bootstraps the bolt-http and bolt-dataservice libraries
 * @param config - initiation configuration
 * @returns
 */
export const bootstrapBolt = async (context: IInitialContext): Promise<BoltHttpClient> => {
  const { device, storage } = context.platformAdapter;
  const { deviceId, manufacturer, model, operatingSystem, operatingSystemVersion, boltPlatformName } =
    context.deviceBootInfo;

  const deviceInfo: IDeviceInfo = { manufacturer, model, operatingSystem, operatingSystemVersion };

  const clientID = device.getBoltClientId();

  // create persistent storage adapter
  const tokenKeyPrefix = context.boltEnvironment !== BoltEnvironment.PRD ? `${context.boltEnvironment}-` : '';
  const authTokenProvider = new AuthMigrationTokenProvider(
    deviceId,
    storage,
    `${tokenKeyPrefix}${BEAM_AUTH_TOKEN_KEY}`
  );

  // listen to any errors from token provider
  authTokenProvider.onError((error) => {
    InstrumentationSDK.Errors.Platform.Runtime.v1.capture({
      err: generateV1Err<IPlatformRuntimeV1Err>(error, {
        scope: PlatformRuntimeV1Scope.RECOVERABLE
      })
    });
  });

  // create http instance & register it
  const httpClient = BoltHttpClient.create(
    {
      ...getBoltSessionConfig(clientID, boltPlatformName),
      deviceId,
      deviceInfo,
      asyncRails: true,
      authTokenProvider,
      environment: context.boltEnvironment
    },
    {},
    storage
  );

  // register bolt-http with bolt-dataservice
  registerHttpClient(httpClient);

  // register JSON API transformer filter
  registerJsonApiFilter({
    imageTypesAllowList: INCLUDE_IMAGE_KINDS
  });

  // wire up bolt-http before token request
  InstrumentationSDK.updateConfiguration({ httpClient });

  // Force getting an anonymous or migrated token
  await authTokenProvider.getTokenAsync();

  // return bolt sonic configuration
  return httpClient;
};
