// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { HttpClient, RequestConfigOverride } from '../http-client';

/**
 * Response format of feature flag decisions
 * @public
 */
export interface IFeatureFlagDecisionData {
  /**
   * This is the config set when created the FF. If config was not set for the FF
   * then it would be null otherwise a JSON.
   **/
  config?: Partial<Record<string, string | number | boolean | unknown>>;
  /** Variant title. */
  label: string;
  /** Indicates whether the FF should be shown or not */
  on: boolean;
}

/**
 * Response format of the feature flag service
 * @public
 */
export interface IFeatureFlagResponse {
  [key: string]: IFeatureFlagDecisionData;
}

/**
 * Available options to pass down the the labs feature-flag service
 * @public
 */
export interface IFeatureFlagOptions {
  /** Project GUID of the feature flags */
  projectId: string;
  /**Tag key */
  tag?: string;
  /**
   * A GUID that is used to track the decision for the given request.
   * All subsequent requests with the same seedId and projectId will receive the same decision.
   * This is optional, if not provided new decision will be calculated for each request
   * i.e. user may see a different response when accessing it multiple times.
   */
  seedId?: string;
  /**
   * This is optional and default is false,
   * when set to true, this api will only return flags that are enabled
   */
  ignoreDisabled?: boolean;

  /**
   * Additional data clients can provide to the feature flags service
   * Note that context data forces the request to assume the POST HTTP verb instead
   * due to how Labs is configured to receive this data
   *
   * @see https://wbdstreaming.atlassian.net/wiki/spaces/~62f36f555111209f4fe0c126/pages/288667465/Changes+to+CDEV+calling+into+Labs
   */
  context?: Record<string, string>;
}

/**
 * By providing a project Id (and tag) it will return a list of feature flags filtered by project Id and tag.
 *
 * @param options - parameters to configure Labs return
 * @param config - config to override the default request config
 * @public
 *
 * @see https://wbdstreaming.atlassian.net/wiki/spaces/EX/pages/241008641/Authenticated+Decision+s+API+for+Beam+Clients
 */
export const featureFlagsRequest = async <FF extends Partial<IFeatureFlagResponse>>(
  options: IFeatureFlagOptions,
  config?: RequestConfigOverride
): Promise<FF> => {
  const http = HttpClient.instance;
  const labsURL = '/labs/api/v1/sessions/feature-flags/decisions';

  if (options.context) {
    return (await http.post<FF>(labsURL, options, config)).data;
  }

  return (await http.get<FF>(labsURL, { ...config, params: options })).data;
};
