// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IStorage } from '../session-state';
import type { IBootstrapConfig, IBootstrapConfigJson } from './IBootstrapConfig';

const CONFIG_STORAGE_KEY: string = 'bootstrap-config';

/**
 * This class manages the caching and manipulation of Headwaiter responses for the client
 * @public
 */
export class BootstrapConfig {
  /**
   * private default state of the bootstrap config
   */
  private _default: IBootstrapConfig;

  /**
   * private local state of the bootstrap config
   */
  private _config: IBootstrapConfig;

  /**
   * reference to the storage provider
   */
  private _persistentStorage?: IStorage;

  /**
   * Initialize `BootstrapConfig` with our hard-coded default values to be merged with a headwaiter bootstrap response.
   * @param base - The default config to fallback on for things not specified by HeadWaiter
   * @param response - The response of a config request from HeadWaiter
   * @public
   */
  public constructor(defaultConfig: IBootstrapConfig, storage?: IStorage) {
    this._default = defaultConfig;
    this._config = this.update();
    this._persistentStorage = storage;
  }

  /**
   * Allow downstream consumers to update `BootstrapConfig` from the latest call to /bootstrap
   * @param response - The response of a config request from HeadWaiter
   */
  public update(response?: IBootstrapConfigJson): IBootstrapConfig {
    // apply a shallow merge of default and server response
    this._config = {
      ...this._default,
      ...(response || {})
    };

    if (response) {
      this._persistentStorage?.writeSync(CONFIG_STORAGE_KEY, JSON.stringify(this._config));
    }

    return this._config;
  }

  /**
   * Attempts to restore a previously saved bootstrap config
   *
   * @returns the restored config if it exists
   */
  public restore(): IBootstrapConfig | undefined {
    const storedConfig = this._persistentStorage?.readSync(CONFIG_STORAGE_KEY);
    if (storedConfig) {
      try {
        const config = JSON.parse(storedConfig);
        this._config = config;
        return config;
      } catch {
        return undefined;
      }
    }
  }

  /**
   * returns the current bootstrap state
   * @returns
   */
  public get(): IBootstrapConfig {
    return this._config;
  }

  /**
   * Find route keys in template url and replace with routing values
   * @param baseUrlTemplate - baseUrl template
   * @returns
   */
  private _replaceRouteKeys(baseUrlTemplate: string): string {
    return baseUrlTemplate.replace(/\{.*?\}/gm, (substring) => {
      const routeKey = substring.replace(/{|}/g, '');
      if (routeKey in this._config.routing) {
        return this._config.routing[routeKey];
      }
      return substring;
    });
  }

  /**
   * Resolve a relative bolt path to a full request uri
   * @param path - relative path
   * @returns
   */
  public resolveRequestUrl(path: string): string {
    // ensure relative paths always start with a slash
    if (path.charAt(0) !== '/') path = `/${path}`;

    // try to find a 'startsWith' match against the endpoint table
    for (const endpoint of this._config.endpoints) {
      if (path.startsWith(endpoint.path)) {
        // construct the full bolt request url
        const baseUrlTemplate = this._config.apiGroups[endpoint.apiGroup].baseUrl;
        const baseUrl = this._replaceRouteKeys(baseUrlTemplate);
        return `${baseUrl}${path}`;
      }
    }
    // return the original relative path
    // if no match is found
    return path;
  }
}
