// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * Entity representing a given ContentRestrictionLevel.
 *
 * @see https://docs.disco-api.com/apidocs/#content-decorators
 * @public
 */
export interface IContentRestrictionLevelAttributes {
  /** Localized description of the restriction level. */
  description?: string;
  /** Generalized name of the restriction level. */
  name: string;
  /** List of localized names of rating codes included within this level. */
  ratingCodes: string[];
}

/**
 * @public
 */
export class ContentRestrictionLevel extends TypedModel.generate<
  DataTypes.ContentRestrictionLevel,
  IContentRestrictionLevelAttributes
>() {}
