// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { Article } from './Article';
import type { Collection } from './Collection';
import type { DataTypes } from '../json-api';
import type { Image } from './Image';
import type { Route } from './Route';
import type { Show } from './Show';
import { TypedModel } from '../json-api';
import type { Video } from './Video';

/**
 * @public
 */
export interface ILinkAttributes {
  alias: string;
  description?: string;
  downloadHref?: string;
  downloadExpiryDays?: string;
  hints?: string[];
  href?: string;
  isFavorite?: boolean;
  kind: string;
  name?: string;
  packages?: string[];
  state?: string;
  title?: string;
}
/**
 * @public
 */
export interface ILinkRelationships {
  images?: Image[];
  linkedContent?: Article | Collection | Image | Show | Video;
  linkedContentRoutes?: Route[];
}

/**
 * @see https://docs.disco-api.com/apidocs/#links
 * @public
 */
export class Link extends TypedModel.generate<DataTypes.Link, ILinkAttributes, ILinkRelationships>() {}
