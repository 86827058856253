// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { Collection } from './Collection';
import type { DataTypes } from '../json-api';
import type { Image } from './Image';
import type { Link } from './Link';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#pages-deprecated
 * @public
 */
export interface IPageItemAttributes {
  /** CTA text for this item. */
  callToAction?: string;
  /** Item description. Overrides description of the content item. */
  description?: string;
  /** Array of rendering hints for the item. */
  hints?: string[];
  /** Item title. Overrides title/name of the content item. */
  title?: string;
}

/**
 * @public
 */
export interface IPageItemRelationships {
  collection?: Collection;
  image?: Image;
  /** Collection of images. Contains exactly one entry.
   * Overrides contents of default image. */
  images?: Image[];
  link?: Link;
}

/**
 * @public
 */
export class PageItem extends TypedModel.generate<
  DataTypes.PageItem,
  IPageItemAttributes,
  IPageItemRelationships
>() {}
