// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ClientId, ISessionConfig, Platform } from '@wbd/bolt-http';
// eslint-disable-next-line @rushstack/packlets/mechanics
import appManifest from '../../generated/app-manifest.json';

/**
 * Initial bolt site lookup key
 */
const BOLT_SITE_LOOKUP_KEY: string = 'beam_us';

/**
 * Initial bolt application id
 */
const APPLICATION_ID: string = 'beam';

/**
 * Initial bolt realm
 */
const REALM: string = 'bolt';

/**
 * Initial BEAM brand
 */
const BRAND: string = 'beam';

/**
 * Default bolt session configuration
 */
export function getBoltSessionConfig(
  clientID: ClientId,
  platformName: Platform
): Omit<ISessionConfig, 'deviceId' | 'deviceInfo'> {
  return {
    brandId: BRAND,
    realm: REALM,
    clientId: clientID,
    applicationId: APPLICATION_ID,
    applicationVersion: appManifest.appVersion,
    siteLookupKey: BOLT_SITE_LOOKUP_KEY,
    platform: platformName
  };
}
