// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Enum definition for device performance
 * @public
 */
export enum DevicePerformance {
  /**
   * HIGH performant device
   * rule set classification TBD
   */
  HIGH = 'high',
  /**
   * AVERAGE performant device
   * rule set classification TBD
   */
  AVERAGE = 'average',

  /**
   * LOW performant device
   * rule set classification TBD
   */
  LOW = 'low'
}
