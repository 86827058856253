// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#partners
 * @public
 */
export interface IPartnerAttributes {
  /** Partner display priority. */
  displayPriority?: number;
  /** Partner help page URL. */
  helpUrl?: string;
  /** Partner homepage URL. */
  homeUrl?: string;
  /** Partner logo URL. */
  logoUrl?: string;
  /** Partner name. */
  name: string;
}

/**
 * @public
 */
export class Partner extends TypedModel.generate<DataTypes.Partner, IPartnerAttributes>() {}
