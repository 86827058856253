// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { getIsoLanguageCodes, getSupplementaryIetfLanguageCodes } from '../../localization-enums';
import { PrimaryLanguageCode } from '../PrimaryLanguageCode';

let primaryLanguageCodes: ReadonlySet<PrimaryLanguageCode>;

/**
 * Returns a ReadonlySet containing all of the PrimaryLanguageCode values.
 *
 * @public
 */
export function getPrimaryLanguageCodes(): ReadonlySet<PrimaryLanguageCode> {
  if (primaryLanguageCodes === undefined) {
    primaryLanguageCodes = new Set([...getIsoLanguageCodes(), ...getSupplementaryIetfLanguageCodes()]);
  }
  return primaryLanguageCodes;
}
