// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { CanonicalizedCldrLocale, CanonicalizedCldrLocalesToCldrLocales } from '../generated';

import { CldrLocale } from '../generated/CldrLocale';
import { getCanonicalizedCldrLocales } from './getCanonicalizedCldrLocales';
import { getCldrLocales } from './getCldrLocales';

/**
 * Gets the CldrLocale code that corresponds to the given CanonicalizedCldrLocale code.
 *
 * @param canonicalizedCldrLocale - The CanonicalizedCldrLocale code for which we want the corresponding CldrLocale code
 * @returns the CldrLocale that corresponds to the given CanonicalizedCldrLocale
 *
 * @public
 */
export function getCldrLocaleForCanonicalizedCldrLocale(
  canonicalizedCldrLocale: CanonicalizedCldrLocale
): CldrLocale {
  // first validate if there is a 1:1 match between CanonicalizedCldrLocale and CldrLocale
  if (
    getCanonicalizedCldrLocales().has(canonicalizedCldrLocale) &&
    getCldrLocales().has(canonicalizedCldrLocale as string as CldrLocale)
  ) {
    return canonicalizedCldrLocale as string as CldrLocale;
  }

  // else validate if there is a match in the exception map
  const cldrLocale: CldrLocale | undefined =
    CanonicalizedCldrLocalesToCldrLocales.get(canonicalizedCldrLocale);

  if (!cldrLocale) {
    throw new Error(
      `The given Canonical CLDR Locale Code "${canonicalizedCldrLocale}" is not supported by the CLDR data set`
    );
  }

  return cldrLocale;
}
