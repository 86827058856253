// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#genres
 * @public
 */
export interface IGenreAttributes {
  /** Alternate ID for the genre. */
  alternateId: string;
  /** If the genre is marked as favorite in the user API. */
  isFavorite: boolean;
  /** Genre kind. */
  kind: string;
  /** Genre name. */
  name: string;
}

/**
 * @public
 */
export class Genre extends TypedModel.generate<DataTypes.Genre, IGenreAttributes>() {}
