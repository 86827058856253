// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * The ISO 3166-1 Alpha 2 Region Codes (see https://wiki.hbo.com/pages/viewpage.action?pageId=160140159#RegionCodesandFormatCodes:Locale\>GloablizationConteISO-311Alpha2RegionCodes).
 *
 * Official values were sourced from the IANA website:
 *    Website: https://www.iana.org/protocols
 *    Data endpoint URL: https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
 *    Sourced on: 2021-03-25
 *
 * This file was generated by `localization-enum-generator`. Please edit the template there to make any desired
 * changes. Changes made directly to this file will be overwritten without warning if this file is re-generated.
 *
 * @public
 */
export const enum IsoRegionCode {
  /** Ascension Island */
  AC = 'AC',

  /** Andorra */
  AD = 'AD',

  /** United Arab Emirates */
  AE = 'AE',

  /** Afghanistan */
  AF = 'AF',

  /** Antigua and Barbuda */
  AG = 'AG',

  /** Anguilla */
  AI = 'AI',

  /** Albania */
  AL = 'AL',

  /** Armenia */
  AM = 'AM',

  /** Netherlands Antilles */
  AN = 'AN',

  /** Angola */
  AO = 'AO',

  /** Antarctica */
  AQ = 'AQ',

  /** Argentina */
  AR = 'AR',

  /** American Samoa */
  AS = 'AS',

  /** Austria */
  AT = 'AT',

  /** Australia */
  AU = 'AU',

  /** Aruba */
  AW = 'AW',

  /** Åland Islands */
  AX = 'AX',

  /** Azerbaijan */
  AZ = 'AZ',

  /** Bosnia and Herzegovina */
  BA = 'BA',

  /** Barbados */
  BB = 'BB',

  /** Bangladesh */
  BD = 'BD',

  /** Belgium */
  BE = 'BE',

  /** Burkina Faso */
  BF = 'BF',

  /** Bulgaria */
  BG = 'BG',

  /** Bahrain */
  BH = 'BH',

  /** Burundi */
  BI = 'BI',

  /** Benin */
  BJ = 'BJ',

  /** Saint Barthélemy */
  BL = 'BL',

  /** Bermuda */
  BM = 'BM',

  /** Brunei Darussalam */
  BN = 'BN',

  /** Bolivia */
  BO = 'BO',

  /** Bonaire, Sint Eustatius and Saba */
  BQ = 'BQ',

  /** Brazil */
  BR = 'BR',

  /** Bahamas */
  BS = 'BS',

  /** Bhutan */
  BT = 'BT',

  /** Burma */
  BU = 'BU',

  /** Bouvet Island */
  BV = 'BV',

  /** Botswana */
  BW = 'BW',

  /** Belarus */
  BY = 'BY',

  /** Belize */
  BZ = 'BZ',

  /** Canada */
  CA = 'CA',

  /** Cocos (Keeling) Islands */
  CC = 'CC',

  /** The Democratic Republic of the Congo */
  CD = 'CD',

  /** Central African Republic */
  CF = 'CF',

  /** Congo */
  CG = 'CG',

  /** Switzerland */
  CH = 'CH',

  /** Côte d'Ivoire */
  CI = 'CI',

  /** Cook Islands */
  CK = 'CK',

  /** Chile */
  CL = 'CL',

  /** Cameroon */
  CM = 'CM',

  /** China */
  CN = 'CN',

  /** Colombia */
  CO = 'CO',

  /** Clipperton Island */
  CP = 'CP',

  /** Costa Rica */
  CR = 'CR',

  /** Serbia and Montenegro */
  CS = 'CS',

  /** Cuba */
  CU = 'CU',

  /** Cabo Verde | Cape Verde */
  CV = 'CV',

  /** Curaçao */
  CW = 'CW',

  /** Christmas Island */
  CX = 'CX',

  /** Cyprus */
  CY = 'CY',

  /** Czechia | Czech Republic */
  CZ = 'CZ',

  /** German Democratic Republic */
  DD = 'DD',

  /** Germany */
  DE = 'DE',

  /** Diego Garcia */
  DG = 'DG',

  /** Djibouti */
  DJ = 'DJ',

  /** Denmark */
  DK = 'DK',

  /** Dominica */
  DM = 'DM',

  /** Dominican Republic */
  DO = 'DO',

  /** Algeria */
  DZ = 'DZ',

  /** Ceuta, Melilla */
  EA = 'EA',

  /** Ecuador */
  EC = 'EC',

  /** Estonia */
  EE = 'EE',

  /** Egypt */
  EG = 'EG',

  /** Western Sahara */
  EH = 'EH',

  /** Eritrea */
  ER = 'ER',

  /** Spain */
  ES = 'ES',

  /** Ethiopia */
  ET = 'ET',

  /** European Union */
  EU = 'EU',

  /** Eurozone */
  EZ = 'EZ',

  /** Finland */
  FI = 'FI',

  /** Fiji */
  FJ = 'FJ',

  /** Falkland Islands (Malvinas) */
  FK = 'FK',

  /** Federated States of Micronesia */
  FM = 'FM',

  /** Faroe Islands */
  FO = 'FO',

  /** France */
  FR = 'FR',

  /** Metropolitan France */
  FX = 'FX',

  /** Gabon */
  GA = 'GA',

  /** United Kingdom */
  GB = 'GB',

  /** Grenada */
  GD = 'GD',

  /** Georgia */
  GE = 'GE',

  /** French Guiana */
  GF = 'GF',

  /** Guernsey */
  GG = 'GG',

  /** Ghana */
  GH = 'GH',

  /** Gibraltar */
  GI = 'GI',

  /** Greenland */
  GL = 'GL',

  /** Gambia */
  GM = 'GM',

  /** Guinea */
  GN = 'GN',

  /** Guadeloupe */
  GP = 'GP',

  /** Equatorial Guinea */
  GQ = 'GQ',

  /** Greece */
  GR = 'GR',

  /** South Georgia and the South Sandwich Islands */
  GS = 'GS',

  /** Guatemala */
  GT = 'GT',

  /** Guam */
  GU = 'GU',

  /** Guinea-Bissau */
  GW = 'GW',

  /** Guyana */
  GY = 'GY',

  /** Hong Kong */
  HK = 'HK',

  /** Heard Island and McDonald Islands */
  HM = 'HM',

  /** Honduras */
  HN = 'HN',

  /** Croatia */
  HR = 'HR',

  /** Haiti */
  HT = 'HT',

  /** Hungary */
  HU = 'HU',

  /** Canary Islands */
  IC = 'IC',

  /** Indonesia */
  ID = 'ID',

  /** Ireland */
  IE = 'IE',

  /** Israel */
  IL = 'IL',

  /** Isle of Man */
  IM = 'IM',

  /** India */
  IN = 'IN',

  /** British Indian Ocean Territory */
  IO = 'IO',

  /** Iraq */
  IQ = 'IQ',

  /** Islamic Republic of Iran */
  IR = 'IR',

  /** Iceland */
  IS = 'IS',

  /** Italy */
  IT = 'IT',

  /** Jersey */
  JE = 'JE',

  /** Jamaica */
  JM = 'JM',

  /** Jordan */
  JO = 'JO',

  /** Japan */
  JP = 'JP',

  /** Kenya */
  KE = 'KE',

  /** Kyrgyzstan */
  KG = 'KG',

  /** Cambodia */
  KH = 'KH',

  /** Kiribati */
  KI = 'KI',

  /** Comoros */
  KM = 'KM',

  /** Saint Kitts and Nevis */
  KN = 'KN',

  /** Democratic People's Republic of Korea */
  KP = 'KP',

  /** Republic of Korea */
  KR = 'KR',

  /** Kuwait */
  KW = 'KW',

  /** Cayman Islands */
  KY = 'KY',

  /** Kazakhstan */
  KZ = 'KZ',

  /** Lao People's Democratic Republic */
  LA = 'LA',

  /** Lebanon */
  LB = 'LB',

  /** Saint Lucia */
  LC = 'LC',

  /** Liechtenstein */
  LI = 'LI',

  /** Sri Lanka */
  LK = 'LK',

  /** Liberia */
  LR = 'LR',

  /** Lesotho */
  LS = 'LS',

  /** Lithuania */
  LT = 'LT',

  /** Luxembourg */
  LU = 'LU',

  /** Latvia */
  LV = 'LV',

  /** Libya */
  LY = 'LY',

  /** Morocco */
  MA = 'MA',

  /** Monaco */
  MC = 'MC',

  /** Moldova */
  MD = 'MD',

  /** Montenegro */
  ME = 'ME',

  /** Saint Martin (French part) */
  MF = 'MF',

  /** Madagascar */
  MG = 'MG',

  /** Marshall Islands */
  MH = 'MH',

  /** North Macedonia */
  MK = 'MK',

  /** Mali */
  ML = 'ML',

  /** Myanmar */
  MM = 'MM',

  /** Mongolia */
  MN = 'MN',

  /** Macao */
  MO = 'MO',

  /** Northern Mariana Islands */
  MP = 'MP',

  /** Martinique */
  MQ = 'MQ',

  /** Mauritania */
  MR = 'MR',

  /** Montserrat */
  MS = 'MS',

  /** Malta */
  MT = 'MT',

  /** Mauritius */
  MU = 'MU',

  /** Maldives */
  MV = 'MV',

  /** Malawi */
  MW = 'MW',

  /** Mexico */
  MX = 'MX',

  /** Malaysia */
  MY = 'MY',

  /** Mozambique */
  MZ = 'MZ',

  /** Namibia */
  NA = 'NA',

  /** New Caledonia */
  NC = 'NC',

  /** Niger */
  NE = 'NE',

  /** Norfolk Island */
  NF = 'NF',

  /** Nigeria */
  NG = 'NG',

  /** Nicaragua */
  NI = 'NI',

  /** Netherlands */
  NL = 'NL',

  /** Norway */
  NO = 'NO',

  /** Nepal */
  NP = 'NP',

  /** Nauru */
  NR = 'NR',

  /** Neutral Zone */
  NT = 'NT',

  /** Niue */
  NU = 'NU',

  /** New Zealand */
  NZ = 'NZ',

  /** Oman */
  OM = 'OM',

  /** Panama */
  PA = 'PA',

  /** Peru */
  PE = 'PE',

  /** French Polynesia */
  PF = 'PF',

  /** Papua New Guinea */
  PG = 'PG',

  /** Philippines */
  PH = 'PH',

  /** Pakistan */
  PK = 'PK',

  /** Poland */
  PL = 'PL',

  /** Saint Pierre and Miquelon */
  PM = 'PM',

  /** Pitcairn */
  PN = 'PN',

  /** Puerto Rico */
  PR = 'PR',

  /** State of Palestine */
  PS = 'PS',

  /** Portugal */
  PT = 'PT',

  /** Palau */
  PW = 'PW',

  /** Paraguay */
  PY = 'PY',

  /** Qatar */
  QA = 'QA',

  /** Réunion */
  RE = 'RE',

  /** Romania */
  RO = 'RO',

  /** Serbia */
  RS = 'RS',

  /** Russian Federation */
  RU = 'RU',

  /** Rwanda */
  RW = 'RW',

  /** Saudi Arabia */
  SA = 'SA',

  /** Solomon Islands */
  SB = 'SB',

  /** Seychelles */
  SC = 'SC',

  /** Sudan */
  SD = 'SD',

  /** Sweden */
  SE = 'SE',

  /** Singapore */
  SG = 'SG',

  /** Saint Helena, Ascension and Tristan da Cunha */
  SH = 'SH',

  /** Slovenia */
  SI = 'SI',

  /** Svalbard and Jan Mayen */
  SJ = 'SJ',

  /** Slovakia */
  SK = 'SK',

  /** Sierra Leone */
  SL = 'SL',

  /** San Marino */
  SM = 'SM',

  /** Senegal */
  SN = 'SN',

  /** Somalia */
  SO = 'SO',

  /** Suriname */
  SR = 'SR',

  /** South Sudan */
  SS = 'SS',

  /** Sao Tome and Principe */
  ST = 'ST',

  /** Union of Soviet Socialist Republics */
  SU = 'SU',

  /** El Salvador */
  SV = 'SV',

  /** Sint Maarten (Dutch part) */
  SX = 'SX',

  /** Syrian Arab Republic */
  SY = 'SY',

  /** Eswatini | eSwatini | Swaziland */
  SZ = 'SZ',

  /** Tristan da Cunha */
  TA = 'TA',

  /** Turks and Caicos Islands */
  TC = 'TC',

  /** Chad */
  TD = 'TD',

  /** French Southern Territories */
  TF = 'TF',

  /** Togo */
  TG = 'TG',

  /** Thailand */
  TH = 'TH',

  /** Tajikistan */
  TJ = 'TJ',

  /** Tokelau */
  TK = 'TK',

  /** Timor-Leste */
  TL = 'TL',

  /** Turkmenistan */
  TM = 'TM',

  /** Tunisia */
  TN = 'TN',

  /** Tonga */
  TO = 'TO',

  /** East Timor */
  TP = 'TP',

  /** Turkey */
  TR = 'TR',

  /** Trinidad and Tobago */
  TT = 'TT',

  /** Tuvalu */
  TV = 'TV',

  /** Taiwan, Province of China */
  TW = 'TW',

  /** United Republic of Tanzania */
  TZ = 'TZ',

  /** Ukraine */
  UA = 'UA',

  /** Uganda */
  UG = 'UG',

  /** United States Minor Outlying Islands */
  UM = 'UM',

  /** United Nations */
  UN = 'UN',

  /** United States */
  US = 'US',

  /** Uruguay */
  UY = 'UY',

  /** Uzbekistan */
  UZ = 'UZ',

  /** Holy See (Vatican City State) */
  VA = 'VA',

  /** Saint Vincent and the Grenadines */
  VC = 'VC',

  /** Venezuela */
  VE = 'VE',

  /** British Virgin Islands */
  VG = 'VG',

  /** U.S. Virgin Islands */
  VI = 'VI',

  /** Viet Nam */
  VN = 'VN',

  /** Vanuatu */
  VU = 'VU',

  /** Wallis and Futuna */
  WF = 'WF',

  /** Samoa */
  WS = 'WS',

  /** Kosovo */
  XK = 'XK',

  /** Democratic Yemen */
  YD = 'YD',

  /** Yemen */
  YE = 'YE',

  /** Mayotte */
  YT = 'YT',

  /** Yugoslavia */
  YU = 'YU',

  /** South Africa */
  ZA = 'ZA',

  /** Zambia */
  ZM = 'ZM',

  /** Zaire */
  ZR = 'ZR',

  /** Zimbabwe */
  ZW = 'ZW',

  /**
   * The pseudo-localization "region" that indicates that:
   *    1) English characters should be replaced with non-English characters, and
   *    2) Strings should be surrounded with delimiters "[!!!" and "!!!]".
   *
   * Used in manual testing to verify that:
   *    1) All customer-facing strings have been properly globalized in the codebase,
   *    2) Non-English Unicode characters are properly supported,
   *    3) Accents (both superscript and subscript) are not clipped by the UI elements, and
   *    4) Strings are not being composed in code, but are being translated as whole phrases/sentences.
   */
  LOC = 'LOC',

  /**
   * The pseudo-localization "region" that indicates that:
   *    1) The same transformations as given above for "LOC" should be applied, and in addition
   *    2) The length of the original string should be increased significantly.
   *
   * Used in manual testing for the same purposes as the "LOC" pseudo-region, and also to verify that
   * the UI accommodates strings which are longer in many target regions than they are in English.
   */
  LONG = 'LONG'
}

// prettier-ignore
/**
 * set of values from the IsoRegionCode const enums
 * @public
 */
export const IsoRegionCodeSet: ReadonlySet<IsoRegionCode> = new Set([
'AC', 'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AN', 'AO', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR', 'BS', 'BT', 'BU', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CP', 'CR', 'CS', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DD', 'DE', 'DG', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EA', 'EC', 'EE', 'EG', 'EH', 'ER', 'ES', 'ET', 'EU', 'EZ', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'FX', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'IC', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NT', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SU', 'SV', 'SX', 'SY', 'SZ', 'TA', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TP', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UM', 'UN', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'XK', 'YD', 'YE', 'YT', 'YU', 'ZA', 'ZM', 'ZR', 'ZW', 'LOC', 'LONG'
]) as Set<IsoRegionCode>;
