// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { getPrimaryLanguageCodes } from '../getters';
import { PrimaryLanguageCode } from '../PrimaryLanguageCode';

/**
 * Type guard function for PrimaryLanguageCode values.
 *
 * @param tag - a possible PrimaryLanguageCode
 *
 * @public
 */
export function isPrimaryLanguageCode(tag: string): tag is PrimaryLanguageCode {
  return getPrimaryLanguageCodes().has(tag as PrimaryLanguageCode);
}
