// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @public
 */
export interface IContentRatingSystemAttributes {
  /** Regulator who rated the content. */
  system: string;
}

/**
 * @public
 */
export class ContentRatingSystem extends TypedModel.generate<
  DataTypes.ContentRatingSystem,
  IContentRatingSystemAttributes
>() {}
