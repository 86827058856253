// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { ICurrencyValue } from './ICurrencyValue';
import { ObjectUtils } from '@wbd/localization-core';

/**
 * Type guard function to ensure that an unknown value is an `ICurrencyValue`.
 *
 * @param value - the value to be checked for being an `ICurrencyValue`
 * @returns true if `value` is an `ICurrencyValue`
 *
 * @public
 */
export function isICurrencyValue(value: unknown): value is ICurrencyValue {
  return (
    typeof value === 'object' &&
    value !== null &&
    ObjectUtils.hasOwnProperty(value, 'currencyAmount') &&
    typeof value.currencyAmount === 'number' &&
    ObjectUtils.hasOwnProperty(value, 'currencyCode') &&
    typeof value.currencyCode === 'string'
  );
}
