// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { type IStorage } from '@wbd/beam-dom-extensions';
import { Configuration, VERSIONED_EVENT_KEY, type IQueueableEvent } from '../core';
import { EventBatcher } from './EventBatcher';
import { IPlatformRuntimeV1Props } from '../generated';

/**
 * Event Queue Class
 * @public
 */
export class EventQueue {
  private static _STATUS_CODE_BAD_REQUEST: number = 400;
  private static _persistentStorage: IStorage;
  private static _storage: IQueueableEvent[] = [];
  private static _maxBatchSize: number = 10;
  private static _maxQueueSize: number = 2000;
  private static _flushInterval: number = 2000;
  private static _minimumFlushInterval: number = 300;
  private static _minDisableInterval: number = 30; // Measured in minutes
  private static _flushTimer?: ReturnType<typeof setTimeout> = undefined;
  private static _disableTimer?: ReturnType<typeof setTimeout> = undefined;
  private static _isOnline: boolean = true;
  private static _currentAttempts: number = 0;
  private static _maxRetries: number = 5;
  private static _flushThrottleFactor: number = 2;
  private static _disableCallback: (disableState: boolean) => void;
  private static _createRuntimeErrorCallback: (payload: IPlatformRuntimeV1Props) => void;

  public static initialize(config: Configuration): void {
    this._maxBatchSize = config.maxEventBatchSize;
    this._flushInterval = config.flushInterval;
    this._maxRetries = config.maxRetries;
    this._flushThrottleFactor = config.flushThrottleFactor;
    this._minDisableInterval = config.minDisableInterval;
    this._scheduleFlusher();
    this._currentAttempts = 0;
    if (config.eventStorage) {
      this._persistentStorage = config.eventStorage;
      this._storage = this._getIStorageEventQueue();
    }
  }

  /**
   * Passes in a call back function to communicate when the ISDK should disable due to out of retries.
   * @param disableCallback - The method to be called to indicate the disable state.
   * @public
   */
  public static onQueueDisableChanged(disableCallback: (isDisabled: boolean) => void): void {
    this._disableCallback = disableCallback;
  }

  /**
   * Passes in a call back function to communicate when the queue should capture a PlatformRuntimeV1 error,
   * this should only be called on a 400 bad_request from telegraph.
   * @param disableCallback - The method to be called to capture the error.
   * @public
   */
  public static onTelegraphBadRequest(
    createRuntimeErrorCallback: (payload: IPlatformRuntimeV1Props) => void
  ): void {
    this._createRuntimeErrorCallback = createRuntimeErrorCallback;
  }

  /**
   * Enqueues a given event into the storage.
   * @param event - A single IQueueableEvent.
   * @public
   */
  public static enqueueEvent(event: IQueueableEvent): void {
    if (this._storage.length >= this._maxQueueSize) {
      this._popTopEvents();
    }

    this._storage.push(event);
    if (this._persistentStorage) {
      this._persistentStorage.writeSync('ISDKEventQueue', JSON.stringify(this._storage));
    }
  }

  private static _popTopEvents(): void {
    let eventsOverMax = 0;
    if (this._storage.length >= this._maxQueueSize) {
      eventsOverMax = this._storage.length - this._maxQueueSize;
    }
    // pop all events over our max size + 1 (for the new event) off of our storage
    this._storage.splice(0, eventsOverMax + 1);
  }

  /**
   * Gets the number of events in the queue.
   * @returns A number corresponding to the size of the queue.
   * @public
   */
  public static size(): number {
    return this._storage.length;
  }

  /**
   * Resets the queue storage back to empty.
   * @public
   */
  public static clearQueue(): void {
    this._storage = [];
    if (this._persistentStorage) this._persistentStorage.removeSync('ISDKEventQueue');
  }

  /**
   * Sets the online status for transmission.
   * @public
   */
  public static setOnlineStatus(isOnline: boolean): void {
    this._isOnline = isOnline;
    if (isOnline && !this._disableTimer && !this._flushTimer) {
      // we need to respect whether or not we are disabled due to retry failures.
      // reuse the old flush timer if we have one running from before we went offline.
      this._currentAttempts = 0;
      this._scheduleFlusher();
    }
  }

  /**
   * Flushes the event queue by transmitting batches of size _maxBatchSize.
   * @public
   */
  public static async flushEventQueue(): Promise<void> {
    if (this._shouldFlushQueuedEvents()) {
      let batch = this._getEventBatch();
      try {
        await EventBatcher.batchAsync(batch);
      } catch (error) {
        // Creates a platform runtime error and culls the current batch when a specific 400 is returned from telegraph
        if (error.status === this._STATUS_CODE_BAD_REQUEST) {
          const payload: IPlatformRuntimeV1Props = {
            err: {
              message: 'Unable to send batch to telegraph',
              stackTrace: batch.map((item) => item[VERSIONED_EVENT_KEY].eventType).join(',')
            }
          };
          this._createRuntimeErrorCallback(payload);
          batch = [];
        } else {
          await this._throttleIfFailedRequest(error, batch);
        }
        return;
      }
      // Our flush was successful reset our retry counter
      this._currentAttempts = 0;
      // If the queue still has items after batch transmission, expedite the next batch.
      if (this.size() > 0) {
        this._scheduleFlusher(this._minimumFlushInterval);
      }
    }
  }

  /**
   * Clears the flush timer and schedules a new flush.
   * We are using a setTimeout so we can hold off on the flush until the previous request has completed.
   * @param interval - Time in milliseconds until all logs are sent to telegraph.
   */
  private static _scheduleFlusher(interval?: number): void {
    this._clearFlushTimer();

    this._flushTimer = setTimeout(async () => {
      try {
        this._clearFlushTimer();
        if (this._isOnline) {
          // send off requests
          await this.flushEventQueue();
          // flushEventQueue will throttle the timer on failure, only create a new timer on a success
          if (!this._flushTimer && !this._disableTimer) {
            this._scheduleFlusher();
          }
        }
      } catch (error) {
        // TODO GCX-8936: Notify the Client that we have stopped scheduling flushes because we failed our retry policy
      }
    }, interval ?? this._flushInterval);
  }

  /**
   * Returns whether or not we should attempt a flush.
   */
  private static _shouldFlushQueuedEvents(): boolean {
    return this.size() > 0 && this._isOnline;
  }

  /**
   * Clears the flush timer.
   */
  private static _clearFlushTimer(): void {
    if (this._flushTimer) {
      clearTimeout(this._flushTimer);
      this._flushTimer = undefined;
    }
  }

  // Places events back into the front of our array of QueueableEvents
  private static _insertToFront(events: IQueueableEvent[]): void {
    this._storage.unshift(...events);
    if (this._persistentStorage) {
      this._persistentStorage.writeSync('ISDKEventQueue', JSON.stringify(this._storage));
    }
  }

  /**
   * Gets the first _maxBatchSize number of events from the queue.
   * @returns An array of IQueueableEvents of size minor or equal to _maxBatchSize.
   */
  private static _getEventBatch(): IQueueableEvent[] {
    const batch = this._storage.splice(0, this._maxBatchSize);
    if (this._persistentStorage) {
      this._persistentStorage.writeSync('ISDKEventQueue', JSON.stringify(this._storage));
    }
    return batch;
  }

  /**
   * Synchronously reads "ISDKEventQueue" key from IStorage.
   * @returns An array containing all the events stored in IStorage under that key, or empty array if no value was found.
   */
  private static _getIStorageEventQueue(): IQueueableEvent[] {
    const iStorageEventQueue = this._persistentStorage.readSync('ISDKEventQueue');
    try {
      return iStorageEventQueue ? JSON.parse(iStorageEventQueue) : [];
    } catch (error) {
      // Error parsing iStorageEventQueue, returning empty array
      return [];
    }
  }

  private static async _throttleIfFailedRequest(error: unknown, batch: IQueueableEvent[]): Promise<void> {
    // Increment our retry counter
    this._currentAttempts++;
    // We failed to push the batch, place all the events in the batch back into the queue
    this._insertToFront(batch);
    // If we have remaining retries, retry
    if (this._currentAttempts <= this._maxRetries) {
      this._scheduleFlusher(this._flushInterval * Math.pow(this._flushThrottleFactor, this._currentAttempts));
    } else {
      // Out of retries, disable further transmission attempts.
      this._disableTransmission();
    }
  }

  private static _disableTransmission(): void {
    //disable flush timer
    this._clearFlushTimer();

    //disable heartbeat
    this._disableCallback(true);

    //set re-enable timer
    this._disableTimer = setTimeout(() => {
      // re-enable flushing and heartbeat
      this._disableTimer = undefined;
      this._currentAttempts = 0;
      this._scheduleFlusher();
      this._disableCallback(false);
    }, this._minDisableInterval * 60 * 1000);
  }
}
