// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Data types for globalization formatting.
 *
 * Sourced in part from `hadron/src/go/js/ui/Localization.js`.
 *
 * @public
 */
export enum DataType {
  /**
   * A currency value.
   */
  CURRENCY = 'CURRENCY',

  /**
   * A date.
   */
  DATE = 'DATE',

  /**
   * A duration.
   */
  DURATION = 'DURATION',

  /**
   * Any number.
   */
  NUMBER = 'NUMBER',

  /**
   * A phone number.
   */
  PHONE_NUMBER = 'PHONE_NUMBER',

  /**
   * A relative time (e.g. "3 days ago", "next week").
   */
  RELATIVE_TIME = 'RELATIVE_TIME',

  /**
   * A string to be interpolated. Note that string interpolation should be a last-ditch option, as translation
   * units shorter than a full sentence can be difficult or impossible to translate correctly.
   *
   * If the string to be interpolated has a limited set of values, break the message into multiple messages, one for
   * each value. For example, instead of
   * @example
   * ```
   * NewDeviceCongratulations: {
   *   template: 'Congratulations on your purchase of a {deviceName}.',
   *   parameters: {
   *     deviceName: {
   *       type: DataType.Number,
   *       format: NumberFormat.INTEGER,
   *       notes: 'One of "Apple TV", "Samsung TV", "XBox One"'
   *     }
   *   }
   * }
   * ```
   * use
   * @example
   * ```
   * NewDeviceCongratulations_AppleTV: {
   *   template: 'Congratulations on your purchase of an Apple TV.',
   * }
   * NewDeviceCongratulations_SamsungTV: {
   *   template: 'Congratulations on your purchase of a Samsung TV.',
   * }
   * NewDeviceCongratulations_XBoxOne: {
   *   template: 'Congratulations on your purchase of an XBox One.',
   * }
   * ```
   *
   * Note how the text surrounding the device name changes, depending on whether the first letter of the device name
   * is a consonant sound ("Samsung") or a vowel sound ("Apple", "XBox"). This kind of translation complexity occurs
   * in other languages in ways that are unpredictable to an English speaker. Reformulations like
   * @example
   * ```
   * NewDeviceCongratulations_XBoxOne: {
   *   template: 'Congratulations on your purchase of a new {deviceName}',
   * }
   * ```
   * may address the problem in English, but provide no guarantee of addressing the problem in any particular
   * target language.
   *
   * General string interpolation does not permit this sort of translation flexibility, resulting in poorer-quality
   * translations in languages that require it.
   */
  TEXT = 'TEXT',

  /**
   * A "units" format, for formatting a certain number of some unit (e.g. "3 seconds").
   */
  UNIT = 'UNIT'
}
