// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IRuntimeMessageSpecification } from './IRuntimeMessageSpecification';
import { RuntimeResourceSpecification } from './RuntimeResourceSpecification';

/**
 * Type guard function for `IRuntimeMessageSpecification`.  Returns true when the provided template is an
 * `IRuntimeMessageSpecification`, otherwise returns false.
 * @param template - The value to check, which may be either an `IRuntimeMessageSpecification` or a
 * `JsonCompatibleString`
 *
 * @public
 */
export function isIRuntimeMessageSpecification(
  template: RuntimeResourceSpecification
): template is IRuntimeMessageSpecification {
  return typeof template !== 'string' && !Array.isArray(template);
}
