// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import type { IAxiosRetryConfig } from 'axios-retry';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import { stringify } from 'qs';

import { BootstrapConfig, clientConfigDefaults } from '../bootstrap-config';
import { isHttpTimeoutError } from '../http-error';
import type {
  IInterceptorManager,
  IInterceptors,
  IRequestConfig,
  IRequestRetryConfig,
  IResponse
} from '../http-internal';
import { discoHeadersInterceptorFactory } from '../interceptors';
import type { ISessionConfig } from '../session-config';
import { SessionConfig } from '../session-config';
import type { IStorage } from '../session-state';
import { SessionState } from '../session-state';

/**
 * The maximum number of retries before failing a request
 * @public
 */
export const REQUEST_MAX_RETRIES: number = 2;

/**
 * The maximum time before failing a request
 * @public
 */
export const REQUEST_MAX_TIMEOUT: number = 20_000;

/**
 * @public
 */
export interface IDefaultRequestOptions {
  /**
   * Specify a default base url for requests
   *
   * @deprecated use ISessionConfig.environment to set / override the environment for bolt services.
   * only use baseUrl for legacy D+ services that don't support global roaming.
   */
  baseUrl?: string;

  /**
   * `withCredentials` indicates whether or not cross-site Access-Control requests
   * should be made using credentials
   */
  withCredentials?: boolean;
}

/**
 * Request config as exposed on BoltHttpClient
 *
 * @public
 */
export type IRequestMethodConfig = Omit<IRequestConfig, 'url' | 'method' | 'data' | 'originalRequestUrl'>;

/**
 * Returns the raw unmapped json api responses from Sonic CMS
 * @public
 */
export class BoltHttpClient {
  /**
   * Internal instance of axios
   */
  private _http: AxiosInstance;

  /**
   * Internal instance of axios
   */
  public readonly bootstrapConfig: BootstrapConfig | undefined;

  /**
   * HTTPClient config as passed on creation
   */
  public readonly sessionConfig: SessionConfig;

  /**
   * HTTPClient interceptors
   */
  public readonly interceptors: IInterceptors;

  /**
   * HTTPClient session state
   */
  public readonly sessionState: SessionState;

  /**
   * Internal constructor to create the HTTP client
   * @param config - session configuration
   * @param axiosConfig - optional axios instance default override configuration
   * @param retryConfig - optional axios-retry override configuration
   *
   * @internal
   */
  private constructor(
    config: ISessionConfig,
    axiosConfig?: Partial<AxiosRequestConfig>,
    retryConfig?: IAxiosRetryConfig,
    storage?: IStorage
  ) {
    this.sessionState = new SessionState(storage);
    this.sessionConfig = new SessionConfig(config);
    this._http = axios.create(axiosConfig);

    // create default bootstrap config
    // for the targeted environment
    const defaultBootstrapConfig = clientConfigDefaults(
      this.sessionConfig.environment,
      this.sessionConfig.globalDomain
    );

    // init bootstrap config
    // except if there is a baseUrl override
    // this allows clients to still connect to legacy D+ backend
    if (axiosConfig?.baseURL === undefined) {
      this.bootstrapConfig = new BootstrapConfig(defaultBootstrapConfig, storage);
    }

    const self = this;
    this.interceptors = {
      get request() {
        return self._http.interceptors.request as IInterceptorManager<IRequestConfig>;
      },
      get response() {
        return self._http.interceptors.response as IInterceptorManager<IResponse>;
      }
    };

    this._reverseExecutionOrder(this.interceptors.request);

    if (retryConfig) {
      axiosRetry(this._http, retryConfig);
    }

    this.sessionState.configureInterceptors(this.interceptors);
  }

  /**
   * Axios executes request interceptors in last in first out order, this makes it particularly useless
   * for us as we expect the base interceptors to always fire first. This overrides the forEach method to
   * execute in reverse order, without modifying the original array.
   *
   * https://github.com/axios/axios/blob/v1.x/lib/core/Axios.js#L90-L97
   *
   * https://github.com/axios/axios/issues/1663
   *
   * @param interceptorManager - interceptor manager
   */
  private _reverseExecutionOrder(
    interceptorManager: IInterceptorManager<IRequestConfig> | IInterceptorManager<IResponse>
  ): void {
    interceptorManager.forEach = (fn) => {
      const handlers = interceptorManager.handlers;
      [...handlers].reverse().forEach((handler) => {
        if (handler !== null) {
          fn(handler);
        }
      });
    };
  }

  /**
   * create a new instance of HTTP client
   * @param session - session configuration
   * @param requestOptions - optional request options
   * @returns
   */
  public static create(
    session: ISessionConfig,
    requestOptions?: IDefaultRequestOptions,
    storage?: IStorage
  ): BoltHttpClient {
    const httpClient = new BoltHttpClient(
      session,
      {
        baseURL: requestOptions?.baseUrl,
        paramsSerializer: (params) => {
          return params instanceof URLSearchParams
            ? params.toString()
            : stringify(params, { arrayFormat: 'comma', encode: false });
        },
        withCredentials: requestOptions?.withCredentials || false,
        timeout: REQUEST_MAX_TIMEOUT,
        transitional: {
          /**
           * this will cause axios to throw a ETIMEDOUT error instead of the generic ECONNABORTED
           * when a timeout has occurred.
           * see transitional under https://github.com/axios/axios#request-config
           */
          clarifyTimeoutError: true
        }
      },
      {
        // retries should reset the timeout, so that don't immediately retry with
        // no time remaining, and immediately fail again
        shouldResetTimeout: true,
        retries: REQUEST_MAX_RETRIES,
        // retry on network timeout failures only
        // the expectation is that in the near future Headwaiter
        // will return retry policy by endpoint for 5xx errors
        retryCondition: (error) => isHttpTimeoutError(error),
        retryDelay: exponentialDelay
      },
      storage
    );
    httpClient.interceptors.request.use(discoHeadersInterceptorFactory(httpClient.sessionConfig));

    return httpClient;
  }

  /**
   * Execute a GET request
   * @param url - request url
   * @param config - request config
   * @returns
   */
  public async get<T, D = unknown, R = IResponse<T, D>>(
    url: string,
    config?: IRequestMethodConfig,
    retryConfig?: IRequestRetryConfig
  ): Promise<R> {
    const resolvedUrl = this.bootstrapConfig?.resolveRequestUrl(url) ?? url;
    const requestConfig = { ...config, originalRequestUrl: url };

    return this._http.get<T, R, D>(resolvedUrl, {
      ...requestConfig,
      'axios-retry': retryConfig
    });
  }

  /**
   * Execute a POST request
   * @param url - request url
   * @param config - request config
   * @returns
   */
  public async post<T, D = unknown, R = IResponse<T, D>>(
    url: string,
    data?: D,
    config?: IRequestMethodConfig,
    retryConfig?: IRequestRetryConfig
  ): Promise<R> {
    const resolvedUrl = this.bootstrapConfig?.resolveRequestUrl(url) ?? url;
    const requestConfig = { ...config, originalRequestUrl: url };

    return this._http.post<T, R, D>(resolvedUrl, data, {
      ...requestConfig,
      'axios-retry': retryConfig
    });
  }

  /**
   * Execute a PATCH request
   * @param url - request url
   * @param config - request config
   * @returns
   */
  public async patch<T, D = unknown, R = IResponse<T, D>>(
    url: string,
    data?: D,
    config?: IRequestMethodConfig,
    retryConfig?: IRequestRetryConfig
  ): Promise<R> {
    const resolvedUrl = this.bootstrapConfig?.resolveRequestUrl(url) ?? url;
    const requestConfig = { ...config, originalRequestUrl: url };

    return this._http.patch<T, R, D>(resolvedUrl, data, {
      ...requestConfig,
      'axios-retry': retryConfig
    });
  }

  /**
   * Execute a DELETE request
   * @param url - request url
   * @param config - request config
   * @returns
   */
  public async delete<T, D = unknown, R = IResponse<T, D>>(
    url: string,
    config?: IRequestMethodConfig,
    retryConfig?: IRequestRetryConfig
  ): Promise<R> {
    const resolvedUrl = this.bootstrapConfig?.resolveRequestUrl(url) ?? url;
    const requestConfig = { ...config, originalRequestUrl: url };

    return this._http.delete<T, R, D>(resolvedUrl, {
      ...requestConfig,
      'axios-retry': retryConfig
    });
  }

  /**
   * Execute a PUT request
   * @param url - request url
   * @param config - request config
   * @returns
   */
  public async put<T, D = unknown, R = IResponse<T, D>>(
    url: string,
    data?: D,
    config?: IRequestMethodConfig,
    retryConfig?: IRequestRetryConfig
  ): Promise<R> {
    const resolvedUrl = this.bootstrapConfig?.resolveRequestUrl(url) ?? url;
    const requestConfig = { ...config, originalRequestUrl: url };

    return this._http.put<T, R, D>(resolvedUrl, data, {
      ...requestConfig,
      'axios-retry': retryConfig
    });
  }
}
