// Copyright text placeholder, Warner Bros. Discovery, Inc.

/* eslint-disable @rushstack/packlets/mechanics */
import { GlobalContextManager } from '../managers/GlobalContextManager';
import { EventQueue } from '../transmission/EventQueue';
import { IQueueableEvent } from './IQueueableEvent';
import { IVersionedEvent } from './IVersionedEvent';

/**
 * Emitter class for telegraph events and errors, handles adding shared properties keys onto events
 * @public
 */
export class Emitter {
  /** This events stored set of callback functions */
  private static _callbacks: Map<string, Set<(event: IVersionedEvent) => void>> = new Map();

  /**
   * add a listener that only fires when it matches a specific kind
   * @param kind - event schema kind
   * @param callback - callback function
   * @returns
   */
  public static addEventKindListener(kind: string, callback: (event: IVersionedEvent) => void): void {
    const kindSet = this._callbacks.get(kind) ?? new Set();
    this._callbacks.set(kind, kindSet.add(callback));
  }

  /**
   * add a listener that only fires when it matches a specific event type
   * @param eventType - event schema type
   * @param callback - callback function
   */
  public static addEventTypeListener<TEventPayload>(
    eventType: string,
    callback: (event: IVersionedEvent<TEventPayload>) => void
  ): void {
    const eventTypeSet = this._callbacks.get(eventType) ?? new Set();
    this._callbacks.set(eventType, eventTypeSet.add(callback as (event: IVersionedEvent) => void));
  }

  /**
   * Decorate the Event with shared properties keys needed for this event and send to the Queue
   * @param event - the versioned event data to be added
   * @returns promise
   */
  public static async emitEventAsync(event: IVersionedEvent): Promise<void> {
    // add the global context
    const context = await GlobalContextManager.getGlobalContextAsync();

    this._emitToEventKindListeners(event);
    this._emitToEventTypeListeners(event);

    const formedEvent: IQueueableEvent = {
      ...context,
      event: event
    };

    // push to Queue
    EventQueue.enqueueEvent(formedEvent);
  }

  /**
   * Looks up the callbacks by key and emits the event to them
   * @param key - the key to lookup callbacks by
   * @param event - the event to emit to the callbacks
   */
  private static _emitEventByKey(key: string, event: IVersionedEvent): void {
    const keySet = Emitter._callbacks.get(key);

    if (keySet) {
      keySet.forEach((functionCallback) => functionCallback(event));
    }
  }

  /**
   * emits an event to the listeners that is associated with the event.eventType
   * @param event - the versioned event data to be emitted to the kind listener
   */
  private static _emitToEventTypeListeners(event: IVersionedEvent): void {
    this._emitEventByKey(event.eventType, event);
  }

  /**
   * emits an event to the listeners that is associated with the event.kind
   * @param event - the versioned event data to be emitted to the kind listener
   */
  private static _emitToEventKindListeners(event: IVersionedEvent): void {
    const kind = event.eventType.split(/\./)[1];
    this._emitEventByKey(kind, event);
  }
}
