// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IResponse } from '@wbd/bolt-http';
import isEqual from 'lodash.isequal';
import { IQueueableEvent, IVersionedEvent, VERSIONED_EVENT_KEY } from '../core';
import { EventTransmitter } from './EventTransmitter';

interface IStore {
  /** where the prop is stored in sharedProperties */
  index: number;
  /** the top level name for this prop (application, applicationState,...) */
  key: string;
  /** the property object itself */
  prop: unknown;
}

interface IBatchableEvent extends IVersionedEvent {
  /**
   * Add a reference to the shared properties keys that this event uses to grab the common global context props
   */
  sharedPropertiesKeys: string[];
}

/**
 * Event data that has been cleaned up for transmission
 */
interface IBatchedEvents {
  /**
   * The common properties that events share with other events in the array
   */
  sharedProperties: {
    [key: string]: unknown;
  };

  /**
   * The array of events that we are transmitting
   */
  events: IBatchableEvent[];
}

/**
 * Batcher class for events to minimize and serialize the data to be sent over the wire.
 * @public
 */
export class EventBatcher {
  /**
   * Batches all events sent to it and serializes the payload, pulling out all shared properties in events into a dictionary that events can reference.
   * @param events - the array of events to batch
   * @returns The response or failure reported by the Event Transmitter
   */
  public static async batchAsync(events: IQueueableEvent[]): Promise<IResponse<unknown, unknown>> {
    // Pull out the common props that are common between a bunch of events
    const eventsData: IBatchedEvents = this._createEventBatch(events);
    // Serialize the data
    const eventsDataString: string = this._serializeEventData(eventsData);
    // Transmit via the EventTransmitter
    return await EventTransmitter.transmitBatch(eventsDataString);
  }

  private static _createEventBatch(events: IQueueableEvent[]): IBatchedEvents {
    const batch: IBatchedEvents = {
      sharedProperties: {},
      events: []
    };
    const storedProps: IStore[] = [];
    let propIndex: number = 0;

    events.forEach((event: IQueueableEvent) => {
      const sharedPropertiesKeys: Array<string> = [];
      // pull out top level objects and see if we already have it
      Object.keys(event).forEach((key: string) => {
        if (key !== VERSIONED_EVENT_KEY) {
          // if we haven't seen this property yet store it
          let foundMatch = false;
          // for each property object we stored for this key, try and find one that matches
          for (let i: number = 0; i < storedProps.length; i++) {
            const storedProp = storedProps[i];
            // @ts-ignore-next-line
            if (storedProp.key === key && isEqual(storedProp.prop, event[key])) {
              // mark the matching prop for this event
              sharedPropertiesKeys.push(storedProp.index.toString());
              foundMatch = true;
              break;
            }
          }
          // If we didn't find a match push this object to the end of the array
          if (!foundMatch) {
            // update the index for where this prop is located in sharedProperties (start at 1)
            propIndex++;
            // @ts-ignore-next-line
            const eventProp: unknown = event[key];
            // Add the new object to our batch's sharedProperties
            batch.sharedProperties[propIndex.toString()] = { [key]: eventProp };
            // mark this prop for this event and store it for others
            storedProps.push({ index: propIndex, key: key, prop: eventProp });
            sharedPropertiesKeys.push(propIndex.toString());
          }
        }
      });
      // Create an batchable event and add to batch
      batch.events.push(EventBatcher._createBatchableEvent(event, sharedPropertiesKeys));
    });

    return batch;
  }

  private static _createBatchableEvent(
    event: IQueueableEvent,
    sharedPropertiesKeys: string[]
  ): IBatchableEvent {
    return {
      ...event[VERSIONED_EVENT_KEY],
      sharedPropertiesKeys: sharedPropertiesKeys
    };
  }

  private static _serializeEventData(eventsData: IBatchedEvents): string {
    return JSON.stringify(eventsData);
  }
}
