// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * create a hexidecimal string of a given length
 * @returns hex string
 * @public
 */
export function createHEXDIGLC(strLen: number): string {
  const hexChars = '0123456789abcdef';
  return Array.from({ length: strLen })
    .map(() => hexChars.charAt(Math.floor(Math.random() * 16)))
    .join('');
}
