// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { KeyValueObject } from 'json-api-models';
import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';
import type { PageItem } from './PageItem';
import type { Route } from './Route';

/**
 * @public Used to describe metadata regarding pagination.
 */
export interface IPaginationMeta extends KeyValueObject {
  /**
   * Tracks the current pagination page number.
   */
  itemsCurrentPage?: number;
  /**
   * Tracks the pagination page size.
   */
  itemsPageSize?: number;
  /**
   * Tracks the total number of pages available, based on page size
   * and number of items.
   */
  itemsTotalPages: number;
  /**
   * Tracks the total number of items.
   */
  itemsTotalResults?: number;
}

/**
 * @see https://docs.disco-api.com/apidocs/#pages-deprecated
 * @public
 */
export interface IPageAttributes {
  /** Accessibility text to be displayed to end users. */
  accessibilityTitle?: string;
  /** Unique page alias. */
  alias: string;
  /** Component configuration for the page. */
  component?: {
    /** Component ID. */
    id: string;
    /** Component template ID. */
    templateId: string;
  };
  /** Longer page description. */
  description?: string;
  /** Page rendering hints. */
  hints?: string[];
  /** Internal name used in admin UI. */
  name?: string;
  /** Page author. Used for SEO and web crawlers. */
  pageMetadataAuthor?: string;
  /** Page description. Used for SEO and web crawlers. */
  pageMetadataDescription?: string;
  /** Page keywords. Used for SEO and web crawlers. */
  pageMetadataKeywords?: string;
  /** Page title. Used for SEO and web crawlers. */
  pageMetadataTitle?: string;
  /** Title text displayed to end users. */
  title?: string;
}

/**
 * @public
 */
export interface IPageRelationships {
  /** List of PageItems. */
  items?: PageItem[];
  /** List of Routes that point to this page. */
  routes?: Route[];
}

/**
 * @public
 */
export class Page extends TypedModel.generate<
  DataTypes.Page,
  IPageAttributes,
  IPageRelationships,
  IPaginationMeta
>() {}
