// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#packages
 * @public
 */
export interface IPackageAttributes {
  /** Package color (e.g. `0x0000FFFF`) */
  color: string;
  /** Whether to display the label. */
  labelVisible: boolean;
  /** Package name (e.g. `Sport`, `Premium`). */
  name: string;
}

/**
 * @public
 */
export class Package extends TypedModel.generate<DataTypes.Package, IPackageAttributes>() {}
