// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { type IPlatformRuntimeV1Props, PlatformRuntimeV1 } from '../generated';

/**
 * DISCLOSURE: DO NOT USE OR ADD ERRORS INTO THIS CLASS UNLESS ITS STRICTLY NECESSARY,
 * THIS APPROACH SHOULD BE THE LAST RESOURCE AND WE SHOULD TRY TO AVOID DOING THIS AS MUCH
 * AS POSSIBLE.
 * The error manager class used to capture errors from inside the ISDK
 * @public
 */
export class _ISDKErrorGeneratorManager {
  private _runtimeErrorV1: PlatformRuntimeV1 = new PlatformRuntimeV1();

  public constructor() {}

  /**
   * Captures a PlatformRuntimeV1 error
   * @param payload - Object containing the props to capture the error
   */
  public createPlatformRuntimeError(payload: IPlatformRuntimeV1Props): void {
    this._runtimeErrorV1.capture(payload);
  }
}

/**
 * The error manager namespace used to capture errors from inside the ISDK
 * @public
 */
/* eslint-disable @typescript-eslint/no-namespace */
export namespace ISDKErrorGeneratorManager {
  /** instance of the heartbeat monitor that is defined on initialization */
  export let errorGeneratorManagerInstance: _ISDKErrorGeneratorManager;

  /** Initializes the error generator manager and connects the private methods with the public ones  */
  export function initialize(): void {
    errorGeneratorManagerInstance = new _ISDKErrorGeneratorManager();
  }

  /** Captures a PlatformRuntimeV1 error */
  export function createPlatformRuntimeError(payload: IPlatformRuntimeV1Props): void {
    return errorGeneratorManagerInstance.createPlatformRuntimeError(payload);
  }
}
