// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { AuthToken, IStorage } from '@wbd/bolt-http';
import { getMigratedToken, getToken } from '../authentication';
import { AuthTokenProvider, DEFAULT_AUTH_TOKEN_STORAGE_KEY } from './AuthTokenProvider';

/**
 * AuthTokenProvider with retain auth capabilities using the device signature.
 *
 * @public
 */
export class AuthMigrationTokenProvider extends AuthTokenProvider {
  /**
   * device signature for retain auth feature.
   */
  private _deviceSignature: string;

  public constructor(
    deviceSignature: string,
    tokenStorage?: IStorage,
    storageKey: string = DEFAULT_AUTH_TOKEN_STORAGE_KEY
  ) {
    super(tokenStorage, storageKey);
    this._deviceSignature = deviceSignature;
  }

  /**
   * Tries to fetch a migrated token using the device signature.
   * Otherwise it returns an anonymous token.
   * The migrated token requests needs an anonymous token,
   * the getToken request needs to be fired in first place.
   *
   * @returns AuthToken
   */
  protected async _getToken(): Promise<AuthToken> {
    const token = await getToken(true);
    try {
      const migratedToken = await getMigratedToken(this._deviceSignature);

      if (migratedToken) return migratedToken;
    } catch (error) {
      this._errorSubscribers.fire(error).catch((error) => {
        // fail silently
      });
    }

    return token;
  }
}
