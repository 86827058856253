// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { CanonicalizedCldrLocale, CldrLocale } from '../../localization-enums';
import { FormatCode, LanguageCode } from '../../nominals';

import { PrimaryLanguageCode } from '../PrimaryLanguageCode';
import { _parseIetfBcp47LanguageTag } from './_parseIetfBcp47LanguageTag';

/**
 * Returns the primary language subtag of an IETF BCP 47 language tag (i.e., a `LanguageCode`, `FormatCode`,
 * `CanonicalizedCldrLocale`, or `CldrLocale`).
 *
 * @param ietfLanguageTag - the given IETF BCP 47 language tag
 * @returns the primary language subtag of the given language tag
 *
 * @public
 */
export function getPrimaryLanguageCode(
  ietfLanguageTag: LanguageCode | FormatCode | CanonicalizedCldrLocale | CldrLocale
): PrimaryLanguageCode {
  return _parseIetfBcp47LanguageTag(ietfLanguageTag).primaryLanguageSubtag;
}
