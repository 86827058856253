// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { InMemoryStorage } from './InMemoryStorage';
import type { IStorage } from './IStorage';
import type { IStorageObject } from './IStorageObject';

/**
 * Storage class to handle key/value pair operations
 * @remarks Storage is implemented as an instance class. We are leaving it to the Application to create and manage Storage instances.
 * We highly recommend that Applications only ever handle one instance per scope of Storage at a time.
 * @public
 */
export class MemoryStorage implements IStorage {
  /**
   * memory storage
   */
  protected _storage: IStorageObject;

  /**
   * Constructs an instance of Storage for browser clients, using local storage if possible, and in-memory storage if not.
   * Allow an option to pass in the local and session Storage location (used for test purposes)
   * @public
   */
  public constructor() {
    this._storage = new InMemoryStorage();
  }

  /** {@inheritDoc IStorage.readAsync} */
  public readAsync(key: string): Promise<string | undefined> {
    return Promise.resolve(this.readSync(key));
  }

  /** {@inheritDoc IStorage.readSync} */
  public readSync(key: string): string | undefined {
    const item: unknown = this._storage.getItem(key);
    return item ? String(item) : undefined;
  }

  /** {@inheritDoc IStorage.writeAsync} */
  public writeAsync(key: string, value: string, append?: boolean): Promise<void> {
    try {
      this.writeSync(key, value, append);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** {@inheritDoc IStorage.writeSync} */
  public writeSync(key: string, value: string, append?: boolean): void {
    const curValue: string = append === true ? this._storage.getItem(key) || '' : '';
    this._storage.setItem(key, curValue + value);
  }

  /** {@inheritDoc IStorage.removeAsync} */
  public removeAsync(key: string): Promise<void> {
    return Promise.resolve(this.removeSync(key));
  }

  /** {@inheritDoc IStorage.removeSync} */
  public removeSync(key: string): void {
    return this._storage.removeItem(key);
  }

  /** {@inheritDoc IStorage.clearAsync} */
  public clearAsync(): Promise<void> {
    return Promise.resolve(this._storage.clear());
  }
}
