// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { AuthToken, IRequestHeaders, IResponse } from '@wbd/bolt-http';
import { HttpClient } from '../http-client';
import type { RequestConfigOverride } from '../http-client';
import {
  IArkoseHeaders,
  IDeviceInitiateLinkingData,
  IDeviceSignatureLogin,
  IInitiatedDevice,
  IInitiateDeviceLinkingPayload,
  ILoginData,
  ILoginPayload,
  ITokenAuthenticationData,
  IUserCredentials
} from './IAuthenticationHttp';

/**
 * Returns the token.
 * @param isAnonymous - Whether to apply Authorization token in request.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getToken = async (isAnonymous = false, config?: RequestConfigOverride): Promise<AuthToken> => {
  const http = HttpClient.instance;
  const { realm } = http.sessionConfig;

  const url = `/token?realm=${realm}`;
  const headers: IRequestHeaders = {};

  const response = await http.get<IResponse<ITokenAuthenticationData>>(url, {
    headers,
    isAnonymous,
    signal: config?.signal
  });
  const resource = response.data?.data;
  const token = resource?.attributes?.token;
  await http.sessionConfig.authTokenProvider?.clearTokenAsync();
  await http.sessionConfig.authTokenProvider?.setTokenAsync(token);

  return token;
};
/**
 * Returns the linking code and url.
 * @param payload - Object containing the initiateDeviceLinking payload.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const initiateDeviceLinking = async (
  payload: IInitiateDeviceLinkingPayload = {},
  config?: RequestConfigOverride
): Promise<IInitiatedDevice | undefined> => {
  // this has to be done since sending any of the options to false make this service to not return any of the urls.
  const data: IInitiateDeviceLinkingPayload = Object.entries(payload).reduce(
    (acc: IInitiateDeviceLinkingPayload, [key, value]) => {
      if (value) {
        return { ...acc, [key]: value };
      }
      return acc;
    },
    {}
  );
  const http = HttpClient.instance;
  const response = await http.post<IResponse<IDeviceInitiateLinkingData>, IInitiateDeviceLinkingPayload>(
    '/authentication/linkDevice/initiate',
    data,
    {
      signal: config?.signal
    }
  );
  const resource = response.data?.data;
  return resource?.attributes;
};

/**
 * Logs a user in.
 * @param username - The username.
 * @param password - The password.
 * @param arkoseHeaders - The arkose headers.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const login = async (
  username: string,
  password: string,
  arkoseHeaders?: IArkoseHeaders,
  config?: RequestConfigOverride
): Promise<AuthToken> => {
  const http = HttpClient.instance;
  const isArkoseRequest = !!arkoseHeaders;
  const response = await HttpClient.instance.post<IResponse<ILoginData>, ILoginPayload>(
    '/login',
    { credentials: { username, password } },
    {
      headers: {
        ...(isArkoseRequest && {
          'X-disco-arkose-token': arkoseHeaders.token,
          'X-disco-arkose-sitekey': arkoseHeaders.siteKey
        })
      },
      signal: config?.signal
    }
  );
  const resource = response.data?.data;
  const token = resource?.attributes?.token;
  await http.sessionConfig.authTokenProvider?.setTokenAsync(token);

  return token;
};

/**
 * Checks if the user has logged in with the linking code.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const loginDeviceLinking = async (config?: RequestConfigOverride): Promise<AuthToken | undefined> => {
  const http = HttpClient.instance;
  const response = await http.post<IResponse<ILoginData>, undefined>(
    '/authentication/linkDevice/login',
    undefined,
    config
  );

  if (response.status === 204) {
    // not logged in yet;
    return undefined;
  }

  const resource = response?.data.data;
  const token = resource?.attributes?.token;
  await http.sessionConfig.authTokenProvider?.setTokenAsync(token);

  return token;
};

/**
 * Logs a user out.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const logout = async (config?: RequestConfigOverride): Promise<AuthToken> => {
  const http = HttpClient.instance;
  const response = await http.post<IResponse<ITokenAuthenticationData>, undefined>(
    '/logout',
    undefined,
    config
  );
  const resource = response.data?.data;
  const token = resource?.attributes?.token;

  await http.sessionConfig.authTokenProvider?.clearTokenAsync();
  await http.sessionConfig.authTokenProvider?.setTokenAsync(token);

  return token;
};

/**
 * Returns a migrated user token based on the deviceSignature.
 * @param deviceSignature - The device signature associated with a migrated token.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getMigratedToken = async (
  deviceSignature: string,
  config?: RequestConfigOverride
): Promise<AuthToken | undefined> => {
  const http = HttpClient.instance;
  const response = await http.post<IResponse<ITokenAuthenticationData>, IDeviceSignatureLogin>(
    '/login',
    {
      credentials: {
        legacyData: deviceSignature,
        legacyDataType: 'deviceSignature',
        provider: 'migration',
        legacyClient: 'HBOMax'
      }
    },
    config
  );

  const resource = response.data?.data;
  const token = resource?.attributes?.token;

  await http.sessionConfig.authTokenProvider?.setTokenAsync(token);

  return token;
};

/**
 * Registers and logs a user in.
 * @param username - The username.
 * @param password - The password.
 * @param arkoseHeaders - The arkose headers.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const registerAndLogin = async (
  username: string,
  password: string,
  arkoseHeaders?: IArkoseHeaders,
  config?: RequestConfigOverride
): Promise<AuthToken> => {
  const http = HttpClient.instance;
  const isArkoseRequest = !!arkoseHeaders;
  const response = await HttpClient.instance.post<IResponse<ITokenAuthenticationData>, IUserCredentials>(
    '/users/registration/registerAndLogin',
    { username, password },
    {
      headers: {
        ...(isArkoseRequest && {
          'X-disco-arkose-token': arkoseHeaders.token,
          'X-disco-arkose-sitekey': arkoseHeaders.siteKey
        })
      },
      signal: config?.signal
    }
  );
  const resource = response.data?.data;
  const token = resource?.attributes?.token;
  await http.sessionConfig.authTokenProvider?.setTokenAsync(token);

  return token;
};
