// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#custom-attributes
 * @public
 */

export class UserCustomAttributes extends TypedModel.generate<DataTypes.UserCustomAttributes>() {}
