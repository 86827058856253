// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  CastAttributes,
  JsonApiRelationship,
  JsonApiRelationships,
  JsonApiResource,
  KeyValueObject,
  Model,
  Store
} from 'json-api-models';

import { DataTypes } from './BoltDataTypes';

/**
 * JSON API MODEL root properties types
 */
type JsonApiKeys = keyof JsonApiResource;

/**
 * JSON API MODEL root properties that should be protected from overwriting by hoisting
 */
const ProtectedKeys: readonly JsonApiKeys[] = ['attributes', 'id', 'links', 'meta', 'relationships', 'type'];

/**
 * Typed model keys that should be excluded from logs / snapshots
 */
const ExcludeFromLogKeys: readonly string[] = ['casts', 'store', 'attributes', 'relationships'];

/**
 * Defines the supported cast types for attributes properties
 * @public
 */
export type CastAttributeType =
  | StringConstructor
  | NumberConstructor
  | BooleanConstructor
  | ((value: unknown) => unknown)
  | (new (value: unknown) => unknown);

/**
 * Provides a CastAttribute type with typed keys based on the attributes property names
 * @public
 */
export type TypedCastAttributeType<A> = Partial<Record<keyof A, CastAttributeType>>;

/**
 * Patched constructor types for json-api-models 'model'
 *
 * this is required to augment the dynamically assigned attributes and relationship properties
 * that the `merge` function is hoisting to the root of the model
 * @public
 */
export interface ITypedModelConstructor<
  T extends DataTypes,
  A extends KeyValueObject = {},
  R extends KeyValueObject = {},
  M extends KeyValueObject = {},
  L extends KeyValueObject = {}
> {
  /** patch attributes & relationships types with the class definition */
  new (data: JsonApiResource<T>, store: Store): TypedModel<T, A, R, M, L> &
    Omit<A, JsonApiKeys> &
    Omit<R, JsonApiKeys>;
  /** expose the static generator helper function */
  generate(): ITypedModelConstructor<T, A, R, M, L>;
}

/**
 * Improved types for json-api-models 'model' class
 * @public
 */
export class TypedModel<
  T extends DataTypes,
  A extends KeyValueObject = {},
  R extends KeyValueObject = {},
  M extends KeyValueObject = {},
  L extends KeyValueObject = KeyValueObject
> extends Model<T> {
  public type!: T;
  public id!: string;
  public attributes!: A;
  public relationships!: Record<Partial<keyof R>, JsonApiRelationship>;
  public meta!: M;
  public links!: L;
  protected casts: TypedCastAttributeType<A> & CastAttributes = {};

  /**
   * Helper function to generate a TypeModel with patched constructor type
   * that includes 'attributes' and 'relationships' at the class root
   *
   * dynamically assigning property values as done by json-api-models
   * to classes is not something typescript recommends
   * so we have to manually patch the constructor type
   *
   * @public
   */
  public static generate<
    T extends DataTypes,
    A extends KeyValueObject = {},
    R extends KeyValueObject = {},
    M extends KeyValueObject = {},
    L extends KeyValueObject = KeyValueObject
  >(): ITypedModelConstructor<T, A, R, M, L> {
    return this as ITypedModelConstructor<T, A, R, M, L>;
  }

  /**
   * get a (casted) attribute value by attribute name
   * @param name - attribute name
   * @returns
   */
  public getAttribute<K extends keyof A>(name: K): A[K] {
    return super.getAttribute(name as string);
  }

  /**
   * get a relationship model from the store by relationship name
   * @param name  - relationship name
   * @returns
   */
  public getRelationship<K extends keyof R>(name: K): R[K] {
    return super.getRelationship(name as string);
  }

  /**
   * Merge new JSON:API resource data into the model.
   * includes some small patches compared to the original model
   */
  public merge(data: Partial<JsonApiResource<T>>): void {
    if (data.type) {
      this.type = data.type;
    }

    if (data.id) {
      this.id = data.id;
    }

    if (data.attributes) {
      Object.assign(this.attributes, data.attributes);

      const attributes = data.attributes as KeyValueObject;
      Object.keys(attributes).forEach((name) => {
        /**
         * Some bolt json api models use the 'type' property as attribute
         * which conflicts with the json api root model 'type'
         *
         * we can't not be hoisted to the top level model
         * so to prevent a conflict we are escaping these properties using
         * the below 'isProtected' check as a patch on the original library
         */
        const isProtected = ProtectedKeys.includes(name as JsonApiKeys);
        if (!isProtected && !Object.getOwnPropertyDescriptor(Object.getPrototypeOf(this), name)) {
          /** hoist attributes to the top level */
          Object.defineProperty(this, name, {
            get: () => this.getAttribute(name),
            configurable: true,
            enumerable: true
          });
        }
      });
    }

    if (data.relationships) {
      const relationships = data.relationships as JsonApiRelationships;
      for (const [name, relationship] of Object.entries(relationships)) {
        this.relationships[name as keyof R] = this.relationships[name as keyof R] || {};

        Object.assign(this.relationships[name], relationship);

        if (!Object.getOwnPropertyDescriptor(Object.getPrototypeOf(this), name)) {
          /** hoist relationships to the top level */
          Object.defineProperty(this, name, {
            get: () => this.getRelationship(name),
            configurable: true,
            enumerable: true
          });
        }
      }
    }

    if (data.links) {
      this.links = data.links as L;
    }

    if (data.meta) {
      this.meta = data.meta as M;
    }
  }

  /**
   * Custom toJSON function which helps generating more readable and lean snapshots for testing
   * @returns
   */
  public toJSON(): { [key: string]: unknown } {
    // create a custom reducer that limits the output to the top level properties
    const typedModelSnapshotLogReducer = (
      result: { [key: string]: unknown },
      keyValuePair: [string, unknown]
    ): { [key: string]: unknown } =>
      // hide functions and 'private' properties for snapshots
      typeof keyValuePair[1] === 'function' || ExcludeFromLogKeys.includes(keyValuePair[0])
        ? result
        : { ...result, [keyValuePair[0]]: keyValuePair[1] };

    return Object.entries(this).reduce(typedModelSnapshotLogReducer, {
      // inherit the constructor name
      constructor: this.constructor
    });
  }
}
