// Copyright text placeholder, Warner Bros. Discovery, Inc.

// Design has a specific naming pattern for tokens (with dashes), which are used heavily in this file.
/* eslint-disable @typescript-eslint/naming-convention */

// ***DO NOT EDIT THIS FILE DIRECTLY***
// Generated with @wbd/generate-design-resources

/* eslint-disable @rushstack/typedef-var */

/**
 * Text tokens derived from design data
 * @public
 */
export const TextToken = {
  heading: {
    xl: {
      fontSize: 56,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontFace: 'medium',
      lineHeight: 76.6
    },
    lg: {
      fontSize: 48,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontFace: 'medium',
      lineHeight: 65.8
    },
    md: {
      fontSize: 36,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontFace: 'medium',
      lineHeight: 49.6
    },
    sm: {
      fontSize: 30,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontFace: 'medium',
      lineHeight: 41.5
    },
    xs: {
      fontSize: 24,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontFace: 'medium',
      lineHeight: 33.4
    }
  },
  body: {
    lg: {
      fontSize: 28,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      lineHeight: 43
    },
    lg_strong: {
      fontSize: 28,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontFace: 'medium',
      lineHeight: 43
    },
    md: {
      fontSize: 24,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      lineHeight: 37
    },
    md_strong: {
      fontSize: 24,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontFace: 'medium',
      lineHeight: 37
    },
    sm: {
      fontSize: 21,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      lineHeight: 32.5
    },
    sm_strong: {
      fontSize: 21,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontFace: 'medium',
      lineHeight: 32.5
    }
  },
  misc: {
    metadata_sm: {
      fontSize: 19,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      lineHeight: 20
    },
    metadata_sm_strong: {
      fontSize: 19,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontFace: 'medium',
      lineHeight: 20
    },
    caps: {
      fontSize: 21,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      lineHeight: 22
    },
    caps_strong: {
      fontSize: 21,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontFace: 'medium',
      lineHeight: 22
    },
    badge: {
      fontSize: 19,
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontFace: 'medium',
      lineHeight: 20
    }
  }
} as const;
