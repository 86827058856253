// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IetfScriptCode } from '../generated';
import { getIetfScriptCodes } from '../getters';

/**
 * Type guard function for IetfScriptCode values.
 *
 * @param tag - a possible IetfScriptCode
 *
 * @public
 */
export function isIetfScriptCode(tag: string): tag is IetfScriptCode {
  return getIetfScriptCodes().has(tag as IetfScriptCode);
}
