// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IetfScriptCode, IetfScriptCodeSet } from '../generated';

/**
 * Returns a ReadonlySet containing all of the IetfScriptCode values.
 *
 * @public
 */
export function getIetfScriptCodes(): ReadonlySet<IetfScriptCode> {
  return IetfScriptCodeSet;
}
