// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { JsonApiDocument, JsonApiResource, ModelForType, Store } from 'json-api-models';

import { DataTypes } from '../json-api';
import { JsonApiFilter } from './JsonApiFilter';

/**
 * This type improves 'ModelCollection' by returning the actual model
 * instead of the generic 'ModelConstructor' type
 * @public
 */
export type TypedModelCollection<Models> = {
  [Type in keyof Models]: Models[Type];
};

/**
 * Transformer overrides the 'store'
 *
 * @public
 */
export class Transformer<T extends DataTypes, M extends TypedModelCollection<M>> extends Store<M> {
  public static filter: JsonApiFilter | undefined;

  public constructor(models: M, data: JsonApiDocument<T>) {
    super(models);
    this.sync(data);
  }

  /**
   * Filter JSON API store
   */
  public syncResource<Type extends string>(data: JsonApiResource<Type>): ModelForType<Type, M> {
    // strip unused image formats and unused relationships
    if (Transformer.filter?.excludes(data)) {
      return {} as ModelForType<Type, M>;
    }

    return super.syncResource(data);
  }

  /**
   * Bolt services guarantee for some scenario's of model to be returned
   * this helper will type cast to remove the null result
   * @param type - model data type
   * @param id - model identifier
   * @returns
   */
  public findNoNull<Type extends string>(type: Type, id: string): ModelForType<Type, M> {
    return this.find(type, id) as ModelForType<Type, M>;
  }
}
