// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Bolt client environment for an app session.
 * @public
 */
export enum BoltEnvironment {
  /**
   * Run the application against the bolt production env.
   */
  PRD = 'prd',

  /**
   * Run the application against the bolt staging env.
   */
  STG = 'stg',

  /**
   * Run the application against the bolt integration env.
   */
  INT = 'int',

  /**
   * Run the application against the bolt development env.
   */
  DEV = 'dev'
}
