// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ArticleBodyRichText } from './ArticleBodyRichText';
import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export interface IPlanCardProductAttributeAttribute {
  /** Denotes if the attribute is included/excluded in the product. */
  kind: 'included' | 'excluded';
}
/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export interface IPlanCardProductAttributeRelationships {
  /** Rich text for the product attribute. */
  text: ArticleBodyRichText;
}

/**
 * @public
 */
export class PlanCardProductAttribute extends TypedModel.generate<
  DataTypes.PlanCardProductAttribute,
  IPlanCardProductAttributeAttribute,
  IPlanCardProductAttributeRelationships
>() {}
