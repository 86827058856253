// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';
import type { Image } from './Image';

/**
 * @see https://wbdstreaming.atlassian.net/wiki/spaces/CD/pages/211552846/BEAM+Content+API+-+Edit#Edit-attributes-and-relationships
 * @public
 */
export interface IGlyphAttributes {
  /** Glyph name, for visual presentation. */
  name: string;
  /** Glyph name, for audible presentation. */
  nameAccessibility: string;
}

/**
 * @public
 * The glyph interface combining the attributes and relationships.
 */
export interface IGlyphRelationships {
  images?: Image[];
}

/**
 * @public
 */
export class Glyph extends TypedModel.generate<DataTypes.Glyph, IGlyphAttributes, IGlyphRelationships>() {}
