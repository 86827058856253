// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IDeeplinkParams, INetworkInformation, IStorage } from '@wbd/beam-ctv-platform-adapters';
import {
  AbstractDevice,
  DevicePerformance,
  DeviceType,
  NetworkType,
  StorageDeviceConstants
} from '@wbd/beam-ctv-platform-adapters';
import { ClientId, Platform } from '@wbd/bolt-http';
import {
  generateV1Err,
  InstrumentationSDK,
  PlatformVendorV1Scope,
  type IPlatformVendorV1Err
} from '@wbd/instrumentation-sdk';
import { getPlaystationOSVersionFromUserAgent } from '../model-versions';
import { debounce } from '@wbd/beam-js-extensions';

export class PlayStationDevice extends AbstractDevice {
  /**
   * Return tts settings for platforms that use speech synthesis
   */
  public static _ttsSettings: { enabled: boolean } = { enabled: false };

  /**
   * Performance map by platform
   */
  protected static readonly _lowPerformanceMap: { platformId: string[] }[] = [
    {
      platformId: ['PS4']
    }
  ];

  /**
   * Returns the system name
   */
  protected static async getSystemName(): Promise<string> {
    const systemName = window.msdk.system.getServiceParameter(
      window.msdk.system.serviceParameter.id.SYSTEM_NAME
    );
    return systemName;
  }

  /**
   * Returns the unique client identifier
   */
  public static getBoltClientId(): ClientId {
    return ClientId.PLAYSTATION;
  }

  /**
   * Returns the platform name
   */
  public static getBoltPlatformNameAsync(): Promise<Platform> {
    return Promise.resolve(Platform.PLAYSTATION);
  }

  /**
   * Returns the device model
   */
  public static async getDeviceModelAsync(): Promise<string> {
    try {
      // TODO: memoize the getSystemName call
      let modelGroup = await this.getSystemName();
      modelGroup = modelGroup.split('-')[0];
      return modelGroup;
    } catch (error) {
      InstrumentationSDK.Errors.Platform.Vendor.v1.capture({
        err: generateV1Err<IPlatformVendorV1Err>(error, {
          classification: 'platform-adapter-device',
          scope: PlatformVendorV1Scope.RECOVERABLE
        })
      });
      return 'PS-Unknown';
    }
  }

  /**
   * Get the current model name (ex. PS4-337)
   * @returns string
   */
  public static async getDeviceModelNameAsync(): Promise<string> {
    try {
      const realModel = await this.getSystemName();
      return realModel;
    } catch (error) {
      InstrumentationSDK.Errors.Platform.Vendor.v1.capture({
        err: generateV1Err<IPlatformVendorV1Err>(error, {
          classification: 'platform-adapter-device',
          scope: PlatformVendorV1Scope.RECOVERABLE
        })
      });
      return 'PS-Unknown';
    }
  }

  /**
   * Get the current performance (HIGH, AVERAGE, LOW)
   */
  public static async getDevicePerformanceIndicatorAsync(): Promise<DevicePerformance> {
    const modelCode = await this.getDeviceModelAsync();
    const isLowPerformanceModel = PlayStationDevice._lowPerformanceMap.some(({ platformId }) => {
      return platformId.some((platformId) => modelCode.includes(platformId));
    });
    if (isLowPerformanceModel) {
      return DevicePerformance.LOW;
    }
    return DevicePerformance.AVERAGE;
  }

  /**
   * Get the current device type classification (TV, STB, CONSOLE)
   */
  public static async getDeviceTypeAsync(): Promise<DeviceType> {
    return DeviceType.CONSOLE;
  }

  /**
   * Get the current device OS version (ex. 4.5)
   */
  public static async getDeviceOSVersionAsync(): Promise<string> {
    let version = getPlaystationOSVersionFromUserAgent(window.navigator.userAgent);
    if (!version.length) {
      version = await super.getDeviceOSVersionAsync();
    }
    return version;
  }

  /**
   * Get device OS name and version as string (ex. Tizen / webOS)
   */
  public static async getDeviceOsAsync(): Promise<string> {
    return 'Orbis';
  }

  /**
   * Get device brand name like the TV brand or MVPD name (ex. Sony)
   */
  public static async getDeviceBrandAsync(): Promise<string> {
    return 'Sony';
  }

  /**
   * Get device manufacturer (ex. Sony)
   */
  public static async getDeviceManufacturerAsync(): Promise<string> {
    return 'Sony';
  }

  /**
   * Get the device network information
   */
  public static async getDeviceNetworkInformationAsync(): Promise<INetworkInformation> {
    let connectionType;

    try {
      connectionType = await window.msdk.device.getNetworkConnectionType();
    } catch (e) {
      connectionType = undefined;
    }

    switch (connectionType) {
      case 0:
        return { type: NetworkType.WIRED };
      case 1:
        return { type: NetworkType.WIRELESS };
      default:
        return { type: NetworkType.UNKNOWN };
    }
  }

  /**
   * Get unique device identifier UUID
   */
  public static async getDeviceIdentifierAsync(storage: IStorage): Promise<string> {
    try {
      const deviceId = (await window.msdk.device.getId()).toUpperCase();
      storage.writeSync(StorageDeviceConstants.UUID, deviceId);
      return deviceId;
    } catch (error) {
      InstrumentationSDK.Errors.Platform.Vendor.v1.capture({
        err: generateV1Err<IPlatformVendorV1Err>(error, {
          classification: 'platform-adapter-device',
          scope: PlatformVendorV1Scope.RECOVERABLE
        })
      });
      return super.getDeviceIdentifierAsync(storage);
    }
  }

  /**
   * Get current device locale following ISO-639 and ISO-3166 (example en-US)
   */
  public static async getDeviceLocaleAsync(): Promise<string> {
    try {
      const accountInfo = await new Promise<msdk.psn.AccountInfoResult>((resolve, reject) => {
        try {
          resolve(window.msdk.psn.getAccountInfo());
        } catch (error) {
          reject(error);
        }
      });
      const languageCode = accountInfo.languageCode;
      if (languageCode === undefined) {
        // this can be undefined if PSN is disabled
        throw new TypeError('languageCode is undefined');
      }
      return languageCode;
    } catch (error) {
      InstrumentationSDK.Errors.Platform.Vendor.v1.capture({
        err: generateV1Err<IPlatformVendorV1Err>(error, {
          classification: 'platform-adapter-device',
          scope: PlatformVendorV1Scope.RECOVERABLE
        })
      });
      return super.getDeviceLocaleAsync();
    }
  }

  /**
   * Check if the current device is connected to the internet
   */
  public static isDeviceConnectedAsync(): Promise<boolean> {
    let onlineCheck = false;
    try {
      onlineCheck = window.navigator.onLine;
    } catch (error) {
      InstrumentationSDK.Errors.Platform.Vendor.v1.capture({
        err: generateV1Err<IPlatformVendorV1Err>(error, {
          classification: 'platform-adapter-device',
          scope: PlatformVendorV1Scope.RECOVERABLE
        })
      });
      return Promise.resolve(false);
    }

    return Promise.resolve(onlineCheck);
  }

  /**
   * Returns the Store country code for In-App-Purchase as ISO-3166 (example US)
   */
  public static async getStoreCountryCodeAsync(): Promise<string> {
    try {
      const accountInfo = await window.msdk.psn.getAccountInfo();
      const countryCode = accountInfo.countryCode;
      return countryCode.toUpperCase();
    } catch (error) {
      InstrumentationSDK.Errors.Platform.Vendor.v1.capture({
        err: generateV1Err<IPlatformVendorV1Err>(error, {
          classification: 'platform-adapter-device',
          scope: PlatformVendorV1Scope.RECOVERABLE
        })
      });
      return '';
    }
  }

  /**
   * Capture and process deeplink payload
   */
  public static async getDeeplinkParamsAsync(): Promise<IDeeplinkParams | undefined> {
    try {
      const payload = await window.msdk.device.getLaunchArgument();
      if (payload && payload.length) {
        const values = payload.match(/\w+\-(show|video)-(.+)/) ?? [];
        const [, type, id] = values;
        if (id && type) {
          return { id, type } as IDeeplinkParams;
        } else {
          throw new Error(`Invalid deeplink payload: ${btoa(payload)}`);
        }
      }
    } catch (error) {
      InstrumentationSDK.Errors.Platform.Vendor.v1.capture({
        err: generateV1Err<IPlatformVendorV1Err>(error, {
          classification: 'platform-adapter-device',
          scope: PlatformVendorV1Scope.RECOVERABLE
        })
      });
    }
  }

  /**
   * Add Listener for launch arguments when app returns to foreground
   */
  public static onDeeplinkResume(onResume: (params?: IDeeplinkParams) => void): void {
    try {
      if (window.msdk) {
        window.msdk.addEventListener('onLaunchArgument', async () =>
          onResume(await this.getDeeplinkParamsAsync())
        );
      }
    } catch (error) {
      InstrumentationSDK.Errors.Platform.Vendor.v1.capture({
        err: generateV1Err<IPlatformVendorV1Err>(error, {
          classification: 'platform-adapter-device',
          scope: PlatformVendorV1Scope.RECOVERABLE
        })
      });
    }
  }

  /**
   * Determine if platform guidelines recommend to show a confirmation dialog before closing the app
   */
  public static requiresCloseDialog(): boolean {
    return false;
  }

  /**
   ** When speech synthesis should be used instead of DOM announcement, this should return a function it will then be enabled when the function returns true
   */
  public static useSpeechSynthesis(): () => boolean {
    try {
      if (window.msdk) {
        window.msdk.addEventListener('onForeground', PlayStationDevice.onGetTtsSettings);
      }
    } catch (error) {
      InstrumentationSDK.Errors.Platform.Vendor.v1.capture({
        err: generateV1Err<IPlatformVendorV1Err>(error, {
          classification: 'platform-adapter-device',
          scope: PlatformVendorV1Scope.RECOVERABLE
        })
      });
    }
    const getInitalTtsSettings = debounce(async () => {
      await PlayStationDevice.onGetTtsSettings();
    }, 1000);
    getInitalTtsSettings();
    const isEnabled = (): boolean => {
      return PlayStationDevice._ttsSettings.enabled;
    };

    return isEnabled;
  }

  public static async onGetTtsSettings(): Promise<void> {
    try {
      if (window.msdk) {
        const ttsSettings = await window.msdk.system.getTextToSpeechSettings();
        PlayStationDevice._ttsSettings.enabled = ttsSettings.enabled;
      }
    } catch (error) {
      InstrumentationSDK.Errors.Platform.Vendor.v1.capture({
        err: generateV1Err<IPlatformVendorV1Err>(error, {
          classification: 'platform-adapter-device',
          scope: PlatformVendorV1Scope.RECOVERABLE
        })
      });
    }
  }

  /**
   * NOOP Override as to many calls to window.close() crash the app
   */
  public static exit(): void {}

  /**
   * Returns whether the device / OS has a native keyboard available
   */
  public static isNativeKeyboardAvailable(): boolean {
    return true;
  }
}
