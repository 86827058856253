// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { AuthToken } from '@wbd/bolt-http';

/**
 * Internal payload of a Bolt AuthToken JWT
 */
interface IAuthTokenPayload {
  /** token identifier */
  jti: string;
  /** issuer */
  iss: string;
  /** subscriber */
  sub: string;
  /** creation */
  iat: number;
  /** expiration */
  exp: number;
  /** anonymous user */
  anonymous: boolean;
  /** device-id */
  deviceId: string;
  /** token version */
  version: string;
}

/**
 * Helper to parse an AuthToken
 *
 * @public
 */
export class AuthTokenParser {
  private _payload: IAuthTokenPayload;

  public constructor(token: AuthToken) {
    this._payload = this._decodePayload<IAuthTokenPayload>(token);
  }

  /**
   * decodes a base64 encoded JWT auth token value into a json object
   * @param token - the AuthToken JWT value
   * @returns Decoded payload object
   */
  private _decodePayload<DecodedPayloadFormat>(token: AuthToken): DecodedPayloadFormat {
    try {
      const decodedValue = atob(token.split('.')[1]);
      return JSON.parse(decodedValue);
    } catch (e) {
      /**
       * if we can not parse the localization payload throw an error
       */
      throw new Error(`Invalid encoding for AuthToken ${token}`);
    }
  }

  /** is the auth token anonymous */
  public isAnonymous(): boolean {
    return this._payload.anonymous;
  }

  /** date when the token expires */
  public expiresAt(): Date {
    return new Date(this._payload.exp);
  }

  /** token version */
  public version(): string {
    return this._payload.version;
  }
}
