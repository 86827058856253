// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { BaseEvent } from '../core';

/**
 * The LaunchStateChange Event class to be used within the Application namespace
 * @public
 */
export class LaunchMonitor {
  /** version 1 events */
  private _v1: BaseEvent<void> = new BaseEvent();

  /** method to create and emit event when launch is resumed */
  public resume(): void {
    /** Do nothing Stub */
  }

  /** method to create and emit event when launch is suspended */
  public suspend(): void {
    /** Do nothing Stub */
  }
}
