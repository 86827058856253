// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * The standardized formats for durations/timespans.
 *
 * See `hadron/src/go/js/ui/Localization.js` in Hadron for the meanings of the various enum values.
 *
 * @public
 */
export enum DurationFormat {
  /**
   * A simple duration expressed in minutes and hours (ex. 1 min, 1 hr 1 min etc)
   */
  DURATION = 'DURATION',

  /**
   * A duration expressed as recency relative to current time. (ex. yesterday, 2 hr. ago, 1 min. ago etc)
   */
  RECENCY = 'RECENCY'
}
