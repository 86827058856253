// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IsoLanguageCode, IsoLanguageCodeSet } from '../generated';

/**
 * Returns a ReadonlySet containing all of the IsoLanguageCode values.
 *
 * @public
 */
export function getIsoLanguageCodes(): ReadonlySet<IsoLanguageCode> {
  return IsoLanguageCodeSet;
}
