// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  Article,
  ArticleBodyRichText,
  Avatar,
  Badge,
  Channel,
  Collection,
  CollectionItem,
  Config,
  Consent,
  ContentDescriptor,
  ContentRating,
  ContentRatingSystem,
  ContentRestrictionLevel,
  ContentRestrictionLevels,
  CurrentPaymentStatus,
  Edit,
  Genre,
  Glyph,
  Image,
  LanguageTag,
  LegalRegion,
  Link,
  MarketingBadge,
  MarketingCallToAction,
  MarketingCollection,
  MarketingCollectionGroup,
  MarketingCollectionItem,
  MarketingPage,
  MarketingPageItem,
  MarketingPageSection,
  MarketingRoute,
  Package,
  Page,
  PageItem,
  Partner,
  PaymentMethod,
  PlanCard,
  PlanCardProductAttribute,
  Price,
  PricePlan,
  Product,
  Profile,
  Route,
  Season,
  Show,
  Subscription,
  TaxonomyNode,
  Term,
  TermKind,
  TermOption,
  User,
  UserCustomAttributes,
  UserEntitlementsSummary,
  Video
} from '../data-models';
import { DataTypes } from '../json-api';

/**
 * Collection of all the bolt data models
 *
 * we escape 'typedef-var' rule as we want to use the
 * inferred types here as 'const'
 *
 * @public
 */
// eslint-disable-next-line @rushstack/typedef-var
export const BoltDataModelCollection = {
  article: Article,
  articleBodyRichText: ArticleBodyRichText,
  avatar: Avatar,
  badge: Badge,
  channel: Channel,
  currentPaymentStatus: CurrentPaymentStatus,
  config: Config,
  consent: Consent,
  collection: Collection,
  collectionItem: CollectionItem,
  contentDescriptor: ContentDescriptor,
  contentRating: ContentRating,
  contentRatingSystem: ContentRatingSystem,
  contentRestrictionLevel: ContentRestrictionLevel,
  contentRestrictionLevels: ContentRestrictionLevels,
  edit: Edit,
  genre: Genre,
  glyph: Glyph,
  image: Image,
  languageTag: LanguageTag,
  link: Link,
  marketingBadge: MarketingBadge,
  marketingCallToAction: MarketingCallToAction,
  marketingCollection: MarketingCollection,
  marketingCollectionGroup: MarketingCollectionGroup,
  marketingCollectionItem: MarketingCollectionItem,
  marketingPage: MarketingPage,
  marketingPageItem: MarketingPageItem,
  marketingPageSection: MarketingPageSection,
  marketingRoute: MarketingRoute,
  package: Package,
  page: Page,
  pageItem: PageItem,
  partner: Partner,
  paymentMethod: PaymentMethod,
  planCard: PlanCard,
  price: Price,
  pricePlan: PricePlan,
  product: Product,
  productAttribute: PlanCardProductAttribute,
  profile: Profile,
  route: Route,
  season: Season,
  show: Show,
  subscription: Subscription,
  taxonomyNode: TaxonomyNode,
  term: Term,
  termKind: TermKind,
  termOption: TermOption,
  user: User,
  userCustomAttributes: UserCustomAttributes,
  userEntitlementsSummary: UserEntitlementsSummary,
  video: Video,
  legalRegion: LegalRegion
} as const;

/**
 * Provides a type-guard for BoltDataModelCollection
 * to ensure that we have a model defined
 * for each type as defined in DataTypes enum
 * @public
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BoltDataModelCollectionTypeGuard: {
  readonly [Type in DataTypes]: typeof BoltDataModelCollection[Type];
} = BoltDataModelCollection;
