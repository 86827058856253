// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { Configuration, IInstrumentationSDKConfig } from '../core';
import { IGlobalContextV1Props, IPlatformRuntimeV1Props } from '../generated';
import { EventQueue, EventTransmitter } from '../transmission';
import { GlobalContextManager } from './GlobalContextManager';
import { HeartbeatMonitor } from './HeartbeatMonitor';
import { PerformanceManager } from './performance-manager';
import { RequestMonitor } from './request-monitor';
import { ISDKErrorGeneratorManager } from './ISDKErrorGeneratorManager';

/**
 * Manager that is responsible for coordinating between multiple managers and monitors. It's a hard job, but
 * someone needs to do it.
 * @public
 */
export class Coordinater {
  /**
   * Connects and initializes multiple managers and monitors together
   * @param globalContext - optional global context to be used by the SDK
   * @param instrumentationConfig - configuration used by the Instrumentation SDK to wire up data transmission and heartbeat events
   */
  public static initialize(
    instrumentationConfig: IInstrumentationSDKConfig,
    globalContextProps?: IGlobalContextV1Props
  ): void {
    const configuration = new Configuration(instrumentationConfig);

    ISDKErrorGeneratorManager.initialize();
    GlobalContextManager.initialize(globalContextProps);
    HeartbeatMonitor.initialize(configuration);
    RequestMonitor.initialize(configuration);
    EventQueue.initialize(configuration);
    EventQueue.onQueueDisableChanged((isDisabled) => {
      this.setDisableState(isDisabled);
    });
    EventQueue.onTelegraphBadRequest((payload) => {
      this.createPlatformRuntimeV1Error(payload);
    });
    EventTransmitter.initialize(configuration);
    PerformanceManager.initialize();
  }

  /**
   * updates the configuration provided to the SDK
   *
   * @param configuration - configuration used by the Instrumentation SDK to wire up data transmission and heartbeat events.
   * This will omit eventStorage as it should only be set during the initialize method. Storage is meant to be
   * immutable once set.
   */
  public static updateConfiguration(
    instrumentationConfig: Omit<IInstrumentationSDKConfig, 'eventStorage'>
  ): void {
    const configuration = new Configuration(instrumentationConfig);

    HeartbeatMonitor.update(configuration);
    RequestMonitor.update(configuration);
    EventQueue.initialize(configuration);
    EventTransmitter.initialize(configuration);
  }

  /**
   * Coordinates the disable state across multiple managers and monitors together
   * Note: This should only be used for manually-disabled and failure-disabled scenarios.
   * @param isDisabled - boolean indicating whether or not the library should be disabled
   */
  public static setDisableState(isDisabled: boolean): void {
    if (isDisabled) {
      this._disable();
    } else {
      this._enable();
    }
  }

  /**
   * Coordinates enabling the ISDK across multiple managers and monitors together
   */
  private static _enable(): void {
    HeartbeatMonitor.Heartbeat.enable();
  }

  /**
   * Coordinates disabling the ISDK across multiple managers and monitors together
   */
  private static _disable(): void {
    HeartbeatMonitor.Heartbeat.disable();
  }

  /**
   * Calls the ErrorGeneratorManager in order to capture a PlatformRuntimeV1
   * @param payload - Object containing the props to capture the error
   */
  public static createPlatformRuntimeV1Error(payload: IPlatformRuntimeV1Props): void {
    ISDKErrorGeneratorManager.createPlatformRuntimeError(payload);
  }
}
