// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ArticleBodyRichText } from './ArticleBodyRichText';
import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export interface IPriceAttributes {
  /** Localized format of the price.
   *
   * Not required by clients getting a localized price from IAP store.
   */
  format: string;

  /** The currency code to be used  */
  currencyCode: string;
}

/**
 * @public
 */
export interface IPriceRelationships {
  /** Rich text describing the amount. */
  amount: ArticleBodyRichText;
  /** Rich text of a localized currency symbol. */
  currency: ArticleBodyRichText;
  /** Rich text describing the payment period. */
  period: ArticleBodyRichText;
}

/**
 * @public
 */
export class Price extends TypedModel.generate<DataTypes.Price, IPriceAttributes, IPriceRelationships>() {}
