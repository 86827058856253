// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import type { MarketingBadge } from './MarketingBadge';
import type { MarketingCollectionItem } from './MarketingCollectionItem';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export interface IMarketingCollectionAttributes {
  /** Collection alias. */
  alias: string;
  /** Collection title. */
  title: string;
}

/**
 * @public
 */
export interface IMarketingCollectionRelationships {
  /** Optional badge to display for the collection when part of a collection group. */
  badge?: MarketingBadge;
  /** Copy of the initially-selected item in the list of items. */
  initiallySelectedItem?: MarketingCollectionItem;
  /** Ordered list of items inside the collection. */
  items: MarketingCollectionItem[];
}

/**
 * @public
 */
export class MarketingCollection extends TypedModel.generate<
  DataTypes.MarketingCollection,
  IMarketingCollectionAttributes,
  IMarketingCollectionRelationships
>() {}
