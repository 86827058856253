// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { CurrencyFormatterOptions } from 'globalize';

/**
 * Defines the options that Globalize accepts for formatting currency values.
 *
 * @public
 */
export interface IGlobalizeCurrencyFormatInfo {
  /**
   * The Globalize currency formatter options.
   */
  options?: CurrencyFormatterOptions;
}

/**
 * The standard currency formats supported by this library. This is a union across the formats required by all projects
 * that use this library. If your project requires a format that is not yet defined, feel free to add it.
 *
 * @public
 */
export enum CurrencyFormat {
  /**
   * The currency symbol and the currency amount, with the default number of decimal places.
   * For example, "R$ 3,99" for 3.99 Brazilian reals, or "¥15" for 14.99 Japanese yen.
   */
  DEFAULT_WITH_SYMBOL = 'DEFAULT_WITH_SYMBOL'
}

/**
 * A mapping from `CurrencyFormats` enum values to the corresponding Globalize currency format options objects.
 *
 * @public
 */
export const CURRENCY_FORMATS_MAP: Map<CurrencyFormat, IGlobalizeCurrencyFormatInfo> = new Map<
  CurrencyFormat,
  IGlobalizeCurrencyFormatInfo
>([
  [
    CurrencyFormat.DEFAULT_WITH_SYMBOL,
    {
      options: {
        style: 'symbol'
      }
    }
  ]
]);
