// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { BoltEnvironment } from '@wbd/bolt-http';
import type { IPlatformAdapter } from '@wbd/beam-ctv-platform-adapters';

/**
 * url param key for holding bolt environment information
 */
export const ENVIRONMENT_URL_PARAM: 'environment' = 'environment';

/**
 * helper function to get bolt environment from url query override
 * @param platformAdapter - device abstraction adapter
 * @returns
 */
export function getEnvironmentFromUrl(platformAdapter: IPlatformAdapter): BoltEnvironment | undefined {
  const urlParameters = platformAdapter.device.getURLParams();
  const queryEnvironment = urlParameters.get(ENVIRONMENT_URL_PARAM) ?? '';

  function isValidEnvironment(environment: string): boolean {
    return Object.values<string>(BoltEnvironment).includes(environment);
  }

  /**
   * Env if it's defined, we still need to check if an valid environment e.g.:
   * localhost:8080/?debug=true&environment=unknown
   */
  if (isValidEnvironment(queryEnvironment)) {
    return queryEnvironment as BoltEnvironment;
  } else {
    return undefined;
  }
}
