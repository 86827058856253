// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IPaymentInfo } from '@wbd/bolt-dataservice';

/**
 * Subscription flow types
 * @public
 */
export enum SubscriptionFlow {
  /**
   * DTC flow
   */
  DTC = 'DTC',
  /**
   * IAP flow
   */
  IAP = 'IAP',
  /**
   * MVPD flow
   */
  MVPD = 'MVPD'
}

/**
 * Supported Payment providers, this will be used later on on the subscription-journey/start service
 * @public
 */
export enum PaymentProvider {
  /**
   * Amazon
   */
  Amazon = 'Amazon',
  /**
   * Apple
   */
  Apple = 'Apple',
  /**
   * Google
   */
  Google = 'Google',
  /**
   * Roku
   */
  Roku = 'Roku',
  /**
   * Samsung
   */
  Samsung = 'Samsung',
  /**
   * Vizio
   */
  Vizio = 'Vizio'
}

/**
 * Purchase plan data needed to provide to the IAP or MVPD flows
 * @public
 */
export interface IPurchasePlan {
  /**
   * Plan sku/id
   */
  id: string;
  /**
   * Plan title
   */
  title?: string;
  /**
   * Plan current price
   */
  currentPrice?: string | number;
  /**
   * Plan currency code like USD
   */
  currencyCode?: string;
}

/**
 * Purchase store receipt needed to create a subscription plan for the IAP flow.
 * @public
 */
export interface IStoreReceipt {
  /**
   * Fixed name required and defined by backend(Samsung, Amazon, etc.)
   */
  provider: string;
  /**
   * The invoice id retrieved from provider(Samsung, etc.) Checkout
   */
  token: string;
  /**
   * The id that identifies the user in the platform
   */
  userId: string;
  /**
   * A country code in ISO 3166-1 alpha-2 format identifying the country from the TV.
   */
  countryCode: string;
  /**
   * A price plan which refers to the productId of the product which the user purchased.
   */
  pricePlan: string;
  /**
   * application id in which the purchase was made
   */
  appId: string;
}

/**
 * Unlinked receipt payload for the restore purchase feature.
 * @public
 */
export interface IUnlinkedReceipt {
  /**
   * Plan ID
   */
  planId: string;
  /**
   * Receipt information
   */
  receipt: IPaymentInfo;
}

/**
 * Payload provided for the subscription MVDP flows
 * @public
 */
export interface ISubscriptionPayloadMVPD {
  /**
   * Purchase plan data
   */
  plan: IPurchasePlan;
  /**
   * redirect url to be used as deep link to get back to the app, not all providers support this option
   */
  redirectUrl?: string;
}
