// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * creates a valid uuid with a date offset
 * https://en.wikipedia.org/wiki/Universally_unique_identifier#Version_4_(random)
 *
 * NOTE:
 * This generates a uuid that matches the uuid standard definition, but offsets it by the current
 * datetime. This adds additional uniqueness across multiple devices and browsers, but does not
 * guarantee it.
 *
 *
 * @returns string a valid v4 uuid
 * @public
 */
export function createV4Uuid(): string {
  let d: number;
  try {
    d = performance.now();
  } catch (e) {
    d = Date.now();
  }

  /* eslint-disable no-bitwise */
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    r = (d + r) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  /* eslint-enable no-bitwise */
}
