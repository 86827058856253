// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IErrorLikeErr } from '../generated';

function getCircularReplacer(): (key: string, value: unknown) => unknown {
  const seen = new WeakSet();
  return (key: string, value: unknown) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
}

/**
 * Helper method to convert a 'unknown' error into a standardized V1 'err'
 * generating both the 'message' and 'stackTrace properties
 * @param maybeError - potential error
 * @param payload - additional 'err; properties required for the specific error
 * @public
 */
export function generateV1Err<T extends IErrorLikeErr>(
  maybeError: unknown,
  payload: Omit<T, 'message' | 'stackTrace' | 'id'>
): T {
  if (maybeError instanceof Error) {
    return {
      message: maybeError.message,
      stackTrace: maybeError.stack || '',
      ...payload
    } as T;
  } else if (typeof maybeError === 'string') {
    return {
      message: maybeError,
      ...payload
    } as T;
  } else {
    try {
      return {
        message: JSON.stringify(maybeError, getCircularReplacer()),
        ...payload
      } as T;
    } catch {
      return {
        message: 'Non-serializable error',
        ...payload
      } as T;
    }
  }
}
