// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IResponse } from '@wbd/bolt-http';
import { JsonApiDocument, KeyValueObject } from 'json-api-models';
import {
  Avatar,
  ContentRestrictionLevels,
  Partner,
  Profile,
  ProfileDetails,
  User,
  UserCustomAttributes,
  UserEntitlementsSummary
} from '../data-models';
import type { RequestConfigOverride } from '../http-client';
import { HttpClient } from '../http-client';
import type { DataTypes, ITypedJsonApiDocumentFromModel } from '../json-api';
import type {
  ISwitchProfileData,
  ISwitchProfileResponse,
  IUserCreateProfileAttributes,
  IUserCreateProfileData,
  IUserCreateProfilePinData,
  IUserExitPinPayloadData,
  IUserPatchProfileContentRestrictionLevelData,
  IUserPatchProfileData,
  IUsersMePatchData,
  IUsersMePayloadData,
  IUsersPayload
} from './IUsersHttp';

/**
 * @public
 */
export type IPartnerResponse = ITypedJsonApiDocumentFromModel<DataTypes.Partner, Partner[]>;
/**
 * Gets the partner objects associated with a user. A partner is associated with a user either through registering with the GAuth authentication
 * provider or adding a GAuth authentication provider.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getPartnersRequest = async (config?: RequestConfigOverride): Promise<IPartnerResponse> => {
  const response = await HttpClient.instance.get<IPartnerResponse>('/users/me/partners', config);
  return response.data;
};

/**
 * @public
 */
export type IUserResponse = ITypedJsonApiDocumentFromModel<DataTypes.User, User>;
/**
 * Gets info about the user connected to the supplied token
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getUserRequest = async (config?: RequestConfigOverride): Promise<IUserResponse> => {
  const response = await HttpClient.instance.get<IUserResponse>('/users/me', config);
  return response.data;
};

/**
 * @public
 */
export type IUserCustomAttributesResponse = ITypedJsonApiDocumentFromModel<
  DataTypes.UserCustomAttributes,
  UserCustomAttributes
>;
/**
 * Returns the user.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getUserCustomAttributesRequest = async (
  attributeId: string,
  config?: RequestConfigOverride
): Promise<IUserCustomAttributesResponse> => {
  const response = await HttpClient.instance.get<IUserCustomAttributesResponse>(
    `/users/me/customAttributes/${attributeId}`,
    config
  );
  return response.data;
};

/**
 * Returns a custom attribute collection by its attributeId.
 * @param attributeId - the attributeId we are fetching information about.
 * @param customAttributeAttributes - the attributes object to update the custom attribute with.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const patchUserCustomAttributesRequest = async (
  attributeId: string,
  customAttributeAttributes: KeyValueObject,
  config?: RequestConfigOverride
): Promise<IUserCustomAttributesResponse> => {
  const payload = {
    data: {
      attributes: customAttributeAttributes,
      id: attributeId,
      type: 'userCustomAttributes'
    } as UserCustomAttributes
  };

  const response = await HttpClient.instance.patch<
    IUserCustomAttributesResponse,
    IUserCustomAttributesResponse
  >(`/users/me/customAttributes/${attributeId}`, payload, config);
  return response.data;
};

/**
 * @public
 */
export type IUserEntitlementResponse = ITypedJsonApiDocumentFromModel<
  DataTypes.UserEntitlementsSummary,
  UserEntitlementsSummary
>;
/**
 * Gets a high level summary of the customer entitlements.
 * The use cases for this endpoint includes for clients to decide if a Paywall should be displayed, and if it in such cases should contain win back information for lapsed users.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getUserEntitlementsRequest = async (
  config?: RequestConfigOverride
): Promise<IUserEntitlementResponse> => {
  const response = await HttpClient.instance.get<IUserEntitlementResponse>(
    '/entitlements/userEntitlementsSummary/me',
    config
  );
  return response.data;
};

type IPatchUserResponse = ITypedJsonApiDocumentFromModel<DataTypes.User, User[]>;
/**
 * Updates some attributes like first name, last name selected profile id and newsletter preference of the user
 * @param userId - the ID of the user
 * @param userAttributes - the user attributes to update the user with
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const patchUserRequest = async (
  userId: string,
  userAttributes: Partial<
    Pick<User, 'firstName' | 'lastName' | 'selectedProfileId' | 'newsletterPreference' | 'migrationStatus'>
  >,
  config?: RequestConfigOverride
): Promise<IPatchUserResponse> => {
  const payload: IUsersPayload<IUsersMePayloadData> = {
    data: {
      attributes: userAttributes,
      id: userId,
      type: 'user'
    } as IUsersMePatchData
  };

  const response = await HttpClient.instance.patch<IPatchUserResponse, IUsersPayload<IUsersMePayloadData>>(
    '/users/me',
    payload,
    config
  );
  return response.data;
};

/**
 * create/update exitPin for a user
 * @param profileId - the ID of the profile of the account (required by API now, but actually not used - should be removed in future)
 * @param exitPin - exitPin (4 digit string) to set for the user
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const patchExitPin = async (
  profileId: string,
  exitPin: string,
  config?: RequestConfigOverride
): Promise<IResponse> => {
  const payload: IUserExitPinPayloadData = { exitPin };

  const response = await HttpClient.instance.put<IResponse, IUserExitPinPayloadData>(
    `/users/me/profiles/${profileId}/exitPin`,
    payload,
    config
  );
  return response;

  //response.status 204 = Adding kid-proof exit pin was successful
  // https://docs.discomax.com/#profiles
};

// Profiles

/**
 * @public
 */
export type ICreateProfileResponse = ITypedJsonApiDocumentFromModel<DataTypes.Profile, Profile>;
/**
 * Creates a new profile
 * @param profile - the data to create the profile with
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const createProfileRequest = async (
  profile: ProfileDetails,
  config?: RequestConfigOverride
): Promise<ICreateProfileResponse> => {
  const payload: IUsersPayload<IUserCreateProfileData> = {
    data: {
      attributes: profile as IUserCreateProfileAttributes,
      type: 'profile'
    }
  };

  const response = await HttpClient.instance.post<
    ICreateProfileResponse,
    IUsersPayload<IUserCreateProfileData>
  >('/users/me/profiles', payload, config);
  return response.data;
};

/**
 * Deletes a profile
 * @param profileId - the ID of the profile to delete
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const deleteProfile = async (
  profileId: string,
  config?: RequestConfigOverride
): Promise<IResponse> => {
  const response = await HttpClient.instance.delete(`/users/me/profiles/${profileId}`, config);
  //response.status 204 = is success
  return response;
};

/**
 * @public
 */
export type IGetAvatarsResponse = ITypedJsonApiDocumentFromModel<DataTypes.Avatar, Avatar[]>;
/**
 * Returns a list of all avatars.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getAvatarsRequest = async (config?: RequestConfigOverride): Promise<IGetAvatarsResponse> => {
  const response = await HttpClient.instance.get<IGetAvatarsResponse>('/avatars', config);
  return response.data;
};

type IContentRestrictionLevelsResponse = ITypedJsonApiDocumentFromModel<
  DataTypes.ContentRestrictionLevels,
  ContentRestrictionLevels
>;
/**
 * Returns the content restriction levels and the default level
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getContentRestrictionLevelsRequest = async (
  config?: RequestConfigOverride
): Promise<IContentRestrictionLevelsResponse> => {
  const response = await HttpClient.instance.get<IContentRestrictionLevelsResponse>(
    '/contentrestrictions/levels',
    config
  );
  return response.data;
};

/**
 * @public
 */
export type IGetProfilesResponse = ITypedJsonApiDocumentFromModel<DataTypes.Profile, Profile[]>;
/**
 * Returns a list of all profiles for the user.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getProfilesRequest = async (config?: RequestConfigOverride): Promise<IGetProfilesResponse> => {
  const response = await HttpClient.instance.get<IGetProfilesResponse>('/users/me/profiles', config);
  return response.data;
};

/**
 * @public
 */
export type IGetSelectedProfileResponse = ITypedJsonApiDocumentFromModel<DataTypes.Profile, Profile>;
/**
 * Returns the selected profile of the user
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getSelectedProfileRequest = async (
  config?: RequestConfigOverride
): Promise<IGetSelectedProfileResponse> => {
  const response = await HttpClient.instance.get<IGetSelectedProfileResponse>(
    '/users/me/profiles/selected',
    config
  );
  return response.data;
};

/**
 * @public
 */
export type IPatchProfileResponse = ITypedJsonApiDocumentFromModel<DataTypes.Profile, Profile>;
/**
 * Updates a profile
 * @param profile - the data to update the profile with
 * @param profileId - the ID of the profile to update
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const patchProfileRequest = async (
  profile: Partial<ProfileDetails>,
  profileId: string,
  config?: RequestConfigOverride
): Promise<IPatchProfileResponse> => {
  const payload: IUsersPayload<IUserPatchProfileData> = {
    data: {
      attributes: profile as IUserCreateProfileAttributes,
      id: profileId,
      type: 'profile'
    }
  };

  const response = await HttpClient.instance.patch<
    IPatchProfileResponse,
    IUsersPayload<IUserPatchProfileData>
  >(`/users/me/profiles/${profileId}`, payload, config);
  //response.status 200 = is success
  return response.data;
};

/**
 * Updates a profile's content restriction level
 * @param profileId - the ID of the profile to update
 * @param contentRestrictionLevelId - the ID of the restriction level to set
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const patchProfileContentRestrictionLevelRequest = async (
  profileId: string,
  contentRestrictionLevelId: string,
  config?: RequestConfigOverride
): Promise<IPatchProfileResponse> => {
  const payload: IUsersPayload<IUserPatchProfileContentRestrictionLevelData> = {
    data: {
      attributes: { contentRestrictionLevelId },
      id: profileId,
      type: 'profile'
    }
  };

  const response = await HttpClient.instance.patch<
    IPatchProfileResponse,
    IUsersPayload<IUserPatchProfileContentRestrictionLevelData>
  >(`/users/me/profiles/${profileId}/contentRestrictionLevelId`, payload, config);
  //response.status 200 = is success
  return response.data;
};

/**
 * Adds a profile pin to a given profile
 * @param profileId - the ID of the profile to update
 * @param profilePin - the profile pin to set for the user
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const createProfilePinRequest = async (
  profileId: string,
  profilePin: string,
  config?: RequestConfigOverride
): Promise<IPatchProfileResponse> => {
  const payload: IUsersPayload<IUserCreateProfilePinData> = {
    data: {
      attributes: { profilePin },
      id: profileId,
      type: 'profile'
    }
  };

  const response = await HttpClient.instance.post<
    IPatchProfileResponse,
    IUsersPayload<IUserCreateProfilePinData>
  >(`/users/me/profiles/${profileId}/pin`, payload, config);
  return response.data;
};

/**
 * Removes a profile pin to a given profile
 * @param profileId - the ID of the profile to update
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const deleteProfilePin = async (
  profileId: string,
  config?: RequestConfigOverride
): Promise<IResponse> => {
  const response = await HttpClient.instance.delete(`/users/me/profiles/${profileId}/pin`, config);
  return response;
};

/**
 * Switch to a profile with a pin
 * @param userId - The user ID
 * @param selectedProfileId - The ID of the profile being switched to
 * @param profilePin - The pin for the profile being switched to
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const switchProfile = async (
  userId: string,
  selectedProfileId: string,
  profilePin: string,
  config?: RequestConfigOverride
): Promise<ISwitchProfileResponse> => {
  const payload: IUsersPayload<ISwitchProfileData> = {
    data: {
      attributes: {
        selectedProfileId,
        profilePin
      },
      id: userId,
      type: 'user'
    }
  };

  const response = await HttpClient.instance.post<JsonApiDocument, IUsersPayload<ISwitchProfileData>>(
    '/users/me/profiles/switchProfile',
    payload,
    config
  );

  //response.status 204 = is success
  //response.status 403 = pin is incorrect
  //response.status 404 = profile not found
  //response.status 401 = (unauthorized) Only a registered user may add a profile pin
  return response;
};

/**
 * Verify a kids exit proof pin
 * @param profileId - the ID of the profile
 * @param exitPin - the exit pin to validate
 * @public
 */
export const verifyExitPin = async (
  profileId: string,
  exitPin: string,
  config?: RequestConfigOverride
): Promise<IResponse> => {
  const payload: IUserExitPinPayloadData = { exitPin };

  const response = await HttpClient.instance.post<JsonApiDocument, IUserExitPinPayloadData>(
    `/users/me/profiles/${profileId}/verifyExitPin`,
    payload,
    config
  );

  //response.status 204 = Kid-proof exit pin verification was successful
  //response.status 400 = There was no profile ID provided or the exitPin field is malformed
  //response.status 401 = Only a registered user may verify an exit pin
  //response.status 403 = Provided kid-proof exit pin did not match
  //response.status 404 = This user does not have kid-proof exit pin
  return response;
};

/**
 * Graduates a profile
 * @param profileId - the ID of the profile to graduate
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const graduateProfile = async (
  profileId: string,
  config?: RequestConfigOverride
): Promise<IResponse> => {
  const response = await HttpClient.instance.post(`/users/me/profiles/${profileId}/graduate`, config);
  //response.status 200 = is success
  return response;
};

// Favourites

/**
 * Adds an item to the current user's favourites list
 * @param type - The type of the resource being favourited
 * @param id - The ID of the resource being favourited
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const addFavorite = async (
  type:
    | DataTypes.Video
    | DataTypes.Channel
    | DataTypes.Show
    | DataTypes.Link
    | DataTypes.Genre
    | DataTypes.TaxonomyNode,
  id: string,
  config?: RequestConfigOverride
): Promise<IResponse> => {
  const response = await HttpClient.instance.post(`/my-list/${type}/${id}`, undefined, config);
  //response.status 201 = is success
  return response;
};

/**
 * Deletes an item from the current user's favourites list
 * @param type - The type of resource being deleted
 * @param id - The ID of the resource being deleted
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const deleteFavorite = async (
  type:
    | DataTypes.Video
    | DataTypes.Channel
    | DataTypes.Show
    | DataTypes.Link
    | DataTypes.Genre
    | DataTypes.TaxonomyNode,
  id: string,
  config?: RequestConfigOverride
): Promise<IResponse> => {
  const response = await HttpClient.instance.delete(`/my-list/${type}/${id}`, config);
  //response.status 204 = is success
  return response;
};
