// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Maximum width and height bounds for video.  Do not construct directly, but use the available public instances as needed.
 * @public
 */
export class ResolutionConstraint {
  /**
   * No restriction on maximum resolution.
   */
  public static readonly UNRESTRICTED: ResolutionConstraint = new ResolutionConstraint(-1, -1);

  /**
   * Restricted to below HD resolutions; commonly used when DRM is not fully available.
   */
  public static readonly BELOW_HD: ResolutionConstraint = new ResolutionConstraint(1278, 719);

  /**
   * Lowest HD resolution; used to constrain low power devices when necessary.
   */
  public static readonly HD_720P: ResolutionConstraint = new ResolutionConstraint(1280, 720);

  /**
   * Middle HD resolution; used conservatively for devices without proper FHD support.
   */
  public static readonly HD_900P: ResolutionConstraint = new ResolutionConstraint(1600, 900);

  /**
   * Highest HD resolution; pre-4K devices.
   */
  public static readonly HD_1080P: ResolutionConstraint = new ResolutionConstraint(1920, 1080);

  /**
   * Standard UHD resolution, aka 4K.
   */
  public static readonly UHD_4K: ResolutionConstraint = new ResolutionConstraint(3840, 2160);

  /**
   * The maximum horizontal resolution to allow, inclusive.
   */
  public readonly width: number;

  /**
   * The maximum vertical resolution to allow, inclusive.
   */
  public readonly height: number;

  private constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }
}
