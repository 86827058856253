// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * The standardized formats for phone numbers. We don't yet have an i18n strategy for phone numbers,
 * so this is a placeholder at the moment.
 *
 * Here are some examples of phone number formats: https://docs.microsoft.com/en-us/globalization/locale/telephone-number
 *
 * @public
 */
export enum PhoneNumberFormat {
  /**
   * Land-line phone number format.
   */
  LAND_LINE = 'LAND_LINE',

  /**
   * Mobile phone number format.
   */
  MOBILE = 'MOBILE',

  /**
   * International phone number format.
   */
  INTERNATIONAL = 'INTERNATIONAL'
}
