// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { CollectionItem } from './CollectionItem';
import type { DataTypes } from '../json-api';
import type { IPaginationMeta } from './Page';
import type { Image } from './Image';
import type { Link } from './Link';
import { TypedModel } from '../json-api';

/**
 * Options allowing users to filter videos by letter.
 * @public
 */
export interface ILetterFilter {
  /** ID of available interaction. Always `letter`. */
  id: string;
  /** IDs of initially-selected options. Always `A`. */
  initiallySelectedOptionIds: string[];
  /** Whether to allow multiple selections. Always `false`. */
  multiSelect: boolean;
  /** Array of filter options. */
  options: Array<{
    /** Number of shows available for the current letter option. */
    count: number;
    /** Option ID. */
    id: string;
    /** Parameter to supply to collection endpoint. */
    parameter: string;
    /** Option value. */
    value: string;
  }>;
}

/**
 * Options allowing users to filter videos by season.
 * @public
 */
export interface ISeasonFilter {
  /** ID of available interaction. Always `seasonNumber`. */
  id: string;
  /** IDs of initially-selected options.
   * Either `All` or the first available season number,
   * depending on the sort order selected in CMS UI. */
  initiallySelectedOptionIds: string[];
  /** Whether to allow multiple selections. Always `false`. */
  multiSelect: boolean;
  /** Array of filter options. */
  options: Array<{
    /** Option ID. */
    id: string;
    /** Parameter to be supplied to collection endpoint.
     * Used in conjunction with `mandatoryParams` from component attribute. */
    parameter: string;
    /** Option value. */
    value?: string;
    /** Loolup key for localization. */
    l10nkey?: string;
  }>;
}

/**
 * Tile metadata (0: none, 1: attribution, 2: full).
 * To be used to display different amount of metadata for different tiles
 * @public
 */
export enum TileMetadata {
  /** None. */
  None = '0',
  /** Attribution. */
  Attribution = '1',
  /** Full. */
  Full = '2'
}

/**
 * Component information of the collection
 * @Public
 */
export interface ICollectionComponent {
  /** Component ID. */
  id: string;
  /** Component template ID. */
  templateId: string;
  /** Required query params. */
  mandatoryParams?: string;
  /** Array of filters */
  filters?: Array<ILetterFilter | ISeasonFilter>;
}

/**
 * Collection attributes.
 * @see https://docs.disco-api.com/apidocs/#collections
 * @public
 */
export interface ICollectionAttributes {
  /** Accessibility title text. */
  accessibilityTitle?: string;
  /** Collection alias. */
  alias: string;
  /** Whether to fetch the collection asynchronously. */
  async?: boolean;
  /** Array of excluded badge-types which should not be rendered. */
  badgeExclusion?: string[];
  /** Reference to the collection component. */
  component?: ICollectionComponent;
  /** More detailed channel description. */
  description?: string;
  /** Array of channel hints.  */
  hints?: string[];
  /** The kind of collection being referenced.
   * `manual` curation of content, or `automatic` generation. */
  kind?: 'automatic' | 'manual';
  /** Internal name used in admin UI. */
  name?: string;
  /** Used for search engine optimization and web crawlers. */
  pageMetadataDescription?: string;
  /** Revision number for the collection. */
  revision?: number;
  /** Collection secondary title. */
  secondaryTitle?: string;
  /** Collection state (e.g. `published`). */
  state?: string;
  /** Collection title. */
  title?: string;
}

/**
 * @public
 */
export interface ICollectionRelationships {
  /** Array of CollectionItems that contain a target article, collection, image, link, show, taxonomyNode, video */
  items?: CollectionItem[];
  /** InfoBlock CTA Link. */
  infoBlockCtaLink?: Link;
  /** InfoBlock logo image. */
  infoBlockLogoImage?: Image;
  /** Wide background image. */
  wideBackgroundImage?: Image;
  /** Menu logo image. */
  cmpContextHeaderLogo?: Image;
  /** Menu mobile logo image. */
  cmpContextHeaderMobileLogo?: Image;
}

/**
 * @public
 * The collection BoltModel combining the attributes and relationships.
 */
export class Collection extends TypedModel.generate<
  DataTypes.Collection,
  ICollectionAttributes,
  ICollectionRelationships,
  IPaginationMeta
>() {}
