// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Additional IETF BCP 47 primary language subtags (see https://wiki.hbo.com/pages/viewpage.action?pageId=160140159#LanguageCodesandFormatCodes:Locale\>GloablizationContext-IETFBCP47LanguageTags)
 * which appear in Unicode CLDR Locale codes, but which are not ISO 639-1 Alpha 2 language codes.
 *
 * We only include as supplementary those languages which appear in the CLDR Locale codespace, because there are over 8,000 languages,
 * and we can't afford to include all of them in a core library. If additional values are required (e.g. for language fallback/language matching),
 * they should be added to the code in `LanguageAndRegionData.ts` in `localization-enum-generator`.
 *
 * Official values were sourced from the IANA website:
 *    Website: https://www.iana.org/protocols
 *    Data endpoint URL: https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
 *    Sourced on: 2021-03-25
 *
 * This file was generated by `localization-enum-generator`. Please edit the template there to make any desired
 * changes. Changes made directly to this file will be overwritten without warning if this file is re-generated.
 *
 * @public
 */
export const enum SupplementaryIetfLanguageCode {
  /** Aghem */
  AGQ = 'agq',

  /** Asu (Tanzania) */
  ASA = 'asa',

  /** Asturian | Asturleonese | Bable | Leonese */
  AST = 'ast',

  /** Basa (Cameroon) */
  BAS = 'bas',

  /** Bemba (Zambia) */
  BEM = 'bem',

  /** Bena (Tanzania) */
  BEZ = 'bez',

  /** Bodo (India) */
  BRX = 'brx',

  /** Chakma */
  CCP = 'ccp',

  /** Cebuano */
  CEB = 'ceb',

  /** Chiga */
  CGG = 'cgg',

  /** Cherokee */
  CHR = 'chr',

  /** Central Kurdish */
  CKB = 'ckb',

  /** Taita | Dawida */
  DAV = 'dav',

  /** Zarma */
  DJE = 'dje',

  /** Lower Sorbian */
  DSB = 'dsb',

  /** Duala */
  DUA = 'dua',

  /** Jola-Fonyi */
  DYO = 'dyo',

  /** Embu | Kiembu */
  EBU = 'ebu',

  /** Ewondo */
  EWO = 'ewo',

  /** Filipino | Pilipino */
  FIL = 'fil',

  /** Friulian */
  FUR = 'fur',

  /** Swiss German | Alemannic | Alsatian */
  GSW = 'gsw',

  /** Gusii | Ekegusii */
  GUZ = 'guz',

  /** Hawaiian */
  HAW = 'haw',

  /** Upper Sorbian */
  HSB = 'hsb',

  /** Ngomba */
  JGO = 'jgo',

  /** Machame */
  JMC = 'jmc',

  /** Kabyle */
  KAB = 'kab',

  /** Kamba (Kenya) */
  KAM = 'kam',

  /** Makonde */
  KDE = 'kde',

  /** Kabuverdianu */
  KEA = 'kea',

  /** Koyra Chiini Songhay */
  KHQ = 'khq',

  /** Kako */
  KKJ = 'kkj',

  /** Kalenjin */
  KLN = 'kln',

  /** Konkani (macrolanguage) */
  KOK = 'kok',

  /** Shambala */
  KSB = 'ksb',

  /** Bafia */
  KSF = 'ksf',

  /** Kölsch */
  KSH = 'ksh',

  /** Langi */
  LAG = 'lag',

  /** Lakota */
  LKT = 'lkt',

  /** Northern Luri */
  LRC = 'lrc',

  /** Luo (Kenya and Tanzania) | Dholuo */
  LUO = 'luo',

  /** Luyia | Oluluyia */
  LUY = 'luy',

  /** Masai */
  MAS = 'mas',

  /** Meru */
  MER = 'mer',

  /** Morisyen */
  MFE = 'mfe',

  /** Makhuwa-Meetto */
  MGH = 'mgh',

  /** Meta' */
  MGO = 'mgo',

  /** Mundang */
  MUA = 'mua',

  /** Mazanderani */
  MZN = 'mzn',

  /** Khoekhoe | Nama (Namibia) */
  NAQ = 'naq',

  /** Low German | Low Saxon */
  NDS = 'nds',

  /** Kwasio */
  NMG = 'nmg',

  /** Ngiemboon */
  NNH = 'nnh',

  /** Nuer */
  NUS = 'nus',

  /** Nyankole */
  NYN = 'nyn',

  /** Prussian */
  PRG = 'prg',

  /** Rombo */
  ROF = 'rof',

  /** Rwa */
  RWK = 'rwk',

  /** Yakut */
  SAH = 'sah',

  /** Samburu */
  SAQ = 'saq',

  /** Sangu (Tanzania) */
  SBP = 'sbp',

  /** Sena */
  SEH = 'seh',

  /** Koyraboro Senni Songhai */
  SES = 'ses',

  /** Tachelhit */
  SHI = 'shi',

  /** Inari Sami */
  SMN = 'smn',

  /** Teso */
  TEO = 'teo',

  /** Tasawaq */
  TWQ = 'twq',

  /** Central Atlas Tamazight */
  TZM = 'tzm',

  /** Vai */
  VAI = 'vai',

  /** Vunjo */
  VUN = 'vun',

  /** Walser */
  WAE = 'wae',

  /** Soga */
  XOG = 'xog',

  /** Yangben */
  YAV = 'yav',

  /** Yue Chinese | Cantonese */
  YUE = 'yue',

  /** Standard Moroccan Tamazight */
  ZGH = 'zgh'
}

// prettier-ignore
/**
 * set of values from the SupplementaryIetfLanguageCode const enums
 * @public
 */
export const SupplementaryIetfLanguageCodeSet: ReadonlySet<SupplementaryIetfLanguageCode> = new Set([
'agq', 'asa', 'ast', 'bas', 'bem', 'bez', 'brx', 'ccp', 'ceb', 'cgg', 'chr', 'ckb', 'dav', 'dje', 'dsb', 'dua', 'dyo', 'ebu', 'ewo', 'fil', 'fur', 'gsw', 'guz', 'haw', 'hsb', 'jgo', 'jmc', 'kab', 'kam', 'kde', 'kea', 'khq', 'kkj', 'kln', 'kok', 'ksb', 'ksf', 'ksh', 'lag', 'lkt', 'lrc', 'luo', 'luy', 'mas', 'mer', 'mfe', 'mgh', 'mgo', 'mua', 'mzn', 'naq', 'nds', 'nmg', 'nnh', 'nus', 'nyn', 'prg', 'rof', 'rwk', 'sah', 'saq', 'sbp', 'seh', 'ses', 'shi', 'smn', 'teo', 'twq', 'tzm', 'vai', 'vun', 'wae', 'xog', 'yav', 'yue', 'zgh'
]) as Set<SupplementaryIetfLanguageCode>;
