// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import type { MarketingPage } from './MarketingPage';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export interface IMarketingRouteAttributes {
  /** Whether the path route is canonical. */
  canonical?: boolean;
  /** Direct path to the marketing route. */
  path: string;
}

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export interface IMarketingRouteRelationships {
  /** Page that should be shown to the user. */
  page: MarketingPage;
}

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export class MarketingRoute extends TypedModel.generate<
  DataTypes.MarketingRoute,
  IMarketingRouteAttributes,
  IMarketingRouteRelationships
>() {}
