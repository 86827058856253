// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  IPerformanceMarkV1Payload,
  PerformanceMeasureV1EndMarkName,
  PerformanceMeasureV1Name,
  PerformanceMeasureV1StartMarkName
} from '../../generated';
import { MarkManager } from './MarkManager';
import { MeasureManager } from './MeasureManager';

/**
 * The Performance namespace that allows access to Mark and Measure apis
 * @public
 */
/* eslint-disable @typescript-eslint/no-namespace */
export namespace PerformanceManager {
  /** instance of the mark monitor that is defined on initialization */
  export let markInstance: MarkManager;
  /** instance of the measure monitor that is defined on initialization */
  export let measureInstance: MeasureManager;

  /** initializes the performance monitor and connects the public apis to the private apis */
  export function initialize(): void {
    markInstance = new MarkManager();
    measureInstance = new MeasureManager(markInstance);
  }

  /**
   *  The Mark namespace that allows for creating marks
   */
  export namespace Mark {
    /** creates a mark */
    export function createMark(payload: IPerformanceMarkV1Payload, deviceOccuredAt?: number): void {
      return markInstance.createMark(payload, deviceOccuredAt);
    }
  }

  /**
   *  The Mark namespace that allows for measuring marks
   */
  export namespace Measure {
    /** measures mark defined marks */
    export function createMeasure(
      name: PerformanceMeasureV1Name,
      startMarkName: PerformanceMeasureV1StartMarkName,
      endMarkName?: PerformanceMeasureV1EndMarkName
    ): void {
      return measureInstance.createMeasure(name, startMarkName, endMarkName);
    }
  }
}
