// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-current-payment-status
 * @public
 */
export interface ICurrentPaymentStatusAttributes {
  /** Failed payment Account notification message. */
  accountFailedPaymentMessage?: string;
  /** Failed payment CTV notification message. */
  ctvFailedPaymentMessage?: string;
  /** Failed payment title. */
  failedPaymentTitle?: string;
  /** Failed payment Mobile notification message. */
  mobileFailedPaymentMessage?: string;
  /** Failed payment Modal notification message. */
  modalFailedPaymentMessage?: string;
  /** Next date the failed payment should be retried. */
  nextRetryDate?: string;
  /** ID used to differentiate payment retries. */
  paymentMethodId?: number;
  /** Current retry attempt count of the failed payment. */
  paymentRetryCount?: number;
  /** Subscription provider's name. */
  provider?: string;
  /** Current payment's status. */
  status: 'CREATED' | 'PRE_ACTIVE' | 'ACTIVE' | 'CANCELED' | 'TERMINATED' | 'PAUSED';
  /** Current payment's status code. */
  statusCode:
    | 'PAYMENT_SUCCESSFUL'
    | 'PAYMENT_FAILURE_FINAL_RETRY'
    | 'PAYMENT_FAILURE_NON_FINAL_RETRY'
    | 'PAYMENT_FAILURE_IAP'
    | 'PAYMENT_PENDING'
    | 'PAYMENT_FAILURE_NO_RETRY';
  /** Failed payment Toast notification message. */
  toastFailedPaymentMessage?: string;
  /** Failed payment Web notification message. */
  webFailedPaymentMessage?: string;
}

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-current-payment-status
 * @public
 */
export class CurrentPaymentStatus extends TypedModel.generate<
  DataTypes.CurrentPaymentStatus,
  ICurrentPaymentStatusAttributes
>() {}
