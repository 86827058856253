// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  FormatCode,
  LanguageCode,
  LanguageBasedFormatCode,
  getPrimaryLanguageCode
} from '@wbd/localization-core';

/**
 * Derives a supported FormatCode for use with language-based formats (e.g. unit and some date formats), by comparing
 * the `languageCode` and `formatCode`.
 *
 * @param languageCode - A `LanguageCode` that represents the application's current UI language.
 * @param formatCode - A `FormatCode` that represents the user's current region.
 * @returns A `LanguageBasedFormatCode` that can be used to avoid mixed languages in the UI when the language and
 * region don't match.
 *
 * @internal
 */
export function deriveLanguageBasedFormatCode(
  languageCode: LanguageCode,
  formatCode: FormatCode
): LanguageBasedFormatCode {
  if (getPrimaryLanguageCode(languageCode) === getPrimaryLanguageCode(formatCode)) {
    return formatCode;
  } else {
    return languageCode;
  }
}
