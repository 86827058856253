// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Enum definition for network type
 * @public
 */
export enum NetworkType {
  /**
   * Wired network connection
   */
  WIRED = 'wired',
  /**
   * Wireless network connection
   */
  WIRELESS = 'wireless',
  /**
   * Cellular network connection
   */
  CELLULAR = 'cellular',
  /**
   * Unknown network connection
   */
  UNKNOWN = 'unknown'
}

/**
 * Interface definition for Network information
 * @public
 */
export interface INetworkInformation {
  /**
   * Type of network connection (ex wireless, cellular etc)
   */
  type: NetworkType;
  /**
   * Network carrier
   */
  carrier?: string;
  /**
   * mac address
   */
  mac_address?: string;
  /**
   * ip address
   */
  ip_address?: string;
}
