// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { CanonicalizedCldrLocale, CanonicalizedCldrLocaleSet } from '../generated';

/**
 * Returns a ReadonlySet containing all of the CanonicalizedCldrLocale values.
 *
 * @public
 */
export function getCanonicalizedCldrLocales(): ReadonlySet<CanonicalizedCldrLocale> {
  return CanonicalizedCldrLocaleSet;
}
