// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { EventWithParams } from './EventWithParams';
import { Listener } from './Listener';
import { ParamsForListener } from './ParamsForListener';

/**
 * An event that can register event listeners (callback functions), and invoke them when the event is fired.
 *
 * If the `TListener` call signature accepts parameters, those parameters must be passed to the `fire()` method,
 * which will pass them to each listener when it is invoked.
 *
 * The listeners will be invoked asynchronously and in indeterminate order, using `Promise.allSettled()`.
 *
 * Exception handling:
 *    * All listeners will be invoked, regardless of whether any listener throws an `Error`
 *    * If any listener throws an `Error`:
 *      * The `fire()` method will throw an `Error` once all listeners have completed.
 *      * The thrown `Error` will contain an array containing all of the `Error` objects thrown by the listeners.
 *
 * @example - Non-parameterized event (listeners will be invoked with no parameters).
 * ```
 * export public MyClass {
 *   private readonly _onSomeEvent: NotificationEvent = new NotificationEvent();
 *   public readonly onSomeEvent: ListenerAdder = this._onSomeEvent.addListener;
 *
 *   public async myMethod(): void {
 *     // Listeners will be invoked with no parameters.
 *     await this._onSomeEvent.fire();
 *   }
 * }
 * ```
 *
 * @example - Parameterized event (listeners will be called with the event parameters).
 * ```
 * export type OnNameChangedListener = (oldName: string, newName: string) => Promise<void>;
 *
 * export public MyClass {
 *   private readonly _onNameChanged: NotificationEvent<OnNameChangedListener> = new NotificationEvent();
 *   public readonly onNameChanged: ListenerAdder<OnNameChangedListener> = this._onNameChanged.addListener;
 *
 *   public async myMethod(oldName: string, newName: string): void {
 *     // This will invoke each registered listener with the parameters (oldName, newName).
 *     await this._onNameChanged.fire(oldName, newName);
 *   }
 * }
 * ```
 *
 * The types
 * `NotificationEvent<(foo: string, bar: number) => void | Promise<void>>`
 * and
 * `EventWithParams<[foo: string, bar: number]>`
 * are identical.
 *
 * @param TListener - the type of event listener that can be registered for this event
 *
 * @public
 */
export class NotificationEvent<TListener = Listener<[]>> extends EventWithParams<
  ParamsForListener<TListener>
> {}
