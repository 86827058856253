// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Extracts the Playstation OS version from the user-agent returns '' if nothing can be extracted
 *
 * @param userAgent - playstation browser useragent as string
 * @returns playstation OS version
 */
export function getPlaystationOSVersionFromUserAgent(userAgent: string): string {
  const regex = /(WebMAF\/v|Version\/)(.+)( SDK| Safari)/gm;
  const matchGroup = regex.exec(userAgent);
  if (matchGroup && matchGroup.length >= 3) {
    const version = matchGroup[2];
    return version;
  } else {
    return '';
  }
}
