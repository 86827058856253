// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * A price plan is linked to a product and contains information about the price as well as
 *  related information like renewal period and free trial days.
 *
 * @see https://docs.disco-api.com/apidocs/#price-plans
 * @public
 */
export interface IPricePlanAttributes {
  /** Currency used for the product's price plan. */
  currency: string;
  /** Number of decimal points used.
   * @see https://docs.adyen.com/development-resources/currency-codes */
  currencyDecimalPoints: number;
  /** Any custom attributes that have been added. */
  customAttributes?: {};
  /** The date after which the next subscription renewal is moved to the parent
   * price plan. */
  endDate?: string;
  /** Apple's & Android's price ID set in the app. Must match so Sonic can identify the
   * customer's purchase. */
  inAppStoreId?: string;
  /** Price plan label. */
  label?: string;
  /** If `type: "INSTALMENTS"` this shows the number of periods the user has committed to. */
  numberOfInstalments: number;
  /** Number of periods (defined by the `period` attribute) between payments. Normally `1`.
   * @example For quarterly payments:
   * ```ts
   * {
   *  period: "MONTH",
   *  numberOfPeriodsBetweenPayments: 3
   * }
   * ```
   */
  numberOfPeriodsBetweenPayments: number;
  /** The number of periods paid with this price plan before the subscription is either
   * terminated or moved to a parent price plan. */
  numberOfValidPeriods?: number;
  /** The interval of time between price plan payments. */
  period: 'WEEK' | 'MONTH' | 'YEAR' | 'INFINITY';
  /** The number of days until the first payment (e.g. enabling a 30-day free trial). */
  postponedDays?: number;
  /** Price in minor units (normally cents).
   *
   * @example Config for a 7 EUR price plan:
   * ```ts
   * {
   *  price: 700,
   *  currency: "EUR",
   *  currencyDecimalPoints: 2
   * }
   * ```
   */
  price: number;
  /** The provider connected to the price.
   * Used to identify price plans for in-app purchases. */
  provider?: string;
  /** Whether a payment method is required. */
  requirePaymentMethod: boolean;
  /** Price plan secondary title. */
  secondaryTitle?: string;
  /** Subscription start date. */
  subscriptionStartDate?: string;
  /** Subscription title. */
  title?: string;
  /** Indicates if a user has used a free trial before. */
  userFreeTrialStatus: 'NOT_USED' | 'ALREADY_USED' | 'NOT_AVAILABLE' | 'UNKNOWN';
  /**
   * Describes what sort of payment the price plan is: ONGOING, INSTALMENTS, FREE or THIRD_PARTY.
   * ONGOING is a payment done periodically that the user can cancel at any time.
   * INSTALMENT is a type of ONGOING where the user is bound to complete a number of periods shown in numberOfInstalments.
   * FREE means that the customer is not (currently) charged by Sonic
   * and THIRD_PARTY that another provider handles the billing, like in-app-purchases.
   */
  type: 'ONGOING' | 'INSTALMENTS' | 'FREE' | 'THIRD_PARTY';
}

/**
 * @public
 */
export class PricePlan extends TypedModel.generate<DataTypes.PricePlan, IPricePlanAttributes>() {
  public get pricePlanType(): 'ONGOING' | 'INSTALMENTS' | 'FREE' | 'THIRD_PARTY' {
    return this.attributes.type;
  }
}
