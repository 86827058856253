// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IDevice, IStorage, IVideoCapabilities } from '@wbd/beam-ctv-platform-adapters';
import type { IPlatformDeviceInfo } from '../service';

/**
 * Loads the device info and stores in a cache
 */
export async function getBootstrapDeviceInformation(
  device: IDevice,
  video: IVideoCapabilities,
  storage: IStorage
): Promise<IPlatformDeviceInfo> {
  // TODO: cleanup unused methods/properties
  const [
    boltPlatformName,
    chipset,
    deviceId,
    deviceAdIdentifier,
    model,
    modelGroup,
    modelYear,
    performance,
    type,
    deviceGroup,
    operatingSystem,
    operatingSystemVersion,
    brand,
    manufacturer,
    locale,
    storeCountryCode,
    deviceClosedCaptionsState,
    networkInfo,
    hasLimitAdTracking,
    paywallData,
    scrubberOptions,
    supportsTrickPlayScrubbing,
    supportsHTMLVideoTransformations
  ] = await Promise.all([
    device.getBoltPlatformNameAsync(),
    device.getDeviceChipsetAsync(),
    device.getDeviceIdentifierAsync(storage),
    device.getDeviceAdIdentifierAsync(storage),
    device.getDeviceModelNameAsync(),
    device.getDeviceModelGroupAsync(),
    device.getDeviceModelYearAsync(),
    device.getDevicePerformanceIndicatorAsync(),
    device.getDeviceTypeAsync(),
    device.getDeviceGroupAsync(),
    device.getDeviceOsAsync(),
    device.getDeviceOSVersionAsync(),
    device.getDeviceBrandAsync(),
    device.getDeviceManufacturerAsync(),
    device.getDeviceLocaleAsync(),
    device.getStoreCountryCodeAsync(),
    device.getDeviceClosedCaptionsState(),
    device.getDeviceNetworkInformationAsync(),
    device.getLimitAdTrackingAsync(),
    device.getPaywallDataAsync(),
    video.getScrubberOptions(),
    video.supportsTrickPlayScrubbing(),
    video.supportsHTMLVideoTransformations()
  ]);

  return {
    boltPlatformName,
    chipset,
    deviceId,
    deviceAdIdentifier,
    model,
    modelGroup,
    modelYear,
    performance,
    type,
    deviceGroup,
    operatingSystem,
    operatingSystemVersion,
    brand,
    manufacturer,
    locale,
    storeCountryCode,
    // TODO - below values can change after boot so we should not cache them
    deviceClosedCaptionsState,
    networkInfo,
    hasLimitAdTracking,
    // TODO - below should be cached at video player level not on boot
    scrubberOptions,
    supportsTrickPlayScrubbing,
    supportsHTMLVideoTransformations,
    paywallData
  };
}
