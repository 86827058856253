// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';
import type { Badge } from './Badge';
import type { Channel } from './Channel';
import type { ContentDescriptor } from './ContentDescriptors';
import type { ContentRating } from './ContentRating';
import type { Edit } from './Edit';
import type { Image } from './Image';
import type { Route } from './Route';
import type { Season } from './Season';
import type { IGeoRestriction, Show } from './Show';

/**
 * The values a client can receive from a getActiveVideoForShow request via the meta.state property.
 * @see https://wbdstreaming.atlassian.net/wiki/spaces/CD/pages/242780906/BEAM+Content+API+-+activeVideoForShow
 * @public
 */
export enum ActiveVideoMetaState {
  /** The show is in the Cold Start state. (The user has not watched any of this show yet.) */
  COLD_START = 'COLD_START',
  /** The show is in the Completed state. (The user's last watch position was the end of the last applicable video.) */
  COMPLETED = 'COMPLETED',
  /** The show is in the Resume watching state. (The user's last watch position was not the end of the last applicable video.) */
  RESUME = 'RESUME',
  /** The show is in the Upcoming state. (There is no active main video yet, but there may be a trailer.) */
  UPCOMING = 'UPCOMING'
}

/**
 * Defines metadata which can come with the getActiveVideoForShow request.
 * @see https://wbdstreaming.atlassian.net/wiki/spaces/CD/pages/242780906/BEAM+Content+API+-+activeVideoForShow
 * @public
 */
export interface IActiveVideoMeta {
  /** The user's pick-up state for this show, which influences conditional UI presentation by all the clients. */
  state: ActiveVideoMetaState;
}

/**
 * The full data to be returned with getActiveVideoForShow requests (including applicable metadata).
 * @public
 */
export interface IActiveVideoForShowData {
  /** The metadata which can come with the getActiveVideoForShow request. */
  meta: IActiveVideoMeta;

  /** The active video for this user for this show. */
  video: Video;
}

/**
 * @public
 */
export interface IAvailabilityWindow {
  /** With package the rights are available for. */
  package: string;
  /** Start data when the video can be played. */
  playableEnd?: string;
  /** End date after which rights are no longer given. */
  playableStart: string;
}

/**
 * @public
 */
export interface IDownloadWindow {
  /** Start date a video can be downloaded from. */
  end: string;
  /** End date after which a video can no longer be downloaded. */
  start: string;
}

/**
 * @public
 */
export interface IVideoIdentifiers {
  analyticsId?: string;
  epgId?: string;
  freewheel: string;
  originalMediaId: string;
}

/**
 * @public
 */
export interface IViewingHistory {
  /** Whether the video has been "completely" watched.
   *
   * `true` when 95% of the video has been watched.
   */
  completed?: boolean;
  /** Time when the playback on the video was last reported. */
  lastStartedTimestamp?: string;
  /** The last reported playback time (in milliseconds) for the current user. */
  position?: number;
  /** Indicates if playback has been reported for the current user. */
  viewed: boolean;
}

/**
 * @see https://docs.disco-api.com/apidocs/#videos
 * @public
 */
export interface IVideoAttributes {
  /** Date the video first aired. */
  airDate?: string;
  /** Alternate ID for the video. */
  alternateId: string;
  /** List of language tags codes (lowercase, two-letter ISO-639) denoting the available
   * audio tracks. */
  audioTracks?: string[];
  /** Range denoting start and end dates for when the video is viewable. */
  availabilityWindows?: IAvailabilityWindow[];
  /** List of badges associated with the video. */
  badges?: Badge[];
  /** Broadcast types.
   * @see https://docs.disco-api.com/apidocs/#videos for descriptions of each one.
   */
  broadcastType?: 'LIVE' | 'REPLAY' | 'VTR';
  /** TBD */
  clearkeyEnabled: boolean;
  /** List of ContentRatings associated with the video. */
  contentRatings?: ContentRating[];
  /** Custom attributes applied to the video. */
  customAttributes?: {};
  /** Video description. Design/Product may refer to this as a "shortDescription" and it may be limited in length (e.g. ~150 characters). */
  description?: string;
  /** Date range within with a video can be downloaded. */
  downloadWindow?: IDownloadWindow;
  /** Whether a video is protected by DRM. */
  drmEnabled: boolean;
  /** TBD */
  earliestPlayableStart?: string;
  /** Video episode number. */
  episodeNumber?: number;
  /** Any GeoRestrictions that apply to the video. */
  geoRestrictions?: IGeoRestriction;
  /** Unique identifiers associated with the video. */
  identifiers?: IVideoIdentifiers;
  /** Indicated whether the video will expire at some point. */
  isExpiring?: boolean;
  /** Whether the video has been marked as a favorite by the current user. */
  isFavorite?: boolean;
  /** Indicates if a video is younger than a defined number of days.
   *
   * Can be configured on a per-realm basis (default = 7 days).
   */
  isNew?: boolean;
  /** Included if the video is obtained using the `Continue Watching` auto-collection. */
  isNextEpisode?: boolean;
  /** A longer description of the video's content. */
  longDescription?: string;
  /** @see https://docs.disco-api.com/apidocs/#videos for description of each. */
  materialType?: 'LINEAR' | 'EVENT' | 'VOD';
  /** Minimum age a profile must be to watch. */
  minimumAge?: number;
  /** Video name. */
  name: string;
  /** Path to the video content. */
  path?: string;
  /** Date the video is listable in the API. */
  publishStart?: string;
  /** Date the video is no longer listable in the API. */
  publishEnd?: string;
  /** Often containing `{ embeddable: boolean }`. */
  rights: {};
  /** Included where seasonNumber matches. */
  seasonNumber?: number;
  /** Secondary title for the video. */
  secondaryTitle?: string;
  /** Indicates when the video is live on linear TV. */
  scheduleEnd?: string;
  /** Indicates when the video stops being live on linear TV. */
  scheduleStart?: string;
  /** SourceSystemId of the video to playback. */
  sourceSystemId: string;
  /** Name of the source system associated with the video. */
  sourceSystemName?: string;
  /** Available subtitles for the video, as an array of lowercase two-letter ISO-639 language codes. */
  subtitles?: string[];
  /** Length of video (in milliseconds). */
  videoDuration?: number;
  /** Horizontal video resolution.
   *
   * Available values are `720`, `1280`, `1920`, `3840`, `4096`, `7680`. */
  videoResolution?: number;
  /** @see https://docs.disco-api.com/apidocs/#videos for description of options. */
  videoType?: VideoType;
  /** Viewing history for the video. */
  viewingHistory?: IViewingHistory;
}

/**
 * @public
 */
export enum VideoType {
  CLIP = 'CLIP',
  EPISODE = 'EPISODE',
  EXTRA = 'EXTRA',
  FOLLOW_UP = 'FOLLOW_UP',
  LISTING = 'LISTING',
  LIVE = 'LIVE',
  MOVIE = 'MOVIE',
  PROMO = 'PROMO',
  STANDALONE = 'STANDALONE',
  TRAILER = 'TRAILER'
}

/**
 * @public
 * The video interface combining the attributes and relationships
 */
export interface IVideoRelationships {
  edit?: Edit;
  images?: Image[];
  primaryChannel?: Channel;
  ratingDescriptors?: ContentDescriptor[];
  ratings?: ContentRating[];
  routes?: Route[];
  /** Include season where exists */
  season?: Season;
  show?: Show;
}

/**
 * @public
 * The video BoltModel combining the attributes and relationships
 */
export class Video extends TypedModel.generate<DataTypes.Video, IVideoAttributes, IVideoRelationships>() {}
