// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { createLoggerFactory, ILoggerFactory } from './factories/create-logger';

export { ILoggerFactory };

/**
 * Singleton to create loggers
 *
 * @public
 */
export const loggerFactory: ILoggerFactory = createLoggerFactory();

export { createConsoleAdapter } from './adapters/console';

export { ILogAdapter, ILogger, LogLevel, LogOperation, ILog } from './types';
