// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * An `Error` that wraps other errors, with the wrapped errors held in the `wrappedErrors` property. The wrapped errors
 * may be instances of `Error`, or they may be other values that were thrown by code that does not conform to convention.
 *
 * @public
 */
export class CompositeError extends Error {
  public readonly wrappedErrors: Error[];

  /**
   * Initializes an instance of a `CompositeError` with the provided `message` and `errors`.
   *
   * @param message - the message for the CompositeError
   * @param errors - the list of errors to wrap
   */
  public constructor(message: string, errors: Error[]) {
    super(`${message} Error messages: [${errors.map((error) => `"${error.message}"`).join(', ')}]`);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    // See https://developer.mozilla.org/en-US/docs/web/javascript/reference/global_objects/error#custom_error_types
    if (canCaptureStack(Error)) {
      Error.captureStackTrace(this, CompositeError);
    } else {
      // See https://stackoverflow.com/a/5251506/12757573
      this.stack = new Error().stack;
    }

    this.name = 'CompositeError';
    this.wrappedErrors = errors;
  }
}

function canCaptureStack(
  error: unknown
): error is Error & { captureStackTrace: (error: Error, ctor: new (...args: never) => Error) => void } {
  return typeof (error as { captureStackTrace: unknown }).captureStackTrace === 'function';
}

export function isCompositeError(error: unknown): error is CompositeError {
  return (error as { name: string }).name === 'CompositeError';
}
