// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Default account implementation
 * @public
 */
export class AbstractAccount {
  // prohibit class initialization as we only want to use te static functions
  protected constructor() {}
  /**
   * Tracks a new user sign in
   *
   * @param entitlements - the list of products the user is entitled to
   */
  public static trackSignIn(entitlements?: string[]): void {}

  /**
   * Tracks a silent sign-in on app launch
   *
   * @param entitlements - the list of products the user is entitled to
   */
  public static trackSilentSignIn(entitlements?: string[]): void {}

  /**
   * Tracks an implicit or explicit sign out
   */
  public static trackSignOut(): void {}
}

/**
 * account implementation interface
 * @public
 */
export type IAccount = Omit<typeof AbstractAccount, 'prototype'>;
