// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IStorageObject } from './IStorageObject';
import { MemoryStorage } from './MemoryStorage';
import { StorageScope } from './StorageScope';

/** @public Represents a global window object that supports HTML5 storage */
export interface IBrowserStorageProvider {
  /** The location to an underlying localStorage HTML5 API implementation */
  localStorage: IStorageObject;
  /** The location to an underlying sessionStorage HTML5 API implementation */
  sessionStorage: IStorageObject;
}

/**
 * Storage class to handle key/value pair operations
 * @remarks Storage is implemented as an instance class. We are leaving it to the Application to create and manage Storage instances.
 * We highly recommend that Applications only ever handle one instance per scope of Storage at a time.
 * @public
 */
export class BrowserStorage extends MemoryStorage {
  private readonly _browserStorageProvider: IBrowserStorageProvider;

  /**
   * Constructs an instance of Storage for browser clients, using local storage if possible, and in-memory storage if not.
   * Allow an option to pass in the local and session Storage location (used for test purposes)
   * @public
   */
  public constructor(scope?: StorageScope, browserStorageProvider?: IBrowserStorageProvider) {
    super();

    this._browserStorageProvider = browserStorageProvider ? browserStorageProvider : window;

    if (this._isStorageAccessible(scope)) {
      this._storage = this._getStorageObject(scope);
    }
  }

  /**
   * Tries to store using HTML5
   * @returns a boolean that tells us if HTML5 localStorage is accessible
   */
  private _isStorageAccessible(scope?: StorageScope): boolean {
    try {
      const key: string = 'uCgI2raDsb'; // Random test key we'll never use anywhere else

      const storageObject: IStorageObject = this._getStorageObject(scope);
      storageObject.setItem(key, '55');
      storageObject.removeItem(key);
      return true;
    } catch (__err) {
      return false;
    }
  }

  // Helper method to get the storage object (session or local) from the provided location or global
  private _getStorageObject(scope?: StorageScope): IStorageObject {
    if (scope === StorageScope.SESSION) {
      return this._browserStorageProvider.sessionStorage;
    } else {
      return this._browserStorageProvider.localStorage;
    }
  }
}
