// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import type { PaymentMethod } from './PaymentMethod';
import type { PricePlan } from './PricePlan';
import type { Product } from './Product';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#list-subscriptions
 * @public
 */
export interface ISubscriptionAttributes {
  /** Attribute for marketing affiliates connected to the subscription. */
  affiliateMarketing?: string;
  /** Whether it's possible to cancel the subscription. */
  cancelationAllowed: boolean;
  /** The date that a subscription cancellation was manually requested. */
  cancelationDate?: string;
  /** Termination date for a cancelled/terminated subscription.
   *
   * Preferred over minimumTermEndData for any cancelled subscriptions.
   */
  endDate?: string;
  /** Whether the subscription is currently in a free trial period. */
  inFreeTrialNow: boolean;
  /** End date of the subscription's minimum term. */
  minimumTermEndDate?: string;
  /** Date of next payment.
   *
   * May also represent a condition change for subscriptions without payments, such as free-tier subscriptions. */
  nextRenewalDate?: string;
  /** Date of next attempt at payment for any failed payments. */
  nextRetryDate?: string;
  /** Helps distinguish between different pause states for any paused subscriptions. */
  pauseCode?: string;
  /** Date that a subscription was paused. */
  pauseDate?: string;
  /** Reason that a subscription was paused. */
  pauseReason?: string;
  /** Present if a subscription is an upgrade or a downgrade. */
  previousSubscriptionId?: number;
  /** Date when the subscription will change price plan.
   *
   * New price plan can be found in the `pricePlan` relationship.
   */
  pricePlanChangeDate?: string;
  /** Date when the subscription was created. */
  startDate: string;
  /** Status of the subscription.
   * @see https://docs.disco-api.com/apidocs/#list-subscriptions for descriptions of
   * each status type. */
  status: 'CREATED' | 'PRE_ACTIVE' | 'ACTIVE' | 'CANCELED' | 'TERMINATED' | 'PAUSED';
  /** Whether the subscription can be terminated. */
  terminationAllowed: boolean;
  /** Whether the subscription can be unsubscribed from. */
  unsubscribeAllowed: boolean;
  /** User ID associated with the subscription. */
  userId: string;
  /** subscription type */
  type: 'PAYING' | 'NON_PAYING' | 'VIP' | 'IAP';
}

/**
 * @see https://docs.disco-api.com/apidocs/#list-subscriptions
 * @public
 */
export interface ISubscriptionRelationships {
  /** The payment method associated with the subscription. */
  paymentMethod?: PaymentMethod;
  /** The subscription's price plan. */
  pricePlan: PricePlan;
  /** The product linked to the subscription. */
  product: Product;
}

/**
 * @see https://docs.disco-api.com/apidocs/#list-subscriptions
 * @public
 */
export class Subscription extends TypedModel.generate<
  DataTypes.Subscription,
  ISubscriptionAttributes,
  ISubscriptionRelationships
>() {
  public get subscriptionType(): 'PAYING' | 'NON_PAYING' | 'VIP' | 'IAP' {
    return this.attributes.type;
  }
}
