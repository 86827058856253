// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  AuthTokenProvider,
  IConsentOption,
  Term,
  getRegionRequest,
  termsRequest,
  updateConsentRequest
} from '@wbd/bolt-dataservice';
import { IStorage } from '@wbd/bolt-http';
import { EventWithParams } from '@wbd/light-events';

/**
 * storage key for local consent
 * @public
 */
export const CONSENT_TERMS_STORAGE: string = 'consent-terms';

/**
 * list of known consent aliases
 * @public
 */
export enum ConsentAlias {
  /**
   * product email marketing
   */
  PRODUCT_EMAIL_MARKETING_CONSENT = 'product-email-marketing-consent',
  /**
   * sell share data consent
   */
  SELL_SHARE_DATA_CONSENT = 'sell-share-data-consent',
  /**
   * privacy policy acknowledgment
   */
  PRIVACY_POLICY_ACKNOWLEDGEMENT = 'privacy-policy-acknowledgement',
  /**
   * Terms of use acceptance
   */
  TERMS_OF_USE_ACCEPTANCE = 'terms-of-use-acceptance'
}

/**
 * Consent term item
 * @internal
 */
export interface IConsentManagementDataStorage {
  state: Partial<Record<string, boolean>>;
  aliases: string[];
}

/**
 * Consent term item
 * @public
 */
export interface IConsentManagementItem {
  /**
   * consent unique alias
   */
  alias: string;
  /**
   * consent approval state
   */
  approved: boolean;
  /**
   * term linked to the consent
   */
  term?: Term;
  /**
   * indication if the consent is visible to the user in client UX
   */
  visible: boolean;
}

/**
 * Helper class to more easily manager both local and server consent states
 * @public
 */
export class ConsentManagement {
  /** local consent approval state, this is used for both caching and non-auth users */
  private _state: Partial<Record<string, boolean>> = {};
  /** cached server terms models */
  private _terms: Term[] = [];
  /** cached term kinds that should be visible to the user in the current region */
  private _termsVisibleInRegion: string[] = [];
  /** list of available term aliases */
  private _aliases: string[] = [];
  /** cached server pending terms models */
  private _pendingTerms: Term[] = [];
  /** persistent device storage reference */
  private _storage: IStorage;
  /** reference to auth provider to determine auth state */
  private _authProvider: AuthTokenProvider;
  /** internal error subscribers */
  protected _errorSubscribers: EventWithParams<[unknown]> = new EventWithParams<[unknown]>();

  /**
   * ConsentManagement constructor
   * @param storage - persistent local storage instance
   */
  public constructor(storage: IStorage, authProvider: AuthTokenProvider) {
    this._storage = storage;
    this._authProvider = authProvider;

    // hydrate state from local storage
    if (Object.keys(this._state).length === 0) {
      try {
        const storageDataJson = this._storage.readSync(CONSENT_TERMS_STORAGE) || '';
        if (storageDataJson) {
          const storageData = JSON.parse(storageDataJson) as IConsentManagementDataStorage;
          if (storageData.state) this._state = storageData.state;
          if (storageData.aliases) this._aliases = storageData.aliases;
        }
      } catch (error) {
        const hydrationError = new Error(
          `Error reading ConsentManagement data from storage with key '${CONSENT_TERMS_STORAGE}' with error ${error}`
        );
        this._errorSubscribers.fire(hydrationError).catch((error) => {
          // fail silently
        });
      }
    }
  }

  /**
   * fetching the available terms, pending terms and (auth user) approved consents
   */
  public async syncWithLegalService(): Promise<void> {
    try {
      const [terms, regions] = await Promise.allSettled([
        termsRequest({ include: 'kind,consent' }),
        getRegionRequest()
      ]);
      // do not block terms syncing if region request fails, we fallback to memory state
      if (regions.status === 'fulfilled') this._termsVisibleInRegion = regions.value.metadata.termKinds ?? [];

      // block terms syncing if we do not have fresh data and report error
      if (terms.status === 'rejected') throw terms.reason;
      this._terms = terms.value;

      // infer pending terms from terms and consent to limit the network requests
      // a pending term equals a mandatory term that does not have an explicit consent
      this._pendingTerms = this._terms.filter((term) => term.mandatory && !term.consent);

      // cache unique consent term aliases
      this._aliases = this._terms.flatMap((term) => term.options?.map((option) => option.alias));

      // hydrate local state with server consents
      this._terms.forEach((term) => {
        // consent is only defined on term
        // for auth-user and when the consent is set by the user previously
        term.consent?.consentOptions.map((option) => {
          this._state[option.alias] = option.approved;
        });
      });

      // persist any changes in storage
      this._persistStateInStorage();
    } catch (error) {
      this._errorSubscribers.fire(error).catch((hydrationError) => {
        // fail silently
      });
    }
  }

  /**
   * persist the consent terms data in local storage
   */
  private _persistStateInStorage(): void {
    // save to storage
    try {
      const storageData = {
        state: this._state,
        aliases: this._aliases
      } as IConsentManagementDataStorage;
      const storageDataJson = JSON.stringify(storageData);
      this._storage.writeSync(CONSENT_TERMS_STORAGE, storageDataJson);
    } catch (error) {
      const storageError = new Error(
        `Error writing ConsentManagement state to storage with key '${CONSENT_TERMS_STORAGE}' with error ${error}`
      );
      this._errorSubscribers.fire(storageError).catch((error) => {
        // fail silently
      });
    }
  }

  /**
   * Set a consent approval with NCIS bolt service
   * @param alias - alias of a term options
   * @param approved - user consent approval
   */
  private async _updateConsentWithLegalService(alias: string, approved: boolean): Promise<void> {
    const term = this._terms.find((term) => term.options?.find((option) => option.alias === alias));
    if (term) {
      const consentOption: IConsentOption = {
        alias: alias,
        approved
      };

      try {
        await updateConsentRequest(term.id, [consentOption]);
      } catch (error) {
        this._errorSubscribers.fire(error).catch((error) => {
          // fail silently
        });
      }

      // re-sync terms and pending terms
      await this.syncWithLegalService();
    }
  }

  /**
   * get a specific user consent
   * @param alias - alias of a term options
   */
  public getConsent(alias: string): IConsentManagementItem | undefined {
    if (!this._aliases.includes(alias)) return;
    const term = this._terms.find((term) => term.options?.find((option) => option.alias === alias));
    return {
      alias: alias,
      approved: this._state[alias] ?? false,
      term,
      visible: this._termsVisibleInRegion.includes(term?.kind?.alias || '')
    };
  }

  /**
   * get all user consents
   * @returns
   */
  public getAllConsents(): IConsentManagementItem[] {
    return this._terms.flatMap((term) => {
      return term.options.map((termOption) => {
        return {
          alias: termOption.alias,
          approved: this._state[termOption.alias] ?? false,
          term,
          visible: this._termsVisibleInRegion.includes(term?.kind?.alias || '')
        };
      });
    });
  }

  /**
   * Get the latest published mandatory consent term that the user has not yet consented to.
   */
  public getPendingConsents(): IConsentManagementItem[] {
    // ignore for non-auth user
    // as the server always returns all pending consents for un-auth user
    if (this._authProvider.isAnonymous()) return [];
    return this._pendingTerms.flatMap((term) => {
      return term.options.map((termOption) => {
        return {
          alias: termOption.alias,
          approved: this._state[termOption.alias] ?? false,
          term: term,
          visible: this._termsVisibleInRegion.includes(term?.kind?.alias || '')
        };
      });
    });
  }

  /**
   * Update a user consent term
   * @param alias - alias of a term options
   */
  public async updateConsent(alias: string, approved: boolean): Promise<void> {
    if (this._aliases.includes(alias)) {
      // store state in memory
      this._state[alias] = approved;
      // store state persistently
      this._persistStateInStorage();
      // synchronize consents to NCIS service for auth users
      if (!this._authProvider.isAnonymous()) {
        await this._updateConsentWithLegalService(alias, approved);
      }
    }
  }

  /**
   * Helper to synchronize all (local) consents to NCIS server
   * for example can be used after a user signs-up
   */
  public async updateAllConsents(): Promise<void[]> {
    if (this._authProvider.isAnonymous()) return [];
    const promises = Object.keys(this._state).map((alias) => {
      return this._updateConsentWithLegalService(alias, this._state[alias]!);
    });
    return await Promise.all(promises);
  }

  /**
   * Expose an error subscriber to allow downstream consumers to capture consent exceptions
   * @returns
   */
  public onError(listener: (error: unknown) => void): void {
    this._errorSubscribers.addListener(listener);
  }
}
