// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ParameterlessMessageKeys } from '@wbd/beam-ctv-localization';

/**
 * Enum definition for device type
 * @public
 */
export enum DeviceType {
  /**
   * Set-top box device
   */
  STB = 'stb',
  /**
   * Smart TV device
   */
  TV = 'tv',

  /**
   * Game console devices (xbox / playstation)
   */
  CONSOLE = 'console'
}

/**
 * Enum definition for deeplink content type
 * @public
 */
export enum DeeplinkContentType {
  /**
   * displays content page
   */
  SHOW = 'show',
  /**
   * redirects to content playback
   */
  VIDEO = 'video',
  /**
   * redirects to search page
   */
  SEARCH = 'search',
  /**
   * redirects to a route
   */
  ROUTE = 'route'
}

/**
 * Interface definition for Deeplink Parameters
 * @public
 */
export interface IDeeplinkParams {
  /**
   * deeplink content type
   */
  type: DeeplinkContentType;
  /**
   * content identifier
   */
  id: string;
}

/**
 * Interface definition for paywall cta
 * @public
 */
export interface IPaywallCta {
  /**
   * Title translation key
   */
  title: ParameterlessMessageKeys;
  /**
   * Title analytics
   */
  titleAnalytics: string;
  /**
   * Description translation key
   */
  description: ParameterlessMessageKeys;
  /**
   * Array of a11y label translation key
   */
  accessibleLabel: ParameterlessMessageKeys[];
  /**
   * CTA button id
   */
  id: 'SubscribeButton' | 'ConnectButton' | 'SignInButton';
}

/**
 * Interface definition for paywall data
 * @public
 */
export interface IPaywallData {
  /**
   * Page title translation key
   */
  title: ParameterlessMessageKeys;
  /**
   * Page title a11y translation key
   */
  titleA11y: ParameterlessMessageKeys;
  /**
   * Page description translation key
   */
  description: ParameterlessMessageKeys;
  /**
   * Page description a11y translation key
   */
  descriptionA11y: ParameterlessMessageKeys;
  /**
   * Page CTAs
   */
  ctas: IPaywallCta[];
}
