// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import type { MarketingPageItem } from './MarketingPageItem';
import type { MarketingPageSection } from './MarketingPageSection';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.discomax.com/#subscription-journey-api
 * @public
 */
export interface IMarketingPageAttributes {
  /** The id and template type of the provided page */
  component: {
    id: string;
    template: string;
  };
}

/**
 * @see https://docs.discomax.com/#subscription-journey-api
 * @public
 */
export interface IMarketingPagRelationships {
  /** Ordered list of all call-to-actions that should be presented to the user. */
  ctas?: MarketingPageItem[];
  /** Page item containing the page footer. */
  footer?: MarketingPageItem;
  /** Page item containing the page subtitle. */
  subtitle?: MarketingPageItem;
  /** Ordered list of the sections that make up the page. */
  sections: MarketingPageSection[];
  /** Page item containing the page title. */
  title?: MarketingPageItem;
}

/**
 * @public
 */
export class MarketingPage extends TypedModel.generate<
  DataTypes.MarketingPage,
  IMarketingPageAttributes,
  IMarketingPagRelationships
>() {}
