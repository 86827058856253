// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IDimensions } from '@wbd/player-sdk-core';
import {
  IAudioCodecsOverride,
  IEngineNetworkRequestRetryConfig,
  IVideoCodecsOverride
} from '@wbd/player-sdk-gluon-engine';
import { IScrubberOptions } from './IScrubberOptions';
import { ResolutionConstraint } from './ResolutionConstraint';

/**
 * Default value for *IEngineCreatorConfig* property *supportedAudioCodecs*
 * - Supported audio codecs for the player
 * @public
 */
export const defaultSupportedAudioCodecs: IAudioCodecsOverride = {
  aac: true,
  ac3: false,
  eac3: false,
  atmos: false
};

/**
 * Default value for *IEngineCreatorConfig* property *supportedVideoCodecs*
 * - Supported video codecs for the player
 * @public
 */
export const defaultSupportedVideoCodecs: IVideoCodecsOverride = {
  avc: true,
  dvh: false,
  hevcSdr: false,
  hevcHdr: false
};

/**
 * Default value for *IEngineCreatorConfig* property *maxResolution*
 * - Max resolution for the player
 * @public
 */
export const defaultMaxResolution: IDimensions = ResolutionConstraint.HD_1080P;

/**
 * Default value for *IEngineCreatorConfig* property *networkRequestRetry*
 * - Defines download retry timeouts for segments and manifest
 * @public
 */
export const defaultNetworkRequestRetry: IEngineNetworkRequestRetryConfig = {
  bufferRetryLogic: {
    retryCount: 30,
    timeoutMS: 12000,
    progressTimeoutMS: 8000,
    maxRequestTimeMS: 180000
  }
};

/**
 * Default values for the Player Scrubber.
 * - check IScrubberOptions interface for more details on each property
 * @public
 */
export const defaultScrubberOptions: IScrubberOptions = {
  fixItems: true,
  timeNotifyMS: 33,
  timeRepeatKeyMS: 250,
  timeToKeepNotifyingMS: 100,
  timeRepeatKeyStartLongPressMS: 500,
  items: [
    { afterSeconds: 0, value: 10 },
    { afterSeconds: 1, value: 15 },
    { afterSeconds: 2, value: 20 },
    { afterSeconds: 3, value: 30 },
    { afterSeconds: 4, value: 40 },
    { afterSeconds: 5, value: 60 },
    { afterSeconds: 7, value: 80 }
  ]
};

/**
 * Default value for the video GC interval
 * @public
 */
export const defaultVideoGCInterval: number = 30_000;
