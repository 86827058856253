// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { loadFonts, loadFontsCss } from './loadFonts';

/**
 * Ensure project fonts are loaded
 *
 * @public
 */
export async function loadAllFonts(): Promise<void> {
  await loadFontsCss();
  await loadFonts();
}

export { loadExtraFonts } from './loadFonts';
