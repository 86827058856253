// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import type { MarketingCollection } from './MarketingCollection';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export interface IMarketingCollectionGroupAttributes {
  /** Whether to group the collections grouped by collection title or one after the other. */
  grouped: boolean;
}

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export interface IMarketingCollectionGroupRelationships {
  /** Ordered list of collections inside the collection group. */
  collections: MarketingCollection[];
  /** A copy of the initially-selected collection in the list of collections. */
  initiallySelectedCollection: MarketingCollection;
}

/**
 * @public
 */
export class MarketingCollectionGroup extends TypedModel.generate<
  DataTypes.MarketingCollectionGroup,
  IMarketingCollectionGroupAttributes,
  IMarketingCollectionGroupRelationships
>() {}
