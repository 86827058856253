// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { FormatCode } from '@wbd/localization-core';

/**
 * FormatCodes for the available format packs.
 *
 * @public
 */
export const SUPPORTED_FORMATS: readonly FormatCode[] = [
  'en-US' as FormatCode,
  'es-US' as FormatCode,
  'fr-FR' as FormatCode,
  'pseudo-LOC' as FormatCode,
  'pseudo-LONG' as FormatCode,
  'es-419' as FormatCode
] as const;
