// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { isIetfScriptCode } from '../../localization-enums';
import { isPrimaryLanguageCode, isRegionCode } from '../type-checkers';

import { _ISubtags } from './_ISubtags';

/**
 * Parses an IETF BCP 47 language tag into its subtags (primary language code, script, and region). All but the
 * primary language code are optional.
 *
 * @param ietfLanguageTag - an IETF BCP 47 language tag to be parsed
 * @throws if the provided `ietfLanguageTag` is not a valid IETF BCP 47 language tag with recognized subtag values
 * @returns the subtags of the provided language tag
 *
 * @internal
 *
 */
export function _parseIetfBcp47LanguageTag(ietfLanguageTag: string): _ISubtags {
  const subtags: string[] = ietfLanguageTag.split('-');
  const primaryLanguageCode: string = subtags[0];
  if (!isPrimaryLanguageCode(primaryLanguageCode)) {
    throw new Error(
      `Unrecognized primary language subtag "${primaryLanguageCode}" in IETF BCP 47 language tag "${ietfLanguageTag}"`
    );
  }

  if (subtags.length === 1) {
    return { primaryLanguageSubtag: primaryLanguageCode };
  } else if (subtags.length === 2) {
    const subtag2: string = subtags[1];
    if (isIetfScriptCode(subtag2)) {
      return { primaryLanguageSubtag: primaryLanguageCode, scriptSubtag: subtag2 };
    } else if (isRegionCode(subtag2)) {
      return { primaryLanguageSubtag: primaryLanguageCode, regionSubtag: subtag2 };
    } else {
      throw new Error(
        `Unrecognized script or region subtag "${subtag2}" in IETF BCP 47 language tag "${ietfLanguageTag}"`
      );
    }
  } else if (subtags.length === 3) {
    const ietfScriptCode: string = subtags[1];
    if (!isIetfScriptCode(ietfScriptCode)) {
      throw new Error(
        `Unrecognized script subtag "${ietfScriptCode}" in IETF BCP 47 language tag "${ietfLanguageTag}"`
      );
    }

    const regionCode: string = subtags[2];
    if (!isRegionCode(regionCode)) {
      throw new Error(
        `Unrecognized region subtag "${regionCode}" in IETF BCP 47 language tag "${ietfLanguageTag}"`
      );
    }

    return {
      primaryLanguageSubtag: primaryLanguageCode,
      scriptSubtag: ietfScriptCode,
      regionSubtag: regionCode
    };
  } else {
    throw new Error(`Too many subtags (max 3) in IETF BCP 47 language tag "${ietfLanguageTag}"`);
  }
}
