// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { _parseIetfBcp47LanguageTag } from '../code-parsers';
import { LanguageCode } from '../../nominals';
import { RegionCode } from '../RegionCode';
import { isRegionCode } from './isRegionCode';

/**
 * Determines if the given string is a valid language code (`LanguageCode`).
 * In order to be a `LanguageCode`, the string must:
 *    1) Be a valid IETF BCP 47 language tag, and
 *    2) Have a region subtag.
 *
 * The region subtag can be either an `IsoRegionCode` or a `UnRegionCode`.
 *
 * This is the same formal definition as a `LanguageBasedFormatCode`. The difference between them is conceptual.
 * See `LanguageBasedFormatCode` for details.
 *
 * @param code - a string which may or may not be a valid `LanguageCode`
 *
 * @public
 */
export function isLanguageCode(code: string): code is LanguageCode {
  try {
    const regionCode: RegionCode | undefined = _parseIetfBcp47LanguageTag(code).regionSubtag;
    return regionCode !== undefined && isRegionCode(regionCode);
  } catch (e) {
    return false;
  }
}
