// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { JsonApiResource, KeyValueObject } from 'json-api-models';
import {
  Avatar,
  ContentRestrictionLevels,
  Partner,
  Profile,
  ProfileDetails,
  User,
  UserCustomAttributes,
  UserEntitlementsSummary
} from '../data-models';
import { BoltDataModelCollection, Transformer } from '../data-transformer';
import type { RequestConfigOverride } from '../http-client';
import { DataTypes } from '../json-api';
import {
  createProfilePinRequest,
  createProfileRequest,
  getAvatarsRequest,
  getContentRestrictionLevelsRequest,
  getPartnersRequest,
  getProfilesRequest,
  getSelectedProfileRequest,
  getUserCustomAttributesRequest,
  getUserEntitlementsRequest,
  getUserRequest,
  patchProfileContentRestrictionLevelRequest,
  patchProfileRequest,
  patchUserCustomAttributesRequest,
  patchUserRequest
} from './users.http';

export {
  addFavorite,
  deleteFavorite,
  deleteProfile,
  deleteProfilePin,
  graduateProfile,
  patchExitPin,
  switchProfile,
  verifyExitPin
} from './users.http';

/**
 * Creates a new profile.
 * @param profile - The profile details to create the profile with.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const createProfile = async (
  profile: ProfileDetails,
  config?: RequestConfigOverride
): Promise<Profile> => {
  const sonicProfile = await createProfileRequest(profile, config);
  const store = new Transformer(BoltDataModelCollection, sonicProfile);
  const profileId = sonicProfile.data.id;

  return store.findNoNull(DataTypes.Profile, profileId);
};

/**
 * @deprecated avatars no longer provided as separate service. avatars for selection
 * can be received by calling cms/routes/avatars
 * Returns the avatars.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getAvatars = async (config?: RequestConfigOverride): Promise<Avatar[]> => {
  const sonicAvatars = await getAvatarsRequest(config);
  const store = new Transformer(BoltDataModelCollection, sonicAvatars);

  return store.findAll(DataTypes.Avatar);
};

/**
 * Returns the content restriction levels and the default level.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getContentRestrictionLevels = async (
  config?: RequestConfigOverride
): Promise<ContentRestrictionLevels> => {
  const sonicRestrictionLevels = await getContentRestrictionLevelsRequest(config);
  const store = new Transformer(BoltDataModelCollection, sonicRestrictionLevels);

  const [restrictionLevels] = store.findAll(DataTypes.ContentRestrictionLevels);
  return restrictionLevels;
};

/**
 * Returns the partners associated with the user. A partner is associated with a user either through
 * registering with the GAuth authentication provider or adding a GAuth authentication provider.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getPartners = async (config?: RequestConfigOverride): Promise<Partner[]> => {
  const sonicPartners = await getPartnersRequest(config);
  const store = new Transformer(BoltDataModelCollection, sonicPartners);

  return store.findAll(DataTypes.Partner);
};

/**
 * Returns the user's profiles.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getProfiles = async (config?: RequestConfigOverride): Promise<Profile[]> => {
  const sonicProfiles = await getProfilesRequest(config);
  const store = new Transformer(BoltDataModelCollection, sonicProfiles);

  return store.findAll(DataTypes.Profile);
};

/**
 * Returns the user's selected profile.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getSelectedProfile = async (config?: RequestConfigOverride): Promise<Profile> => {
  const sonicSelectedProfile = await getSelectedProfileRequest(config);
  const store = new Transformer(BoltDataModelCollection, sonicSelectedProfile);
  const selectedProfileId = sonicSelectedProfile.data.id;

  return store.findNoNull(DataTypes.Profile, selectedProfileId);
};

/**
 * Returns the user.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getUser = async (config?: RequestConfigOverride): Promise<User> => {
  const sonicUser = await getUserRequest(config);
  const store = new Transformer(BoltDataModelCollection, sonicUser);

  const [user] = store.findAll(DataTypes.User);
  return user;
};

/**
 * Returns a custom attribute collection by its attributeId.
 * @param attributeId - the attributeId we are fetching information about.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getUserCustomAttributes = async (
  attributeId: string,
  config?: RequestConfigOverride
): Promise<UserCustomAttributes> => {
  const sonicUserCustomAttributes = await getUserCustomAttributesRequest(attributeId, config);
  const store = new Transformer(BoltDataModelCollection, sonicUserCustomAttributes);

  const [customAttributes] = store.findAll(DataTypes.UserCustomAttributes);
  return customAttributes;
};

/**
 * Patches a custom attribute collection by its attributeId
 * @param attributeId - the id of the use
 * @param customAttributeAttributes - the custom attributes of the attributeId to update
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const patchUserCustomAttributes = async (
  attributeId: string,
  customAttributeAttributes: KeyValueObject,
  config?: RequestConfigOverride
): Promise<UserCustomAttributes> => {
  const sonicUserCustomAttributes = await patchUserCustomAttributesRequest(
    attributeId,
    customAttributeAttributes,
    config
  );
  const store = new Transformer(BoltDataModelCollection, sonicUserCustomAttributes);

  const [customAttributes] = store.findAll(DataTypes.UserCustomAttributes);
  return customAttributes;
};

/**
 * Returns a high level summary of the customer entitlements.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getUserEntitlements = async (
  config?: RequestConfigOverride
): Promise<UserEntitlementsSummary> => {
  const sonicEntitlements = await getUserEntitlementsRequest(config);
  const store = new Transformer(BoltDataModelCollection, sonicEntitlements);

  const [entitlements] = store.findAll(DataTypes.UserEntitlementsSummary);
  return entitlements;
};

/**
 * Updates a profile
 * @param profile - the data to update the profile with
 * @param profileId - the ID of the profile to update
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const patchProfile = async (
  profile: Partial<ProfileDetails>,
  profileId: string,
  config?: RequestConfigOverride
): Promise<Profile> => {
  const sonicProfile = await patchProfileRequest(profile, profileId, config);
  const store = new Transformer(BoltDataModelCollection, sonicProfile);
  const patchedProfileId = (sonicProfile.data as JsonApiResource).id;

  return store.findNoNull(DataTypes.Profile, patchedProfileId);
};

/**
 * Updates a profile's content restriction level
 * @param profileId - the ID of the profile to update
 * @param contentRestrictionLevelId - the ID of the restriction level to set
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const patchProfileContentRestrictionLevel = async (
  profileId: string,
  contentRestrictionLevelId: string,
  config?: RequestConfigOverride
): Promise<Profile> => {
  const sonicProfile = await patchProfileContentRestrictionLevelRequest(
    profileId,
    contentRestrictionLevelId,
    config
  );
  const store = new Transformer(BoltDataModelCollection, sonicProfile);
  const patchedProfileId = (sonicProfile.data as JsonApiResource).id;

  return store.findNoNull(DataTypes.Profile, patchedProfileId);
};

/**
 * Adds a profile pin to a given profile
 * @param profileId - the ID of the profile to update
 * @param profilePin - the pin to set for a profile
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const createProfilePin = async (
  profileId: string,
  profilePin: string,
  config?: RequestConfigOverride
): Promise<Profile> => {
  const sonicProfile = await createProfilePinRequest(profileId, profilePin, config);
  const store = new Transformer(BoltDataModelCollection, sonicProfile);
  const patchedProfileId = (sonicProfile.data as JsonApiResource).id;

  return store.findNoNull(DataTypes.Profile, patchedProfileId);
};

/**
 * Updates some attributes like first name, last name selected profile id and newsletter preference of the user
 * @param userId - the ID of the user
 * @param userAttributes - the user attributes to update the user with
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const patchUser = async (
  userId: string,
  userAttributes: Partial<
    Pick<User, 'firstName' | 'lastName' | 'selectedProfileId' | 'newsletterPreference'>
  >,
  config?: RequestConfigOverride
): Promise<User> => {
  const sonicUser = await patchUserRequest(userId, userAttributes, config);
  const store = new Transformer(BoltDataModelCollection, sonicUser);

  const [user] = store.findAll(DataTypes.User);
  return user;
};
