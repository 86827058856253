// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { AuthToken, IAuthTokenProvider, IStorage } from '@wbd/bolt-http';
import { EventWithParams } from '@wbd/light-events';
import { getToken } from '../authentication';
import { AuthTokenParser } from './AuthTokenParser';

/**
 * Default storage key for the auth token
 * @public
 */
export const DEFAULT_AUTH_TOKEN_STORAGE_KEY: string = 'beamAuthToken';

/**
 * Sample AuthToken provider using in-memory storage
 *
 * @public
 */
export class AuthTokenProvider implements IAuthTokenProvider {
  /**
   * in memory storage of the AuthToken
   */
  private _cachedToken?: AuthToken;

  /**
   * custom AuthToken storage provider
   */
  private _tokenStorage?: IStorage;

  /**
   * storage key for AuthToken storage provider
   */
  private _storageKey: string;

  /**
   * internal error subscribers
   */
  protected _errorSubscribers: EventWithParams<[unknown]> = new EventWithParams<[unknown]>();

  public constructor(tokenStorage?: IStorage, storageKey: string = DEFAULT_AUTH_TOKEN_STORAGE_KEY) {
    this._tokenStorage = tokenStorage;
    this._storageKey = storageKey;
  }

  /**
   * Fetches an anonymous token
   *
   * @returns AuthToken
   */
  protected async _getToken(): Promise<AuthToken> {
    return getToken(true);
  }

  /**
   * {@inheritDoc @wbd/bolt-http#IAuthTokenProvider.getTokenAsync}
   */
  public async getTokenAsync(): Promise<AuthToken> {
    // when cache token is empty
    // populate cached token from storage when not set
    if (!this._cachedToken) {
      this._cachedToken = this._tokenStorage?.readSync(this._storageKey) as AuthToken | undefined;
    }

    // when cache token is still empty
    // populate cached token by requesting an anonymous token
    if (!this._cachedToken) {
      this._cachedToken = await this._getToken();
      this._tokenStorage?.writeSync(this._storageKey, this._cachedToken);
    }

    return this._cachedToken;
  }

  /**
   * {@inheritDoc @wbd/bolt-http#IAuthTokenProvider.setTokenAsync}
   */
  public async setTokenAsync(token: AuthToken): Promise<void> {
    this._cachedToken = token;
    this._tokenStorage?.writeSync(this._storageKey, this._cachedToken);
  }

  /**
   * {@inheritDoc @wbd/bolt-http#IAuthTokenProvider.clearTokenAsync}
   */
  public async clearTokenAsync(): Promise<void> {
    this._cachedToken = undefined;
    this._tokenStorage?.removeSync(this._storageKey);
  }

  /**
   * Determine if a user is anonymous (non-auth) based on the token JWT payload
   * @returns
   */
  public isAnonymous(): boolean {
    if (!this._cachedToken) return true;
    try {
      const parsedToken = new AuthTokenParser(this._cachedToken);
      return parsedToken.isAnonymous();
    } catch (error) {
      this._errorSubscribers.fire(error).catch((error) => {
        // fail silently
      });
      return true;
    }
  }

  /**
   * Expose an error subscriber to allow downstream consumers to capture token provider exceptions
   * @returns
   */
  public onError(listener: (error: unknown) => void): void {
    this._errorSubscribers.addListener(listener);
  }
}
