// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IPaginationMeta, Page } from './Page';

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * The Route attributes.
 * @see https://docs.disco-api.com/apidocs/#routes
 * @public
 */
export interface IRouteAttributes {
  /** Whether thr route URL is canonical. */
  canonical?: boolean;
  /** URL to access the Route's target. */
  url?: string;
}

/**
 * Interface combining Route attributes and relationships.
 * @public
 */
export interface IRouteRelationships {
  /** target page from route */
  target?: Page;
}

/**
 * @public
 * Route BoltModel combining attributes and relationships.
 */
export class Route extends TypedModel.generate<
  DataTypes.Route,
  IRouteAttributes,
  IRouteRelationships,
  IPaginationMeta
>() {}
