// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { type IStorage } from '@wbd/beam-dom-extensions';
import { createV4Uuid } from '@wbd/beam-js-extensions';

/**
 * Manager that is responsible for creating and managing its session id and
 * created at time. This is a singleton and expected to only
 * return a single session id and created at time.
 * @public
 */
export class SessionManager {
  private static _callbacks: Set<() => void> = new Set();
  private static _createdAt: number = Date.now();
  private static _maxSessionDuration: number = 60 * 30 * 1000;
  private static _sessionId: string = createV4Uuid();
  private static _sessionExpiration: number = this._createdAt + this._maxSessionDuration;
  private static _storage: IStorage | undefined;
  private static _SESSION_STATE_KEY: string = 'session';

  /**
   * returns the session creation time
   */
  public static get createdAt(): number {
    this._refreshWhenExpired();

    return this._createdAt;
  }

  /**
   * extends the session expiration by the max session duration
   */
  public static extendSession(): void {
    this._sessionExpiration = Date.now() + this._maxSessionDuration;
    this._saveSessionToStorage();
  }

  /**
   * returns the current session id
   */
  public static get sessionId(): string {
    this._refreshWhenExpired();

    return this._sessionId;
  }

  /**
   * checks if the session expiration for a lapsed time
   * @returns boolean true if the session has expired
   */
  public static isSessionExpired(): boolean {
    return !this._sessionId || Date.now() >= this._sessionExpiration;
  }

  /**
   * checks if the session is valid and not expired
   * @param uuid - session id from the storage
   * @param created - created at time from the storage
   * @param expires - expiration time from the storage
   * @returns
   */
  private static _isValidStorageSession(uuid: string, created: number, expires: number): boolean {
    const isValidCreated = Boolean(created) && typeof created === 'number';
    const isValidExpires =
      Boolean(expires) &&
      typeof expires === 'number' &&
      !this.isSessionExpired.apply({
        _sessionId: uuid,
        _sessionExpiration: expires
      });

    return isValidCreated && isValidExpires;
  }

  /**
   * loads the session from storage and updates the session
   * id and created at time if it exists
   */
  private static _loadSessionFromStorage(): void {
    let uuid: string;
    let created: number;
    let expires: number;

    try {
      const sessionStr = SessionManager._storage!.readSync(SessionManager._SESSION_STATE_KEY) ?? '{}';
      ({ created, expires, uuid } = JSON.parse(sessionStr));
    } catch (e) {
      return;
    }

    if (this._isValidStorageSession(uuid, created, expires)) {
      this._updateSession(uuid, created);
    }
  }

  /**
   * event handler for when the session is created
   * @param callback - callback function to be called when the session is created
   */
  public static onSessionCreated(callback: () => unknown): void {
    this._callbacks.add(callback);
  }

  /**
   * refreshes the session if it has expired
   */
  private static _refreshWhenExpired(): void {
    if (this.isSessionExpired()) {
      this._updateSession();
    }
  }

  /**
   * saves the session to storage
   */
  public static _saveSessionToStorage(): void {
    const { _storage } = this;

    if (_storage) {
      _storage.writeSync(
        this._SESSION_STATE_KEY,
        JSON.stringify({
          uuid: this._sessionId,
          created: this._createdAt,
          expires: this._sessionExpiration
        })
      );
    }
  }

  /**
   * Will set the storage to be used for session management and load any
   * existing session data from storage.
   * @param storage - storage to be used for session management
   */
  public static setStorage(storage: Required<IStorage>): void {
    if (!storage || !storage.readSync || !storage.writeSync) {
      throw new Error('SessionManager: IStorage must support readSync and writeSync');
    }

    SessionManager._storage = storage;
    this._loadSessionFromStorage();
  }

  /**
   * updates a new session id and extends the session
   * @param sessionId - optional session id
   * @param createdAt - optional createdAt id
   */
  private static _updateSession(sessionId?: string, createdAt?: number): void {
    this._sessionId = sessionId ?? createV4Uuid();
    this._createdAt = createdAt ?? Date.now();
    this.extendSession();
    this._callbacks.forEach((callback) => callback());
  }
}
