// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * A sequence of asynchronous tasks
 * @public
 */
export interface ISequence<TIn, TOut, TStep extends string> {
  /**
   * The name of the sequence
   */
  title: string;
  /**
   * Append a task to the sequence using the last task output
   */
  pipe: <V, S extends string>(
    stepName: S,
    stepJob: (value: TOut) => Promise<V>
  ) => ISequence<TIn, V, TStep | S>;
  /**
   * Receive the result of the task `stepName`,
   * and optionally return an overriden value
   */
  tap: <V>(stepName: TStep, onStep: (value: V) => V | void) => void;
  /**
   * Start executing the sequence, passing each step's result to the next,
   * until something errors, or until it completes, returning the last step's value
   */
  start: (value: TIn) => Promise<TOut>;
  /**
   * Cancel the sequence which will fail the `start()` Promise.
   * The `SequenceError` will have `reason === undefined` in this case.
   */
  cancel: () => void;
}

/**
 * A sequence without any steps - add the first one!
 * @public
 */
export interface IEmptySequence {
  /**
   * The name of the sequence
   */
  title: string;
  /**
   * Define the first task - this will define the input value of the sequence
   */
  pipe: <TIn, TOut, TStep extends string>(
    stepName: TStep,
    stepJob: (value: TIn) => Promise<TOut>
  ) => ISequence<TIn, TOut, TStep>;
}

type SequenceSteps<S extends string = string> = {
  stepName: S;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stepJob: (value: any) => Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tap: ((value: any) => any)[];
}[];

interface ISequenceAbort {
  cancel: () => void;
  isCancelled: () => boolean;
}

/**
 * Error happening during the sequence execution.
 * @public
 */
export class SequenceError extends Error {
  /**
   * Title of the sequence
   */
  public title: string;
  /**
   * Name of the step executed at the moment of the error
   */
  public step: string;
  /**
   * Error caught (if any)
   */
  public reason: Error | undefined;
  /**
   * Indicates that the sequence was cancelled rather than failed because of an exception
   */
  public wasCancelled: boolean;

  public constructor(title: string, step: string, message: string, reason?: Error) {
    super(message);
    this.title = title;
    this.step = step;
    this.reason = reason;
    this.wasCancelled = reason === undefined;
  }
}

async function runSequence<TIn, TOut>(
  title: string,
  steps: SequenceSteps,
  initValue: TIn,
  abort: ISequenceAbort
): Promise<TOut> {
  // completed?
  if (!steps.length) {
    return initValue as unknown as TOut;
  }
  const { stepName, stepJob, tap } = steps.shift()!;

  // cancelled?
  if (abort.isCancelled()) {
    throw new SequenceError(title, stepName, `Sequence '${title}' aborted`);
  }

  // execute step
  try {
    let nextValue = await stepJob(initValue);
    if (tap.length) {
      tap.forEach((onStep) => {
        const res = onStep(nextValue);
        if (res !== undefined) nextValue = res;
      });
    }
    // next step
    return runSequence(title, steps, nextValue, abort);
  } catch (err) {
    throw new SequenceError(
      title,
      stepName,
      `Sequence '${title}' failed at step '${stepName}' with error: ${err.message}`,
      err
    );
  }
}

function pipeSequence<TInitial, TIn, TOut, TStep extends string>(
  title: string,
  steps: SequenceSteps<TStep>,
  stepName: TStep,
  stepJob: (value: TIn) => Promise<TOut>,
  abort: ISequenceAbort
): ISequence<TInitial, TOut, TStep> {
  steps.push({ stepName, stepJob, tap: [] });
  return {
    title,
    pipe: <V, S extends string>(stepName: S, stepJob: (value: TOut) => Promise<V>) =>
      pipeSequence<TInitial, TOut, V, TStep | S>(title, steps, stepName, stepJob, abort),
    tap: <V>(stepName: TStep, onStep: (value: V) => V | void): void => {
      const step = steps.find((item) => item.stepName === stepName);
      if (!step) throw new Error(`Step '${stepName}' does not exist in sequence '${title}'`);
      step.tap.push(onStep);
    },
    start: (value: TInitial) => runSequence<TInitial, TOut>(title, steps, value, abort),
    cancel: () => abort.cancel()
  };
}

/**
 * Define a sequence of asynchronous tasks
 * @public
 */
export function createSequence(title: string): IEmptySequence {
  let cancelled = false;
  const abort: ISequenceAbort = {
    cancel: () => (cancelled = true),
    isCancelled: () => cancelled
  };
  return {
    title,
    pipe: <TIn, TOut, TStep extends string>(stepName: TStep, stepJob: (value: TIn) => Promise<TOut>) =>
      pipeSequence(title, [], stepName, stepJob, abort)
  };
}
