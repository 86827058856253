// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IStorageObject } from './IStorageObject';

export class InMemoryStorage implements IStorageObject {
  private _storage: Record<string, string> = {};

  /** {@inheritDoc IStorageObject.getItem} */
  public getItem(key: string): string {
    return this._storage[key];
  }
  /** {@inheritDoc IStorageObject.setItem} */
  public setItem(key: string, value: string): void {
    this._storage[key] = value;
  }
  /** {@inheritDoc IStorageObject.removeItem} */
  public removeItem(key: string): void {
    delete this._storage[key];
  }
  /** {@inheritDoc IStorageObject.clear} */
  public clear(): void {
    this._storage = {};
  }
}
