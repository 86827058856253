// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import type { Image } from './Image';
import { TypedModel } from '../json-api';

/**
 * @public
 */

export enum BadgeStyle {
  DEFAULT = 'default',
  SECONDARY = 'secondary'
}

/**
 * @public
 */
export enum BadgeType {
  NEW = 'new',
  PACKAGE = 'package',
  SCHEDULE = 'schedule',
  TAXONOMY = 'taxonomy'
}

/**
 * @public
 */

export interface ISonicBadgeStyle {
  backgroundColor: string;
  borderColor: string;
  fontColor: string;
}

/**
 * @see https://docs.disco-api.com/apidocs/#decorators103
 * @public
 */
export interface IBadgeAttributes {
  /** Accessibility text for TTS. Localization applied to this value.*/
  accessibilityText: string;
  /** Badge background color (e.g. `#FFFFFFFF`). */
  backgroundColor: string;
  /** Badge type to use. */
  badgeType: BadgeType;
  /** Text to display inside the badge. Localization applied to this value. */
  displayText: string;
  /** Badge font color (e.g. `#000000FF`). */
  fontColor: string;
  /** Unique badge ID. */
  id: string;
  /** Badge image. */
  image?: Image;
  /** Badge styles */
  styles: Record<BadgeStyle, ISonicBadgeStyle>;
}

/**
 * @see https://docs.disco-api.com/apidocs/#decorators103
 * @public
 */
export class Badge extends TypedModel.generate<DataTypes.Badge, IBadgeAttributes>() {}
