// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';
import type { Glyph } from './Glyph';
import { Route } from './Route';

/**
 * @see TODO: add link here once documentation is available
 * @public
 */
export interface IEditAttributes {
  /** Edit id. */
  id: string;
  /** Duration of asset. */
  duration?: number;
}

/**
 * @public
 * The edit interface combining the attributes and relationships.
 */
export interface IEditRelationships {
  glyphs?: Glyph[];
  routes?: Route[];
}

/**
 * @public
 */
export class Edit extends TypedModel.generate<DataTypes.Edit, IEditAttributes, IEditRelationships>() {}
