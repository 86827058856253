// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import type { MarketingPageItem } from './MarketingPageItem';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export interface IMarketingPageSectionRelationships {
  //relationships
  /** Page item containing the section footer. */
  footer?: MarketingPageItem;
  /** Ordered list of items making up the section. */
  items: MarketingPageItem[];
  /** Page item containing the section subtitle. */
  subtitle?: MarketingPageItem;
  /** Page item containing the section title. */
  title?: MarketingPageItem;
}

/**
 * @public
 */
export class MarketingPageSection extends TypedModel.generate<
  DataTypes.MarketingPageSection,
  {},
  IMarketingPageSectionRelationships
>() {}
