// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { NumberFormatterOptions } from 'globalize';

/**
 * Defines the options that Globalize accepts for formatting dates.
 *
 * @public
 */
export interface IGlobalizeNumberFormatInfo {
  /**
   * The Globalize number formatter options.
   *
   * For additional documentation on the Globalize number format specifier options,
   * see https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md.
   */
  options?: NumberFormatterOptions;
}

/**
 * The standard number formats supported by this library. This is a union across the formats required by all projects
 * that use this library. If your project requires a format that is not yet defined, feel free to add it.
 *
 * @public
 */
export enum NumberFormat {
  /**
   * Integer numbers: 1, 2, 3, etc.
   */
  INTEGER = 'INTEGER',

  /**
   * Decimal numbers with up to two fractional digits: 1, 3.4, 6.87, etc.
   */
  DECIMAL = 'DECIMAL',

  /**
   * Percentages: 1%, 2%, 3%, etc.
   *
   * Displayed as whole number percentages only (i.e. no fractional digits), with default rounding.
   */
  PERCENTAGE = 'PERCENTAGE',

  /**
   * Fractional percentages: 5%, 1.7%, 2.34%, etc.
   *
   * Displayed as up to two fractional digits (i.e. no fractional digits required), with default rounding.
   */
  FRACTIONAL_PERCENTAGE = 'FRACTIONAL_PERCENTAGE'
}

/**
 * A mapping from `NumberFormats` enum values to the corresponding Globalize number format options objects.
 *
 * @public
 */
export const NUMBER_FORMATS_MAP: Map<NumberFormat, IGlobalizeNumberFormatInfo> = new Map<
  NumberFormat,
  IGlobalizeNumberFormatInfo
>([
  [NumberFormat.INTEGER, { options: { maximumFractionDigits: 0 } }],
  [NumberFormat.DECIMAL, { options: { maximumFractionDigits: 2 } }],
  [NumberFormat.PERCENTAGE, { options: { style: 'percent', maximumFractionDigits: 0 } }],
  [NumberFormat.FRACTIONAL_PERCENTAGE, { options: { style: 'percent', maximumFractionDigits: 2 } }]
]);
