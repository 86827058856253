// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { SupplementaryIetfLanguageCode, SupplementaryIetfLanguageCodeSet } from '../generated';

/**
 * Returns a ReadonlySet containing all of the SupplementaryIetfLanguageCode values.
 *
 * @public
 */
export function getSupplementaryIetfLanguageCodes(): ReadonlySet<SupplementaryIetfLanguageCode> {
  return SupplementaryIetfLanguageCodeSet;
}
