// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { SessionManager } from '@wbd/instrumentation-session-manager';
import { Emitter } from '../core';
import { _EventHierarchy } from '../generated';
import {
  Coordinater,
  GlobalContextManager,
  HeartbeatMonitor,
  PerformanceManager,
  RequestMonitor
} from '../managers';

const { initialize, updateConfiguration } = Coordinater;
const { updateApplication, updateApplicationState, updateDevice, updateGlobalContext } = GlobalContextManager;

/**
 * Instrumentation SDK
 * @public
 */
/* eslint-disable-next-line @rushstack/typedef-var */
export const InstrumentationSDK = {
  ..._EventHierarchy,

  /**
   * There is currently no clean way to do this without having to deal with circular dependencies.
   *
   * Options are
   * - having to manually add every monitor to the EventHierarchy
   * - restructuring generated assets into their own packlets
   *
   * For now, we will utilize EventHierarchy and replace monitor APIS with the actual monitor instances
   */
  Events: {
    addEventListener: Emitter.addEventKindListener.bind(Emitter, 'events'),

    ..._EventHierarchy.Events,

    Application: {
      ..._EventHierarchy.Events.Application,
      Heartbeat: HeartbeatMonitor.Heartbeat
    },

    Request: {
      Measure: RequestMonitor.Measure
    },

    Performance: {
      Measure: PerformanceManager.Measure,
      Mark: PerformanceManager.Mark
    }
  },

  Errors: {
    addEventListener: Emitter.addEventKindListener.bind(Emitter, 'errors'),

    ..._EventHierarchy.Errors
  },

  sessionId: SessionManager.sessionId,

  initialize: initialize.bind(Coordinater),

  updateApplication: updateApplication.bind(GlobalContextManager),

  updateApplicationState: updateApplicationState.bind(GlobalContextManager),

  updateConfiguration: updateConfiguration.bind(Coordinater),

  updateDevice: updateDevice.bind(GlobalContextManager),

  updateGlobalContext: updateGlobalContext.bind(GlobalContextManager)
};

export { InstrumentationSDK as const };
