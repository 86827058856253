// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { createConsoleAdapter, loggerFactory, LogLevel, type ILogger } from '@wbd/beam-ctv-logger';
import type { IPlatformAdapter } from '@wbd/beam-ctv-platform-adapters';
import type {
  ApplicationLaunchStateChangeV1State,
  IApplicationLaunchStateChangeV1Payload,
  IVersionedEvent
} from '@wbd/instrumentation-sdk';
import {
  ApplicationLaunchStateChangeV1,
  InstrumentationSDK,
  PerformanceMarkV1Name
} from '@wbd/instrumentation-sdk';

export function bootstrapInstrumentation(platformAdapter: IPlatformAdapter): void {
  const logLevel = DEBUG ? LogLevel.DEBUG : LogLevel.ERROR;
  const urlLogLevel = platformAdapter.device.getURLParams().get('loglevel');

  loggerFactory.setLevel(urlLogLevel && !isNaN(Number(urlLogLevel)) ? Number(urlLogLevel) : logLevel);

  loggerFactory.attachAdapter(createConsoleAdapter());

  const instrumentationLogger = loggerFactory.createLogger('Instrumentation');
  const errorLogger = loggerFactory.createLogger('Error');

  configureLaunchStateChange(instrumentationLogger);

  InstrumentationSDK.Errors.addEventListener((event) => {
    instrumentationLogger.warn(event.eventType, event.payload);
    const errorStackTrace = (event.payload as { err?: { stackTrace?: string } }).err?.stackTrace ?? '';
    if (errorStackTrace) errorLogger.error(errorStackTrace);
  });

  InstrumentationSDK.Events.addEventListener((event) => {
    instrumentationLogger.debug(event.eventType, event.payload);
  });

  InstrumentationSDK.initialize({});

  InstrumentationSDK.Events.Performance.Mark.createMark({
    name: PerformanceMarkV1Name.BOOT_START,
    page: { uri: '/' }
  });
}

function configureLaunchStateChange(logger: ILogger): void {
  // @ts-ignore
  const isWebkit = typeof document.webkitHidden !== 'undefined';
  const visibilitychangeEventType = isWebkit ? 'webkitvisibilitychange' : 'visibilitychange';
  const emitLaunchStateChange = (state: ApplicationLaunchStateChangeV1State): void => {
    InstrumentationSDK.Events.Application.Launchstatechange.v1.emit({
      state
    });
  };

  const webkitvisibilitychangeListener = (): void => {
    // @ts-ignore
    if (document[isWebkit ? 'webkitHidden' : 'hidden']) {
      emitLaunchStateChange(ApplicationLaunchStateChangeV1.STATE.SUSPENDED);
    } else {
      emitLaunchStateChange(ApplicationLaunchStateChangeV1.STATE.RESUMED);
    }
  };

  InstrumentationSDK.Events.Application.Launchstatechange.v1.addEventListener(
    (event: IVersionedEvent<IApplicationLaunchStateChangeV1Payload>) => {
      if (event.payload.state === ApplicationLaunchStateChangeV1.STATE.COMPLETED) {
        document.removeEventListener(visibilitychangeEventType, webkitvisibilitychangeListener);
      }
    }
  );

  document.addEventListener(visibilitychangeEventType, webkitvisibilitychangeListener, true);

  emitLaunchStateChange(ApplicationLaunchStateChangeV1.STATE.STARTED);

  // Update launchTime
  if (window.launchTime === undefined) {
    logger.warn('launchTime is not defined');
  }
  window.launchTime = Date.now() - window.launchTime;
}
