// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { UnitFormatterOptions } from 'globalize';

/**
 * Defines the options that Globalize accepts for formatting units.
 *
 * @public
 */
export interface IGlobalizeUnitFormatInfo {
  /** The unit to be formatted (e.g. "seconds"). */
  unit: string;

  /** The Globalize unit formatter options. */
  options?: UnitFormatterOptions;
}

/**
 * The standard unit formats supported by this library. This is a union across the formats required by all projects
 * that use this library. If your project requires a format that is not yet defined, feel free to add it.
 *
 * @public
 */
export enum UnitFormat {
  /**
   * A number of days, in long form.
   *
   * @example
   * In `en-US`: "1 day", "3 days".
   * In `fr-FR`:  "1 jour", "3 jours".
   */
  DAY = 'DAY',

  /**
   * A number of days, in short form.
   * Note that in `en-US` this is the same as `UnitFormat.DAY`, but in other regions it can differ.
   *
   * @example
   * Examples in `en-US`: '1 day', '3 days' (same as UnitFormat.DAY)
   * Examples in `fr-FR`: "1 j", "3 j"
   */
  DAY_SHORT = 'DAY_SHORT',

  /**
   * A number of days, in narrow form.
   * Note that in `en-US` this is the same as `UnitFormat.DAY`, but in other regions it can differ.
   *
   * @example
   * Examples in `en-US`: '1d', '3d'
   * Examples in `fr-FR`: "1j", "3j"
   */
  DAY_NARROW = 'DAY_NARROW',

  /**
   * A number of hours, in long form.
   *
   * @example
   * Examples in `en-US`: '1 hour', '3 hours'
   * Examples in `fr-FR`: "1 heure", "3 heures"
   */
  HOUR = 'HOUR',

  /**
   * A number of hours, in short form.
   *
   * @example
   * Examples in `en-US`: '1 hr', '3 hrs'
   * Examples in `fr-FR`: "1 h", "3 h"
   */
  HOUR_SHORT = 'HOUR_SHORT',

  /**
   * A number of hours, in narrow form.
   *
   * @example
   * Examples in `en-US`: '1h', '3h'
   * Examples in `fr-FR`: "1h", "3h"
   */
  HOUR_NARROW = 'HOUR_NARROW',

  /**
   * A number of minutes, in long form.
   *
   * @example
   * Examples in `en-US`: '1 minute', '3 minutes'
   * Examples in `fr-FR`: "1 minute", "3 minutes"
   */
  MINUTE = 'MINUTE',

  /**
   * A number of minutes, in short form.
   *
   * @example
   * Examples in `en-US`: '1 min', '3 min'
   * Examples in `fr-FR`: "1 min", "3 min"
   */
  MINUTE_SHORT = 'MINUTE_SHORT',

  /**
   * A number of minutes, in narrow form.
   *
   * @example
   * Examples in `en-US`: '1m', '3m'
   * Examples in `fr-FR`: "1min", "3min"
   */
  MINUTE_NARROW = 'MINUTE_NARROW',

  /**
   * A number of months, in long form.
   *
   * @example
   * Examples in `en-US`: '1 month', '3 months'
   * Examples in `fr-FR`: "1 mois", "3 mois"
   */
  MONTH = 'MONTH',

  /**
   * A number of months, in short form.
   *
   * @example
   * Examples in `en-US`: '1 mth', '3 mths'
   * Examples in `fr-FR`: "1 m.", "3 m."
   */
  MONTH_SHORT = 'MONTH_SHORT',

  /**
   * A number of months, in narrow form.
   *
   * @example
   * Examples in `en-US`: '1m', '3m'
   * Examples in `fr-FR`: "1m.", "3m."
   */
  MONTH_NARROW = 'MONTH_NARROW',

  /**
   * A number of seconds, in long form.
   *
   * @example
   * Examples in `en-US`: '1 second', '3 seconds'
   * Examples in `fr-FR`: "1 seconde", "3 secondes"
   */
  SECOND = 'SECOND',

  /**
   * A number of seconds, in short form.
   *
   * @example
   * Examples in `en-US`: '1 sec', '3 sec'
   * Examples in `fr-FR`: "1 s", "3 s"
   */
  SECOND_SHORT = 'SECOND_SHORT',

  /**
   * A number of seconds, in narrow form.
   *
   * @example
   * Examples in `en-US`: '1s', '3s'
   * Examples in `fr-FR`: "1s", "3s"
   */
  SECOND_NARROW = 'SECOND_NARROW',

  /**
   * A number of weeks, in long form.
   *
   * @example
   * Examples in `en-US`: '1 week', '3 weeks'
   * Examples in `fr-FR`: "1 semaine", "3 semaines"
   */
  WEEK = 'WEEK',

  /**
   * A number of weeks, in short form.
   *
   * @example
   * Examples in `en-US`: '1 wk', '3 wks'
   * Examples in `fr-FR`: "1 sem.", "3 sem."
   */
  WEEK_SHORT = 'WEEK_SHORT',

  /**
   * A number of weeks, in narrow form.
   *
   * @example
   * Examples in `en-US`: '1w', '3w'
   * Examples in `fr-FR`: "1sem.", "1sem."
   */
  WEEK_NARROW = 'WEEK_NARROW',

  /**
   * A number of years, in long form.
   *
   * @example
   * Examples in `en-US`: '1 year', '3 years'
   * Examples in `fr-FR`: "1 an", "3 ans"
   */
  YEAR = 'YEAR',

  /**
   * A number of years, in short form.
   *
   * @example
   * Examples in `en-US`: '1 yr', '3 yrs'
   * Examples in `fr-FR`: "1 an", "3 ans"
   */
  YEAR_SHORT = 'YEAR_SHORT',

  /**
   * A number of years, in narrow form.
   *
   * @example
   * Examples in `en-US`: '1y', '3y'
   * Examples in `fr-FR`: "1a", "3a"
   */
  YEAR_NARROW = 'YEAR_NARROW'
}

/**
 * A mapping from `UnitFormats` enum values to the corresponding Globalize unit format options objects.
 *
 * @public
 */
export const UNIT_FORMATS_MAP: Map<UnitFormat, IGlobalizeUnitFormatInfo> = new Map<
  UnitFormat,
  IGlobalizeUnitFormatInfo
>([
  [UnitFormat.DAY, { unit: 'day' }],
  [UnitFormat.DAY_SHORT, { unit: 'day', options: { form: 'short' } }],
  [UnitFormat.DAY_NARROW, { unit: 'day', options: { form: 'narrow' } }],
  [UnitFormat.HOUR, { unit: 'hour' }],
  [UnitFormat.HOUR_SHORT, { unit: 'hour', options: { form: 'short' } }],
  [UnitFormat.HOUR_NARROW, { unit: 'hour', options: { form: 'narrow' } }],
  [UnitFormat.MINUTE, { unit: 'minute' }],
  [UnitFormat.MINUTE_SHORT, { unit: 'minute', options: { form: 'short' } }],
  [UnitFormat.MINUTE_NARROW, { unit: 'minute', options: { form: 'narrow' } }],
  [UnitFormat.MONTH, { unit: 'month' }],
  [UnitFormat.MONTH_SHORT, { unit: 'month', options: { form: 'short' } }],
  [UnitFormat.MONTH_NARROW, { unit: 'month', options: { form: 'narrow' } }],
  [UnitFormat.SECOND, { unit: 'second' }],
  [UnitFormat.SECOND_SHORT, { unit: 'second', options: { form: 'short' } }],
  [UnitFormat.SECOND_NARROW, { unit: 'second', options: { form: 'narrow' } }],
  [UnitFormat.WEEK, { unit: 'week' }],
  [UnitFormat.WEEK_SHORT, { unit: 'week', options: { form: 'short' } }],
  [UnitFormat.WEEK_NARROW, { unit: 'week', options: { form: 'narrow' } }],
  [UnitFormat.YEAR, { unit: 'year' }],
  [UnitFormat.YEAR_SHORT, { unit: 'year', options: { form: 'short' } }],
  [UnitFormat.YEAR_NARROW, { unit: 'year', options: { form: 'narrow' } }]
]);
