// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { LanguageCode } from '@wbd/localization-core';

/**
 * Allow url param override of the active language
 * @public
 */
export function getDisplayLanguageFromQueryParam(
  supportedLanguages: LanguageCode[],
  urlParams: URLSearchParams
): LanguageCode | undefined {
  const language = urlParams.get('language');

  if (typeof language !== 'string') {
    return;
  }

  /**
   * For the following case:
   * http://localhost:8080/?language=pseudo-LOC/#profile/profileSelect
   *
   * `language` will be returned as `pseudo-LOC/` so we remove the bar
   */
  const sanitizedLanguage = language.replace('/', '');

  if (supportedLanguages.includes(sanitizedLanguage as LanguageCode)) {
    return sanitizedLanguage as LanguageCode;
  }

  return;
}
