// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * The refresh payload is send over the x-wbd-refresh header
 * by headwaiter to signal client refresh actions
 * @public
 */
export enum RefreshSignal {
  /**
   * User localization has changed (ex. in case of profile switch)
   *
   * The client should take the below action(s):
   * 1. refresh the page the client is currently on; update both content calls and localized UX
   */
  UX = 'ux',
  /**
   * Bootstrap payload has changed (ex. in case of roaming)
   *
   * The client should take the below action(s):
   * 1. refresh bootstrap routing payloads by making a new request to the bootstrap endpoint
   * 2. refresh the page the client is currently on; update both content calls and localized UX
   */
  BOOTSTRAP = 'bootstrap'
}

/**
 * helper to determine if string is of type RefreshSignal
 * @param type - maybe of type RefreshSignal
 * @returns
 * @public
 */
export function isRefreshSignal(type: string): type is RefreshSignal {
  return Object.values(RefreshSignal).includes(type as RefreshSignal);
}
