// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ILogger } from '@wbd/beam-ctv-logger';
import type { IPlatformAdapter } from '@wbd/beam-ctv-platform-adapters';
import type { IUiLaunchV1Err } from '@wbd/instrumentation-sdk';
import {
  generateV1Err,
  InstrumentationSDK,
  UiLaunchV1Classification,
  UiLaunchV1LaunchState,
  UiLaunchV1Scope
} from '@wbd/instrumentation-sdk';
import type { IInitialContext } from '../application';
import { bootstrapGlobalErrors } from './bootstrapGlobalErrors';
import { bootstrapInstrumentation } from './bootstrapInstrumentation';
import { loadUI } from './loadUI';
import { loadCanvasErrorFallback } from './loadUIFallback';
import { setup } from './setup';

/**
 * Application boot config options
 */
export interface IBootstrapAppOptions {
  platformAdapter: IPlatformAdapter;
  logger?: ILogger;
}

/**
 * Helper function to validate if the setup context completed successfully
 * @param context - context as returned from bootstrap 'setup'
 * @returns
 */
export function isValidContext(context: Partial<IInitialContext>): context is IInitialContext {
  return !context.startupError && context.globalError?.totalCount() === 0;
}

/**
 * Bootstrap the BEAM application async
 * @param config - bootstrap configuration
 */
export async function bootstrapAppAsync({ platformAdapter }: IBootstrapAppOptions): Promise<void> {
  // capture global uncaught exceptions
  const globalErrorContext = bootstrapGlobalErrors();

  // initializes instrumentation sdk
  bootstrapInstrumentation(platformAdapter);

  // run init sequence in parallel with loading the main application code
  const [context, { start, startWithError }] = await Promise.all([
    setup(platformAdapter, globalErrorContext),
    loadUI()
  ]);

  try {
    // we can start the application
    if (isValidContext(context)) {
      return await start(context);
    } else {
      // boot sequence failed but we can still try presenting a localized message
      return await startWithError(context);
    }
  } catch (error) {
    InstrumentationSDK.Errors.Ui.Launch.v1.capture({
      err: generateV1Err<IUiLaunchV1Err>(error, {
        scope: UiLaunchV1Scope.USER_FACING,
        classification: UiLaunchV1Classification.UNKNOWN
      }),
      launchState: UiLaunchV1LaunchState.INITIALIZING
    });

    if (!context.startupError) {
      context.startupError = error;
    }
    // failed to start the app
    // pass 'context' with globalizer so we have localized error messaging
    loadCanvasErrorFallback(context);
  }
}
