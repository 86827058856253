// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#subscription-journey-api
 * @public
 */
export interface IArticleBodyRichTextAttributes {
  /** Rich text rendered as plain text without any styling.
   *  Links are written out and inlined with other text (i.e. Legal information
   *  is available here (https://www.discoveryplus.com/legal).) */
  plainText?: string;
  /** Rich text content. */
  richTextContent: object | undefined;
  /** Rich text rendered as HTML.  */
  richTextHtml?: string;
}

/**
 * @public
 */
export class ArticleBodyRichText extends TypedModel.generate<
  DataTypes.ArticleBodyRichText,
  IArticleBodyRichTextAttributes
>() {}
