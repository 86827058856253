// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  CurrencyFormat,
  DateFormat,
  DurationFormat,
  NumberFormat,
  PhoneNumberFormat,
  RelativeTimeFormat,
  UnitFormat,
  _WORD_BASED_DATE_FORMATS
} from '../formats';

import { FormatCode } from '@wbd/localization-core';
import { ICurrencyValue } from '../i18n-types';
import { IFormatter } from './IFormatter';

/**
 * Provides runtime formatting of data to a target format code.
 *
 * Does not provide access to translated strings, formatting of string templates, or interpolation of variables
 * into strings. For that, please see `Translator.ts`.
 * @public
 */
export class HybridLocaleFormatter implements IFormatter {
  private readonly _regionBasedFormatter: IFormatter;
  private readonly _languageBasedFormatter: IFormatter;

  /**
   * Create a `HybridLocaleFormatter` instance which choses between multiple formatters to reduce mixed language
   * in the UI.  Currently uses `regionBasedFormatter` only, but this will change soon.
   *
   * @param regionBasedFormatter - the formatter to use for formats that do not contain words (e.g. numbers, short dates),
   * or that must be formatted according to the region (e.g. currency).
   * @param languageBasedFormatter - the formatter to use for formats that contain words (e.g. units, longer date formats,
   * and relative times).
   */
  public constructor(regionBasedFormatter: IFormatter, languageBasedFormatter: IFormatter) {
    this._regionBasedFormatter = regionBasedFormatter;
    this._languageBasedFormatter = languageBasedFormatter;
  }

  /**
   * Returns the format code for this `HybridLocaleFormatter`.
   *
   * @deprecated going away in a future major version (when we remove the `formatCode` getter from `IFormatter`).
   */
  public get formatCode(): FormatCode {
    return this._regionBasedFormatter.formatCode;
  }

  /**
   * Format a currency value in the general pattern described by `CurrencyFormat`, according to the rules specified
   * by the format code for that general pattern.
   *
   * @param currencyValue - the currency value to be formatted
   * @param currencyFormat - the general way in which the currency value should be formatted; Globalizer will provide
   * the specifics corresponding to the format code
   */
  public formatAsCurrency(currencyValue: ICurrencyValue, currencyFormat: CurrencyFormat): string {
    return this._regionBasedFormatter.formatAsCurrency(currencyValue, currencyFormat);
  }

  /**
   * Format a date in the general pattern described by `DateFormat`, according to the rules specified
   * by the language/format code for that general pattern.
   *
   * To avoid mixed language in the UI, `formatAsDate` will use the `languageBasedFormatter` for formats with words
   * (e.g. month names, days of the week, "AM", "PM", etc.), and the `regionBasedFormatter` for those which only
   * contain numbers (e.g. 12/01/2020).
   *
   * @param date - the date value to be formatted
   * @param dateFormat - the general way in which the date should be formatted; Globalizer will provide
   * the specifics corresponding to the format code
   */
  public formatAsDate(date: Date, dateFormat: DateFormat): string {
    const formatter: IFormatter = _WORD_BASED_DATE_FORMATS.includes(dateFormat)
      ? this._languageBasedFormatter
      : this._regionBasedFormatter;
    return formatter.formatAsDate(date, dateFormat);
  }

  /**
   * Format a duration in the general pattern described by `DurationFormat`, according to the rules specified
   * by the format code for that general pattern.
   *
   * @param duration - the duration value to be formatted
   * @param durationFormat - the general way in which the duration should be formatted; Globalizer will provide
   * the specifics corresponding to the format code
   */
  public formatAsDuration(duration: number, durationFormat: DurationFormat): string {
    return this._regionBasedFormatter.formatAsDuration(duration, durationFormat);
  }

  /**
   * Format a number in the general pattern described by `NumberFormat`, according to the rules specified
   * by the format code for that general pattern.
   *
   * @param number - the number value to be formatted
   * @param numberFormat - the general way in which the number should be formatted; Globalizer will provide
   * the specifics corresponding to the format code
   */
  public formatAsNumber(value: number, numberFormat: NumberFormat): string {
    return this._regionBasedFormatter.formatAsNumber(value, numberFormat);
  }

  /**
   * Format a phone number in the general pattern described by `PhoneNumberFormat`, according to the rules specified
   * by the format code for that general pattern.
   *
   * @param phoneNumber - the phone number value to be formatted
   * @param phoneNumberFormat - the general way in which the phone number should be formatted; Globalizer will provide
   * the specifics corresponding to the format code
   */
  public formatAsPhoneNumber(phoneNumber: string, phoneNumberFormat: PhoneNumberFormat): string {
    return this._regionBasedFormatter.formatAsPhoneNumber(phoneNumber, phoneNumberFormat);
  }

  /**
   * Format a relative time in the general pattern described by `RelativeTimeFormat`, according to the rules specified
   * by the language code for that general pattern.
   *
   * To avoid mixed language in the UI, `formatAsRelativeTime` does not use the format code.  This means that the
   * separators used for large numbers will be those preferred by the language rather than user's region (which is
   * the norm for numbers).  This is a limitation of the current implementation.
   *
   * @param relativeTime - the relativeTime value to be formatted
   * @param relativeTimeFormat - the general way in which the relative time should be formatted; Globalizer will provide
   * the specifics corresponding to the format code
   */
  public formatAsRelativeTime(valueInUnits: number, relativeTimeFormat: RelativeTimeFormat): string {
    return this._languageBasedFormatter.formatAsRelativeTime(valueInUnits, relativeTimeFormat);
  }

  /**
   * Format a unit in the general pattern described by `UnitFormat`, according to the rules specified
   * by the language code for that general pattern.
   *
   * To avoid mixed language in the UI, `formatAsUnit` does not use the format code.  This means that the
   * separators used for large numbers will be those preferred by the language rather than user's region (which is
   * the norm for numbers).  This is a limitation of the current implementation.
   *
   * @param unit - the unit value to be formatted
   * @param unitFormat - the general way in which the unit should be formatted; Globalizer will provide
   * the specifics corresponding to the format code
   */
  public formatAsUnit(value: number, unitFormat: UnitFormat): string {
    return this._languageBasedFormatter.formatAsUnit(value, unitFormat);
  }
}
