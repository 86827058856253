// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Returns a debounced function that when run a will execute the `callback` (param) with any args passed to it after `delay` (param) milliseconds from the call to execute, this function also returns a function which can be used to cancel the debounce
 * Usage
 * -----
 *
 * `function callback(value:string):void {`
 * `  console.log(value);`
 * `}`
 * `const myDebounce = debounce(callback, 500);`
 *
 * to initate the debounce it needs to be executed
 * `const myDebounceCancel = myDebounce(yourArgsForTheCallback);`
 *
 * if you call `myDebounceCancel();` the timer will be cancelled and the callback will NOT be executed
 * other wise the `callback` is executed after 500ms and `console` will log out yourArgsForTheCallback
 *
 * @param callback - function to be executed
 * @param delay - in milliseconds
 * @returns - debounced function - which, in turn, returns a function that will cancel the debounce timer
 * @public
 */
export function debounce<T extends (...args: never[]) => unknown>(
  callback: T,
  delay: number
): (...args: Parameters<T>) => () => void {
  let timeout: number | undefined;

  function cancelTimeout(): void {
    if (typeof timeout === 'number') {
      clearTimeout(timeout);
      timeout = undefined;
    }
  }

  return (...args: Parameters<T>) => {
    cancelTimeout();

    timeout = setTimeout(() => {
      callback(...args);
    }, delay);
    return cancelTimeout;
  };
}
