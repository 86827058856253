// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { BrowserStorage, StorageScope, type IStorage } from '@wbd/beam-dom-extensions';

/* class decorator */
function staticImplements<T>(): <U extends T>(constructor: U) => void {
  return <U extends T>(constructor: U) => {
    // eslint-disable-next-line no-unused-expressions
    constructor;
  };
}

/**
 * Abstract storage implementation
 * @public
 */
@staticImplements<IStorage>()
export class AbstractStorage {
  /**
   * default static instance of persistent browser storage
   */
  protected static _storage: IStorage = new BrowserStorage(StorageScope.PERSISTENT);

  // prohibit class initialization as we only want to use te static functions
  protected constructor() {}

  /**
   * Synchronously takes a key and reads the corresponding value from storage
   * @param key - The key under which the desired value is stored
   * @returns The paired value, or undefined if no value was found
   */
  public static readSync(key: string): string | undefined {
    return this._storage.readSync(key);
  }

  /**
   * Synchronously takes a key and reads the corresponding value from storage
   * @param key - The key under which the desired value is stored
   * @returns The paired value, or undefined if no value was found
   */
  public static readAsync(key: string): Promise<string | undefined> {
    return this._storage.readAsync(key);
  }

  /**
   * Synchronously takes a key/value pair and writes it to storage
   * @param key - The key by which the provided `value` can be accessed at a later time
   * @param value - The value to be stored under `key`
   * @param append - Default to false, if true, the provided value will be appended to any value that is already associated with `key`; if false, the provided value will replace it
   * @returns A resolved promise if the storage operation was successful; a rejected promise with an exception if the storage operation failed
   */
  public static writeSync(key: string, value: string, append?: boolean): void {
    return this._storage.writeSync(key, value, append);
  }

  /**
   * Synchronously takes a key/value pair and writes it to storage
   * @param key - The key by which the provided `value` can be accessed at a later time
   * @param value - The value to be stored under `key`
   * @param append - Default to false, if true, the provided value will be appended to any value that is already associated with `key`; if false, the provided value will replace it
   * @returns A resolved promise if the storage operation was successful; a rejected promise with an exception if the storage operation failed
   */
  public static writeAsync(key: string, value: string, append?: boolean): Promise<void> {
    return this._storage.writeAsync(key, value, append);
  }

  /**
   * Synchronously removes the provided `key`, and any associated value, from storage
   * @param key - The key to be removed (along with its associated value) from storage
   * @returns A `Promise` which will resolve when the key and value have been removed from storage; if the underlying storage mechanism is synchronous, this `Promise` will already be resolved when returned
   */
  public static removeSync(key: string): void {
    return this._storage.removeSync(key);
  }

  /**
   * Synchronously removes the provided `key`, and any associated value, from storage
   * @param key - The key to be removed (along with its associated value) from storage
   * @returns A `Promise` which will resolve when the key and value have been removed from storage; if the underlying storage mechanism is synchronous, this `Promise` will already be resolved when returned
   */
  public static removeAsync(key: string): Promise<void> {
    return this._storage.removeAsync(key);
  }

  /**
   * Asynchronously removes all values from storage
   * @returns A `Promise` which will resolve when the storage has been cleared
   */
  public static clearAsync(): Promise<void> {
    return this._storage.clearAsync();
  }
}
