// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IBootstrapRequestPayload, IBootstrapResponseJson } from './IBootstrapRequest';

import { HttpClient } from '../http-client';
import type { IRequestHeaders } from '@wbd/bolt-http';
import type { RequestConfigOverride } from '../http-client';

/**
 * Fetches the bootstrap client config
 * @param preferredLanguages - ordered list of one (or more) device / OS level preferred BCP 47 language tags (ex. en-US)
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getBootstrap = async (
  preferredLanguages: string[],
  config?: RequestConfigOverride
): Promise<IBootstrapResponseJson> => {
  const http = HttpClient.instance;

  const url = `/session-context/headwaiter/v1/bootstrap`;
  const headers: IRequestHeaders = {};

  const payload: IBootstrapRequestPayload = {};
  try {
    const response = await http.post<IBootstrapResponseJson, IBootstrapRequestPayload>(url, payload, {
      headers,
      signal: config?.signal
    });

    // update bolt-http bootstrap config
    // after a successful bootstrap call
    http.bootstrapConfig?.update(response.data);
    return response.data;
  } catch (error) {
    const restoredConfig = http.bootstrapConfig?.restore();

    if (restoredConfig) {
      return restoredConfig;
    }

    throw error;
  }
};
