// Copyright text placeholder, Warner Bros. Discovery, Inc.

/* eslint-disable @rushstack/packlets/mechanics */
import { IGlobalContextV1 } from '../generated/context';
import { IVersionedEvent } from './IVersionedEvent';

/**
 * The key that queue-able events use to hold the versioned event data
 * @public
 */
/* eslint-disable-next-line @rushstack/typedef-var */
export const VERSIONED_EVENT_KEY = 'event';

/**
 * A fully formed event that is ready to be added to queue
 * @public
 */
export interface IQueueableEvent extends IGlobalContextV1 {
  /**
   * All the instance-level fields that are set for each event
   */
  [VERSIONED_EVENT_KEY]: IVersionedEvent;
}
