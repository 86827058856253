// Copyright text placeholder, Warner Bros. Discovery, Inc.

/* eslint-disable @rushstack/packlets/mechanics */
import { SessionManager } from '@wbd/instrumentation-session-manager';
import {
  GlobalContextV1,
  IGlobalContext,
  IGlobalContextV1,
  IGlobalContextV1Props
} from '../generated/context';

/**
 * The class that will store and manage global context for events
 * @public
 */
export class GlobalContextManager {
  private static _NOT_INITIALIZED_ERROR: string =
    'GlobalContextManager is not initialized or updated with a context';
  private static _globalContextPromise: Promise<void> = new Promise((resolve) => {
    GlobalContextManager._globalContextPromiseResolver = resolve;
  });
  private static _globalContextPromiseResolver: () => void;
  private static _globalContext: GlobalContextV1;

  /**
   * Initializes the GlobalContextManager
   * @param props - the props needed to initialize the GlobalContextManager
   */
  public static initialize(context?: IGlobalContextV1Props): void {
    if (context) {
      this._globalContext = new GlobalContextV1(context);
      this._globalContextPromiseResolver();
    }
  }

  /**
   * Updates the global context
   * @param context - the global context object to set to
   * @throws if the manager has not been initialized
   */
  public static updateGlobalContext(context: IGlobalContext): void {
    this.initialize(context);
  }

  /**
   * Returns the global context
   * @returns the current stored global context
   * @throws if the manager has not been initialized
   */
  public static getGlobalContextAsync(): Promise<IGlobalContextV1> {
    return this._globalContextPromise.then(() => {
      return {
        ...this._globalContext,
        applicationState: {
          ...this._globalContext.applicationState,
          sessionId: SessionManager.sessionId
        }
      };
    });
  }

  /**
   * Console logs an error message
   */
  private static _consoleError(): void {
    console.error(GlobalContextManager._NOT_INITIALIZED_ERROR);
  }

  /**
   * Update the Application state part of the global context
   * @param applicationState - The new application state data to use
   * @throws if the manager has not been initialized
   */
  public static updateApplicationState(applicationState: IGlobalContext['applicationState']): void {
    if (!GlobalContextManager._globalContext) {
      return GlobalContextManager._consoleError();
    }

    this._globalContext = {
      ...this._globalContext,
      applicationState: applicationState
    };
  }

  /**
   * Update the Application part of the global context
   * @param application - the new application data to use
   * @throws if the manager has not been initialized
   */
  public static updateApplication(application: IGlobalContext['application']): void {
    if (!GlobalContextManager._globalContext) {
      return GlobalContextManager._consoleError();
    }

    this._globalContext = {
      ...this._globalContext,
      application: application
    };
  }

  /**
   * Update the device part of the global context
   * @param device - the new device data to use
   * @throws if the manager has not been initialized
   */
  public static updateDevice(device: IGlobalContext['device']): void {
    if (!GlobalContextManager._globalContext) {
      return GlobalContextManager._consoleError();
    }

    this._globalContext = {
      ...this._globalContext,
      device: device
    };
  }
}
