// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { CanonicalizedCldrLocale } from '../localization-enums';
import { asLanguageCode } from './type-decorators';

/**
 * Convenience helper which provides friendly names for supported or soon-to-be-supported
 * @see LanguageCode values.
 *
 * @public
 */
// eslint-disable-next-line @rushstack/typedef-var
export const LanguageCodes = {
  US_ENGLISH: asLanguageCode(CanonicalizedCldrLocale.EN_US),

  // LATAM
  LATAM_SPANISH: asLanguageCode(CanonicalizedCldrLocale.ES_419),
  BRAZIL_PORTUGUESE: asLanguageCode(CanonicalizedCldrLocale.PT_BR),

  // EURO WAVE 1
  CASTILIAN_SPANISH: asLanguageCode(CanonicalizedCldrLocale.ES_ES),
  DANISH: asLanguageCode(CanonicalizedCldrLocale.DA_DK),
  FINNISH: asLanguageCode(CanonicalizedCldrLocale.FI_FI),
  NORWEGIAN: asLanguageCode(CanonicalizedCldrLocale.NB_NO),
  SWEDISH: asLanguageCode(CanonicalizedCldrLocale.SV_SE),

  // EURO WAVE 2
  ALBANIAN: asLanguageCode(CanonicalizedCldrLocale.SQ_AL),
  BULGARIAN: asLanguageCode(CanonicalizedCldrLocale.BG_BG),
  CROATIAN: asLanguageCode(CanonicalizedCldrLocale.HR_HR),
  CZECH: asLanguageCode(CanonicalizedCldrLocale.CS_CZ),
  EUROPEAN_PORTUGUESE: asLanguageCode(CanonicalizedCldrLocale.PT_PT),
  HUNGARIAN: asLanguageCode(CanonicalizedCldrLocale.HU_HU),
  MACEDONIAN: asLanguageCode(CanonicalizedCldrLocale.MK_MK),
  POLISH: asLanguageCode(CanonicalizedCldrLocale.PL_PL),
  ROMANIAN: asLanguageCode(CanonicalizedCldrLocale.RO_RO),
  SERBIAN: asLanguageCode(CanonicalizedCldrLocale.SR_LATN_RS),
  SLOVAKIAN: asLanguageCode(CanonicalizedCldrLocale.SK_SK),
  SLOVENE: asLanguageCode(CanonicalizedCldrLocale.SL_SI),

  // Euro Wave 2.5 (Baltics/Iceland/Netherlands)
  ESTONIAN: asLanguageCode(CanonicalizedCldrLocale.ET_EE),
  LATVIAN: asLanguageCode(CanonicalizedCldrLocale.LV_LV),
  LITHUANIAN: asLanguageCode(CanonicalizedCldrLocale.LT_LT),
  RUSSIAN: asLanguageCode(CanonicalizedCldrLocale.RU_RU),
  DUTCH: asLanguageCode(CanonicalizedCldrLocale.NL_NL),
  ICELANDIC: asLanguageCode(CanonicalizedCldrLocale.IS_IS),

  // NEXT
  TURKISH: asLanguageCode(CanonicalizedCldrLocale.TR_TR),
  GREEK: asLanguageCode(CanonicalizedCldrLocale.EL_GR)
} as const;
