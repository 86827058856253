// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { GlobalizationContext, Globalizer } from '@wbd/localization';
import { FormatCode, LanguageCode, selectLanguage } from '@wbd/localization-core';
// eslint-disable-next-line @rushstack/packlets/mechanics
import { loadFormatterAsync, loadTranslatorAsync } from '../../codegen/resources/ResourceLoaders';
import { CLIENT_ENABLED_LANGUAGE_CODES } from './ClientEnabledLanguageCodes';
import { getDisplayLanguageFromQueryParam } from './getDisplayLanguageFromQueryParam';
import type { IBeamCTVGlobalizer } from './IBeamCTVGlobalizer';

/**
 * Create a globalizer for a specific language/format
 * @public
 */
export async function createGlobalizer(
  wantLanguageCodes: LanguageCode[],
  formatCode?: FormatCode,
  clientEnabledLanguages: LanguageCode[] = CLIENT_ENABLED_LANGUAGE_CODES
): Promise<IBeamCTVGlobalizer> {
  const languageCodeOverride = getDisplayLanguageFromQueryParam(
    clientEnabledLanguages,
    new URLSearchParams(location.search)
  );
  const languageCode = languageCodeOverride ?? selectLanguage(wantLanguageCodes, clientEnabledLanguages);

  const context = new GlobalizationContext(
    languageCode,
    formatCode ?? (languageCode as string as FormatCode)
  );
  // lazy load the new locales and hydrate a new Globalizer instance
  return Globalizer.loadGlobalizerAsync(context, loadTranslatorAsync, loadFormatterAsync);
}
