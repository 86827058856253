// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  IPerformanceMeasureV1EndMark,
  IPerformanceMeasureV1StartMark,
  PerformanceMarkV1Name,
  PerformanceMeasureV1,
  PerformanceMeasureV1EndMarkName,
  PerformanceMeasureV1Name,
  PerformanceMeasureV1StartMarkName
} from '../../generated';
import { MarkManager } from './MarkManager';

/**
 * The measure monitor class used create measures
 * @public
 */
export class MeasureManager {
  private _v1: PerformanceMeasureV1 = new PerformanceMeasureV1();
  private _markManager: MarkManager;

  public constructor(markManager: MarkManager) {
    this._markManager = markManager;
  }

  public createMeasure(
    name: PerformanceMeasureV1Name,
    startMarkName: PerformanceMeasureV1StartMarkName,
    endMarkName?: PerformanceMeasureV1EndMarkName
  ): void {
    const now = Date.now();
    const [storedStartMark, storedEndMark] = [
      PerformanceMarkV1Name[startMarkName],
      endMarkName ? PerformanceMarkV1Name[endMarkName] : undefined
    ].map((markName) => {
      return markName ? this._markManager.getMark(markName) : undefined;
    });

    /**
     * NO START MARK
     * Start mark is required and without it we have no measurement
     */
    if (!storedStartMark) {
      return;
    }

    const { deviceOccuredAt: deviceStartedAt, ...startMark } = storedStartMark;
    const { deviceOccuredAt: deviceEndedAt, ...endMark } = storedEndMark ?? { deviceOccuredAt: now };

    this._v1.emit({
      deviceEndedAt,
      deviceStartedAt,
      duration: deviceEndedAt - deviceStartedAt,
      name,
      /**
       * start and end mark must be cast to unknown because
       * name enums for marks are not compatible with the name enums for measures
       */
      startMark: startMark as unknown as IPerformanceMeasureV1StartMark,
      endMark: storedEndMark ? (endMark as unknown as IPerformanceMeasureV1EndMark) : undefined
    });
  }
}
