// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { getIsoRegionCodes, getUnRegionCodes } from '../../localization-enums';
import { RegionCode } from '../RegionCode';

let regionCodes: ReadonlySet<RegionCode>;

/**
 * Returns a ReadonlySet containing all of the RegionCode values.
 *
 * @public
 */
export function getRegionCodes(): ReadonlySet<RegionCode> {
  if (regionCodes === undefined) {
    regionCodes = new Set([...getIsoRegionCodes(), ...getUnRegionCodes()]);
  }
  return regionCodes;
}
