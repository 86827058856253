// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { CanonicalizedCldrLocale } from './CanonicalizedCldrLocale';
import { CldrLocale } from './CldrLocale';

/**
 * A mapping from `CanonicalizedCldrLocale` enum values to the corresponding `CldrLocale` enum values.
 *
 * See `CanonicalizedCldrLocale` and `CldrLocale` for a detailed explanation.
 *
 * To ensure the lowest client-library payload size this mapping only includes the net
 * differences between the CanonicalizedCldrLocale and CldrLocale tables
 *
 * This file was generated by `localization-enum-generator`. Please edit the template there to make any desired
 * changes. Changes made directly to this file will be overwritten without warning when this file is re-generated.
 *
 * @public
 */
export const CanonicalizedCldrLocalesToCldrLocales: Map<CanonicalizedCldrLocale, CldrLocale> = new Map([
  [CanonicalizedCldrLocale.AF_ZA, CldrLocale.AF],
  [CanonicalizedCldrLocale.AGQ_CM, CldrLocale.AGQ],
  [CanonicalizedCldrLocale.AK_GH, CldrLocale.AK],
  [CanonicalizedCldrLocale.AM_ET, CldrLocale.AM],
  [CanonicalizedCldrLocale.AS_IN, CldrLocale.AS],
  [CanonicalizedCldrLocale.ASA_TZ, CldrLocale.ASA],
  [CanonicalizedCldrLocale.AST_ES, CldrLocale.AST],
  [CanonicalizedCldrLocale.AZ_AZ, CldrLocale.AZ_LATN],
  [CanonicalizedCldrLocale.BAS_CM, CldrLocale.BAS],
  [CanonicalizedCldrLocale.BE_BY, CldrLocale.BE],
  [CanonicalizedCldrLocale.BEM_ZM, CldrLocale.BEM],
  [CanonicalizedCldrLocale.BEZ_TZ, CldrLocale.BEZ],
  [CanonicalizedCldrLocale.BG_BG, CldrLocale.BG],
  [CanonicalizedCldrLocale.BM_ML, CldrLocale.BM],
  [CanonicalizedCldrLocale.BN_BD, CldrLocale.BN],
  [CanonicalizedCldrLocale.BO_CN, CldrLocale.BO],
  [CanonicalizedCldrLocale.BR_FR, CldrLocale.BR],
  [CanonicalizedCldrLocale.BRX_IN, CldrLocale.BRX],
  [CanonicalizedCldrLocale.BS_BA, CldrLocale.BS_LATN],
  [CanonicalizedCldrLocale.CA_ES, CldrLocale.CA],
  [CanonicalizedCldrLocale.CCP_BD, CldrLocale.CCP],
  [CanonicalizedCldrLocale.CE_RU, CldrLocale.CE],
  [CanonicalizedCldrLocale.CEB_PH, CldrLocale.CEB],
  [CanonicalizedCldrLocale.CGG_UG, CldrLocale.CGG],
  [CanonicalizedCldrLocale.CHR_US, CldrLocale.CHR],
  [CanonicalizedCldrLocale.CKB_IQ, CldrLocale.CKB],
  [CanonicalizedCldrLocale.CS_CZ, CldrLocale.CS],
  [CanonicalizedCldrLocale.CU_RU, CldrLocale.CU],
  [CanonicalizedCldrLocale.CY_GB, CldrLocale.CY],
  [CanonicalizedCldrLocale.DA_DK, CldrLocale.DA],
  [CanonicalizedCldrLocale.DAV_KE, CldrLocale.DAV],
  [CanonicalizedCldrLocale.DE_DE, CldrLocale.DE],
  [CanonicalizedCldrLocale.DJE_NE, CldrLocale.DJE],
  [CanonicalizedCldrLocale.DSB_DE, CldrLocale.DSB],
  [CanonicalizedCldrLocale.DUA_CM, CldrLocale.DUA],
  [CanonicalizedCldrLocale.DYO_SN, CldrLocale.DYO],
  [CanonicalizedCldrLocale.DZ_BT, CldrLocale.DZ],
  [CanonicalizedCldrLocale.EBU_KE, CldrLocale.EBU],
  [CanonicalizedCldrLocale.EE_GH, CldrLocale.EE],
  [CanonicalizedCldrLocale.EL_GR, CldrLocale.EL],
  [CanonicalizedCldrLocale.EN_US, CldrLocale.EN],
  [CanonicalizedCldrLocale.EO_001, CldrLocale.EO],
  [CanonicalizedCldrLocale.ES_ES, CldrLocale.ES],
  [CanonicalizedCldrLocale.ET_EE, CldrLocale.ET],
  [CanonicalizedCldrLocale.EU_ES, CldrLocale.EU],
  [CanonicalizedCldrLocale.EWO_CM, CldrLocale.EWO],
  [CanonicalizedCldrLocale.FA_IR, CldrLocale.FA],
  [CanonicalizedCldrLocale.FF_SN, CldrLocale.FF_LATN],
  [CanonicalizedCldrLocale.FI_FI, CldrLocale.FI],
  [CanonicalizedCldrLocale.FIL_PH, CldrLocale.FIL],
  [CanonicalizedCldrLocale.FO_FO, CldrLocale.FO],
  [CanonicalizedCldrLocale.FR_FR, CldrLocale.FR],
  [CanonicalizedCldrLocale.FUR_IT, CldrLocale.FUR],
  [CanonicalizedCldrLocale.FY_NL, CldrLocale.FY],
  [CanonicalizedCldrLocale.GA_IE, CldrLocale.GA],
  [CanonicalizedCldrLocale.GD_GB, CldrLocale.GD],
  [CanonicalizedCldrLocale.GL_ES, CldrLocale.GL],
  [CanonicalizedCldrLocale.GSW_CH, CldrLocale.GSW],
  [CanonicalizedCldrLocale.GU_IN, CldrLocale.GU],
  [CanonicalizedCldrLocale.GUZ_KE, CldrLocale.GUZ],
  [CanonicalizedCldrLocale.GV_IM, CldrLocale.GV],
  [CanonicalizedCldrLocale.HA_NG, CldrLocale.HA],
  [CanonicalizedCldrLocale.HAW_US, CldrLocale.HAW],
  [CanonicalizedCldrLocale.HE_IL, CldrLocale.HE],
  [CanonicalizedCldrLocale.HI_IN, CldrLocale.HI],
  [CanonicalizedCldrLocale.HR_HR, CldrLocale.HR],
  [CanonicalizedCldrLocale.HSB_DE, CldrLocale.HSB],
  [CanonicalizedCldrLocale.HU_HU, CldrLocale.HU],
  [CanonicalizedCldrLocale.HY_AM, CldrLocale.HY],
  [CanonicalizedCldrLocale.IA_001, CldrLocale.IA],
  [CanonicalizedCldrLocale.ID_ID, CldrLocale.ID],
  [CanonicalizedCldrLocale.IG_NG, CldrLocale.IG],
  [CanonicalizedCldrLocale.II_CN, CldrLocale.II],
  [CanonicalizedCldrLocale.IS_IS, CldrLocale.IS],
  [CanonicalizedCldrLocale.IT_IT, CldrLocale.IT],
  [CanonicalizedCldrLocale.JA_JP, CldrLocale.JA],
  [CanonicalizedCldrLocale.JGO_CM, CldrLocale.JGO],
  [CanonicalizedCldrLocale.JMC_TZ, CldrLocale.JMC],
  [CanonicalizedCldrLocale.JV_ID, CldrLocale.JV],
  [CanonicalizedCldrLocale.KA_GE, CldrLocale.KA],
  [CanonicalizedCldrLocale.KAB_DZ, CldrLocale.KAB],
  [CanonicalizedCldrLocale.KAM_KE, CldrLocale.KAM],
  [CanonicalizedCldrLocale.KDE_TZ, CldrLocale.KDE],
  [CanonicalizedCldrLocale.KEA_CV, CldrLocale.KEA],
  [CanonicalizedCldrLocale.KHQ_ML, CldrLocale.KHQ],
  [CanonicalizedCldrLocale.KI_KE, CldrLocale.KI],
  [CanonicalizedCldrLocale.KK_KZ, CldrLocale.KK],
  [CanonicalizedCldrLocale.KKJ_CM, CldrLocale.KKJ],
  [CanonicalizedCldrLocale.KL_GL, CldrLocale.KL],
  [CanonicalizedCldrLocale.KLN_KE, CldrLocale.KLN],
  [CanonicalizedCldrLocale.KM_KH, CldrLocale.KM],
  [CanonicalizedCldrLocale.KN_IN, CldrLocale.KN],
  [CanonicalizedCldrLocale.KO_KR, CldrLocale.KO],
  [CanonicalizedCldrLocale.KOK_IN, CldrLocale.KOK],
  [CanonicalizedCldrLocale.KS_IN, CldrLocale.KS],
  [CanonicalizedCldrLocale.KSB_TZ, CldrLocale.KSB],
  [CanonicalizedCldrLocale.KSF_CM, CldrLocale.KSF],
  [CanonicalizedCldrLocale.KSH_DE, CldrLocale.KSH],
  [CanonicalizedCldrLocale.KU_TR, CldrLocale.KU],
  [CanonicalizedCldrLocale.KW_GB, CldrLocale.KW],
  [CanonicalizedCldrLocale.KY_KG, CldrLocale.KY],
  [CanonicalizedCldrLocale.LAG_TZ, CldrLocale.LAG],
  [CanonicalizedCldrLocale.LB_LU, CldrLocale.LB],
  [CanonicalizedCldrLocale.LG_UG, CldrLocale.LG],
  [CanonicalizedCldrLocale.LKT_US, CldrLocale.LKT],
  [CanonicalizedCldrLocale.LN_CD, CldrLocale.LN],
  [CanonicalizedCldrLocale.LO_LA, CldrLocale.LO],
  [CanonicalizedCldrLocale.LRC_IR, CldrLocale.LRC],
  [CanonicalizedCldrLocale.LT_LT, CldrLocale.LT],
  [CanonicalizedCldrLocale.LU_CD, CldrLocale.LU],
  [CanonicalizedCldrLocale.LUO_KE, CldrLocale.LUO],
  [CanonicalizedCldrLocale.LUY_KE, CldrLocale.LUY],
  [CanonicalizedCldrLocale.LV_LV, CldrLocale.LV],
  [CanonicalizedCldrLocale.MAS_KE, CldrLocale.MAS],
  [CanonicalizedCldrLocale.MER_KE, CldrLocale.MER],
  [CanonicalizedCldrLocale.MFE_MU, CldrLocale.MFE],
  [CanonicalizedCldrLocale.MG_MG, CldrLocale.MG],
  [CanonicalizedCldrLocale.MGH_MZ, CldrLocale.MGH],
  [CanonicalizedCldrLocale.MGO_CM, CldrLocale.MGO],
  [CanonicalizedCldrLocale.MI_NZ, CldrLocale.MI],
  [CanonicalizedCldrLocale.MK_MK, CldrLocale.MK],
  [CanonicalizedCldrLocale.ML_IN, CldrLocale.ML],
  [CanonicalizedCldrLocale.MN_MN, CldrLocale.MN],
  [CanonicalizedCldrLocale.MR_IN, CldrLocale.MR],
  [CanonicalizedCldrLocale.MS_MY, CldrLocale.MS],
  [CanonicalizedCldrLocale.MT_MT, CldrLocale.MT],
  [CanonicalizedCldrLocale.MUA_CM, CldrLocale.MUA],
  [CanonicalizedCldrLocale.MY_MM, CldrLocale.MY],
  [CanonicalizedCldrLocale.MZN_IR, CldrLocale.MZN],
  [CanonicalizedCldrLocale.NAQ_NA, CldrLocale.NAQ],
  [CanonicalizedCldrLocale.NB_NO, CldrLocale.NB],
  [CanonicalizedCldrLocale.ND_ZW, CldrLocale.ND],
  [CanonicalizedCldrLocale.NDS_DE, CldrLocale.NDS],
  [CanonicalizedCldrLocale.NE_NP, CldrLocale.NE],
  [CanonicalizedCldrLocale.NL_NL, CldrLocale.NL],
  [CanonicalizedCldrLocale.NMG_CM, CldrLocale.NMG],
  [CanonicalizedCldrLocale.NN_NO, CldrLocale.NN],
  [CanonicalizedCldrLocale.NNH_CM, CldrLocale.NNH],
  [CanonicalizedCldrLocale.NUS_SS, CldrLocale.NUS],
  [CanonicalizedCldrLocale.NYN_UG, CldrLocale.NYN],
  [CanonicalizedCldrLocale.OM_ET, CldrLocale.OM],
  [CanonicalizedCldrLocale.OR_IN, CldrLocale.OR],
  [CanonicalizedCldrLocale.OS_GE, CldrLocale.OS],
  [CanonicalizedCldrLocale.PA_IN, CldrLocale.PA_GURU],
  [CanonicalizedCldrLocale.PA_PK, CldrLocale.PA_ARAB],
  [CanonicalizedCldrLocale.PL_PL, CldrLocale.PL],
  [CanonicalizedCldrLocale.PRG_001, CldrLocale.PRG],
  [CanonicalizedCldrLocale.PS_AF, CldrLocale.PS],
  [CanonicalizedCldrLocale.PSEUDO_LOC, CldrLocale.EN_001],
  [CanonicalizedCldrLocale.PSEUDO_LONG, CldrLocale.EN_150],
  [CanonicalizedCldrLocale.PT_BR, CldrLocale.PT],
  [CanonicalizedCldrLocale.QU_PE, CldrLocale.QU],
  [CanonicalizedCldrLocale.RM_CH, CldrLocale.RM],
  [CanonicalizedCldrLocale.RN_BI, CldrLocale.RN],
  [CanonicalizedCldrLocale.RO_RO, CldrLocale.RO],
  [CanonicalizedCldrLocale.ROF_TZ, CldrLocale.ROF],
  [CanonicalizedCldrLocale.RU_RU, CldrLocale.RU],
  [CanonicalizedCldrLocale.RW_RW, CldrLocale.RW],
  [CanonicalizedCldrLocale.RWK_TZ, CldrLocale.RWK],
  [CanonicalizedCldrLocale.SAH_RU, CldrLocale.SAH],
  [CanonicalizedCldrLocale.SAQ_KE, CldrLocale.SAQ],
  [CanonicalizedCldrLocale.SBP_TZ, CldrLocale.SBP],
  [CanonicalizedCldrLocale.SD_PK, CldrLocale.SD],
  [CanonicalizedCldrLocale.SE_NO, CldrLocale.SE],
  [CanonicalizedCldrLocale.SEH_MZ, CldrLocale.SEH],
  [CanonicalizedCldrLocale.SES_ML, CldrLocale.SES],
  [CanonicalizedCldrLocale.SG_CF, CldrLocale.SG],
  [CanonicalizedCldrLocale.SHI_MA, CldrLocale.SHI_TFNG],
  [CanonicalizedCldrLocale.SI_LK, CldrLocale.SI],
  [CanonicalizedCldrLocale.SK_SK, CldrLocale.SK],
  [CanonicalizedCldrLocale.SL_SI, CldrLocale.SL],
  [CanonicalizedCldrLocale.SMN_FI, CldrLocale.SMN],
  [CanonicalizedCldrLocale.SN_ZW, CldrLocale.SN],
  [CanonicalizedCldrLocale.SO_SO, CldrLocale.SO],
  [CanonicalizedCldrLocale.SQ_AL, CldrLocale.SQ],
  [CanonicalizedCldrLocale.SR_LATN_RS, CldrLocale.SR_LATN],
  [CanonicalizedCldrLocale.SV_SE, CldrLocale.SV],
  [CanonicalizedCldrLocale.SW_TZ, CldrLocale.SW],
  [CanonicalizedCldrLocale.TA_IN, CldrLocale.TA],
  [CanonicalizedCldrLocale.TE_IN, CldrLocale.TE],
  [CanonicalizedCldrLocale.TEO_UG, CldrLocale.TEO],
  [CanonicalizedCldrLocale.TG_TJ, CldrLocale.TG],
  [CanonicalizedCldrLocale.TH_TH, CldrLocale.TH],
  [CanonicalizedCldrLocale.TI_ET, CldrLocale.TI],
  [CanonicalizedCldrLocale.TK_TM, CldrLocale.TK],
  [CanonicalizedCldrLocale.TO_TO, CldrLocale.TO],
  [CanonicalizedCldrLocale.TR_TR, CldrLocale.TR],
  [CanonicalizedCldrLocale.TT_RU, CldrLocale.TT],
  [CanonicalizedCldrLocale.TWQ_NE, CldrLocale.TWQ],
  [CanonicalizedCldrLocale.TZM_MA, CldrLocale.TZM],
  [CanonicalizedCldrLocale.UG_CN, CldrLocale.UG],
  [CanonicalizedCldrLocale.UK_UA, CldrLocale.UK],
  [CanonicalizedCldrLocale.UR_PK, CldrLocale.UR],
  [CanonicalizedCldrLocale.UZ_AF, CldrLocale.UZ_ARAB],
  [CanonicalizedCldrLocale.UZ_UZ, CldrLocale.UZ_LATN],
  [CanonicalizedCldrLocale.VAI_LR, CldrLocale.VAI_VAII],
  [CanonicalizedCldrLocale.VI_VN, CldrLocale.VI],
  [CanonicalizedCldrLocale.VO_001, CldrLocale.VO],
  [CanonicalizedCldrLocale.VUN_TZ, CldrLocale.VUN],
  [CanonicalizedCldrLocale.WAE_CH, CldrLocale.WAE],
  [CanonicalizedCldrLocale.WO_SN, CldrLocale.WO],
  [CanonicalizedCldrLocale.XH_ZA, CldrLocale.XH],
  [CanonicalizedCldrLocale.XOG_UG, CldrLocale.XOG],
  [CanonicalizedCldrLocale.YAV_CM, CldrLocale.YAV],
  [CanonicalizedCldrLocale.YI_001, CldrLocale.YI],
  [CanonicalizedCldrLocale.YO_NG, CldrLocale.YO],
  [CanonicalizedCldrLocale.YUE_CN, CldrLocale.YUE_HANS],
  [CanonicalizedCldrLocale.YUE_HK, CldrLocale.YUE_HANT],
  [CanonicalizedCldrLocale.ZGH_MA, CldrLocale.ZGH],
  [CanonicalizedCldrLocale.ZH_CN, CldrLocale.ZH_HANS],
  [CanonicalizedCldrLocale.ZH_TW, CldrLocale.ZH_HANT],
  [CanonicalizedCldrLocale.ZU_ZA, CldrLocale.ZU]
]);
