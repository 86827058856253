// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { type JsonApiResource } from 'json-api-models';
import { Transformer } from './Transformer';

/**
 * JSON API transformer filter
 *
 * @public
 */
export class JsonApiFilter {
  public imageTypesAllowList: Set<string>;

  public constructor(imageTypesAllowList: Set<string>) {
    this.imageTypesAllowList = imageTypesAllowList;
  }

  /**
   * Determines whether a JSON API resource should be ignored
   */
  public excludes<Type extends string>(data: JsonApiResource<Type>): boolean {
    const { type } = data;
    return type === 'image' && !this.imageTypesAllowList.has(data.attributes?.kind);
  }
}

/**
 * Set up or clears a JSON API transformer filter
 *
 * @public
 */
export function registerJsonApiFilter(options?: { imageTypesAllowList: Set<string> }): void {
  Transformer.filter = options ? new JsonApiFilter(options.imageTypesAllowList) : undefined;
}
