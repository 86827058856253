// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#list-terms-the-user-have-to-accept
 * @public
 */
export interface ITermKindAttributes {
  /** a term kind name */
  name?: string;
  /** a term kind alias */
  alias?: string;
}

/**
 * @public
 */
export class TermKind extends TypedModel.generate<DataTypes.TermKind, ITermKindAttributes>() {}
