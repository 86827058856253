// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';
import { Term } from './Term';

/**
 * consent options
 * @public
 */
export interface IConsentOption {
  /** Consent option alias. */
  alias: string;
  /** Consent approval state. */
  approved: boolean;
}

/**
 * @see https://docs.disco-api.com/apidocs/#update-user-consents
 * @public
 */
export interface IConsentAttributes {
  /** date the consent was approved */
  consentDate: string;
  /** {@inheritDoc IConsentOption} */
  consentOptions: IConsentOption[];
}

/**
 * @see https://docs.disco-api.com/apidocs/#update-user-consents
 * @public
 */
export interface IConsentRelationships {
  term?: Term;
}

/**
 * @see https://docs.disco-api.com/apidocs/#update-user-consents
 * @public
 */
export class Consent extends TypedModel.generate<
  DataTypes.Consent,
  IConsentAttributes,
  IConsentRelationships
>() {}
