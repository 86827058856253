// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ContentRating } from './ContentRating';
import type { DataTypes } from '../json-api';
import type { IGeoRestriction } from './Show';
import type { Image } from './Image';
import type { Package } from './Package';
import type { Route } from './Route';
import type { TaxonomyNode } from './TaxonomyNode';
import { TypedModel } from '../json-api';

/**
 * @public
 */
export interface IChannelIdentifiers {
  analyticsId: string;
}

/**
 * @see https://docs.disco-api.com/apidocs/#channels
 * @public
 */
export interface IChannelAttributes {
  alternateId: string;
  /** Publicly known channel code. */
  channelCode?: string;
  /** Array of content ratings available for the channel.
   * @see ContentRating
   */
  contentRatings?: ContentRating[];
  /** Object containing custom fields for each product. */
  customAttributes?: {};
  /** Channel description. */
  description?: string;
  /** Array of geo-restrictions.
   * @see IGeoRestriction
   */
  geoRestrictions: IGeoRestriction;
  /** Whether the channel contains a livestream. */
  hasLiveStream: boolean;
  /** Object containing unique channel identifiers.
   * @see IChannelIdentifiers
   */
  identifiers?: IChannelIdentifiers;
  /** A long description of the channel. */
  longDescription?: string;
  /** Channel name. */
  name: string;
  /** Array of available  */
  packages: string[];
}

/**
 * @public
 */
export interface IChannelRelationships {
  contentPackages?: Package[];
  images?: Image[];
  routes?: Route[];
  txChannelTags?: TaxonomyNode[];
}

/**
 * @public
 * The channel BoltModel combining the attributes and relationships
 */
export class Channel extends TypedModel.generate<
  DataTypes.Channel,
  IChannelAttributes,
  IChannelRelationships
>() {}
