// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @public
 */
export interface IRegion {
  /** City name */
  cityName: string;
  /** Country code */
  countryCode: string;
  /** Region code */
  regionCode: string;
}
/**
 * @public
 */
export interface ITermKinds {
  /** Array of term kinds */
  termKinds: string[];
}

/**
 * @public
 */
export interface ILegalRegion {
  /** Meta of term kinds */
  metadata: ITermKinds;
  /** User's region */
  region: IRegion;
}

/**
 * @public
 */
export class LegalRegion extends TypedModel.generate<DataTypes.LegalRegion, ILegalRegion>() {}
