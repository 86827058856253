// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#users-api
 * @public
 */
export interface IUserAttributes {
  /** Returns `true` if the user is not logged in. */
  anonymous: boolean;
  /** Array of values containing all authentication providers the user is connected to. */
  authProviders?: string[];
  /** Used for A/B testing. Denotes which bucket the user is part of. */
  bucket: 'A' | 'B';
  /** Language tags that can be used as the client's display language.
   * Sorted in the same priority order as `derivedLanguageTags`. */
  clientTranslationLanguageTags?: string[];
  /** Whether the user is currently located in the EU. */
  currentlyLocatedInEU?: boolean;
  /** Country code of sovereign territory for current location, if applicable. */
  currentLocationSovereignTerritory?: string;
  /** Country code of user's current location, based on IP address. */
  currentLocationTerritory?: string;
  /** Whether the user has been deleted. */
  deleted?: boolean;
  /** Language tags ordered in priority order based on the user's profile language preferences.
   *
   * Regard as informational and shouldn't be used by clients. */
  derivedLanguageTags?: string[];
  /** Current status of email validation for the user. */
  emailValidationStatus?: 'NOT_VALIDATED' | 'VALIDATION_EMAIL_SENT' | 'VALIDATED';
  /** @deprecated List of features available to the current user. */
  features?: string[];
  /** User's first name. */
  firstName?: string;
  /** User's last logged-in time (ISO-8601 format). */
  lastLoginTime?: string;
  /** User's last name. */
  lastName?: string;
  /** HBO Max migrated users status */
  migrationStatus?: 'MIGRATED' | 'MIGRATED_WELCOMED';
  /** @deprecated and will be removed in the future.  */
  newsletterPreference: 'NOT_YET_DECIDED' | 'SUBSCRIBED' | 'UNSUBSCRIBED';
  /** @deprecated List of packages assigned to the user. */
  packages?: string[];
  /** @deprecated List of products the user has subscribed to. */
  products?: string[];
  /** Sonic Realm (e.g. `dplay`) */
  realm: string;
  /** Determined using client IP if there are no geo overrides. */
  region?: string;
  /** Roles assigned to the user. */
  roles?: string[];
  /** Auth provider used for authentication when creating the current token. */
  selectedAuthProvider?: string;
  /** Profile ID selected by this particular token. */
  selectedProfileId: string;
  /** Number of tokens currently associated with the user. */
  tokensCount?: number;
  toolboxIDP?: string;
  /** User's username. */
  username?: string;
  /** Phone number used to log in with OTP (one-time password). */
  validatedPhoneNumber?: string;
  /** Verified country code. */
  verifiedHomeTerritory?: string;
}

/**
 * @see https://docs.disco-api.com/apidocs/#users-api
 * @public
 */
export class User extends TypedModel.generate<DataTypes.User, IUserAttributes>() {}
