// Copyright text placeholder, Warner Bros. Discovery, Inc.

/* eslint-disable @typescript-eslint/naming-convention */
import type { IInputKeyMap, IControllerButtons } from '@wbd/beam-ctv-platform-adapters';

import { AbstractInput } from '@wbd/beam-ctv-platform-adapters';
import { ButtonCircle, ButtonSquare, ButtonTriangle } from '../assets';
import type { ILocalizationService } from '@wbd/beam-ctv-localization';

export class PlayStationInput extends AbstractInput {
  public static getKeyMapAsync(): Promise<IInputKeyMap> {
    return new Promise((resolve) => {
      resolve({
        8: 'Back', // Circle Cancel / Backspace
        13: 'Enter', //Cross TertiaryAction  / Enter key
        27: 'Exit', // Escape
        32: 'SecondaryAction', // Square - shortcut to longpress AND backspace on search keyboard
        37: 'Left', // ArrowLeft
        38: 'Up', // ArrowUp
        39: 'Right', // ArrowRight
        40: 'Down', // ArrowDown
        47: 'CC', // Subtitle (BD Remote & CEC only)
        48: '0',
        49: '1',
        50: '2',
        51: '3',
        52: '4',
        53: '5',
        54: '6',
        55: '7',
        56: '8',
        57: '9',
        70: 'Forward', // f key
        80: 'Play', // p key
        82: 'Rewind', // q key
        96: '0', // (BD Remote & CEC only)
        97: '1',
        98: '2',
        99: '3',
        100: '4',
        101: '5',
        102: '6',
        103: '7',
        104: '8',
        105: '9',
        116: 'SkipBackward', // L1 Skip 15 seconds backwards
        117: 'SkipForward', // R1 Skip 15 seconds forwards
        118: 'Rewind', // L2 Rewind
        119: 'Forward', // R2 Fast forward
        112: 'TertiaryAction', // Triangle launch search from anywhere in the app
        122: 'SkipBackward', //Prev (BD Remote & CEC only)		Previous video
        123: 'SkipForward', // Next (BD Remote & CEC only)
        126: 'Forward', // Scan forward (BD Remote & CEC only)
        127: 'Rewind', //Scan rewind (BD Remote & CEC only)
        128: 'PlayPause', // Play (BD Remote & CEC only)
        129: 'Pause', // Stop the video (BD Remote & CEC only)
        130: 'Pause', // Pause the video (BD Remote & CEC only)
        132: 'Red' //Red button (BD Remote & CEC only)
      });
    });
  }

  /**
   * PS4 buttons
   * @returns IControllerButtonImages
   */
  public static getControllerButtons(translation: ILocalizationService): IControllerButtons | undefined {
    return {
      left: {
        image: ButtonSquare,
        label: translation.translate('Console.Button.PlayStation.Left')
      },
      top: {
        image: ButtonTriangle,
        label: translation.translate('Console.Button.PlayStation.Top')
      },
      right: {
        image: ButtonCircle,
        label: translation.translate('Console.Button.PlayStation.Right')
      }
    };
  }
}
