// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { Article } from './Article';
import type { Avatar } from './Avatar';
import type { Channel } from './Channel';
import type { Collection } from './Collection';
import type { DataTypes } from '../json-api';
import type { Image } from './Image';
import type { Link } from './Link';
import type { Show } from './Show';
import type { TaxonomyNode } from './TaxonomyNode';
import { TypedModel } from '../json-api';
import type { Video } from './Video';

/**
 * @public
 * Possible Target types.
 */
export type Target = Article | Avatar | Channel | Collection | Image | Link | Show | TaxonomyNode | Video;

/**
 * @public
 */
export interface ICollectionItemAttributes {
  /** Override string used for Hero items */
  description?: string;
  /** Whether to hide this CollectionItem. */
  hidden?: boolean;
  /** Hints associated with this CollectionItem. */
  hints?: string[];
}

/**
 * @public
 */
export interface ICollectionItemRelationships {
  /** Article associated with this CollectionItem. */
  article?: Article;
  /** Avatar associated with this CollectionItem. */
  avatar?: Avatar;
  /** Channel associated with this CollectionItem. */
  channel?: Channel;
  /** Collection associated with this CollectionItem. */
  collection?: Collection;
  /** Image associated with this CollectionItem. */
  image?: Image;
  /** Link associated with this CollectionItem. */
  link?: Link;
  /** Show associated with this CollectionItem. */
  show?: Show;
  /** Video associated with this CollectionItem. */
  video?: Video;
  /** TaxonomyNode associated with this CollectionItem. */
  taxonomyNode?: TaxonomyNode;
  /** route */
  route?: string;
}

/**
 * @public
 */
export class CollectionItem extends TypedModel.generate<
  DataTypes.CollectionItem,
  ICollectionItemAttributes,
  ICollectionItemRelationships
>() {
  /**
   * Can be one of the following types:
   * article, avatar, collection, image, link, show, taxonomyNode, video
   */
  public get target(): Target | undefined {
    const target = Object.keys(this.relationships).find((value) =>
      Boolean(this.relationships[value as keyof ICollectionItemRelationships])
    );
    return target ? (this[target] as Target) : undefined;
  }
}
