// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { Badge } from './Badge';
import type { Channel } from './Channel';
import type { ContentDescriptor } from './ContentDescriptors';
import type { ContentRating } from './ContentRating';
import type { DataTypes } from '../json-api';
import type { Genre } from './Genre';
import type { Image } from './Image';
import type { Package } from './Package';
import type { Route } from './Route';
import { TypedModel } from '../json-api';
import { Video } from './Video';
import { TaxonomyNode } from './TaxonomyNode';
import { Season } from './Season';

/**
 * @public
 */
export interface IGeoRestriction {
  /** Array of ISO-3155 country codes. */
  countries: string[];
  /** Whether to `permit` or `deny` the country codes in `countries`. */
  mode: 'permit' | 'deny';
}

/**
 * The show attributes.
 * @see https://docs.disco-api.com/apidocs/#shows
 * @see https://wbdstreaming.atlassian.net/wiki/spaces/CD/pages/144015536/BEAM+Content+API+-+Shows#Show-attributes-and-relationships
 * @public
 */
export interface IShowAttributes {
  alternateId: string;
  analyticsId: string;
  /** Array of language codes (two letter lowercase IOS-639) for available audio tracks. */
  audioTracks?: string[];
  /** Array of badges to display alongside the show. */
  badges?: Badge[];
  /** Contains alias names of all collections in the CMS associated with the show. */
  collections: {};
  /** Array of ContentRatings associated with the show. */
  contentRatings?: ContentRating[];
  /** Any custom attributes given to the show. */
  customAttributes?: {};
  /** Show description. */
  description?: string;
  /** Number of episodes in the show. */
  episodeCount?: number;
  /** Any GeoRestrictions that apply to this show. */
  geoRestrictions?: IGeoRestriction;
  /** Has episodes that will be unpublished within the next 7 days. */
  hasExpiringEpisodes?: boolean;
  /** Has episodes that were published within the last 7 days. */
  hasNewEpisodes?: boolean;
  /** Marked as a favorite show by the current user. */
  isFavorite?: boolean;
  /** A longer description about the show. */
  longDescription?: string;
  minimumAge?: number;
  /** Show display name. */
  name: string;
  /** The date the newest episode was published. */
  newestEpisodePublishStart?: string;
  /** The date on which the show premieres or premiered. */
  premiereDate?: string;
  /** The date on which the show becomes published. */
  publishStart?: string;
  /** The date on which the show becomes unpublished. */
  publishEnd?: string;
  /** Number of seasons in the show. */
  seasonNumbers?: number[];
  /** Type of show. */
  showType?: ShowType;
  /** Available subtitles for the show, as an array of lowercase two-letter ISO-639 language codes. */
  subtitles?: string[];
  /** Show's universal ID. */
  universalId?: string;
  /** The number of videos that have this show as a parent. */
  videoCount?: number;
  /** Whether the show is exclusive to web platforms. */
  webExclusive?: boolean;
}

/**
 * @public
 * The show interface combining the attributes and relationships
 */
export interface IShowRelationships {
  alternateChannels?: Channel[];
  contentPackages?: Package[];
  images?: Image[];
  primaryChannel: Channel;
  ratingDescriptors?: ContentDescriptor[];
  ratings?: ContentRating[];
  routes?: Route[];
  shortPreviewVideo?: Video;
  trailerVideo?: Video;
  txGenres?: Genre[];
  txAvailabilityMessaging?: TaxonomyNode[];
  seasons?: Season[];
}

/**
 * @public
 */
export enum ShowType {
  MINISERIES = 'MINISERIES',
  MOVIE = 'MOVIE',
  SERIES = 'SERIES',
  STANDALONE = 'STANDALONE',
  TOPICAL = 'TOPICAL'
}

/**
 * @public
 * The show BoltModel combining the attributes and relationships
 */
export class Show extends TypedModel.generate<DataTypes.Show, IShowAttributes, IShowRelationships>() {}
