// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { DataTypes } from '../json-api';
import type { Route } from './Route';
import { TypedModel } from '../json-api';

/**
 * @see https://docs.disco-api.com/apidocs/#taxonomy-nodes
 * @public
 */
export interface ITaxonomyNodeAttributes {
  alternateId?: string;
  /** Description of the TaxonomyNode. */
  description?: string;
  /** Whether this TaxonomyNode is marked as favorite. */
  isFavorite?: boolean;
  /** TaxonomyNode kind. */
  kind?: string;
  /** Name of TaxonomyNode. */
  name?: string;
}
/**
 * @see https://docs.disco-api.com/apidocs/#taxonomy-nodes
 * @public
 */
export interface ITaxonomyNodeRelationships {
  /** Array of routes associated with this TaxonomyNode. */
  routes?: Route[];
}

/**
 * @public
 */
export class TaxonomyNode extends TypedModel.generate<
  DataTypes.TaxonomyNode,
  ITaxonomyNodeAttributes,
  ITaxonomyNodeRelationships
>() {}
